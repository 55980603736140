import { BREAKPOINTS } from '../utils/constants/breakpoints';
import { DefaultTheme } from 'styled-components';

export const theme = {
    colors: {
        pink: '#AB2F85',
        background: '#313542',
        border: '#201F2A',
        white: '#ffffff',
        halfBlurredWhite: 'rgba(255, 255, 255, 0.5)',
        secondaryWhite: '#f2f2f4',
        darken: '#0c1638', //"#0F0E19", #fefefe
        red: '#E33353',
        darkGray: '#838288',
        inputBorder: '#3B4167',
    },
    typography: {
        h1: '2.8125rem', // 45px
        h2: '2.1875rem', // 35px
        h3: '1.5625rem', // 25px
        h4: '1.125rem', // 18px
        h5: '1rem', // 16px
        h6: '0.75rem', // 12px
        textNormal: '1rem', // 16px
        textSmall: '0.875rem', // 14px
        fs1125: '1.125rem', // 18px
        fs1375: '1.375rem',
    },
    spacing: {
        '3': '1rem', //16px
    },
    breakpoints: BREAKPOINTS,
};

const Chat = {
    dark: {
        borderColor: '#3b4167',
        header: {
            background: 'linear-gradient(100.7deg,#0f0e19 5.15%,#3759a5 94.83%)',
        },
        input: {
            background: '#0F0E19',
            placeholder: 'rgba(255,255,255,0.6)',
            opacity: 0.5,
        },
        closeButton: {
            background: 'rgba(255,255,255,0.2)',
            invert: 1,
        },
        searchButton: {
            stroke: '#F2F2F4',
            fill: 'none',
        },
        channel: {
            background: 'rgb(7, 6, 18)',
            backgroundChatChannel: '#fefefe',
            backgroundItem: '#3759A5',
            headerText: '#fff',
            color: '#fff',
            colorTime: '#fefefe',
            status: 'rgb(255 255 255 / 60%)',
            statusActive: 'rgb(255 255 255 / 60%)',
        },
        chatBox: {
            owner: {
                background: '#3759A5',
                color: '#FFFFFF',
            },
            user: {
                background: '#3B4167',
                color: '#FFFFFF',
            },
        },
    },
};

const Profile = {
    dark: {
        main: {
            link: '#1890ff',
        },
        activity: {
            background: '#0f0e19',
        },
        tab: {
            background: '#FFFFFF',
        },
        coinDescription: {
            color: 'rgb(242 242 244 / 70%)',
        },
        table: {
            background: '#fefefe',
            itemBg: '#0F0E19',
            buttonBg: 'transparent',
            colorHead: 'rgba(255, 255, 255, 0.5)',
        },
        image: {
            background: '#0F0E19',
            borderColor: '#3b4167',
        },
        referral: {
            currency: '#FFCE64',
            color: '#fff',
            tableTitleColor: 'rbga(255, 255, 255, 0.5)',
            background: '#0f0e19',

            walletAddressColor: 'rgba(255, 255, 255, 0.5)',
            shareAction: {
                border: 'rgba(255, 255, 255, 0.25)',
            },
        },
    },
};

export const DarkTheme: DefaultTheme = {
    ...theme,
    textOrigin: {
        color: 'rgba(255, 255, 255, 1)',
    },
    text: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    textSmall: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    title: {
        color: '#fff',
    },
    icon: {
        color: '#fff',
    },
    container: {
        background: '#fefefe',
    },
    section: {
        background: '#fefefe',
    },
    loading: {
        background: '#fefefe',
        spinner: 'conic-gradient(rgb(242 242 244 / 8%),#f2f2f4)',
    },
    chatBox: {
        background: '#000',
        showMask: true,
        border: '#3B4167',
        chatBackground: '#fefefe',
    },
    homepage: {
        tabTextColor: 'rgba(255, 255, 255, 0.5)',
        collapseIconColor: '#838288',
        collapseButtonBackgroundColor: '#0f0e1a',
        socials: {
            background: '#fefefe',
            gradient: 'url(/assets/images/home-mask.webp)',
            borderIconColor: '#0f0e19',
            iconColor: '#fff',
        },
    },
    settings: {
        background: '#fefefe',
        color: '#fff',
        input: {
            background: '#0f0e19',
            color: '#fff',
            border: '#3b4167',
        },
        socialIcon: '#FFFFFF',
        placeholderColor: 'rgba(255, 255, 255, 0.5)',
    },
    sider: {
        background: '#0f0e19;',
        borderColor: '#FFFFFF',
        logoText: '#FFF',
        menu: {
            border: '#0f0e19',
            itemText: '#fff',
            itemBorder: '#0f0e19',
            itemOpacity: '0.5',
            itemIconBg: '#22212c',
            itemIconStroke: '',
            itemIconFill: '#fff',
            switchBtnText: '#fff',
            solanaIconText: '#fff',
        },
    },
    connectWallet: {
        title: '#F2F2F4',
        text: 'rgba(242, 242, 244, 0.5)',
    },
    authForm: {
        helpTitle: 'rgba(255, 255, 255, 0.6)',
        helpText: 'rgba(255, 255, 255, 0.6)',
        helpIcon: '#9f9fa3',
        inputText: '#fff',
        inputLabel: 'rgba(255, 255, 255, 0.6)',
        inputBackground: '#0f0e19',
    },
    searchpage: {
        account: {
            background: '#fefefe',
            border: 'no##0f0e19',
        },
    },
    nftItem: {
        card: {
            background: '#fefefe',
            border: '#201F2A',
        },
        titleColor: '#fff',
    },
    header: {
        collapsedSidebarBtn: 'none',
        connectButton: {
            background: '#222534',
            color: '#fff',
        },
        background: '#fefefe',
        border: '#0f0e19',
        search: {
            color: '#fff',
            background: '#fefefe',
            stroke: '#f2f2f4',
            placeholder: '#f2f2f4',
        },
        searchResult: {
            background: '#fefefe',
            hover: '#272634',
            border: '0.0625rem solid #201F2A',
            hintColor: '#838288',
            titleColor: '#838288',
            itemColor: '#F2F2F4',
            itemSecondColor: '#F2F2F4',
        },
        button: {
            border: '0.0625rem solid rgba(255, 255, 255, 0.1)',
            color: '#f2f2f4',
            background: 'transparent',
        },
        lang: {
            background: '#fefefe',
            border: theme.colors.border,
            text: '#fff',
            hover: '#272634',
            activeIcon: '#f2f2f4',
        },
        noti: {
            background: '#fefefe',
            hover: '#272634',
            itemGroupTitle: 'rgb(255 255 255 / 60%)',
            itemIcon: '#fff',
            markRead: '#13121e',
        },
    },
    button: {
        outline: {
            default: {
                color: '#fff',
                background: 'transparent',
            },
            hover: {
                color: '#fff',
                // background: '#AB2F85',
                // border: '#AB2F85',
            },
            disabled: {
                color: '#fff',
                background: 'rgba(153, 158, 175, 1)',
                border: '0.0625rem solid #999eaf',
            },
        },
        primary: {
            default: {
                background: '#222534',
            },
        },
    },
    marketPlace: {
        forSaleText: '#fff',
        menuBg: {
            color: 'rgba(0, 0, 0, 0.85)',
        },
        pagination: {
            bgColor: '#fff',
            color: 'white',
            light: 'rgba(255, 255, 255, 0.4)',
            colorActive: '#000',
            defaultBg: 'transparent',
            filter: 'initial',
        },
        filter: {
            color: '#838288',
            titleColor: '#838288',
            filterBg: ' #222534',
            placeholder: 'rgba(255, 255, 255, 0.5)',
            tagInputBg: '#fefefe',
            allFilterBg: '#fefefe',
            gridIcon: '#fff',
        },
    },

    marketPlaceDetail: {
        viewBtn: {
            color: '#fff',
            borderColor: '#ffff',
            grayBg: '#0f0e19',
        },
        menuBg: {
            color: '#0f0e19',
            borderColor: '#201F2A',
            grayBg: '#0F0E19',
        },
        tag: {
            bgColor: '#262535',
        },
        modal: {
            color: '#fff',
            fee: {
                background: '#0f0e19',
                border: '#201f2a',
                number: '#fff',
            },
        },
    },
    select: {
        default: {
            background: '#fefefe',
            color: '#fff',
            border: '0.0625rem solid #3b4167',
        },
        options: {
            background: '#fefefe',
        },
        hover: {
            background: '#272634',
        },
        activeIcon: '#f2f2f4',
    },
    profile: {
        ...Profile.dark,
    },
    chat: {
        ...Chat.dark,
    },
    forgotPassword: {
        color: '#fff',
        colorDes: 'rgb(255 255 255 / 0.6)',
        input: {
            color: '#fff',
            background: '#0f0e19',
        },
    },
    scrollbar: {
        background: '#fefefe',
        color: '#201f2a',
    },
    profileSetting: {
        descriptionText: 'rgb(242 242 244 / 70%)',
    },
    sellItem: {
        pageTitle: {
            background: '#fefefe',
            border: 'none',
        },
        fee: {
            background: '#0f0e19',
            number: '#fff',
        },
    },
    creator: {
        icon: '#fff',
        halfWhite: 'rgb(255 255 255 / 50%)',
    },
    mint: {
        step: {
            color: '#fff',
            border: '#201F2A',
            background: '#0F0E19',
        },
        checkbox: {
            border: 'rgb(255 255 255 / 40%)',
        },
        box: {
            background: '#0F0E19',
            border: '#201F2A',
            color: '#fff',
            placeholder: 'rgba(255, 255, 255, 0.5)',
            inputBorder: '#3B4167',
            buttonBorder: '#3B4167',
            buttonColor: '#fff',
        },
        tags: {
            border: '#201F2A',
            background: '#53525A',
            color: '#fff',
        },
        modal: {
            background: '#0f0e1a',
        },
        hoverBg: '#171626',
    },
    admin: {
        home: {
            cardBorder: '#201F2A',
            optionBg: '#0F0E19',
        },
        event: {
            background: '#fefefe',
            icon: '#fff',
            chartBtnActive: '#fff',
        },
    },
    modal: {
        background: '#fefefe',
    },
    support: {
        select: '#0f0e19',
        question: '#fefefe',
        fileIcon: '#9E9CAC',
    },
};
