import { Form } from "antd";
import styled from "styled-components";

export const AntdFormItem = styled(Form.Item)`
  margin-top: 4px;
  margin-bottom: 0px;
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0px !important;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-requirzed-mark-optional)::before {
    display: none !important;
    &:after {
      content: "";
    }
  }
  .ant-form-item-control {
    min-height: 80px;
    margin-top: 6px;
  }
  .ant-form-item-label > label::after {
    content: "" !important;
  }

  label {
    font-weight: 400;
    color: #ffffff;
    font-size: 1rem;
    line-height: 1rem;
    padding-bottom: 0.5rem;
    height: auto;
  }

  input::placeholder {
    font-size: 1rem;
  }

  .ant-form-item-control-input-content {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #3b4167, #3b4167), #0f0e1a;
      border-radius: 0.375rem;
    }
  }

  .ant-form-item-explain {
    height: unset;
    min-height: unset;
  }

  .ant-form-item-explain-success {
    padding-top: 0.0625rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #52c41a;
  }

  .ant-form-item-explain-error,
  .ant-form-item-explain-validating {
    padding-top: 0.0625rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    label {
      font-size: 0.875rem;
      line-height: 0.875rem;
      margin-bottom: 0;
    }
  }
`;
