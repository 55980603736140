import Skeleton from "components/base/skeleton";
import { FC, memo } from "react";
import ITextareaProps from "./textarea.props";
import { AntdTextarea } from "./textarea.style";

const TextArea: FC<ITextareaProps> = ({ skeleton, loading, ...props }) => {
  return loading ? <Skeleton {...skeleton} /> : <AntdTextarea {...props} />;
};

export default memo(TextArea);
