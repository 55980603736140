import axios from 'axios';
import { useDAppProvider } from 'context';
import { BUSD, CMCG } from 'contract-address';
import { usePancakeRouterContractGetAmountsOut } from 'contracts/PancakeSwap/PancakeRouter/hooks';
// import { BigNumber } from "ethers";
import { BigNumber } from '@ethersproject/bignumber';
import { parseEther, formatEther } from 'ethers/lib/utils';
// import { formatEther } from 'moralis/node_modules/@ethersproject/units';
import React, { useContext, useEffect, useState } from 'react';

export const COINGECKO_POOL_INTERVAL = 1000 * 60; // 60 sec
export const COINGECKO_API = 'https://api.coingecko.com/api/v3/';
export const COINGECKO_COIN_PRICE_API = `${COINGECKO_API}simple/price`;

interface ITokenInfo {
    last_updated_at?: number;
    usd: number;
    usd_24h_change?: number;
    usd_24h_vol?: number;
    usd_market_cap?: number;
}
export interface CoingeckoContextState {
    bnbToken: ITokenInfo;
    cmcgToken: ITokenInfo;
    busdToken: ITokenInfo;
    bnbConverter: (amount: number) => number;
    cmcgConverter: (amount: number) => number;
    busdConverter: (amount: number) => number;
    fetch: () => void;
}

const instance = axios.create({
    baseURL: COINGECKO_API,
});

// ("https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true");

export const getTokenInfo = async (coingeckoTokenName: string[]) => {
    const query = instance.get<{
        [K in typeof coingeckoTokenName[number]]: ITokenInfo | undefined;
    }>('simple/price', {
        method: 'GET',
        params: {
            ids: coingeckoTokenName.join(','),
            vs_currencies: 'usd',
            include_market_cap: true,
            include_24hr_vol: true,
            include_24hr_change: true,
            include_last_updated_at: true,
        },
    });
    const resp = await query;

    return resp.data;
};

export const bnbToUSD = async () => {
    const tokenName = 'binancecoin' as const;
    return getTokenInfo([tokenName]).then((data) => data[tokenName]);
};

export const cmcgToUSD = async () => {
    const tokenName = 'coingeko cmcg token id' as const;
    return getTokenInfo([tokenName]).then((data) => data[tokenName]);
};

export const busdToUSD = async () => {
    const tokenName = 'binance-usd' as const;
    return getTokenInfo([tokenName]).then((data) => data[tokenName]);
};

const CoingeckoContext = React.createContext<CoingeckoContextState>({
    bnbToken: { usd: 0 },
    cmcgToken: { usd: 0 },
    busdToken: { usd: 0 },
    bnbConverter: () => {
        throw new Error('"bnbConverter" must be inside CoingeckoProvider');
    },
    cmcgConverter: () => {
        throw new Error('"cmcgConverter" must be inside CoingeckoProvider');
    },
    busdConverter: () => {
        throw new Error('"busdConverter" must be inside CoingeckoProvider');
    },
    fetch: () => {},
});

interface CoingeckoProviderProps {
    children?: React.ReactNode;
}

export const CoingeckoProvider: React.FC<CoingeckoProviderProps> = ({ children }) => {
    const { provider } = useDAppProvider();
    const [bnbToken, setBNBToken] = useState<ITokenInfo>({ usd: 0 });
    const [cmcgToken, setCMCGToken] = useState<ITokenInfo>({ usd: 0 });
    const [busdToken, setBUSDToken] = useState<ITokenInfo>({ usd: 0 });
    const cmcgFetcher = usePancakeRouterContractGetAmountsOut(parseEther('1'), [CMCG, BUSD], { saveState: false });

    const queryTokenInfo = async () => {
        const bnbInfoPromise = bnbToUSD();

        const cmcgInfoPromise = cmcgFetcher.fetch();
        const busdInfoPromise = busdToUSD();

        const bnbInfo = await bnbInfoPromise;
        const cmcgPrice = await cmcgInfoPromise;
        const busdInfo = await busdInfoPromise;

        const a = (cmcgPrice ?? [])[1] ?? BigNumber.from('0');

        const cmcgInfo: ITokenInfo = {
            usd: parseFloat(formatEther(a)),
        };
        if (bnbInfo) setBNBToken(bnbInfo);
        if (cmcgInfo) setCMCGToken(cmcgInfo);
        if (busdInfo) setBUSDToken(busdInfo);
    };

    useEffect(() => {
        const intervalId = window.setInterval(async () => {
            queryTokenInfo();
        }, COINGECKO_POOL_INTERVAL);

        queryTokenInfo();
        return () => {
            clearTimeout(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBNBToken, setCMCGToken, provider]);

    const bnbConverter: CoingeckoContextState['bnbConverter'] = (amount) => {
        return bnbToken.usd * amount;
    };

    const cmcgConverter: CoingeckoContextState['cmcgConverter'] = (amount) => {
        return cmcgToken.usd * amount;
    };

    const busdConverter: CoingeckoContextState['busdConverter'] = (amount) => {
        return busdToken.usd * amount;
    };

    return (
        <CoingeckoContext.Provider
            value={{
                bnbToken,
                cmcgToken,
                busdToken,
                bnbConverter,
                cmcgConverter,
                busdConverter,
                fetch: queryTokenInfo,
            }}
        >
            {children}
        </CoingeckoContext.Provider>
    );
};

export const useCoingecko = () => {
    const context = useContext(CoingeckoContext);
    if (!context) throw new Error('useCoingecko hook must be inside a CoingeckoProvider.');
    return context;
};

export const usePrices = () => {
    const bnbPrice = 1;
    const cmcgPrice = 2;
    return {
        sol: bnbPrice,
        cmcg: cmcgPrice,
    };
};

export const getCMCGMarketStat = async (cgTokenName?: string) => {
    const cg_spl_token_id = cgTokenName
        ? cgTokenName.toLowerCase().split(' ').join('-')
        : process.env.REACT_APP_SPL_TOKEN_ID || '';
    const url = `${COINGECKO_API}/coins/markets?vs_currency=usd&ids=${cg_spl_token_id}&order=market_cap_desc&per_page=100&page=1&sparkline=false`;
    try {
        const resp = await window.fetch(url).then((resp) => resp.json());
        return resp;
    } catch (error) {
        return;
    }
};
