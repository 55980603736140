import styled from 'styled-components';
import { Text } from 'components/base';
import CTextThin from 'components/base/textThin';

export const Container = styled.button<{
    collapsed?: boolean;
    w?: string | number;
    h?: string | number;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.125rem 1.5625rem;
    border-radius: 0.625rem;
    width: calc(100% - 2.5rem);
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, #fefefe 44.79%, #0a1227 100%);
    border: 0.0625rem solid rgba(255, 255, 255, 0.1);
    cursor: url(/assets/images/cursor-white.svg), pointer;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 6.25rem 3.125rem;
        width: calc(100% - 5rem);
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
        padding: 9.4375rem 7.875rem;
    }
`;

export const WalletTextContainer = styled(Text)`
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: #fefefe;
    z-index: 10;
    display: flex;
    align-items: center;
    box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
    background: #ffffff;
    border: 0.0625rem solid #d0d5dd;
    border-radius: 0.5rem;
    padding: 1rem 1.4375rem;
    cursor: url(/assets/images/cursor-white.svg), pointer;
`;

export const ConnectTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.9375rem;

    svg {
        width: 1.8125rem;
        height: 1.875rem;
    }

    span:nth-child(2) {
        font-size: 1.375rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.title.color};
        margin-top: 1.9375rem;
        margin-bottom: 0.3125rem;
    }

    span:last-child {
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.text.color};
    }
`;

export const Description = styled(CTextThin)`
    margin: 0 auto;
`;

//   // width: ${({ collapsed, w }) =>
//   collapsed
//   ? "3.4375rem"
//   : w
//   ? typeof w === "string"
//     ? w
//     : w + "rem"
//   : "10.625rem"};
// // height: ${({ collapsed, h }) =>
// collapsed
//   ? "3.75rem"
//   : h
//   ? typeof h === "string"
//     ? h
//     : h + "rem"
//   : "8.375rem"};
