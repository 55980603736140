export const Check = () => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3984 0.976562C16.8555 1.39844 16.8555 2.13672 16.3984 2.55859L7.39844 11.5586C6.97656 12.0156 6.23828 12.0156 5.81641 11.5586L1.31641 7.05859C0.859375 6.63672 0.859375 5.89844 1.31641 5.47656C1.73828 5.01953 2.47656 5.01953 2.89844 5.47656L6.58984 9.16797L14.8164 0.976562C15.2383 0.519531 15.9766 0.519531 16.3984 0.976562Z"
        fill="white"
      />
    </svg>
  );
};

export const FlowerCheck = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0 }}
    >
      <path
        d="M7 0.75C7.98438 0.75 8.85938 1.32422 9.29688 2.17188C10.1992 1.87109 11.2109 2.08984 11.9492 2.80078C12.6602 3.51172 12.8516 4.55078 12.5781 5.45312C13.4258 5.89062 14 6.76562 14 7.75C14 8.76172 13.4258 9.63672 12.5781 10.0742C12.8789 10.9766 12.6602 11.9883 11.9492 12.6992C11.2109 13.4102 10.1992 13.6289 9.29688 13.3555C8.85938 14.2031 7.98438 14.75 7 14.75C5.98828 14.75 5.11328 14.2031 4.67578 13.3555C3.77344 13.6289 2.76172 13.4102 2.02344 12.6992C1.3125 11.9883 1.12109 10.9766 1.39453 10.0742C0.546875 9.63672 0 8.76172 0 7.75C0 6.76562 0.546875 5.89062 1.39453 5.45312C1.09375 4.55078 1.3125 3.51172 2.02344 2.80078C2.76172 2.08984 3.77344 1.87109 4.67578 2.17188C5.11328 1.32422 5.98828 0.75 7 0.75ZM9.625 6.90234C9.89844 6.65625 9.89844 6.24609 9.625 5.97266C9.37891 5.72656 8.96875 5.72656 8.72266 5.97266L6.125 8.59766L5.03125 7.50391C4.78516 7.25781 4.375 7.25781 4.12891 7.50391C3.85547 7.77734 3.85547 8.1875 4.12891 8.43359L5.66016 9.96484C5.90625 10.2383 6.31641 10.2383 6.5625 9.96484L9.625 6.90234Z"
        fill="#4D61CA"
      />
    </svg>
  );
};
