import styled from 'styled-components';
import { Title as CTitle, ButtonOutline as CButtonOutline, TextThin } from 'components/base';
import CModal from 'components/base/newModal';

export const PageWapper = styled.div`
    position: fixed;
    top: 5.6875rem;
    height: calc(100vh - 5.6875rem);
    z-index: 10;
    left: 0;
    width: 100%;
    background: ${({ theme }) => theme.container.background};
    overflow-y: auto;
    scrollbar-width: none;
    padding: 1.875rem 1rem;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
    }
`;

export const ModalCustom = styled(CModal)`
    .ant-modal-body {
        padding: 3.125rem 3.3125rem;
    }
`;

export const ModalsWrapper = styled.div`
    .ant-modal-root {
        display: none;

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
            display: block;
        }
    }

    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.75);
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        ${PageWapper} {
            display: none;
        }
    }
`;

export const Button = styled(CButtonOutline)``;

export const WithEmailBtn = styled(Button)`
    margin-top: 0.9375rem;
    width: 100%;
    padding-block: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.0625rem solid #d0d5dd;
    box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
    background: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #fefefe;
`;

export const WalletContainer = styled.div<{ user?: any }>`
    max-width: 31.25rem;
    margin: 0 auto;
    background: #fefefe;
    border-radius: 0.375rem;
    padding: 3.125rem 1.375rem;

    .connected-user {
        padding: 0.9375rem 0;
        padding-inline: 0.625rem;
        background: ${({ theme }) => theme.container.background};
        border: 1px solid ${({ theme }) => theme.homepage.tabTextColor};
        border-radius: 0.5rem;
        margin-bottom: 2.375rem;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        img,
        svg {
            width: 2.75rem;
            height: 2.75rem;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 0.4063rem;
        }

        .user-info {
            display: flex;
            flex-direction: column;

            span:first-child {
                word-break: break-word;
                line-height: 0.875rem;
                color: ${({ theme }) => theme.title.color};
            }
        }

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
            justify-content: center;
            // padding-left: 2.5rem;
            // padding-right: 0;
        }
    }

    & > h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 1.625rem;
        line-height: 1.625rem;
        text-align: center;
        margin-bottom: ${({ user }) => (user ? '2.6875rem' : '0.8125rem')};
        color: ${({ theme }) => theme.title.color};
    }

    & > button:not(${WithEmailBtn}) {
        width: 100%;
        height: 3.25rem;
        padding: 0.625rem 1rem;
        display: flex;
        align-items: center;
        border: 0.0625rem solid ${({ theme }) => theme.homepage.tabTextColor};
        box-shadow: 0px 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
        justify-content: space-between;
        border-radius: 0.5rem;
        background: transparent;
        margin-bottom: 0.875rem;

        div {
            display: flex;
            align-items: center;
        }

        span {
            line-height: 1.375rem;
            font-weight: 500;
            color: ${({ theme }) => theme.textOrigin.color};
        }

        img {
            width: 1.925rem;
            height: 1.925rem;
            object-fit: cover;
            margin-right: 0.8125rem;
        }
    }

    .get-help {
        margin-top: 2.8125rem;
        border-top: 1px solid #201f2a;
        padding-top: 2.3125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.625rem;
        a {
            font-size: 0.9rem;
            text-align: center;
            color: rgba(256, 256, 256, 0.6);
        }
        &-text {
            display: flex;
            flex-direction: column;

            span {
                font-family: 'Sofia-Pro';
                font-style: normal;
                font-weight: 400;
                text-align: center;
            }

            span:first-child {
                font-size: 1rem;
                line-height: 1;
                color: ${({ theme }) => theme.textOrigin.color};
            }

            span:last-child {
                font-size: 14px;
                line-height: 22px;
                color: ${({ theme }) => theme.text.color};

                &:hover {
                    cursor: url(/assets/images/cursor-white.svg), pointer;
                }
            }
        }

        button {
            padding: 0.625rem 2.1875rem;
            border: 0.0625rem solid #686a7d;
            border-radius: 0.5rem;
            background: transparent;
            cursor: url(/assets/images/cursor-white.svg), pointer;
            color: ${({ theme }) => theme.textOrigin.color};
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        background: transparent;
        border-radius: 0.375rem;
        padding: 0;
    }
`;

export const CancelModalTitle = styled(CTitle)`
    font-size: 1.375rem;
    line-height: 2rem;
    text-align: center;
`;

export const CancelModalIcon = styled.div`
    margin: 0 auto;
    margin-bottom: 1.5rem;
    text-align: center;

    svg {
        width: 2.125rem;
        height: 2.125rem;
    }
`;

export const CancelModalButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1.25rem;
`;

export const CancelButton = styled(Button)`
    background: #912570;
    border: none;
    border-radius: 1.875rem;
    height: 3.625rem;
    padding: 1.25rem 1.5rem;
`;

export const WalletIconWrapper = styled.div<{ bg?: string }>`
    display: inline-block !important;
    margin-right: 1.125rem;
`;

export const CollapseWrapper = styled.div`
    .ant-collapse-ghost {
        border-radius: 0.5rem;
    }

    .ant-collapse > .ant-collapse-item:last-child,
    .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
        border-radius: 0.5rem;
    }

    .ant-collapse-item {
        display: flex;
        flex-direction: column;
    }

    .ant-collapse-header {
        padding: 1.3125rem 0;
        padding-left: 3.9375rem;
        border: 0.0625rem solid ${({ theme }) => theme.homepage.tabTextColor};
        box-shadow: 0px 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
        border-radius: 0.5rem;
        height: 3.25rem;
        display: flex;
        align-items: center !important;
        justify-content: center;
        cursor: url(/assets/images/cursor-white.svg), pointer !important;
        order: 2;

        span {
            color: ${({ theme }) => theme.textOrigin.color};
        }
    }

    .ant-collapse-expand-icon {
        position: relative;

        svg {
            path {
                fill: ${({ theme }) => theme.textOrigin.color};
            }
        }
    }

    .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        right: 0;
        left: 7rem;
        top: 50%;
        transform: translateY(-26%);
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding-block: 0;
    }

    .ant-collapse-content-box {
        padding: 0.75rem 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;

        button {
            width: 100%;
            height: 3.25rem;
            padding: 0.625rem 1.25rem;
            display: flex;
            align-items: center;
            border: 0.0625rem solid ${({ theme }) => theme.homepage.tabTextColor};
            box-shadow: 0px 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
            border-radius: 0.5rem;
            background: transparent;

            div {
                display: flex;
                align-items: center;
            }

            span {
                color: ${({ theme }) => theme.textOrigin.color};
            }

            img {
                width: 1.925rem;
                height: 1.925rem;
                object-fit: cover;
                margin-right: 0.8125rem;
            }
        }

        & > button {
            margin-bottom: 0.875rem;
        }
    }
`;

export const ModalDescription = styled(TextThin)`
    max-width: 22.25rem;
    width: 100%;
    margin: 0 auto 2.6875rem;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    display: block;
`;

export const WalletGradientWrapper = styled.div`
    @media screen and (max-width: 48rem) {
        background: linear-gradient(180deg, 0.0625rem solid #a1b7f8 0%, #ffdba8 100%);
        border-radius: 0.375rem;
        padding: 0.0625rem;
        max-width: 31.25rem;
        margin: 0 auto;
        position: relative;
    }
`;
