import React, { useState, useCallback, useEffect } from "react";
// import { PlaceholderMessage, placeholderMessages } from "./PlaceholderMessage";
import { IGif } from "@giphy/js-types";
import { Gif, GifSize } from "../Gif";
import { useDebounce, usePagination, GetNextPage } from "hooks";
import { typed } from "../Masonry/Masonry";
// import { Input } from "components/base";
import { Input, Tabs } from "antd";
// import { useAppSelector } from "utils/hooks";
import {
  GifPickerContainer,
  GifPickerContent,
  GifTabs,
  GifWrapper,
} from "../GifInput/gifInput.style";
import { Text } from "components/base";
import { SearchIcon } from "components/assets/chatBox.icon";
import { mouseEnter, mouseLeave } from "utils";

const Masonry = typed<IGif>();

const PAGE_SIZE = 25;
const GIF_TABS = ["Trending", "HAHA", "SAD", "Love", "Reaction", "TV"];
interface GiphySearch {
  data: IGif[];
  pagination: { total_count: number; count: number; offset: number };
}

// make a request to the giphy search api
const search = async (query: string, offset: number): Promise<GiphySearch> => {
  try {
    const response = await fetch(
      `https://api.giphy.com/v1/gifs/search?api_key=${
        process.env.REACT_APP_GIPHY_API_KEY
      }&q=${encodeURIComponent(
        query
      )}&limit=${PAGE_SIZE}&offset=${offset}&rating=G&lang=en`
    );
    const results = (await response.json()) as GiphySearch;
    return results;
  } catch {
    return { data: [], pagination: { total_count: 0, count: 0, offset: 0 } };
  }
};

export type onSelectedHandler = (gif: IGif, query: string) => void;

interface GifPickerOptions {
  onSelected: onSelectedHandler;
  setVisible: (visible: boolean) => void;
}

// gif picker with infinite loading and search
const GifPicker = ({ onSelected, setVisible }: GifPickerOptions) => {
  const [query, setQuery] = useState("");
  const [gifTab, setGifTab] = useState<string>("trending");
  const debouncedQuery = useDebounce(query ? query : gifTab, 300);
  // const { theme } = useAppSelector((state) => state.theme);

  const getNextPage: GetNextPage<IGif, GiphySearch["pagination"], string> =
    useCallback(async (prev, _total, query) => {
      // bail out
      const { data: results, pagination } = await search(
        query,
        prev ? prev.offset + prev.count : 0
      );
      return {
        results,
        pagination,
        pagesRemain:
          pagination.offset + pagination.count < pagination.total_count,
      };
    }, []);
  const isEnabled = debouncedQuery.length > 0;
  const {
    results,
    containerRef: picker,
    // endRef: bottom,
    responseId: reset,
  } = usePagination(
    getNextPage,
    debouncedQuery,
    undefined,
    undefined,
    isEnabled
  );

  const curried = (gif: IGif) => {
    setVisible(false);
    onSelected(gif, query);
  };

  useEffect(() => {
    const more = document.querySelectorAll(".ant-tabs-nav-more");
    more.forEach((item) => {
      item.addEventListener("mouseenter", mouseEnter);
      item.addEventListener("mouseleave", mouseLeave);
    });

    return () => {
      more.forEach((item) => {
        item.removeEventListener("mouseenter", mouseEnter);
        item.removeEventListener("mouseleave", mouseLeave);
      });
    };
  }, []);

  return (
    <GifPickerContainer>
      <GifPickerContent ref={picker}>
        {!query ? (
          <GifWrapper>
            <GifTabs
              activeKey={gifTab}
              onChange={(key: string) => setGifTab(key)}
              defaultActiveKey={gifTab}
            >
              {GIF_TABS.map((item) => (
                <Tabs.TabPane
                  tab={
                    <Text onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                      {item}
                    </Text>
                  }
                  key={item.toLocaleLowerCase()}
                >
                  <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                    {gifTab && results && results.length > 0 && (
                      <Masonry
                        groupedItems={results || []}
                        reset={reset}
                        columns={3}
                        getSizeForItem={(i) => {
                          const item = i.images.fixed_width;
                          // workaround for https://github.com/Giphy/giphy-js/issues/126
                          return {
                            height: parseInt(item.height as unknown as string),
                            width: parseInt(item.width as unknown as string),
                          };
                        }}
                        render={(item, key) => (
                          <div
                            style={{
                              borderRadius: "strong",
                              overflow: "hidden",
                              marginTop: "1",
                              cursor:
                                "url(/assets/images/cursor-white.svg), pointer",
                            }}
                            onMouseEnter={mouseEnter}
                            onMouseLeave={mouseLeave}
                            key={key}
                          >
                            <Gif
                              gif={item}
                              size={GifSize.Preview}
                              key={key}
                              container={picker}
                              onClick={curried}
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>
                </Tabs.TabPane>
              ))}
            </GifTabs>
            <Input
              placeholder="Find gifs"
              value={query}
              onChange={(e) => {
                if (e.target.value) {
                  setQuery(e.target.value);
                } else {
                  setQuery(e.target.value);
                  setGifTab("trending");
                }
              }}
              prefix={<SearchIcon />}
            />
          </GifWrapper>
        ) : (
          <GifWrapper>
            <div
              style={{
                height: "calc(100% - 3.7875rem)",
                overflowY: "auto",
              }}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
            >
              {query && results && results.length > 0 && (
                <Masonry
                  groupedItems={results || []}
                  reset={reset}
                  columns={3}
                  getSizeForItem={(i) => {
                    const item = i.images.fixed_width;
                    // workaround for https://github.com/Giphy/giphy-js/issues/126
                    return {
                      height: parseInt(item.height as unknown as string),
                      width: parseInt(item.width as unknown as string),
                    };
                  }}
                  render={(item, key) => (
                    <div
                      style={{
                        borderRadius: "strong",
                        overflow: "hidden",
                        marginTop: "1",
                      }}
                      key={key}
                    >
                      <Gif
                        gif={item}
                        size={GifSize.Preview}
                        key={key}
                        container={picker}
                        onClick={curried}
                      />
                    </div>
                  )}
                />
              )}
            </div>
            <Input
              placeholder="Find gifs"
              value={query}
              onChange={(e) => {
                if (e.target.value) {
                  setQuery(e.target.value);
                } else {
                  setQuery(e.target.value);
                  setGifTab("trending");
                }
              }}
              prefix={<SearchIcon />}
            />
          </GifWrapper>
        )}
        {/* {!query && <PlaceholderMessage message={placeholderMessages.empty} />}
        {query && results?.length === 0 && (
          <PlaceholderMessage message={placeholderMessages.notFound} />
        )}
        <div>
          {query && results && results.length > 0 && (
            <Masonry
              groupedItems={results || []}
              reset={reset}
              columns={2}
              getSizeForItem={(i) => {
                const item = i.images.fixed_width;
                // workaround for https://github.com/Giphy/giphy-js/issues/126
                return {
                  height: parseInt(item.height as unknown as string),
                  width: parseInt(item.width as unknown as string),
                };
              }}
              render={(item, key) => (
                <div
                  style={{
                    borderRadius: "strong",
                    overflow: "hidden",
                    marginTop: "1",
                  }}
                  // borderRadius="strong"
                  // overflow="hidden"
                  // marginTop="1"
                >
                  <Gif
                    gif={item}
                    size={GifSize.Preview}
                    key={key}
                    container={picker}
                    onClick={curried}
                  />
                </div>
              )}
            />
          )}
        </div>
        <div ref={bottom} /> */}
      </GifPickerContent>
    </GifPickerContainer>
  );
};

export { GifPicker };
