import React from "react";
import { ISkeletonProps } from "./skeleton.props";
import { Container } from "./skeleton.style";

const SkeletonView: React.FC<ISkeletonProps> = (props: ISkeletonProps) => {
  const { borderRadius = 0, width = "6.25rem", ...otherProps } = props;

  return (
    <Container
      {...otherProps}
      width={width}
      style={{ borderRadius: borderRadius }}
    />
  );
};

export default React.memo(SkeletonView);
