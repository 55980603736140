import styled from "styled-components";
import { Select } from "components/base";
import { Select as ASelect } from "antd";

export const Container = styled.div`
  .ant-select-dropdown {
    background: none;
    /* animation-duration: 0s; */
  }

  .ant-select {
    width: 100%;
    height: 100%;
  }
`;

export const Option = styled(ASelect.Option)`
  background: ${({ theme }) => theme.marketPlaceDetail.menuBg.color};
  display: none;
`;

export const TagsSelect = styled(Select)`
  width: 100%;

  .ant-select-selection-placeholder {
    overflow: visible;
    color: ${({ theme }) => theme.text.color};
  }

  .ant-select-selector {
    min-height: 3.875rem;
    padding-block: 0 !important;
    border: 0.0625rem solid ${({ theme }) => theme.mint.box.inputBorder} !important;
    box-shadow: none !important;
    border-radius: 0.375rem !important;
    background: ${({ theme }) => theme.mint.box.background} !important;
    transition: none !important;
    padding-right: 2.5rem;
  }

  .ant-select-selection-overflow {
    gap: 0.5rem;
    z-index: 1000;
  }

  .ant-select-selection-overflow-item {
    height: 2.75rem;
    display: flex;
    align-items: center;
  }

  .ant-select-selection-search-input {
    height: 100%;
  }

  .ant-select-selection-search {
    color: ${({ theme }) => theme.mint.box.color};
    overflow: visible;
  }

  .ant-select-selection-item {
    height: 1.75rem;
    margin: 0;
    align-items: center;
    border: none;
    background: ${({ theme }) => theme.mint.tags.background};
    border-radius: 0.375rem;
    padding-inline: 0.5rem;
    border: 0.0625rem solid ${({ theme }) => theme.mint.tags.border};

    &-content {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.mint.tags.color};
      margin-top: -0.125rem;
      overflow: visible;
    }

    svg {
      fill: ${({ theme }) => theme.mint.tags.color};
    }
  }

  .ant-select-selection-item-remove {
    cursor: url(/assets/images/cursor-white.svg), pointer;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 0.9375rem;
  }

  &:hover {
    .ant-select-selector {
      background: ${({ theme }) => theme.mint.hoverBg} !important;
    }
  }

  &:focus {
    .ant-select-selector {
      background: ${({ theme }) => theme.mint.box.background} !important;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border: ${({ theme }) => theme.select.default.border};
  border-radius: 0.375rem;
  line-height: 1.6875rem;
  cursor: url(/assets/images/cursor.svg), pointer;
  padding: 0.375rem 2rem 0.375rem 0.625rem;

  & > span {
    display: flex;
    align-items: center;
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);

  svg {
    path {
      fill: ${({ theme }) => theme.select.default.color};
    }
  }
`;

export const DropdownMenu = styled.div`
  margin: -0.25rem 0;
  border-radius: 0.375rem;
  overflow: hidden;

  .rc-virtual-list {
    border-radius: 0.375rem;
    border: 0.0625rem solid ${({ theme }) => theme.colors.border};
    box-shadow: 0rem 0.25rem 1.5rem rgba(255, 255, 255, 0.15);
    background: ${({ theme }) => theme.select.options.background};
    overflow: hidden;
  }

  .ant-select-item {
    background: ${({ theme }) => theme.select.options.background};
    transition: 0.3s;
    font-weight: 400;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    &:hover {
      background: ${({ theme }) => theme.select.hover?.background};
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${({ theme }) => theme.title.color};

      span {
        position: relative;
        top: -0.125rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      svg {
        width: 1.3125rem;
        height: 1.3125rem;
      }

      svg:not(.none-fill) {
        path {
          fill: ${({ theme }) => theme.select.activeIcon};
        }
      }
    }
  }
`;
