import instance from './common';
import { IMarkRead, INotifyGroup, ITranslate } from 'models';
import axios, { AxiosResponse } from 'axios';

export const translateDynamicData = (body: ITranslate) => {
    return instance.post('/aws/translate', body);
};

export const uploadToS3 = ({ file, url }: { file: File; url: string }) =>
    axios.put(url, file, {
        headers: {
            'Access-control-allow-origin': '*',
        },
    });

export const getNotifications = (type: string[]): Promise<AxiosResponse<any>> => {
    return instance.get(`/noti/${encodeURIComponent(JSON.stringify(type))}`);
};

export const sendNotifyToOther = (body: INotifyGroup) => {
    return instance.post('/noti/user', body);
};

export const markRead = (body: IMarkRead) => {
    return instance.post('/noti/mark-read', body);
};

export const getPresignURL = (body: { folder: string; fileName: string }) => {
    return instance.post('/aws/presignURL', body);
};
