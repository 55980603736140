export const Plus = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.78125 4.875C9.78125 5.17578 9.54492 5.39062 9.26562 5.39062H5.82812V8.82812C5.82812 9.12891 5.5918 9.36523 5.3125 9.36523C5.01172 9.36523 4.79688 9.12891 4.79688 8.82812V5.39062H1.35938C1.05859 5.39062 0.84375 5.17578 0.84375 4.89648C0.84375 4.5957 1.05859 4.35938 1.35938 4.35938H4.79688V0.921875C4.79688 0.642578 5.01172 0.427734 5.3125 0.427734C5.5918 0.427734 5.82812 0.642578 5.82812 0.921875V4.35938H9.26562C9.54492 4.35938 9.78125 4.5957 9.78125 4.875Z"
        fill="white"
      />
    </svg>
  );
};
