import { Tooltip } from "antd";
import { Text } from "components/base";
import styled from "styled-components";

export const StyledTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  svg {
    position: relative;
    top: 0.125rem;
  }
`;

export const Description = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-tooltip {
    max-width: 100%;
  }

  .ant-tooltip-arrow-content {
    background: #1d1c2c;
  }

  .ant-tooltip-content,
  .ant-tooltip-inner {
    background: #1d1c2c;
  }

  .ant-tooltip-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    padding: 0.625rem;
  }
`;

export const CryptoWalletHint = styled(Text)`
  line-height: 1.375rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
