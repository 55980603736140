import {
  FC,
  CSSProperties,
  useState,
  useRef,
  RefObject,
  useEffect,
} from "react";
import debounce from "lodash/debounce";
import toFinite from "lodash/toFinite";
import measureText from "utils/measureText";
import units from "units-css";

const getStartOffset = (start: string | null | number, text: string) => {
  if (start === "" || start === null) {
    return 0;
  }

  if (!isNaN(parseInt(start as string, 10))) {
    return Math.round(toFinite(start));
  }

  const result = new RegExp(start as string).exec(text);
  return result ? result.index + result[0].length : 0;
};

const getEndOffset = (end: string | null | number, text: string) => {
  if (end === "" || end === null) {
    return 0;
  }

  if (!isNaN(parseInt(end as string, 10))) {
    return Math.round(toFinite(end));
  }

  const result = new RegExp(end as string).exec(text);
  return result ? result[0].length : 0;
};

// A React component for truncating text in the middle of the string.
//
// This component automatically calculates the required width and height of the text
// taking into consideration any inherited font and line-height styles, and compares it to
// the available space to determine whether to truncate or not.

// By default the component will truncate the middle of the text if
// the text would otherwise overflow using a position 0 at the start of the string,
// and position 0 at the end of the string.
//
// You can pass start and end props a number to offset this position, or alternatively
// a Regular Expression to calculate these positions dynamically against the text itself.
interface MiddleTruncateProps {
  className?: string;
  ellipsis: string;
  end: string | null | number;
  onResizeDebounceMs?: number;
  start: string | null | number;
  style?: CSSProperties;
  text: string;
}

const MiddleTruncate: FC<MiddleTruncateProps> = (props) => {
  const [truncatedText, setTruncatedText] = useState("");
  const [start, setStart] = useState(getStartOffset(props.start, props.text));
  const [end, setEnd] = useState(getEndOffset(props.end, props.text));
  const ref = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const ellipsisRef = useRef<HTMLSpanElement>(null);

  const getTextMeasurement = (ref: RefObject<HTMLSpanElement>) => {
    const { current } = ref;

    if (!current) return;
    const text = current.textContent;

    if (current.offsetWidth) {
    }
    const { fontFamily, fontSize, fontWeight, fontStyle } =
      window.getComputedStyle(current);

    const { width, height } = measureText({
      text,
      fontFamily,
      fontSize,
      fontWeight,
      fontStyle,
      lineHeight: 1,
    });

    return { width, height };
  };

  const getComponentMeasurement = () => {
    const { current } = ref;

    if (!current) return;

    if (current.offsetWidth) {
    }

    const offsetWidth = current.offsetWidth || 0;
    const offsetHeight = current.offsetHeight || 0;

    return {
      width: units.parse(offsetWidth, "px"),
      height: units.parse(offsetHeight, "px"),
    };
  };

  const calculateMeasurements = () => {
    return {
      component: getComponentMeasurement(),
      ellipsis: getTextMeasurement(ellipsisRef),
      text: getTextMeasurement(textRef),
    };
  };

  const truncateText = (
    measurements: ReturnType<typeof calculateMeasurements>
  ) => {
    const { text, ellipsis } = props;

    const delta = Math.ceil(
      measurements.text?.width.value - measurements.component?.width.value
    );

    const totalLettersToRemove = Math.ceil(
      delta / measurements.ellipsis?.width.value
    );

    const middleIndex = Math.round(text.length / 2);

    const preserveLeftSide = text.slice(0, start);
    const leftSide = text.slice(
      start,
      middleIndex - totalLettersToRemove <= 0
        ? start
        : middleIndex - totalLettersToRemove
    );
    const rightSide = text.slice(
      middleIndex + totalLettersToRemove,
      text.length - end
    );
    const preserveRightSide = text.slice(text.length - end, text.length);

    return `${preserveLeftSide}${leftSide}${ellipsis}${rightSide}${preserveRightSide}`;
  };

  const parseTextForTruncation = (text: string) => {
    const measurements = calculateMeasurements();

    const truncatedText =
      Math.round(measurements.text?.width.value) >
      Math.round(measurements.component?.width.value)
        ? truncateText(measurements)
        : text;

    setTruncatedText(truncatedText || props.text);
  };

  useEffect(() => {
    const debounced = debounce(parseTextForTruncation, 0);

    const resize = () => {
      debounced(props.text);
    };

    const debouncedResize = debounce(resize, props.onResizeDebounceMs || 0);

    debouncedResize();

    window.addEventListener("resize", debouncedResize);

    return () => {
      debounced.cancel();
      debouncedResize.cancel();
      window.removeEventListener("resize", debouncedResize);
    };

    // eslint-disable-next-line
  }, [props.onResizeDebounceMs, props.text]);

  useEffect(() => {
    setStart(getStartOffset(props.start, props.text));
  }, [props.start, props.text]);

  useEffect(() => {
    setEnd(getEndOffset(props.end, props.text));
  }, [props.end, props.text]);

  const componentStyle: CSSProperties = {
    ...props.style,
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  const hiddenStyle = {
    display: "none",
  };

  return (
    <div ref={ref} style={componentStyle}>
      <span ref={textRef} style={hiddenStyle}>
        {props.text}
      </span>
      <span ref={ellipsisRef} style={hiddenStyle}>
        {props.ellipsis}
      </span>
      {truncatedText}
    </div>
  );
};

export default MiddleTruncate;
export { MiddleTruncate };
