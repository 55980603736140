import { FC, memo } from "react";
import { ContainerView } from "./container.style";

const Container: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  return <ContainerView {...rest}>{children}</ContainerView>;
};

export default memo(Container);
