export const ImageCategory = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M6.5 8.5C7.60457 8.5 8.5 7.60457 8.5 6.5C8.5 5.39543 7.60457 4.5 6.5 4.5C5.39543 4.5 4.5 5.39543 4.5 6.5C4.5 7.60457 5.39543 8.5 6.5 8.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 13L15.1314 9.1314C14.7354 8.7354 14.5373 8.5373 14.309 8.4632C14.1082 8.3979 13.8918 8.3979 13.691 8.4632C13.4627 8.5373 13.2646 8.7354 12.8686 9.1314L4.36569 17.6343C3.93731 18.0627 3.72312 18.2769 3.70865 18.4608C3.69609 18.6203 3.76068 18.7763 3.88238 18.8802M5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const VideoCategory = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M21 4.9313C21 4.32548 21 4.02257 20.8802 3.88231C20.7763 3.76061 20.6203 3.69602 20.4608 3.70858C20.2769 3.72305 20.0627 3.93724 19.6343 4.36561L16 7.9999L19.6343 11.6342C20.0627 12.0626 20.2769 12.2768 20.4608 12.2913C20.6203 12.3038 20.7763 12.2392 20.8802 12.1175C21 11.9773 21 11.6744 21 11.0686V4.9313Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H11.2C12.8802 1 13.7202 1 14.362 1.32698C14.9265 1.6146 15.3854 2.07354 15.673 2.63803C16 3.27976 16 4.11984 16 5.8V10.2C16 11.8802 16 12.7202 15.673 13.362C15.3854 13.9265 14.9265 14.3854 14.362 14.673C13.7202 15 12.8802 15 11.2 15H5.8C4.11984 15 3.27976 15 2.63803 14.673C2.07354 14.3854 1.6146 13.9265 1.32698 13.362C1 12.7202 1 11.8802 1 10.2V5.8Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AudioCategory = () => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M18.748 2.29297C20.1653 4.26321 21.0001 6.6806 21.0001 9.29297C21.0001 11.9054 20.1653 14.3228 18.748 16.293M14.7454 5.29297C15.5362 6.4268 16.0001 7.80577 16.0001 9.29297C16.0001 10.7802 15.5362 12.1592 14.7454 13.293"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.89289C1 6.33283 1 6.05281 1.10899 5.8389C1.20487 5.65073 1.35785 5.49775 1.54601 5.40188C1.75992 5.29289 2.03995 5.29289 2.6 5.29289H4.33726C4.58185 5.29289 4.70414 5.29289 4.81923 5.26526C4.92127 5.24076 5.01881 5.20036 5.10828 5.14553C5.2092 5.08369 5.29568 4.99721 5.46863 4.82426L8.63432 1.65857C9.0627 1.2302 9.2769 1.01601 9.4608 1.00154C9.6203 0.988985 9.7763 1.05357 9.8802 1.17527C10 1.31553 10 1.61844 10 2.22426V16.3616C10 16.9674 10 17.2703 9.8802 17.4105C9.7763 17.5322 9.6203 17.5968 9.4608 17.5843C9.2769 17.5698 9.0627 17.3556 8.63431 16.9272L5.46863 13.7616C5.29568 13.5886 5.2092 13.5021 5.10828 13.4403C5.01881 13.3855 4.92127 13.3451 4.81923 13.3206C4.70414 13.2929 4.58185 13.2929 4.33726 13.2929H2.6C2.03995 13.2929 1.75992 13.2929 1.54601 13.1839C1.35785 13.0881 1.20487 12.9351 1.10899 12.7469C1 12.533 1 12.253 1 11.6929V6.89289Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArCategory = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16 15L21 10L16 5M6 5L1 10L6 15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 1L9 19"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FileIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M5.34927 10.6511L10.6508 5.34961"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.53553 12.8198L7.46447 13.8908C5.98563 15.3697 3.58796 15.3697 2.10913 13.8908C0.630292 12.412 0.630292 10.0144 2.10913 8.53555L3.18019 7.46449M12.8198 8.53555L13.8909 7.46449C15.3697 5.98563 15.3697 3.58796 13.8909 2.10913C12.412 0.630292 10.0143 0.630292 8.53553 2.10913L7.46447 3.18019"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
