import { InputNumber as AInputNumber } from "antd";
import styled from "styled-components";

export const InputNumber = styled(AInputNumber)`
  width: 100%;
  background: ${({ theme }) => theme.mint.box.background};
  border: 0.0625rem solid #201f2a;
  height: 3.375rem;
  border-radius: 0.375rem;
  color: ${({ theme }) => theme.mint.box.color};
  outline: none;
  font-size: 0.875rem;
  line-height: 1.625rem;
  padding: 0.625rem 0.875rem;

  &:hover {
    background: ${({ theme }) => theme.mint.hoverBg};
    border-color: ${({ theme }) => theme.colors.border};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.halfBlurredWhite};
    font-size: 1rem;
  }

  .ant-input-number-input {
    height: 100%;
  }

  .ant-input-number-disabled {
    background-color: #2a2846 !important;
    color: rgba(255, 255, 255, 0.6) !important;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1.25rem;
  }
`;
