import { SVGProps } from "./svg.props";

const FlagKorea = (props: SVGProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M25 0H-5v20h30V0Z" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m-.377 6.104.69.466L3.11 2.424l-.691-.466-2.796 4.146Zm.967.652.69.466 2.797-4.145-.691-.466L.59 6.756Zm.967.652.691.466L5.044 3.73l-.69-.466-2.797 4.145Zm3.487 8.863-.69.466-2.797-4.145.691-.466 2.796 4.146Zm-.967.653-.691.466-1.305-1.935.691-.466 1.305 1.935Zm-1.491-2.21-.691.465L.59 13.245l.69-.466 1.306 1.934Zm.524 2.862-.691.466-2.796-4.145.69-.466 2.797 4.145ZM20.377 6.104l-.69.466-1.305-1.935.69-.466 1.305 1.935Zm-1.49-2.211-.692.466-1.305-1.935.691-.466 1.305 1.935Zm.523 2.863-.69.466-2.797-4.145.691-.466 2.796 4.145Zm-.967.652-.691.466-1.305-1.934.691-.466 1.305 1.934Zm-1.491-2.21-.691.466-1.305-1.935.69-.466 1.306 1.935Zm3.425 8.698-.69-.466-1.305 1.935.69.466 1.305-1.934Zm-1.49 2.211-.692-.466-1.305 1.935.691.466 1.305-1.935Zm.523-2.863-.69-.466-1.305 1.934.69.466 1.305-1.934Zm-1.491 2.21-.69-.465-1.306 1.934.691.466 1.305-1.934Zm.524-2.862-.691-.466-1.305 1.934.691.466 1.305-1.934Zm-1.491 2.21-.691-.466-1.305 1.935.69.466 1.306-1.934Z"
        fill="#000"
      />
      <path d="M10 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z" fill="#CA4638" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.218 12.686c-1.451 2.322-4.526 3.018-6.868 1.555-2.341-1.464-3.064-4.532-1.613-6.854a2.49 2.49 0 0 0 .807 3.427 2.49 2.49 0 0 0 3.433-.777 2.49 2.49 0 0 1 3.434-.777 2.49 2.49 0 0 1 .807 3.426s.725-1.16 0 0Z"
        fill="#335A91"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={20} height={20} rx={10} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default FlagKorea;
