import { useCallback, useEffect, useState } from 'react';
// icons
import { CrossMark, RightArrowWhiteLong, WalletIconDark } from 'components/assets';
import { setInfo, setToken } from 'redux/store/slices';
import { LoginFormProps } from './login.props';
import {
    ButtonWrapper,
    ForgotText,
    FormInput,
    FormItem,
    FormPasswordInput,
    Line,
    ModalTitle,
    ModalDescription,
    PinkButtonOutline,
    ModalForm,
    Wrapper,
    WhiteButtonOutline,
    GradientWrapper,
    NoAccountText,
    HelpTooltipWrapper,
    CloseModalBtn,
} from '../auth.style';
import { BtnWrapper } from './login.style';
import { useTranslation } from 'react-i18next';
import { canAuth, updateUserWallet, walletLogin } from 'redux/apis';
import { Form as AForm } from 'antd';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { canLogin } from 'redux/apis';
import { initialState, showModal } from 'redux/store/slices/modalSlice';
import { ErrorMessage } from 'components/modules/mintFlow/upload/upload.style';
import { Notification } from 'components/base';
import { useSearchParams } from 'react-router-dom';
import { useWallet } from 'hooks';
import HelpInfoTooltip from 'components/modules/HelpInfoTooltip';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { mouseEnter, mouseLeave } from 'utils';

export const LoginFormContent: React.FC<LoginFormProps> = ({
    handleForgotOpen,
    switchToSignUp,
    close,
    authLoadingState,
}) => {
    const [form] = AForm.useForm();
    const dispatch = useAppDispatch();
    const modal = useAppSelector((state) => state.modal);
    const [authLoading, setAuthLoading] = authLoadingState!;
    const [searchParams, setSearchParams] = useSearchParams();
    const { connectedWallet } = useAppSelector((state) => state.wallet);
    const { t } = useTranslation('translation', { keyPrefix: 'login' });
    const { t: tException } = useTranslation('translation', {
        keyPrefix: 'exception',
    });

    const [isEmail, setIsEmail] = useState(false);
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const typeAsObject = Object.fromEntries(new URLSearchParams(searchParams));
    const { executeRecaptcha } = useGoogleReCaptcha();

    const { type } = typeAsObject;
    const { account } = useWallet();

    const getError = (msg: string) => {
        let message = '';
        switch (msg) {
            case 'wallet_and_user_not_mapping':
                message = t('errors.wallet.0');
                break;
            case 'email_and_wallet_not_mapping':
                message = t('errors.email.0');
                break;
            case 'user_and_wallet_not_mapping_and_wallet_not_connected':
                message = t('errors.email.0');
                break;
            case 'user_and_wallet_not_mapping_and_wallet_connected':
                message = t('errors.email.0');
                break;
            case 'user_and_wallet_not_mapping_and_email_not_connected_with_wallet':
                message = t('errors.wallet.0');
                break;
            case 'user_and_wallet_not_mapping_and_email_connected_with_wallet':
                message = t('errors.wallet.0');
                break;
            case 'user_not_found':
                message = t('errors.user.0');
                break;
            case 'user_is_registered_by_another_provider':
                message = t('errors.user.1');
                break;
            case 'user_not_active':
                message = t('errors.user.2');
                break;
            case 'user_is_inactive':
                message = 'Your account is inactive';
                break;
            case 'user_is_freeze':
                message = 'Your account has been freezed';
                break;
            case 'user_is_banned':
                message = 'Your account has been banned';
                break;
            case 'user_has_been_deleted':
                message = 'Your account has been deleted';
                break;
            default:
                break;
        }

        return message;
    };

    const handleLogin = async (email: string, password: string) => {
        if (authLoading || !captchaToken) {
            setShowWarning(true);
            return;
        }

        try {
            setAuthLoading(true);
            if (connectedWallet) {
                const response = await canLogin(
                    {
                        email: email.toLowerCase(),
                        walletAddress: connectedWallet.toLocaleLowerCase(),
                    },
                    'walletFirst',
                );

                if (response.data.code === 400) {
                    close?.();
                    const message = getError(response.data.message?.toLowerCase());
                    setAuthLoading(false);
                    dispatch(
                        showModal({
                            ...initialState,
                            used: message,
                        }),
                    );
                    return;
                }
            }

            const response = await walletLogin({
                email: email.toLowerCase(),
                password: password,
                type: 'email',
            });

            const { token } = response.data.data;

            const accessToken = token as string;

            localStorage.setItem('access-token', accessToken);
            dispatch(setToken(accessToken));

            if (accessToken && connectedWallet) {
                await updateUserWallet({
                    walletAddress: connectedWallet.toLocaleLowerCase(),
                    email: email,
                });
            }

            close?.();
            Notification({
                type: 'success',
                message: t('success'),
            });
        } catch (e: any) {
            Notification({
                type: 'error',
                message: tException('notAuthorized'),
            });
        } finally {
            form.resetFields();
            setAuthLoading(false);
        }
    };

    // const onChangeReCaptCha = useCallback(async () => {
    //   if (!executeRecaptcha) {
    //     setShowWarning(true);
    //     return;
    //   }

    //   const token = await executeRecaptcha("login");
    //   setCaptchaToken(token);
    //   setShowWarning(false);
    // }, [executeRecaptcha]);

    const handleEmailLogin = async (values: any) => {
        if (authLoading || !captchaToken) {
            setShowWarning(true);
            return;
        }
        try {
            setAuthLoading(true);
            if (connectedWallet) {
                const response = await canLogin(
                    {
                        email: values.email.toLowerCase(),
                        walletAddress: connectedWallet.toLocaleLowerCase(),
                    },
                    'walletFirst',
                );

                if (response.data.code === 400) {
                    const message = getError(response.data.message?.toLowerCase());
                    dispatch(
                        showModal({
                            ...initialState,
                            used: message,
                        }),
                    );

                    return;
                }
            }

            const canAuthResponse = await canAuth({
                email: values.email.toLowerCase(),
            });

            if (canAuthResponse.data.code !== 200) {
                const message = getError(canAuthResponse.data.message?.toLowerCase());
                setAuthLoading(false);
                dispatch(
                    showModal({
                        ...initialState,
                        used: message,
                    }),
                );

                return;
            }

            const response = await walletLogin({
                email: values.email.toLowerCase(),
                password: values.password,
                type: 'email',
            });

            const { token, user } = response.data.data;

            const accessToken = token as string;

            localStorage.setItem('access-token', accessToken);
            dispatch(setToken(accessToken));
            dispatch(setInfo(user));

            if (accessToken && connectedWallet) {
                await updateUserWallet({
                    walletAddress: connectedWallet.toLocaleLowerCase(),
                    // wallet.publicKey.toBase58(),
                    email: values.email,
                });
            }

            close?.();
            Notification({
                type: 'success',
                message: t('success'),
            });
            setAuthLoading(false);
            form.resetFields();
        } catch (e: any) {
            if (e.stack?.includes('setItem')) {
                const items = Object.entries(localStorage).filter(([key]) => !key.startsWith('pda'));
                localStorage.clear();
                items.forEach((item) => localStorage.setItem(item[0], item[1]));
                handleLogin(values.email, values.password);
            } else {
                Notification({
                    type: 'error',
                    message: tException('notAuthorized'),
                });
                setAuthLoading(false);
                form.resetFields();
            }
        } finally {
            setAuthLoading(false);
        }
    };

    const handleSwitchToWallets = () => {
        if (type !== 'wallet') {
            dispatch(
                showModal({
                    ...initialState,
                    wallet: true,
                    login: false,
                }),
            );

            searchParams.set('type', 'wallet');
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        let isMount = true;

        if (Object.values(modal).every((item) => !item)) {
            setTimeout(() => {
                isMount && setIsEmail(false);
                isMount && setCaptchaToken(null);
            }, 300);
        }

        return () => {
            isMount = false;
        };
    }, [modal]);

    // useEffect(() => {
    //     onChangeReCaptCha();
    // }, [onChangeReCaptCha]);

    return (
        <GradientWrapper>
            <CloseModalBtn onClick={() => close?.()}>
                <CrossMark />
            </CloseModalBtn>
            {/* <ButtonWrapper>
            <PinkButtonOutline fillSvg={false} onClick={() => setIsEmail(true)}>
              {t("withEmail")}
            </PinkButtonOutline>
            </ButtonWrapper> */}
            <Wrapper>
                <ModalTitle level={3}>{isEmail ? t('title.0') : t('title.1')}</ModalTitle>
                <ModalDescription>{t('description')}</ModalDescription>
                {isEmail ? (
                    <ModalForm onFinish={handleEmailLogin} form={form}>
                        <FormItem
                            name="email"
                            labelCol={{ span: 24 }}
                            label={t('form.email.label')}
                            rules={[
                                { required: true, message: t('form.email.error') },
                                {
                                    type: 'email',
                                    message: 'Email is invalid',
                                },
                            ]}
                        >
                            <FormInput placeholder={t('form.email.label')} />
                        </FormItem>
                        <FormItem
                            name="password"
                            labelCol={{ span: 24 }}
                            label="Password"
                            rules={[
                                { required: true, message: t('form.password.error.0') },
                                { min: 8, message: t('form.password.error.1') },
                            ]}
                        >
                            <FormPasswordInput placeholder={t('form.password.label')} visibilityToggle={false} />
                        </FormItem>
                        {showWarning ? (
                            <ErrorMessage style={{ textAlign: 'center' }}>
                                Captcha is loading, please try again
                            </ErrorMessage>
                        ) : null}
                        <PinkButtonOutline loading={authLoading} disabled={authLoading}>
                            {t('form.submit')}
                        </PinkButtonOutline>
                    </ModalForm>
                ) : (
                    <ButtonWrapper vertical>
                        <PinkButtonOutline fillSvg={false} onClick={() => setIsEmail(true)}>
                            {t('withEmail')}
                        </PinkButtonOutline>
                        {!account && (
                            <WhiteButtonOutline fillSvg={false} onClick={handleSwitchToWallets}>
                                <BtnWrapper>
                                    <WalletIconDark />
                                </BtnWrapper>
                                {t('withWallet')}
                            </WhiteButtonOutline>
                        )}
                    </ButtonWrapper>
                )}
                <NoAccountText onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={switchToSignUp}>
                    {t('noAccount')}{' '}
                    <span>
                        {t('changeType')} <RightArrowWhiteLong />
                    </span>{' '}
                </NoAccountText>
                {/* <ModalButton onClick={switchToSignUp}>{t("changeType")}</ModalButton> */}
                <ForgotText onClick={handleForgotOpen} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                    {t('forgotText')}
                </ForgotText>
                {/* <HelpWrapper>
          <HelpInfo />
        </HelpWrapper> */}
                <HelpTooltipWrapper>
                    <Line />
                    <HelpInfoTooltip />
                </HelpTooltipWrapper>
            </Wrapper>
        </GradientWrapper>
    );
};
