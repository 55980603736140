import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum EThemeType {
    DARK = 'DARK',
}

interface ThemeState {
    theme: EThemeType;
    isView: boolean;
    isExistCollection: boolean;
}

const initialState: ThemeState = {
    theme: EThemeType.DARK,
    isView: false,
    isExistCollection: false,
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeTheme: (state, action: PayloadAction<EThemeType>) => {
            state.theme = action.payload;
            localStorage.setItem('theme', action.payload);
        },
        changeViewSearch: (state, action: PayloadAction<boolean>) => {
            state.isView = action.payload;
        },
        updateVisiable: (state, action: PayloadAction<boolean>) => {
            state.isExistCollection = action.payload;
        },
    },
});

export const { changeTheme, changeViewSearch, updateVisiable } = themeSlice.actions;
