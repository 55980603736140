import { FC, memo } from 'react';
import { createPortal } from 'react-dom';
// import Lottie from "react-lottie-light";
import Lottie from 'react-lottie-player';
import { LoadingContainer, LoadingContent } from './loading.style';
import loadingData from './loading.json';
import { ILoadingProps } from './loading.props';
import Spin from 'components/base/loadingIndicator/spin';
import './style.scss';
const Loading: FC<ILoadingProps> = ({ loading }) => {
    // return loading
    //   ? createPortal(
    //       <LoadingContainer>
    //         <LoadingContent>
    //           <Spin size={90} rotation="counterclockwise" />
    //           <Lottie
    //             style={{
    //               width: "3.75rem",
    //               height: "2.5rem",
    //             }}
    //             loop
    //             play
    //             animationData={loadingData}
    //           />
    //         </LoadingContent>
    //       </LoadingContainer>,
    //       document.getElementById("portal-root")!
    //     )
    //   : null;
    return loading ? (
        <LoadingContainer>
            <div className="content">
                {/* <div className="planet">
                    <div className="ring"></div>
                    <div className="cover-ring"></div>
                    <div className="spots">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div> */}
                <p>loading</p>
            </div>
        </LoadingContainer>
    ) : null;
};

export default memo(Loading);
