export enum AdminPaths {
    // events = 'events',
    // createEvent = 'event/create',
    // eventDetail = 'event/:id',
    // accounts = 'accounts',
    // brands = 'brands',
    // support = 'support',
    // marketplace = 'marketplace',
    home = '',
}

export enum ProfilePaths {
    profile = 'profile/',
    activity = 'profile/activity',
    inventory = 'profile/inventory',
    settings = 'profile/settings',
    offerlist = 'profile/offerlist',
    watchlist = 'profile/watchlist',
}

export enum SupportPaths {
    article = 'article',
    getStarted = 'get-started',
    buying = 'buying',
    selling = 'selling',
    creating = 'creating',
    faq = 'faq',
    userSafety = 'user-safety',
    submit = 'submit',
    ticketDetail = ':id',
    allTickets = 'tickets',
}

export enum UserPaths {
    home = '/',
    marketplace = 'marketplace',
    create = 'create',
    marketplaceDetail = 'marketplace/:id',
    sellItem = 'item/:id',
    nftDetail = 'nft-detail/:id',
    inventory = 'inventory',
    events = 'events',
    eventDetail = 'events/:id',
    eventRegisterSuccessful = 'events/:id/register-successful',
    pastEvents = 'past-events',
    currentEvents = 'upcoming-events',
    about = 'about',
    guildGaming = 'guild-gaming',
    search = 'search',
    p403 = '403',
    p500 = '500',
    commingSoon = 'comming-soon',
    creatorDetail = 'creators/:id',
    unsubscribe = 'unsubscribe/:id',
    staking = 'staking',
    activeAccount = 'active-account',
    verifyAccount = 'verify-account',
    generateLink = 'generate-link',
    membership = 'membership',
    dashboard = 'dashboard',
    createCollection = 'brand/collection/create',
    createFt = 'ft/createFt',
    tokenInfo = 'ft/tokenInfo',
    tokenVerify = 'ft/tokenVerify',
    createMyCollection = 'my-collections/create',
    upcomingMints = 'upcoming-mints',
    createUpcomingMint = 'upcoming-mints/create',
    upcomingMintDetail = 'upcoming-mint/:id',
    collectionList = 'brand/collection',
    updateCollection = 'collection/:id/update',
    collectionDetail = 'collection/:id',
    collections = 'collections',
    myCollections = 'my-collections',
    baseComponents = 'base-components',
    auctions = 'auctions',
    auctionDetail = 'auction-detail/:id',
    createAuction = 'auction/create',
}
