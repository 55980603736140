import { FC } from 'react';
import Skeleton from '../skeleton';
import { IImageProps } from './image.props';
import { ImageView } from './image.style';

const Image: FC<IImageProps> = ({
    skeleton,
    fallback = '/assets/images/unavailable-image.jpg',
    imgKey,
    id,
    ...props
}) => {
    return (
        <ImageView
            fallback={fallback}
            preview={false}
            loading="eager"
            key={imgKey}
            id={imgKey}
            placeholder={
                <Skeleton
                    width={skeleton?.width || '100%'}
                    height={skeleton?.height || '100%'}
                    style={{ aspectRatio: skeleton?.aspectRatio }}
                    borderRadius={skeleton?.borderRadius}
                />
            }
            {...props}
        />
    );
};

export default Image;
