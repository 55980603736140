import { AxiosResponse } from 'axios';
import { IUpdateUser, UpdateProfile, UpdateSocial, IResponse, IInfoData, IUpdateEmail, IBrandInfo } from 'models';
import instance from './common';

export const getProfile = (): Promise<AxiosResponse<any>> => {
    return instance.get(`/user/profile`);
};

export const updateUserWallet = (body: IUpdateUser) => {
    return instance.patch(`/user/update`, body);
};

export const getWalletByUser = (wallet: string) => {
    return instance.get(`/user/${wallet}`);
};

export const getUserInfo = (id: string): Promise<AxiosResponse<IResponse<IInfoData | null>>> => {
    return instance.get(`/user/id/${id}`);
};

export const updateUserProfile = (body: UpdateProfile, owner: string) => {
    return instance.put(`/user/${owner}`, body);
};

export const updateBrandProfile = (body: IBrandInfo) => {
    return instance.patch(`/brand/update-info`, body);
};

export const updateEmail = (body: IUpdateEmail) => {
    return instance.put(`/user/change-email`, body);
};

export const updateUserSocial = (body: { social?: UpdateSocial }) => {
    return instance.patch(`/user/socials`, body);
};

export const updateAccountField = (body: any) => {
    return instance.patch('/user/admin/update', body);
};

export const updateBrandField = (body: any) => {
    return instance.post('/brand/admin', body);
};

export const deleteAccount = (id: string) => {
    return instance.delete(`/user/${id}`);
};

export const getAllAccounts = (limit?: number) => {
    return instance.get(`/user/admin/accounts${limit !== undefined ? `?limit=${limit}` : ''}`);
};

export const updateLatestNftList = (body: any) => {
    return instance.post('/user/nft-counting', body);
};

export const getUsersByWallet = (wallets: string[]) => {
    return instance.post(`/user/users-by-wallets`, { wallets });
};

export const getBoughtNfts = () => {
    return instance.get('/nft/bought?filter=allTimes');
};

export const sendRequestToBuy = (nftId: string | undefined) => {
    return instance.post(`/nft/${nftId}/request-to-buy`);
};

export const changePassword = (body: any) => {
    return instance.patch('/user/password', body);
};
