import OtpInput from "react-otp-input";
import styled from "styled-components";
import { FormItem, ModalDescription } from "../auth.style";

export const ResendText = styled(({ isSend, children, ...rest }) => (
  <ModalDescription {...rest}>{children}</ModalDescription>
))`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1.25rem;
  cursor: ${({ isSend }) =>
    isSend
      ? "url(/assets/images/cursor.svg), pointer"
      : "url(/assets/images/cursor-white.svg), pointer"};
`;

export const CodeInputNumber = styled(OtpInput)`
  width: 20%;
  height: 3.5rem;
  color: ${({ theme }) => theme.forgotPassword.input.color};
  margin-bottom: 2.47rem;
  flex-shrink: 0;
  flex-grow: 1;
  max-width: unset;

  @media screen and (min-width: 429px) {
    max-width: 2.9375rem;
    height: 2.5rem;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    max-width: 2.9375rem;
    height: 2.5rem;
    margin-bottom: 0;
    flex-shrink: unset;
  }

  input {
    width: 100% !important;
    height: 100%;
    background: ${({ theme }) => theme.forgotPassword.input.background};
    border: 0.0625rem solid #3b4167;
    outline: none;
    font-size: 1.25rem;
    line-height: 1rem;

    &::placeholder {
      color: ${({ theme }) => theme.forgotPassword.input.color};
      opacity: 0.5;
      font-weight: 400;
      padding-top: 1.25rem;
      color: #3b4167;
      font-size: 1.875rem;
    }
  }

  &:first-child {
    input {
      border-radius: 0.375rem 0rem 0rem 0.375rem;
    }
  }

  &:nth-child(even) {
    input {
      border-left: 0;
      border-right: 0;
    }
  }

  &:last-child {
    input {
      border-right: 0.0625rem solid #3b4167;
      border-radius: 0rem 0.375rem 0.375rem 0rem;
    }
  }
`;

export const RecoverSuccessIcon = styled.div`
  background: #1d1c2c;
  border-radius: 0.375rem;
  width: 6.1875rem;
  height: 4.8125rem;
  display: grid;
  place-items: center;
  margin: 2.375rem auto 1.875rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
    margin: 2.375rem auto 1.875rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 2.9375rem auto 1.875rem;
  }
`;

export const CodeInputWrapper = styled.div`
  width: 100%;
  margin: 0;

  & > div {
    width: 100%;
    justify-content: center;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      justify-content: center;
      max-width: 100%;
      width: unset;
    }

    & > div {
      width: calc(100% / 6);
      max-width: unset;
      height: 3.5rem;
    }
  }
`;

export const OtpForm = styled(FormItem)`
  .ant-form-item-control-input-content::before {
    content: none;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1;
  margin-top: 0.3125rem;
`;

export const HelpContainer = styled.div`
  border-top: 1px solid #201f2a;
  padding-top: 2.3125rem;
  margin-top: 2.625rem;
`;

export const HelpTitle = styled.p`
  font-size: 1.125rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0.125rem;
`;
export const HelpDescription = styled.a`
  text-align: center;
  color: rgba(256, 256, 256, 0.6);
  display: block;
  font-size: ${({ theme }) => theme.typography.textSmall};
  line-height: 1.375rem;
`;
