import { FC, useRef, useEffect } from "react";
import { CursorView, CursorGlobal } from "./cursor.style";

const Cursor: FC = () => {
  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mousemove = (e: MouseEvent) => {
      if (!cursorRef.current) return;

      cursorRef.current.style.top = `${e.clientY}px`;
      cursorRef.current.style.left = `${e.clientX}px`;
    };

    window.addEventListener("mousemove", mousemove);

    return () => {
      window.removeEventListener("mousemove", mousemove);
    };
  }, []);

  return (
    <>
      <CursorGlobal />
      <CursorView ref={cursorRef} />
    </>
  );
};

export default Cursor;
