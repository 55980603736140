import { Upload } from 'antd';
import Text from 'components/base/text';
import styled from 'styled-components';

export const UploadBlock = styled(Upload)`
    .ant-upload.ant-upload-select-picture-card {
        aspect-ratio: 1/1;
        margin-bottom: 0;
        width: 100%;
        min-height: 10.375rem;
        background-color: #0f0e19;
        border: 0.0625rem solid #3b4167;
        border-radius: 0.375rem;
        cursor: url(/assets/images/cursor-white.svg), pointer;
        &:hover {
            background-color: #171626;
        }
    }
`;

export const PreviewCustom = styled.div<{ preview: string }>`
    ${({ preview }) =>
        preview
            ? `
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.375rem;
    }
  `
            : `
    padding-inline: 0.875rem;
  `}
`;

export const UploadInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const UploadTextDesc = styled(Text)`
    line-height: 1.375rem;
`;
