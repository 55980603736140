import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  login: boolean;
  register: boolean;
  forgot: boolean;
  wallet: boolean;
  used: "WALLET" | "EMAIL" | string | null;
  add: boolean;
  visibleChat: boolean;
  channel: string;
}

export const initialState: InitialState = {
  login: false,
  register: false,
  forgot: false,
  wallet: false,
  used: null,
  add: false,
  visibleChat: false,
  channel: "",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<InitialState>) => {
      state = action.payload;
      return state;
    },
    toggleVisibleChat: (state, action: PayloadAction<boolean>) => {
      state.visibleChat = action.payload;
    },
    setChannel: (state, action: PayloadAction<string>) => {
      state.channel = action.payload;
    },
  },
});

export const { showModal, toggleVisibleChat, setChannel } = modalSlice.actions;
