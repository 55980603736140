import React from "react";
import { mouseEnter, mouseLeave } from "utils/mouseEvent";
import { IButtonPrimaryProps } from "./buttonPrimary.props";
import { Button, ButtonContent, Glow } from "./buttonPrimary.style";

const ButtonPrimaryView: React.FC<IButtonPrimaryProps> = ({
  children,
  icon,
  iconPosition = "LEFT",
  disabled,
  onClick,
  ...props
}: IButtonPrimaryProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    mouseLeave();
  };

  return (
    <Button
      disabled={disabled}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={handleClick}
      {...props}
    >
      {!disabled && (
        <>
          <Glow />
          <Glow />
          <Glow />
        </>
      )}
      <ButtonContent iconPosition={iconPosition}>
        {iconPosition === "LEFT" && icon}
        {children}
        {iconPosition === "RIGHT" && icon}
      </ButtonContent>
    </Button>
  );
};

export default React.memo(ButtonPrimaryView);
