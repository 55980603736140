export const BREAKPOINTS = {
  xs: "20rem",
  sm: "30.0625rem",
  md: "48.0625rem",
  lg: "64.0625rem",
  xl: "75.0625rem",
  xxl: "90.0625rem",
  fhd: "120rem",
};

// 320px — 480px
// 481px — 768px
// 769px — 1024px
// 1025px — 1200px
// 1025px — 1440px
// 1441px and above
