import { FC, memo, useRef, useEffect } from 'react';
import { IButtonConnectWalletProps } from './buttonConnectWallet.props';
import { Container, WalletTextContainer, ConnectTitleWrapper, Description } from './buttonConnectWallet.style';
import Text from 'components/base/text';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { WalletIcon } from 'components/assets';
import { mouseEnter, mouseLeave } from 'utils';

const ButtonConnectWalletView: FC<IButtonConnectWalletProps> = (props) => {
    const ref = useRef<HTMLButtonElement | null>(null);
    const { collapsed, ...buttonProps } = props;
    const { t } = useTranslation('translation', {
        keyPrefix: 'walletModal',
    });

    useEffect(() => {
        if (!props.fillAvailable) return;

        const resize = () => {
            if (!ref.current) return;

            const top = ref.current.offsetTop;
            const remaing = window.innerHeight - top - 40;

            if (remaing < 250) return;

            ref.current.style.height = remaing + 'px';
        };
        const debounced = debounce(resize, 100);

        resize();

        window.addEventListener('resize', debounced);

        return () => {
            window.removeEventListener('resize', debounced);
        };
    }, [props.fillAvailable]);

    return (
        <Container collapsed={collapsed} ref={ref} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} {...buttonProps}>
            {!collapsed && (
                <>
                    <ConnectTitleWrapper>
                        <WalletIcon />
                        <Description>{t('connectWallet')}</Description>
                        <Text>{t('walletRequired')}</Text>
                    </ConnectTitleWrapper>
                    <WalletTextContainer>{t('connectWallet')}</WalletTextContainer>
                </>
            )}
        </Container>
    );
};

export default memo(ButtonConnectWalletView);
