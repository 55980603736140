import { FC, useEffect, useState, useCallback } from 'react';
import {
    ModalTitle,
    Line,
    ForgotText,
    ButtonWrapper,
    PinkButtonOutline,
    ModalDescription,
    Wrapper,
    GradientWrapper,
    NoAccountText,
    HelpTooltipWrapper,
    CloseModalBtn,
    ButtonWrapperOutline,
    MiniText,
} from '../auth.style';
import { SignUpBrand } from './signUpBrand';
import { SignUpConsumer } from './signUpConsumer';
import { SignupFormProps } from './signup.props';
import { CrossMark, RightArrowWhiteLong } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks';
import HelpInfoTooltip from 'components/modules/HelpInfoTooltip';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import clsx from 'clsx';
import { mouseEnter, mouseLeave } from 'utils';

export const SignupContent: FC<SignupFormProps> = ({
    switchToSignIn,
    onFinishFailed,
    handleForgotOpen,
    authLoadingState,
    onCancel,
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'signUp' });
    const [isEmail, setIsEmail] = useState(true);
    const [isConsumer, setIsConsumer] = useState(true);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const modal = useAppSelector((state) => state.modal);

    const isSafariOnMobile =
        window.navigator.userAgent.includes('iPhone') && window.navigator.userAgent.includes('Safari');

    const onChangeReCaptCha = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }
    }, [executeRecaptcha]);

    useEffect(() => {
        let isMount = true;

        if (Object.values(modal).every((item) => !item)) {
            setTimeout(() => {
                isMount && setIsEmail(false);
            }, 300);
        }

        return () => {
            isMount = false;
        };
    }, [modal]);

    // useEffect(() => {
    //   onChangeReCaptCha();
    // }, [onChangeReCaptCha]);

    return (
        <GradientWrapper className={clsx(isSafariOnMobile && 'mb-20')}>
            <CloseModalBtn onClick={() => onCancel?.()}>
                <CrossMark />
            </CloseModalBtn>
            <Wrapper>
                <ModalTitle level={3}>{isEmail ? t('titleSingupEmail') : t('title')}</ModalTitle>
                <ModalDescription>{t('description')}</ModalDescription>
                <ButtonWrapper>
                    <ButtonWrapperOutline isPink={isConsumer} onClick={() => setIsConsumer(true)}>
                        <MiniText>{t('signUpType.title')}</MiniText>
                        {t('signUpType.role.consumer')}
                    </ButtonWrapperOutline>
                    <ButtonWrapperOutline isPink={!isConsumer} onClick={() => setIsConsumer(false)}>
                        <MiniText>{t('signUpType.title')}</MiniText>
                        {t('signUpType.role.brand')}
                    </ButtonWrapperOutline>
                </ButtonWrapper>
                {isEmail ? (
                    isConsumer ? (
                        <SignUpConsumer
                            switchToSignIn={switchToSignIn}
                            onFinishFailed={onFinishFailed}
                            handleForgotOpen={handleForgotOpen}
                            authLoadingState={authLoadingState}
                            onCancel={onCancel}
                        />
                    ) : (
                        <SignUpBrand
                            switchToSignIn={switchToSignIn}
                            onFinishFailed={onFinishFailed}
                            handleForgotOpen={handleForgotOpen}
                            authLoadingState={authLoadingState}
                            onCancel={onCancel}
                        />
                    )
                ) : (
                    <>
                        <ButtonWrapper>
                            <PinkButtonOutline fillSvg={false} onClick={() => setIsEmail(true)}>
                                {t('withEmail')}
                            </PinkButtonOutline>
                        </ButtonWrapper>
                    </>
                )}
                <NoAccountText onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={switchToSignIn}>
                    {t('haveAccount')}{' '}
                    <span>
                        {t('changeType')} <RightArrowWhiteLong />
                    </span>{' '}
                </NoAccountText>
                <ForgotText onClick={handleForgotOpen} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                    {t('forgotText')}
                </ForgotText>
                <HelpTooltipWrapper>
                    <Line />
                    <HelpInfoTooltip />
                </HelpTooltipWrapper>
            </Wrapper>
        </GradientWrapper>
    );
};
