import { Image } from 'antd';

export const BlueIcon: React.FC<{ size?: number }> = ({ size = 30 }) => {
    return (
        // <svg
        //   width={size}
        //   height={size}
        //   viewBox="0 0 30 30"
        //   fill="none"
        //   xmlns="http://www.w3.org/2000/svg"
        //   style={{ flexShrink: 0 }}
        // >
        //   <circle cx="15" cy="15" r="15" fill="#404F9F" />
        //   <path
        //     d="M19.4452 16.2758H25L20.9761 9L17.8085 14.7251H13.9699L14.8278 16.2758H16.9506L15.5 18.8987L10.0247 9L6 16.2758H12.3336L15.5 22L20.9753 12.1026L22.4288 14.7251H20.306L19.4452 16.2758ZM8.57407 14.7251L10.0247 12.1026L11.4761 14.7251H8.57407Z"
        //     fill="white"
        //   />
        // </svg>
        <Image src="/assets/images/avatar.png" style={{ width: 37, height: 'auto', borderRadius: '50%' }} />
    );
};

export const PinkIcon: React.FC<{ size?: number }> = ({ size = 30 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ flexShrink: 0 }}
        >
            <circle cx="15" cy="15" r="15" fill="0.0625rem solid #a1b7f8 " />
            <path
                d="M19.4452 16.2758H25L20.9761 9L17.8085 14.7251H13.9699L14.8278 16.2758H16.9506L15.5 18.8987L10.0247 9L6 16.2758H12.3336L15.5 22L20.9753 12.1026L22.4288 14.7251H20.306L19.4452 16.2758ZM8.57407 14.7251L10.0247 12.1026L11.4761 14.7251H8.57407Z"
                fill="white"
            />
        </svg>
    );
};
