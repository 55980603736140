import { FC, memo, useRef } from "react";
import { Info } from "components/assets";
import { HelpInfoTooltipProps } from "./helpInfoTooltip.props";
import {
  StyledTooltip,
  CryptoWalletHint,
  TooltipContainer,
  Description,
  IconWrapper,
} from "./helpInfoTooltip.style";

const HelpInfoTooltip: FC<HelpInfoTooltipProps> = ({ className, ...props }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  return (
    <TooltipContainer className={className} ref={popupRef}>
      <StyledTooltip
        title={
          <Description>
            A crypto-wallet is a place where you can safely store all your
            cryptocurrencies and digital game ASSETS.
          </Description>
        }
        getPopupContainer={(node) => popupRef.current || node}
        {...props}
      >
        <CryptoWalletHint>What's a crypto-wallet?</CryptoWalletHint>
        <IconWrapper>
          <Info />
        </IconWrapper>
      </StyledTooltip>
    </TooltipContainer>
  );
};

export default memo(HelpInfoTooltip);
