import { FC, useMemo } from 'react';
import { NftPriceProps } from './nftPrice.props';
import { CoinPrice, TextWrapper, USDPrice, PriceDetail, Icon } from './nftPrice.style';
import Skeleton from 'components/base/skeleton';
import { NewCMCG, NewCMCGBlack } from 'components/assets';
import { useCoingecko } from 'utils/context';
import { findSupportToken } from 'utils/constants/supportTokens';
import { CMCG } from 'contract-address';
import { formatPrice, valuePrecision } from 'utils/number';
import { BigNumber } from '@ethersproject/bignumber';
import { ethers } from 'ethers';
const NftPrice: FC<NftPriceProps> = ({ priceFormatted, coinLoading, sale, className, coinIcon, token }) => {
    const { cmcgToken, bnbToken, busdToken } = useCoingecko();

    const tokenFound = findSupportToken(token ? token : CMCG);

    const coinPrice = useMemo(() => {
        switch (tokenFound?.name) {
            case 'CMCG':
                return cmcgToken;
            case 'BUSD':
                return busdToken;
            case 'BNB':
                return bnbToken;
            default:
                return cmcgToken;
        }
    }, [tokenFound, cmcgToken, bnbToken, busdToken]);

    const finalIcon = coinIcon || (sale ? tokenFound ? tokenFound.icon : <NewCMCG /> : <NewCMCGBlack />);

    const usdPrice = formatPrice((priceFormatted ? +priceFormatted : 0) * coinPrice.usd);
    {
        /* ? formatPrice(+ethers.utils.formatEther(priceFormatted?.toString() ?? 0), valuePrecision) */
    }
    return (
        <PriceDetail sale={sale} className={className}>
            {coinLoading ? (
                <Skeleton
                    circle
                    width="1rem"
                    height="1rem"
                    baseColor="#515159"
                    style={{
                        marginRight: 10,
                    }}
                />
            ) : (
                <Icon>{finalIcon}</Icon>
            )}
            <TextWrapper>
                <CoinPrice loading={coinLoading} skeleton={{ width: '2.5rem' }}>
                    {sale && (priceFormatted || priceFormatted === 0)
                        ? formatPrice(+priceFormatted, valuePrecision)
                        : '-'}
                </CoinPrice>
                <USDPrice loading={coinLoading} skeleton={{ width: '2.5rem' }}>
                    {sale && (priceFormatted || priceFormatted === 0) ? `$${usdPrice}` : '-'}
                </USDPrice>
            </TextWrapper>
        </PriceDetail>
    );
};

export default NftPrice;
