import { Form as AntdForm, Radio, Switch, Checkbox } from 'antd';
import UploadImage from 'components/base/baseModules/uploadImage';
import InputNumber from 'components/base/baseComponents/inputNumber';
import Text from 'components/base/text';

import Input from 'components/base/baseComponents/input';
import InputUrl from 'components/base/baseComponents/inputUrl';
import Select from 'components/base/baseComponents/select';
import TextArea from 'components/base/baseComponents/textarea';
import styled from 'styled-components';
import buttonOutline from 'components/base/baseComponents/button/buttonOutline';

export const BaseForm = styled(AntdForm.Item)`
    margin-top: 8px;
    margin-bottom: 0px;
    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
        padding: 0px !important;
    }
    .ant-form-item {
        margin-bottom: 0px;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-requirzed-mark-optional)::before {
        display: none !important;
        &:after {
            content: '';
        }
    }
    .ant-form-item-control {
        min-height: 80px;
    }
    .ant-form-item-label > label::after {
        content: '' !important;
    }

    label {
        font-weight: 400;
        color: #ffffff;
        font-size: 1rem;
        line-height: 1rem;
        padding-bottom: 0.5rem;
        height: auto;
    }

    input::placeholder {
        font-size: 1rem;
    }

    .ant-form-item-control-input-content {
        position: relative;

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #3b4167, #3b4167), #0f0e1a;
            border-radius: 0.375rem;
        }
    }

    .ant-form-item-explain-success {
        padding-top: 0.0625rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: #52c41a;
    }

    .ant-form-item-explain-error,
    .ant-form-item-explain-validating {
        padding-top: 0.0625rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
    }

    .ant-form-vertical .ant-form-item-label > label,
    .ant-col-24.ant-form-item-label > label,
    .ant-col-xl-24.ant-form-item-label > label {
        margin: 0;
        color: #0f0e19;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        label {
            font-size: 0.875rem;
            line-height: 0.875rem;
            margin-bottom: 0;
        }
    }
`;

export const CollectionFormContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 12.5rem;
`;

export const CollectionFormWrapper = styled.div`
    padding: 0rem 2.5rem;
    max-width: 50rem;
    width: 50rem;
`;

export const FormBlock = styled(AntdForm)``;

export const SwitchButton = styled(Switch)`
    .ant-switch-checked {
        background: #992424 !important;
    }
`;

export const CheckBoxButton = styled(Checkbox)`
    .ant-switch-checked {
        background: #992424 !important;
    }
`;

export const TitleBlock = styled.div``;

export const MainTitle = styled(Text)`
    font-style: normal;
    font-weight: normal;
    text-align: center;
    line-height: 1.3;
    font: normal 500 2rem/2.3125rem 'sofia-pro', sans-serif;
    margin-left: 1.0625rem;
    color: #0f0e19;
    margin: 2.5rem 0rem 0rem 0rem;
    display: inline-block;
    white-space: nowrap;
`;

export const SubTitle = styled(Text)`
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 0.25rem;
`;

export const FormItem = styled(BaseForm)``;

export const BasicInfo = styled.div`
    display: block;
    flex-grow: 1;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: flex;
        margin-top: 27px;
        column-gap: 28px;
    }
`;

export const TextInput = styled(Input)`
    color: #000 !important;
`;

export const LogoUploadFormItem = styled(BaseForm)`
    flex: 1;
    flex-direction: column;

    label {
        width: 100%;
    }

    .ant-row {
        display: inline-block !important;
    }

    .ant-form-item-control-input-content::before {
        width: 0px !important;
    }
    .ant-row {
        flex-direction: column;
    }
    .ant-form-item {
        display: flex;
        flex-direction: column;
    }

    .ant-form-item-control-input {
        flex-grow: 1;
    }

    .ant-form-item-control-input-content,
    .ant-upload-picture-card-wrapper {
        height: 100%;
    }
`;

export const LogoUpload = styled(UploadImage)``;

export const NameSymbolBlock = styled.div`
    flex-grow: 1;
`;

export const TextareaBlock = styled.div`
    margin: 0px 0px 24px 0px;
`;

export const CustomTextarea = styled(TextArea)`
    height: 75px !important;
    .ant-input {
        height: 75px !important;
    }
`;

export const UrlInput = styled(InputUrl)`
    padding: 0 !important;
    border: none !important;
    .ant-input-group-addon {
        background: #0f0e19 !important;
        border-color: transparent !important;
        border-right: none;
        padding-right: 0rem !important;
        padding-left: 0.75rem !important;
        height: 100% !important;
        color: rgba(255, 255, 255, 0.6);
        margin: 0.25rem !important;
        border-bottom-left-radius: 0.375rem;
        border-top-left-radius: 0.375rem;
        box-shadow: none !important;
    }
    .ant-input {
        background: #0f0e19 !important;
        border-color: transparent !important;
        border-left: none !important;
        height: 100% !important;
        padding-left: 0rem !important;
        border-radius: 0.375rem;
        box-shadow: none !important;
        border-color: none;
    }
    .ant-input-group {
        height: 100% !important;
        border-radius: 0.375rem;
        position: relative;
    }
    .ant-input-wrapper {
        padding: 1px;
    }
    .ant-input-prefix {
        margin-right: 0px;
    }
    .ant-input-affix-wrapper {
        height: 100%;
        border: transparent !important;
        padding-left: 0px;
        box-shadow: none;
        background: #0f0e19 !important;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
    }
    .ant-input-affix-wrapper-focused {
        box-shadow: none !important;
    }
    .ant-input-status-validating {
        border: none !important;
    }
    .ant-input-status-error {
        border: none !important;
    }
    .ant-form-item-control-input-content {
        background: unset !important;
        &:before {
            background: #0f0e19 !important;
            border-color: #3b4167;
        }
    }

    &.ant-input-group-wrapper-status-error {
        .ant-input-wrapper {
            background: #ff4d4f;
        }
    }
`;

export const CoverUploadFormItem = styled(BaseForm)`
    width: 70%;
    flex-direction: column;

    label {
        width: 100%;
    }

    .ant-form-item-label {
        margin-bottom: 2px;
        line-height: 0;
    }
    .ant-row {
        display: inline-block !important;
    }
    .ant-form-item-control-input-content::before {
        width: 0px !important;
    }
    .ant-row {
        flex-direction: column;
    }
    .ant-form-item {
        display: flex;
        flex-direction: column;
    }

    .ant-form-item-control-input {
        flex-grow: 1;
    }

    .ant-form-item-control-input-content,
    .ant-upload-picture-card-wrapper {
        height: 100%;
    }
`;

export const CoverUpload = styled(UploadImage)`
    .ant-upload.ant-upload-select-picture-card {
        aspect-ratio: 14/5;
        width: 100%;
        height: 15.625rem;
    }
`;

export const GridContractTypeBlock = styled.div`
    margin-top: 22px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 38px;
    align-items: center;
`;

export const ContractTypeSelect = styled(Select)``;

export const CategorySelect = styled(Select)``;

export const GridPermissionBlock = styled.div`
    margin-top: 0px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 38px;
    align-items: center;
`;

export const PermissionBlock = styled.div`
    .ant-form-item-control-input-content::before {
        background: unset !important;
    }
`;

export const RoyaltyBlock = styled.div`
    .ant-form-item-control-input-content::before {
        background: unset !important;
    }
    margin: 10px 0;
`;

export const InputNumberCustom = styled(InputNumber)``;

export const RadioWithoutHand = styled(Radio)`
    cursor: url(/assets/images/cursor-white.svg), pointer;

    .ant-radio-checked .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.pink};
    }

    .ant-radio-inner::after {
        background-color: ${({ theme }) => theme.colors.pink};
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.pink};
    }
`;

export const ButtonCreate = styled(buttonOutline)`
    border: 0.0625rem solid rgb(42, 123, 199);
    background: #2a7bc7 !important;
    color: #fff;
    height: 48px;
    width: 100%;
    border-radius: 0.375rem;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border: unset;
    padding-block: 1rem;

    &:hover {
        border: 0.0625rem solid #a1b7f8;
    }
`;
