import { forwardRef } from 'react';
import styled from 'styled-components';

export const Container = styled(
    forwardRef<any, any>(({ fillSvg, children, ...props }, ref) => {
        return (
            <button ref={ref} {...props}>
                {children}
            </button>
        );
    }),
)`
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.625rem;
    cursor: url(/assets/images/cursor.svg), pointer;
    border: 0.0625rem solid white;
    border-radius: 0.375rem;
    color: ${({ theme }) => theme.button.outline.default.color};
    background: ${({ theme }) => theme.button.outline.default.background};

    span {
        color: ${({ theme }) => theme.button.outline.default.color};
    }

    &:not(:disabled) {
        cursor: url(/assets/images/cursor-white.svg), pointer;

        &:hover,
        &:focus,
        &:active {
            border: 0.0625rem solid ${({ theme }) => theme.button.outline.hover?.background};
            background: ${({ theme }) => theme.button.outline.hover?.background};
            color: ${({ theme }) => theme.button.outline.hover?.color};

            span {
                color: ${({ theme }) => theme.button.outline.hover?.color};
            }
        }
    }

    &:disabled {
        border: ${({ theme }) => theme.button.outline.disabled?.border};
        background: ${({ theme }) => theme.button.outline.disabled?.background};
        opacity: 0.7;
    }
`;
