import styled from 'styled-components';

export const MarkdownContainerView = styled.div`
    img {
        margin: 0;
        border: 0;
    }

    p {
        margin: 1em 0;
        color: ${({ theme }) => theme.text.color};
        white-space: pre-line;
    }

    a {
        color: #5a87f3;
        white-space: pre-line;
    }

    a:visited {
        color: #5a87f3;
        background-color: transparent;
    }

    a:active {
        color: #5a87f3;
        background-color: transparent;
    }

    a:hover {
        text-decoration: none;
    }

    p img {
        border: 0;
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: ${({ theme }) => theme.title.color};
        background-color: transparent;
        margin: 0.25em 0 0.5em;
        font-weight: normal;
        line-height: 1;
        white-space: pre-line;
    }

    h1 {
        font-size: 2.8125rem;
    }

    h2 {
        font-size: 2.1875rem;
    }

    h3 {
        font-size: 1.5625rem;
    }

    h4 {
        font-size: 1.125rem;
    }

    h5 {
        font-size: 1rem;
    }

    h6 {
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.125rem;
    }

    dt {
        font-style: italic;
    }

    dd {
        margin-bottom: 1.5em;
    }

    ul {
        list-style-image: url(/assets/images/triangle.svg);
        padding-left: 0;

        li {
            margin: 0 0.625rem 0 1.375rem;
            color: ${({ theme }) => theme.text.color};
        }

        li:not(:last-child) {
            margin-bottom: 0.625rem;
        }
    }

    li {
        line-height: 1.5em;
    }

    code {
        padding: 0.1em;
        font-size: 0.875rem;
        border: 0.0625rem solid #efefef;
        font-family: monospace, san-serif;
        white-space: pre-line;
    }

    pre {
        padding: 0.5em;
        line-height: 1.25em;
        border: 1px solid #efefef;
        border-bottom: 1px solid #ddd;
        white-space: pre-line;
    }

    pre code {
        background-color: transparent;
        border-width: 0;
        white-space: pre-line;
    }

    blockquote {
        padding-left: 1.25rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.6;

        p {
            color: ${({ theme }) => theme.text.color};
            font-size: 1.125rem;
            line-height: 1.3;
            font-weight: 400;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 400;
        }

        :nth-of-type(even) {
            border-left: 0.3125rem solid 0.0625rem solid #a1b7f8;
        }

        :nth-of-type(odd) {
            border-left: 0.3125rem solid #3e5ba7;
        }
    }

    table {
        border-collapse: collapse;
        border: 1px solid #efefef;
        border-bottom: 1px solid #ddd;
    }

    td,
    th {
        border: 0.0625rem solid #ddd;
        padding: 0.5em;
    }

    th {
    }
`;
