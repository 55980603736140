import { notification } from "antd";
import { ErrorNoti, WarningNoti, SuccessNoti } from "components/assets";
import { ReactNode } from "react";

export type NOTIFICATION_TYPE = "success" | "info" | "warning" | "error";

interface NotificationProps {
  type: NOTIFICATION_TYPE;
  description?: ReactNode;
  message?: ReactNode;
  duration?: number;
  icon?: ReactNode;
  className?: string;
}

export const Notification = ({
  type,
  description = "",
  message,
  duration = 4.5,
  icon = undefined,
  className,
}: NotificationProps) => {
  const getIcon = () => {
    switch (type) {
      case "error":
        return <ErrorNoti />;

      case "success":
        return <SuccessNoti />;

      default:
        return <WarningNoti />;
    }
  };

  return notification[type]({
    message,
    description,
    duration,
    getContainer: () => document.getElementById("popup-root") as HTMLElement,
    icon: icon ?? getIcon(),
    placement: "bottomLeft",
    className: description ? className : "no-desc",
  });
};
