export const BackPagination = () => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.21529 0.35696C9.42675 0.52742 9.54554 0.758584 9.54554 0.999615C9.54554 1.24065 9.42675 1.47181 9.21529 1.64227L3.63185 6.14176L9.21529 10.6413C9.42076 10.8127 9.53445 11.0423 9.53188 11.2806C9.52931 11.519 9.41068 11.747 9.20155 11.9155C8.99242 12.084 8.70951 12.1796 8.41376 12.1817C8.11801 12.1838 7.83308 12.0921 7.62034 11.9266L1.23943 6.78442C1.02797 6.61396 0.90918 6.38279 0.90918 6.14176C0.90918 5.90073 1.02797 5.66957 1.23943 5.49911L7.62034 0.35696C7.83187 0.186551 8.11872 0.0908204 8.41782 0.0908204C8.71691 0.0908204 9.00377 0.186551 9.21529 0.35696Z"
        fill="white"
      />
    </svg>
  );
};

export const NextPagination = () => {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.512249 0.35696C0.300788 0.52742 0.181995 0.758584 0.181995 0.999615C0.181995 1.24065 0.300788 1.47181 0.512249 1.64227L6.09569 6.14176L0.512249 10.6413C0.306781 10.8127 0.193088 11.0423 0.195658 11.2806C0.198228 11.519 0.316855 11.747 0.525989 11.9155C0.735124 12.084 1.01803 12.1796 1.31378 12.1817C1.60953 12.1838 1.89446 12.0921 2.10719 11.9266L8.48811 6.78442C8.69957 6.61396 8.81836 6.38279 8.81836 6.14176C8.81836 5.90073 8.69957 5.66957 8.48811 5.49911L2.10719 0.35696C1.89567 0.186551 1.60882 0.0908204 1.30972 0.0908204C1.01062 0.0908204 0.723774 0.186551 0.512249 0.35696Z"
        fill="#fff"
      />
    </svg>
  );
};
