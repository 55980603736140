import { FC } from 'react';

export const ExpandSidebarIcon: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g opacity="0.5">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 15.0714V2.92857C17 1.58731 15.9127 0.5 14.5714 0.5H2.42857C1.08731 0.5 -2.38419e-07 1.58731 -2.38419e-07 2.92857V15.0714C-2.38419e-07 16.4127 1.08731 17.5 2.42857 17.5H14.5714C15.9127 17.5 17 16.4127 17 15.0714Z"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5714 15.0714V2.92857C14.5714 1.58731 13.4841 0.5 12.1429 0.5H14.5714C15.7857 0.5 17 1.58731 17 2.92857V15.0714C17 16.4127 15.7857 17.5 14.5714 17.5H12.1429C13.4841 17.5 14.5714 16.4127 14.5714 15.0714Z"
                    fill="black"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.50021 12.6441L12.1431 9.00126L8.50021 5.3584"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M2.42878 9H12.1431" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};

export const CollapseSidebarIcon: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g opacity="0.5">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 15.0714V2.92857C0 1.58731 1.08731 0.5 2.42857 0.5H14.5714C15.9127 0.5 17 1.58731 17 2.92857V15.0714C17 16.4127 15.9127 17.5 14.5714 17.5H2.42857C1.08731 17.5 0 16.4127 0 15.0714Z"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.42857 15.0714V2.92857C2.42857 1.58731 3.51588 0.5 4.85714 0.5H2.42857C1.21429 0.5 0 1.58731 0 2.92857V15.0714C0 16.4127 1.21429 17.5 2.42857 17.5H4.85714C3.51588 17.5 2.42857 16.4127 2.42857 15.0714Z"
                    fill="black"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.49979 12.6441L4.85693 9.00126L8.49979 5.3584"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M14.5712 9H4.85693" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};
