import { createSlice } from "@reduxjs/toolkit";
import { IInfoData } from "models";

interface IAccount {
  accounts: IInfoData[];
}

const initialState: IAccount = {
  accounts: [],
};

export const accountSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    updateAccount: (state, action) => {
      state.accounts = state.accounts.map((acc) => {
        if (action.payload.id === acc.id) {
          return {
            ...acc,
            ...action.payload,
          };
        }

        return acc;
      });
    },
  },
});

export const { setAccounts, updateAccount } = accountSlice.actions;
