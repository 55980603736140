import { FC } from "react";
import { useCountDown } from "utils/hooks/useCountDown";
interface CountDownProps {
  timestamp: number;
  text?: string;
  onFinish?: () => void;
}

export const CountDown: FC<CountDownProps> = ({
  timestamp,
  text,
  onFinish = () => {},
}) => {
  const { days, hours, minutes, seconds, time } = useCountDown(
    timestamp,
    onFinish
  );

  return (
    <>
      <span>
        {`${text} in ${days !== "00" ? days : ""} ${
          time.days > 1 ? "days" : time.days === 0 ? "" : "day"
        }`}
        {time.days < 1 && `${hours}:${minutes}:${seconds}`}
      </span>
    </>
  );
};
