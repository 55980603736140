import { FC, memo } from "react";
import * as Styled from "./connectWallet.style";
import { WalletIcon } from "components/assets/wallet.icon";
import { IConnectWalletProps } from "./connectWallet.props";
import { useTranslation } from "react-i18next";
import { mouseEnter, mouseLeave } from "utils";

const ConnectWallet: FC<IConnectWalletProps> = ({
  w,
  h,
  className,
  onClick,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "common" });

  return (
    <Styled.Button
      w={w}
      h={h}
      className={className}
      role="button"
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <Styled.Content>
        <Styled.Icon>
          <WalletIcon />
        </Styled.Icon>
        <Styled.Title level={5}>{t("connectWallet.title")}</Styled.Title>
        <Styled.Text>{t("connectWallet.text")}</Styled.Text>
      </Styled.Content>
    </Styled.Button>
  );
};

export default memo(ConnectWallet);
