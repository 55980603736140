import React from "react";
import Skeleton from "../skeleton";
import { ITitleProps } from "./title.props";
import { H1, H2, H3, H4, H5, H6 } from "./title.style";

const TitleView: React.FC<ITitleProps> = ({
  children,
  loading,
  skeleton,
  skeletonLineAlign,
  ...props
}: ITitleProps) => {
  const skeArrgs = loading
    ? Array.isArray(skeleton)
      ? skeleton
      : skeleton
      ? [skeleton]
      : [{}]
    : [];
  let RenderComponent: any;

  switch (props.level) {
    case 1:
      RenderComponent = H1;
      break;
    case 2:
      RenderComponent = H2;
      break;
    case 3:
      RenderComponent = H3;
      break;
    case 4:
      RenderComponent = H4;
      break;
    case 5:
      RenderComponent = H5;
      break;

    default:
      RenderComponent = H6;
      break;
  }

  return (
    <RenderComponent
      {...props}
      isSkeletonMultiLine={skeArrgs.length > 1}
      skeletonLineAlign={skeletonLineAlign}
    >
      {skeArrgs.length > 0
        ? skeArrgs.map((ske, idx) => <Skeleton key={idx} {...ske} />)
        : children}
    </RenderComponent>
  );
};

export default React.memo(TitleView);
