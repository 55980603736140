export const Badge = ({ className }: { className?: string }) => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="6" cy="6" r="6" fill="0.0625rem solid #a1b7f8 " />
        </svg>
    );
};
