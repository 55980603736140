import { LinkSVG } from "components/assets/location.icon";
import { FC, memo } from "react";
import Skeleton from "react-loading-skeleton";
import { IInputUrlProps } from "./inputUrl.props";
import { Input as InputStyled } from "./inputUrl.style";

const InputUrl: FC<IInputUrlProps> = ({
  skeleton,
  loading,
  prefixIcon,
  isShowPrefixIcon,
  isDomainGuard,
  ...rest
}) => {
  return loading ? (
    <Skeleton {...skeleton} />
  ) : (
    <InputStyled
      prefix={isShowPrefixIcon ? prefixIcon || <LinkSVG /> : <></>}
      {...rest}
    />
  );
};

export default memo(InputUrl);
