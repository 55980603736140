import { useWeb3React } from '@web3-react/core';
import { useDAppProvider } from 'context';
import { ethers } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { Falsy } from 'type/models';
import { debugContractError } from 'utils/debug/contract.debug';

export interface ITransactionOptions {
    signer?: ethers.Signer;
    transactionName?: string;
}

export interface ITransactionState {
    status: 'PendingSignature' | 'Mining' | 'Success' | 'Fail' | 'Exception' | 'None';
    transaction?: ethers.providers.TransactionResponse;
    receipt?: ethers.providers.TransactionReceipt;
    message?: string | Error;
}

export interface IOverrides {
    gasPrice?: any;
    gasLimit?: any;
    value?: any;
    none?: any;
}

export interface ChainCall {
    address: string;
    data: string;
}

export function connectContractToSigner(
    contract: ethers.Contract,
    options?: ITransactionOptions,
    web3Provider?: ethers.providers.Web3Provider,
) {
    if (contract.signer) {
        return contract;
    }
    if (options?.signer) {
        return contract.connect(options.signer);
    }
    if (web3Provider?.getSigner) {
        return contract.connect(web3Provider.getSigner());
    }
    throw new TypeError('No signer available in contract, options or library');
}

export const useContractFunction = (
    contract: ethers.Contract | undefined,
    functionName: string | undefined,
    options?: ITransactionOptions,
) => {
    const [state, setState] = useState<ITransactionState>({
        status: 'None',
    });

    const { account, library: provider } = useWeb3React();

    const send = useCallback(
        async (...params: any[]): Promise<ITransactionState> => {
            if (provider && account) {
                if (!contract || !functionName) {
                    const error: ITransactionState = {
                        status: 'Exception',
                        message: 'Contract or method is invalid!',
                    };
                    setState(error);
                    return error;
                }

                try {
                    setState({
                        status: 'PendingSignature',
                    });

                    const contractWithSigner = connectContractToSigner(contract, options, provider);
                    // const provider = new ethers.providers.JsonRpcProvider(REACT_APP_MORALIS_RPC_URL);
                    const providerGasPrice = await provider.getGasPrice();

                    const gasLimit = await contractWithSigner.estimateGas[functionName](...params, {
                        gasPrice: providerGasPrice,
                    });
                    let transaction: ethers.providers.TransactionResponse;
                    if (gasLimit) {
                        transaction = await contractWithSigner[functionName](...params);
                    } else {
                        transaction = await contractWithSigner[functionName](...params, {
                            gasLimit: 400000,
                        });
                    }

                    setState({
                        status: 'Mining',
                        transaction,
                    });

                    const receipt: ethers.providers.TransactionReceipt = await transaction.wait();

                    setState({
                        status: 'Success',
                        transaction,
                        receipt,
                    });

                    return { status: 'Success', transaction, receipt };
                } catch (error: any) {
                    debugContractError(error);
                    setState({
                        status: 'Fail',
                        message: error,
                    });

                    return {
                        status: 'Fail',
                        message: error,
                    };
                }
            } else {
                console.error('Provider is not initilazed yet');

                const error: ITransactionState = {
                    status: 'Exception',
                    message: 'Provider is not initilazed yet',
                };
                setState(error);
                return error;
            }
        },
        [provider, account, contract, functionName, options],
    );

    const resetState = useCallback(() => {
        setState({ status: 'None' });
    }, [setState]);

    return { state, send, resetState };
};

export interface ContractCall {
    contract: ethers.Contract;
    method: string;
    args: any[];
}

export const encodeCallData = (call: ContractCall | undefined): ChainCall | undefined => {
    if (!call || !call.contract || !call.method) {
        return undefined;
    }
    try {
        return {
            address: call.contract.address,
            data: call.contract.interface.encodeFunctionData(call.method, call.args),
        };
    } catch {
        return undefined;
    }
};

export interface IConfigs {
    saveState?: boolean;
    autoFetch?: boolean;
    intervalTime?: number;
}

export const useContractCall = <T = any>(
    call: ContractCall | Falsy,
    { saveState = true, autoFetch = false, intervalTime = 5000 }: IConfigs = {},
) => {
    const [value, setValue] = useState<T | undefined>(undefined);
    const [state, setState] = useState<'None' | 'Loading' | 'Finish' | 'Error' | 'Exception'>('None');
    const [errorMessage, setErrorMessage] = useState<Error | undefined>(undefined);

    const { provider } = useDAppProvider();

    if (!call) call = undefined;
    if (call && !call.args) call.args = [];
    const callEncoded = encodeCallData(call);
    const stringCallEncoded = JSON.stringify(callEncoded);

    const handleFetchData = useCallback(
        async (...params: any[]): Promise<T | undefined> => {
            if (provider) {
                if (!call) return undefined;
                try {
                    if (saveState) {
                        setState('Loading');
                    }
                    const contractWithProvider = call.contract.connect(provider);
                    const finalArgs = params.length > 0 ? params : call.args;
                    const res: T = await contractWithProvider[call.method](...finalArgs);
                    if (saveState) {
                        setState('Finish');
                        setValue(res);
                    }
                    return res;
                } catch (error: any) {
                    setState('Error');
                    setErrorMessage(error);
                }
            } else {
                // console.error("Provider is not initilazed yet");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [provider, stringCallEncoded, saveState],
    );

    useEffect(() => {
        if (!!provider) handleFetchData();
    }, [provider, stringCallEncoded, handleFetchData]);

    useEffect(() => {
        let id: any;

        const refetch = () => {
            id = setTimeout(() => {
                if (provider && call) {
                    call.contract
                        .connect(provider)
                        [call.method](...call.args)

                        .then((res: any) => {
                            setValue(res);
                        })
                        .finally(refetch);
                }
            }, intervalTime);
        };

        if (autoFetch && state === 'Finish') {
            refetch();
        }

        return () => {
            clearTimeout(id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider, stringCallEncoded, autoFetch, intervalTime, state]);

    return {
        value,
        fetch: handleFetchData,
        loading: state === 'Loading',
        finish: state === 'Finish',
        error: state === 'Error' && errorMessage,
    };
};

export const excuteContractCall = async <V, T extends any[] = [...any]>(
    params: T,
    {
        method,
        contract,
        provider,
    }: {
        method: string;
        contract: ethers.Contract;
        provider: ethers.providers.Provider;
    },
) => {
    try {
        const contractWithProvider = contract.connect(provider);
        const result = await contractWithProvider[method](...params);

        return { ok: true, result: result as V };
    } catch (error) {
        return {
            ok: false,
            error,
        };
    }
};

export const excuteContractFunction = <T extends [...any], P extends any[] = [...args: T, override?: IOverrides]>(
    contract: ethers.Contract,
    method: string,
    provider: ethers.providers.Web3Provider,
) => {
    const handleSend = async (...params: P): Promise<ITransactionState> => {
        try {
            const contractWithSigner = connectContractToSigner(contract, undefined, provider);
            const transaction: ethers.providers.TransactionResponse = await contractWithSigner[method](...params);
            const receipt: ethers.providers.TransactionReceipt = await transaction.wait();

            return { status: 'Success', transaction, receipt };
        } catch (error: any) {
            debugContractError(error);
            return { status: 'Fail', message: error };
        }
    };
    return { send: handleSend };
};
