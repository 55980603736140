import { FC, memo } from "react";
import Skeleton from "react-loading-skeleton";
import { mouseEnter, mouseLeave } from "utils";
import RoundedButtonProps from "./roundedButton.props";
import { StyledButton } from "./roundedButton.style";

const RoundedButton: FC<RoundedButtonProps> = ({
  skeleton = { loading: false },
  isPink,
  children,
  onClick,
  loading,
  ...rest
}) => {
  const { loading: skeletonLoading, ...skeletonProps } = skeleton;
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    mouseLeave();
  };

  return (
    <StyledButton
      isPink={isPink}
      onMouseEnter={loading ? undefined : mouseEnter}
      onMouseLeave={loading ? undefined : mouseLeave}
      onClick={handleClick}
      loading={loading}
      {...rest}
    >
      {skeletonLoading ? <Skeleton {...skeletonProps} /> : <>{children}</>}
    </StyledButton>
  );
};

export default memo(RoundedButton);
