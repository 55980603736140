export const TREASURY = '0x775B5413cC25bD6640F76da2f8DFa932702A38aC';
export const CMCG = '0xe80b0fBc993abEcB228381AAD751C444b7b0705a';
export const TOKEN_MINT_ERC721 = '0xA67a56F1F4771E60Ce8882C2c740EF7ec4Ee752a';
export const TOKEN_MINT_ERC1155 = '0x23e6737624582C605425d740A12cD13570F2B4eC';
export const MKP_MANAGER = '0x79Fc4882CAc366bF50150a42F2CDbdd866F9004a';
export const COLLECTION_FACTORY = '0xD9cd6D0b4128202F3F14b8f7a5c22ed60eC122e4';
export const AUCTION_FACTORY = '0xBA2233068f0B445DE2E6D6725b396aF4Ac149444';

export const PANCAKE_ROUTER = '0xD99D1c33F9fC3444f8101754aBC46c52416550D1';
export const BUSD = '0x0E1e5b636Ae7CaeA48FA63Ec8ce428ba89b2BbF8';
export const BNB = '0x0000000000000000000000000000000000000000';

export const MTVS_MANAGER = '0xe099337347aa04025AD15B512dE2ab4dA4B99d53';
export const META_DROP = '0x33485F930fFC1e3ED800ee931708a2C4fc36C969';
export const META_CITIZEN = '0x4555A76aa5e98596DCb54FE3673889DAa90806A4';
export const ORDER_MANAGER = '0x00728F91a92eB2E29eb863D0586366c260f4079A';
export const STAKING_30_DAY = '0x55518E87c58E6450Ca33d2bf29679F39AbA548d5';
export const STAKING_60_DAY = '0x22873759D252728186a7f2Ce3b3F39336472873d';
export const STAKING_90_DAY = '0x97Efed33018FC81F2C5Ffc39770c1d6f7c46eE71';
export const POOL_FACTORY = '0x2C7f6369aB3688cE890dcC139371099c18dD475e';
