import { AboutIcon, Report } from 'components/assets';
import { CollectionIcon } from 'components/assets/sidebar/collection';
import { Membership } from 'components/assets/sidebar/membership';
import { Staking, StakingMobile } from 'components/assets/sidebar/staking';
import { UserRole } from 'models';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportWrapper } from 'theme/default/sider/sider.style';

import {
    AboutMobile,
    Accounts,
    Events,
    EventsMobile,
    Home,
    HomeMobile,
    Land,
    LandMobile,
    Marketplace,
    Support,
} from '../../components/assets/sidebar';

export type ITEM_MENU = {
    id: string;
    title: string;
    icon: JSX.Element;
    iconMobile: JSX.Element;
    children?: ITEM_MENU[];
    role?: UserRole.Admin | UserRole.Brand | UserRole.User | undefined;
};

export const useMenu = () => {
    const { t } = useTranslation();
    const menu: ITEM_MENU[] = useMemo(() => {
        return [
            {
                id: 'ft/createFt',
                title: t('Create Token'),
                icon: <Home />,
                iconMobile: <Home />,
            },
            {
                id: 'ft/tokenInfo',
                title: t('Token Infomation'),
                icon: <Home />,
                iconMobile: <Home />,
            },
            {
                id: 'ft/tokenVerify',
                title: t('Verify Token'),
                icon: <Home />,
                iconMobile: <Home />,
            },
            // {
            //     id: '/',
            //     title: t('sider.menu.0.key'),
            //     icon: <Home />,
            //     iconMobile: <Home />,
            // },
            // {
            //     id: '/marketplace',
            //     title: t('sider.menu.1.key'),
            //     icon: <Marketplace />,
            //     iconMobile: <Marketplace />,
            // },
            // {
            //   id: "/land",
            //   title: t("sider.menu.2.key"),
            //   icon: <Land />,
            //   iconMobile: <LandMobile />,
            // },
            // {
            //   id: "/upcoming-mints",
            //   title: t("sider.menu.3.key"),
            //   icon: <Land />,
            //   iconMobile: <LandMobile />,
            // },
            // {
            //     id: '/dashboard',
            //     title: t('sider.menu.4.key'),
            //     icon: <LandMobile />,
            //     iconMobile: <LandMobile />,
            //     role: UserRole.Brand,
            // },
            // {
            //   id: "/staking",
            //   title: t("sider.menu.5.key"),
            //   icon: <Staking />,
            //   iconMobile: <StakingMobile />,
            // },
            // {
            //   id: "events",
            //   title: t("sider.menu.6.key"),
            //   icon: <Events />,
            //   iconMobile: <EventsMobile />,
            //   children: [
            //     {
            //       id: "/upcoming-events",
            //       title: t("sider.menu.6.children.0"),
            //       icon: <Events />,
            //       iconMobile: <EventsMobile />,
            //     },
            //     {
            //       id: "/past-events",
            //       title: t("sider.menu.6.children.1"),
            //       icon: <Events />,
            //       iconMobile: <EventsMobile />,
            //     },
            //   ],
            // },
            // {
            //     id: '/collections',
            //     title: 'Collections',
            //     icon: (
            //         <ReportWrapper>
            //             <CollectionIcon />
            //         </ReportWrapper>
            //     ),
            //     iconMobile: (
            //         <ReportWrapper>
            //             <CollectionIcon />
            //         </ReportWrapper>
            //     ),
            // },
            // {
            //     id: '/auctions',
            //     title: 'Auctions',
            //     icon: (
            //         <ReportWrapper>
            //             <Events />
            //         </ReportWrapper>
            //     ),
            //     iconMobile: (
            //         <ReportWrapper>
            //             <Events />
            //         </ReportWrapper>
            //     ),
            // },
            // {
            //     id: '/about',
            //     title: t('sider.menu.7.key'),
            //     icon: <Land />,
            //     iconMobile: <LandMobile />,
            // },
        ];
    }, [t]);

    return menu;
};

export const useAdminMenu = () => {
    const { t } = useTranslation();
    const adminMenu: ITEM_MENU[] = useMemo(() => {
        return [
            {
                id: '/admin',
                title: t('sider.adminMenu.0.key'),
                icon: <Home />,
                iconMobile: <HomeMobile />,
            },
            {
                id: '/admin/marketplace',
                title: t('sider.adminMenu.1.key'),
                icon: <Marketplace />,
                iconMobile: <Marketplace />,
            },
            {
                id: '/admin/events',
                title: t('sider.adminMenu.2.key'),
                icon: <Events />,
                iconMobile: <Events />,
            },
            {
                id: '/admin/accounts',
                title: t('sider.adminMenu.3.key'),
                icon: <Accounts />,
                iconMobile: <HomeMobile />,
            },
            {
                id: '/admin/brands',
                title: t('sider.adminMenu.4.key'),
                icon: <Accounts />,
                iconMobile: <HomeMobile />,
            },
            {
                id: '/admin/support',
                title: t('sider.adminMenu.5.key'),
                icon: <Support />,
                iconMobile: <Support />,
                children: [],
            },
            {
                id: '/admin/report',
                title: t('sider.adminMenu.6.key'),
                icon: (
                    <ReportWrapper>
                        <Report />
                    </ReportWrapper>
                ),
                iconMobile: (
                    <ReportWrapper>
                        <Report />
                    </ReportWrapper>
                ),
                children: [],
            },
            {
                id: '/admin/membership',
                title: t('sider.adminMenu.7.key'),
                icon: (
                    <ReportWrapper>
                        <Membership />
                    </ReportWrapper>
                ),
                iconMobile: (
                    <ReportWrapper>
                        <Membership />
                    </ReportWrapper>
                ),
                children: [],
            },
        ];
    }, [t]);

    return adminMenu;
};

export const ADMIN_MENU: ITEM_MENU[] = [
    {
        id: '/admin',
        title: 'Home',
        icon: <Home />,
        iconMobile: <HomeMobile />,
    },
    {
        id: '/admin/marketplace',
        title: 'Marketplace',
        icon: <Marketplace />,
        iconMobile: <Marketplace />,
    },
    {
        id: '/admin/events',
        title: 'Events',
        icon: <Events />,
        iconMobile: <Events />,
    },
    {
        id: '/admin/accounts',
        title: 'Accounts',
        icon: <Accounts />,
        iconMobile: <HomeMobile />,
    },
    {
        id: '/admin/brands',
        title: 'Brands',
        icon: <Accounts />,
        iconMobile: <HomeMobile />,
    },
    // {
    //   id: "/admin/airdrop",
    //   title: "Airdrop",
    //   icon: <Airdrop />,
    //   iconMobile: <HomeMobile />,
    // },
    {
        id: '/admin/support',
        title: 'Support',
        icon: <Support />,
        iconMobile: <Support />,
        children: [],
    },
    {
        id: '/admin/report',
        title: 'Report',
        icon: (
            <ReportWrapper>
                <Report />
            </ReportWrapper>
        ),
        iconMobile: (
            <ReportWrapper>
                <Report />
            </ReportWrapper>
        ),
        children: [],
    },
    {
        id: '/admin/membership',
        title: 'Membership',
        icon: (
            <ReportWrapper>
                <Membership />
            </ReportWrapper>
        ),
        iconMobile: (
            <ReportWrapper>
                <Membership />
            </ReportWrapper>
        ),
        children: [],
    },
];
