import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(({ isPink, flexCenter, ...props }) => (
  <Button {...props} />
))`
  transform-style: preserve-3d;
  width: 100%;
  height: 2.5rem;
  border: 1px solid
    ${({ theme, isPink }) => (isPink ? theme.colors.pink : theme.colors.white)};
  background: ${({ theme, isPink }) =>
    isPink ? theme.colors.pink : theme.colors.background};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 1.875rem;

  span {
    position: relative;
    z-index: 10;
  }

  ${({ flexCenter }) =>
    flexCenter &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  ${({ isPink }) =>
    isPink &&
    `
    &::after {
      content: "";
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      visibility: hidden;
      border-radius: 2.5rem;
      transition: opacity 0.3s, visibility 0.3s;
      left: 0; 
      background: linear-gradient(90deg, #ce73bb 0%, #8f3981 50%, #3759a4 100%);
      filter: blur(0.875rem); 
      transform: translateZ(-0.625rem);
    }
  `}

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ isPink }) => (isPink ? "#912570" : "#902570")};
    border: 1px solid
      ${({ theme, isPink }) => (isPink ? theme.colors.pink : "#902570")};

    ${({ isPink }) =>
      isPink &&
      `
        &::after {
          opacity: 1; 
          visibility: visible;
        }
      `}
  }

  &:disable {
    border: 1px solid #999eaf;
    color: #999eaf;
  }
`;
