import { BNB, BUSD, CMCG } from 'contract-address';
import { NewCMCG } from 'components/assets';
import { BscIcon, BusdIcon } from 'components/assets/fixSidebar.icon';

export type SUPPORTED_TOKEN = typeof CMCG | typeof BUSD | typeof BNB;

export const SUPPORT_TOKEN = [
    { name: 'CMCG', address: CMCG, icon: <NewCMCG /> },
    // { name: 'BUSD', address: BUSD, icon: <BusdIcon className="none-fill" /> },
    // { name: 'BNB', address: BNB, icon: <BscIcon className="none-fill" /> },
];

export const findSupportToken = (address: string) => {
    const tokenFound = SUPPORT_TOKEN.find((item) => item.address.toLowerCase() === address?.toLowerCase());
    return tokenFound;
};
