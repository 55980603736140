import styled from 'styled-components';
import { Modal as AModal } from 'antd';

export const Modal = styled(AModal)<{ hideOnMobile?: boolean }>`
    .ant-modal-content {
        background: linear-gradient(180deg, 0.0625rem solid #a1b7f8 0%, #ffdba8 100%);
        // box-shadow: 0px 1.5rem 2.75rem 1.25rem rgba(255, 244, 244, 0.06);
        border-radius: 0.375rem;
        padding: 0.0625rem;
    }

    .ant-modal-close {
        /* display: none; */
    }

    .ant-modal-body {
        padding: 3.125rem;
        background: #0f0e19;
        border-radius: 0.375rem;
    }

    button.ant-modal-close:hover {
        cursor: url(/assets/images/cursor-white.svg), pointer;
    }
`;

export const CloseIconWrapper = styled.div`
    svg {
        path {
            stroke: #fff;
        }
    }
`;
