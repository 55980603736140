import { MSearchBox } from "components/modules";
import styled from "styled-components";
import { NoOutlineButton } from "../chatBox/chatBox.style";

export const SearchButton = styled(NoOutlineButton)`
  height: 2.8125rem;
  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      path {
        fill: ${({ theme }) => theme.chat.searchButton.fill} !important;
      }
    }
  }
  svg path {
    fill: ${({ theme }) => theme.chat.searchButton.fill} !important;
    stroke: ${({ theme }) => theme.chat.searchButton.stroke};
  }
`;

export const SearchWrapper = styled.div<{
  showSearch?: boolean;
}>`
  position: relative;
  max-width: 100%;
  margin-left: 0;
`;

export const SearchChannelBox = styled(MSearchBox)`
  width: 15.1875rem;
  .ant-input-prefix {
    margin-right: 0;
  }

  span {
    padding-right: 0;

    svg {
      margin-right: 0.375rem;
    }
  }
`;
