import styled from 'styled-components';
import clsx from 'clsx';

const Icon: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('icon', className)}
        >
            <path
                d="M0.891113 13.7173C0.891113 2.7074 3.25086 0.347656 14.2608 0.347656H18.4125C29.4225 0.347656 31.7822 2.7074 31.7822 13.7173V17.8691C31.7822 28.879 29.4225 31.2387 18.4125 31.2387H14.2608C3.25086 31.2387 0.891113 28.879 0.891113 17.8691V13.7173Z"
                fill="#22212C"
            />
            <path
                d="M20.0439 16.685H25.8911L21.6555 9.34766L18.3211 15.1212H14.2805L15.1835 16.685H17.4181L15.8911 19.3301L10.1276 9.34766L5.89111 16.685H12.5581L15.8911 22.4577L21.6546 12.4765L23.1846 15.1212H20.95L20.0439 16.685ZM8.60066 15.1212L10.1276 12.4765L11.6555 15.1212H8.60066Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="test"
                    x1="5.07974"
                    y1="2.12542"
                    x2="27.3318"
                    y2="28.8279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="0.0625rem solid #a1b7f8 " />
                    <stop offset="1" stopColor="#4154B0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const IconMobile: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('icon', className)}
        >
            <path
                d="M0.891113 13.7173C0.891113 2.7074 3.25086 0.347656 14.2608 0.347656H18.4125C29.4225 0.347656 31.7822 2.7074 31.7822 13.7173V17.8691C31.7822 28.879 29.4225 31.2387 18.4125 31.2387H14.2608C3.25086 31.2387 0.891113 28.879 0.891113 17.8691V13.7173Z"
                fill="#22212C"
            />
            <path
                d="M20.0439 16.685H25.8911L21.6555 9.34766L18.3211 15.1212H14.2805L15.1835 16.685H17.4181L15.8911 19.3301L10.1276 9.34766L5.89111 16.685H12.5581L15.8911 22.4577L21.6546 12.4765L23.1846 15.1212H20.95L20.0439 16.685ZM8.60066 15.1212L10.1276 12.4765L11.6555 15.1212H8.60066Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="test"
                    x1="5.07974"
                    y1="2.12542"
                    x2="27.3318"
                    y2="28.8279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="0.0625rem solid #a1b7f8 " />
                    <stop offset="1" stopColor="#4154B0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const About = styled(Icon)`
    & {
        transition: opacity 0.3s;
    }
`;

export const AboutMobile = styled(IconMobile)`
    & {
        transition: opacity 0.3s;
    }
`;
