import { FC } from "react";
import { mouseEnter, mouseLeave } from "utils";
import { ISocialProps } from "./social.props";
import { Social, SocialContainer, SocialItem, Title } from "./social.style";

const SocialView: FC<ISocialProps> = ({
  title,
  className,
  items,
  flex,
  stopPropagation,
  scale = false,
  link = "",
  isExternal = false,
}) => {
  return (
    <SocialContainer className={className}>
      {title && <Title level={4}>{title}</Title>}
      <Social
        flex={flex}
        basicNumber={scale ? Math.round(100 / items.length) : undefined}
      >
        {items.map((item, idx) => (
          <a
            onClick={(e) => {
              !isExternal && stopPropagation && e.stopPropagation();
            }}
            href={`${isExternal ? item.href : item.href + link}`}
            target="_blank"
            rel="noreferrer"
            key={idx}
          >
            <SocialItem onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
              {item.icon}
            </SocialItem>
          </a>
        ))}
      </Social>
    </SocialContainer>
  );
};

export default SocialView;
