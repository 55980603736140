import { DownArrow } from "components/assets";
import Skeleton from "components/base/skeleton";
import { FC, memo } from "react";

import { mouseEnter, mouseLeave } from "utils";
import { ISelectProps } from "./select.props";
import {
  Container,
  DropdownMenu,
  Icon,
  Option,
  Select as SelectStyled,
  Wrapper,
} from "./select.style";

const Select: FC<ISelectProps> = ({
  skeleton,
  onMouseEnter,
  onMouseLeave,
  className,
  loading,
  hover = true,
  ...rest
}) => {
  if (loading)
    return (
      <Wrapper style={{ width: skeleton?.width }}>
        <Skeleton width="100%" {...skeleton} />
        <Icon>
          <DownArrow />
        </Icon>
      </Wrapper>
    );

  return (
    <Container
      className={className}
      onMouseEnter={hover ? mouseEnter : undefined}
      onMouseLeave={hover ? mouseLeave : undefined}
    >
      <SelectStyled
        {...rest}
        suffixIcon={<DownArrow />}
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
        dropdownRender={(menu) => {
          return <DropdownMenu>{menu}</DropdownMenu>;
        }}
        aria-expanded="false"
        aria-label="Select"
      />
    </Container>
  );
};

export { Option };

export default memo(Select);
