import styled from 'styled-components';

export const TicketUsers = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
`;

export const Circle = styled.div<{ background?: string }>`
    font-size: 0.6875rem;
    line-height: 1.625rem;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    border: 1px solid white;
    object-fit: cover;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;

    margin-right: -0.625rem;
    left: -0.625rem;
`;

export const Owner = styled(Circle)`
    background-color: #c4c4c4;
    background-image: url('${({ background }) => background}');
    background-size: cover;
`;

export const ShowMoreOwner = styled(Owner)`
    background-color: 0.0625rem solid #a1b7f8;
    cursor: url(/assets/images/cursor-white.svg), pointer;
`;

export const Owners = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    .ant-popover-title {
        border-bottom: none;
        padding-left: 1.125rem;
        margin-bottom: 0.5rem;
    }
`;

export const Avatar = styled(Circle)`
    margin-right: 0;
    background: none;
    left: 0;
`;

export const Span = styled.span`
    margin: 0;
`;

export const Wrapper = styled.div`
    padding-left: 1.3125rem;
    max-height: 20.25rem;
    overflow-y: auto;
`;

export const VStack = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 12.5rem;
    min-width: 10rem;
    position: relative;
    gap: 0.9375rem;
`;

export const HStack = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 0.5625rem;
    font-size: 0.875rem;
    line-height: 1.625rem;
`;

export const Title = styled.div`
    line-height: 1.625rem;
    padding-bottom: 0;
`;
