import styled from 'styled-components';
import { Card, Empty } from 'antd';
import { Text as CText, Title as CTitle, SocialView } from 'components/base';
// import { MNFTItem } from "..";

export const OtherNFT = styled(Card)`
    margin-top: 53px;
    margin-bottom: 1.875rem;
    background: ${({ theme }) => theme.marketPlaceDetail.menuBg.color};
    border: 0.0625rem solid ${({ theme }) => theme.colors.border};
    padding-bottom: 1.625rem;
    overflow: hidden;
    position: relative;
    border-radius: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding: 0;

    & > .ant-card-body {
        padding: 0;

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
            padding: 18px 30px 30px;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 3.75rem;
        margin-bottom: 0;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        background: none;
        border: none;
    }
`;

export const OtherNFTHead = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const CreatedBy = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-top: 0rem;
    }
    .ant-space {
        gap: 0 !important;
    }
`;

export const OwnerAvatar = styled.img`
    width: 3.1875rem;
    height: 3.1875rem;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
`;

export const TextMeta = styled(CText)`
    font-size: 1rem;
    text-align: unset;
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    white-space: nowrap;
    font-weight: 400;
    color: ${({ theme }) => theme.textSmall.color};
`;

export const SocialWrapper = styled.div`
    width: auto;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 368px;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: auto;
    }
`;

export const OwnerName = styled(CText)`
    font-size: 1.125rem;
    text-align: unset;
    margin-bottom: 0;
    margin-top: -5px;
    display: block;
    opacity: 1;

    a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: ${({ theme }) => theme.typography.textNormal};
        width: 100%;
        div {
            color: 0.0625rem solid #a1b7f8;
        }
    }

    a:not([disabled]) {
        padding: 0;
        color: 0.0625rem solid #a1b7f8;

        &:hover {
            background: none;
            color: 0.0625rem solid #a1b7f8;
        }
    }
`;

export const ShareContainer = styled(SocialView)`
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 100%;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-top: 1.25rem;
    }
`;

export const NFTs = styled.div`
    position: relative;
`;

export const NftContent = styled.div`
    position: relative;
    z-index: 5;
`;

export const EmptyData = styled(({ isEmpty, ...props }) => <Empty {...props} />)`
    ${({ isEmpty }) => !isEmpty && `display: none;`}
`;

export const NFTsInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.9375rem;
`;

// export const NFTItem = styled(MNFTItem)`
//   width: 14rem;
//   flex-shrink: 0;
// `;

export const Title = styled(CTitle)`
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 500;
`;

export const NFTTitle = styled(Title)`
    padding-left: 3.6rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-left: 0;
    }
    font-size: 1rem;
    font-weight: 500;
`;

export const SeeAll = styled.button`
    display: flex;
    align-items: baseline;
    gap: 0.625rem;
    background: none;
    border: none;
    cursor: url(/assets/images/cursor-white.svg), pointer;
    font-size: 1rem;
    font-weight: 500;
`;

export const NftList = styled.div<{ isEmpty: boolean }>`
    display: flex;
    touch-action: pan-x;
    justify-content: flex-start;
    gap: 1.125rem;
    cursor: grabbing;
    ${({ isEmpty }) => isEmpty && `display: none;`}

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        overflow: unset;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        gap: 0.3rem;
    }

    overflow-x: auto;
    overflow: scroll;
    flex-wrap: nowrap;
    // width: 100vw;
`;

export const Text = styled(CText)``;
