import { FC } from "react";

export const Binance: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M34.5 14.7152C34.5 2.59723 31.9028 0 19.7848 0H15.2152C3.09723 0 0.5 2.59723 0.5 14.7152V19.2848C0.5 31.4028 3.09723 34 15.2152 34H19.7848C31.9028 34 34.5 31.4028 34.5 19.2848V14.7152Z"
        fill="white"
      />
      <path
        d="M14.1346 15.0707L17.8862 11.3193L21.6397 15.0727L23.8226 12.8898L17.8862 6.95312L11.9517 12.8878L14.1346 15.0707Z"
        fill="#F3BA2F"
      />
      <path
        d="M12.5935 16.6106L10.4106 14.4277L8.22765 16.6107L10.4105 18.7936L12.5935 16.6106Z"
        fill="#F3BA2F"
      />
      <path
        d="M14.1349 18.1542L17.8864 21.9056L21.6398 18.1523L23.824 20.334L23.8229 20.3353L17.8864 26.2717L11.9518 20.3373L11.9487 20.3342L14.1349 18.1542Z"
        fill="#F3BA2F"
      />
      <path
        d="M25.3629 18.7943L27.5459 16.6113L25.363 14.4284L23.18 16.6114L25.3629 18.7943Z"
        fill="#F3BA2F"
      />
      <path
        d="M20.1003 16.6096H20.1012L17.8861 14.3945L16.2491 16.0315H16.249L16.061 16.2197L15.673 16.6077L15.6699 16.6107L15.673 16.6139L17.8861 18.8271L20.1012 16.6119L20.1023 16.6107L20.1003 16.6096Z"
        fill="#F3BA2F"
      />
    </svg>
  );
};
