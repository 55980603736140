import { ethers } from "ethers";
import CollectionFactoryAbi from "abis/Collection/CollectionFactory.sol/CollectionFactory.json";
import { COLLECTION_FACTORY } from "contract-address";


export const CollectionFactoryInterface = new ethers.utils.Interface(
    CollectionFactoryAbi.abi
);

export const CollectionFactoryContract = new ethers.Contract(
  COLLECTION_FACTORY,
  CollectionFactoryInterface
);
