import { Form as AntdForm, Radio, Switch } from 'antd';
import { BigNumber } from '@ethersproject/bignumber';

import { Notification } from 'components/base';
import { NOTIFICATION_TYPE } from 'components/base/Notification';
import { useAppSelector, useWallet } from 'hooks';
import { FC, memo, useEffect, useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import { getShortedUrlByUrl } from 'redux/apis/shortedUrl';
import { getBase64, mouseEnter, mouseLeave } from 'utils';
import { generateSlug } from 'utils/formatter';
import { isContainOnlyHyphen } from 'utils/validateText';
import { fetchData } from 'utils/fetchApi';
import {
    ECollectonFormType,
    ICollectionForm,
    ICollectionFormProps,
    IFieldValidation,
    ICategory,
    NFTType,
    CategoryType,
} from './collectionForm.props';
import {
    BasicInfo,
    ButtonCreate,
    CollectionFormContainer,
    CollectionFormWrapper,
    ContractTypeSelect,
    CategorySelect,
    CoverUpload,
    CoverUploadFormItem,
    CustomTextarea,
    FormBlock,
    FormItem,
    GridContractTypeBlock,
    GridPermissionBlock,
    InputNumberCustom,
    LogoUpload,
    LogoUploadFormItem,
    MainTitle,
    NameSymbolBlock,
    PermissionBlock,
    RoyaltyBlock,
    RadioWithoutHand,
    SubTitle,
    TextareaBlock,
    TextInput,
    TitleBlock,
    UrlInput,
    SwitchButton,
    CheckBoxButton,
} from './collectionForm.style';

import { useNavigate } from 'react-router-dom';

import { ethers } from 'ethers';
import xssFilters from 'xss-filters';

const FtForm = ({ getTokenInfo, isProcessing, tokenInfo }) => {
    const { account } = useWallet();
    const navigate = useNavigate();

    const { connectedWallet } = useAppSelector((state) => state.wallet);
    const [tokenType, setTokenType] = useState(0);
    const [form] = AntdForm.useForm();

    const [isDisable, setDisable] = useState(false);

    const [tokenAddress, setTokenAddress] = useState('');

    const getSupplyLabel = (value) => {
        if (value < 1000) return value;
        if (value >= 1000 && value < 1000000) return `≈ ${value / 1000}k`;
        if (value >= 1000000 && value < 1000000000) return `≈ ${value / 1000000} million`;
        if (value >= 1000000000 && value < 1000000000000) return `≈ ${value / 1000000000} billion`;
        if (value >= 1000000000000 && value < 1000000000000000) return `≈ ${value / 1000000000000} trillion`;
        if (value >= 1000000000000000) return `≈ ${value / 1000000000000000} quadrillion`;
    };

    const RedStarLabel = ({ children }) => (
        <span>
            <span style={{ color: 'red' }}>*</span>
            {children}
        </span>
    );
    const showNotification = (type, message) => {
        return Notification({
            type: type,
            message: message,
        });
    };

    useEffect(() => {
        if (!isProcessing) {
            setDisable(false);
        }
    }, [isProcessing]);

    return (
        <CollectionFormContainer>
            <CollectionFormWrapper>
                <FormBlock
                    form={form}
                    onFinish={() => {
                        // if (isFormError) {
                        //   return;
                        // }
                        try {
                            getTokenInfo(tokenAddress);
                        } catch (e) {
                            setDisable(false);
                        }
                        setDisable(true);
                    }}
                    autoComplete="off"
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '2px dashed #3b4167',
                            padding: '25px 0',
                            margin: '25px 0',
                        }}
                    >
                        <TitleBlock>
                            <MainTitle>Get Token Information</MainTitle>
                        </TitleBlock>
                    </div>

                    <NameSymbolBlock>
                        <FormItem
                            name="address"
                            labelCol={{ span: 24 }}
                            label={<RedStarLabel> Token Address</RedStarLabel>}
                            rules={[
                                { required: true, message: 'This field is required' },
                                () => {
                                    return {
                                        validator(_, value) {
                                            if (value && value.trim().length === 0 && value.length > 0) {
                                                return Promise.reject(new Error('Address is invalid'));
                                            }

                                            if (value && xssFilters.inHTMLData(value) !== value && value.length > 0) {
                                                return Promise.reject(new Error('Address is invalid'));
                                            }

                                            return Promise.resolve();
                                        },
                                    };
                                },
                            ]}
                            initialValue={null}
                        >
                            <TextInput
                                placeholder={'Enter Token Address'}
                                onChange={(event) => {
                                    setTokenAddress(event.target.value);
                                }}
                                disabled={isDisable}
                            />
                        </FormItem>
                    </NameSymbolBlock>
                    {tokenInfo.name && (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '45%' }}>
                                <FormItem
                                    name="name"
                                    labelCol={{ span: 24 }}
                                    label={'Name'}
                                    initialValue={tokenInfo.name}
                                >
                                    <TextInput placeholder={'Enter name'} disabled={true} />
                                </FormItem>
                                <FormItem
                                    name="symbol"
                                    labelCol={{ span: 24 }}
                                    label={'Symbol'}
                                    initialValue={tokenInfo.symbol}
                                >
                                    <TextInput placeholder={'Enter symbol'} disabled={true} />
                                </FormItem>
                            </div>
                            <div style={{ width: '45%' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <FormItem
                                        name="supply"
                                        labelCol={{ span: 24 }}
                                        label={'Total Supply'}
                                        style={{ width: '65%' }}
                                        initialValue={BigNumber.from(tokenInfo.totalSupply)
                                            .div(BigNumber.from(10).pow(tokenInfo.decimals))
                                            .toString()}
                                    >
                                        <InputNumberCustom
                                            placeholder={''}
                                            type="number"
                                            min={1}
                                            max={100000000000000000000}
                                            disabled={true}
                                        />
                                    </FormItem>
                                    <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                        {getSupplyLabel(
                                            BigNumber.from(tokenInfo.totalSupply)
                                                .div(BigNumber.from(10).pow(tokenInfo.decimals))
                                                .toString(),
                                        )}
                                    </div>
                                </div>

                                <FormItem
                                    name="decimals"
                                    labelCol={{ span: 24 }}
                                    label={'Decimals(1-18)'}
                                    initialValue={tokenInfo.decimals}
                                >
                                    <InputNumberCustom
                                        placeholder={''}
                                        type="number"
                                        min={1}
                                        max={18}
                                        disabled={true}
                                    />
                                </FormItem>
                            </div>
                        </div>
                    )}
                    {tokenInfo.createdByCryptool === null && (
                        <div style={{ backgroundColor: 'rgba(255,0,0,0.1)', padding: '10px', marginTop: '20px' }}>
                            This token is not generated by cryptool.app so cannot verify
                        </div>
                    )}

                    <ButtonCreate
                        onMouseEnter={mouseEnter}
                        onMouseLeave={mouseLeave}
                        loading={isProcessing}
                        style={{
                            padding: '25px 0',
                            margin: '25px 0',
                        }}
                    >
                        {'Get Token Info'}
                    </ButtonCreate>
                </FormBlock>
            </CollectionFormWrapper>
        </CollectionFormContainer>
    );
};

export default FtForm;
