import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { nFormatter } from './context/numberFormatter';

export const valuePrecision = 6;
export const MAX_UINT_256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const isValidNumber = (str: string, { precision = 2 } = {}): boolean => {
    // @ts-ignore
    if (!str || isNaN(str)) return false;

    const regex = new RegExp(`^\\d*(\\.\\d{0,${precision}})?$`); // allow 0 -> 'precision' decimals
    if (!regex.test(str)) return false;

    return true;
};

export const formatPrice = (num: number, valuePre?: number) => {
    if (!num || isNaN(+num)) return num.toString();

    return nFormatter(+num, valuePre ?? valuePrecision);
};

export const formatBigNumber = (balance?: BigNumber | string | number) =>
    balance ? parseFloat(formatEther(balance)) : undefined;
