import clsx from 'clsx';

export const Support: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('icon', className)}
        >
            <path
                d="M0.890625 13.8033C0.890625 2.79334 3.25037 0.433594 14.2603 0.433594H18.4121C29.422 0.433594 31.7817 2.79334 31.7817 13.8033V17.955C31.7817 28.9649 29.422 31.3247 18.4121 31.3247H14.2603C3.25037 31.3247 0.890625 28.9649 0.890625 17.955V13.8033Z"
                fill="#22212C"
            />
            <path
                d="M16 6.66406C10.48 6.66406 6 11.1441 6 16.6641C6 22.1841 10.48 26.6641 16 26.6641C21.52 26.6641 26 22.1841 26 16.6641C26 11.1441 21.52 6.66406 16 6.66406ZM23.46 13.7841L20.68 14.9341C20.17 13.5741 19.1 12.4941 17.73 11.9941L18.88 9.21406C20.98 10.0141 22.65 11.6841 23.46 13.7841ZM16 19.6641C14.34 19.6641 13 18.3241 13 16.6641C13 15.0041 14.34 13.6641 16 13.6641C17.66 13.6641 19 15.0041 19 16.6641C19 18.3241 17.66 19.6641 16 19.6641ZM13.13 9.20406L14.3 11.9841C12.92 12.4841 11.83 13.5741 11.32 14.9541L8.54 13.7941C9.35 11.6841 11.02 10.0141 13.13 9.20406ZM8.54 19.5341L11.32 18.3841C11.83 19.7641 12.91 20.8441 14.29 21.3441L13.12 24.1241C11.02 23.3141 9.35 21.6441 8.54 19.5341ZM18.88 24.1241L17.73 21.3441C19.1 20.8341 20.18 19.7541 20.68 18.3741L23.46 19.5441C22.65 21.6441 20.98 23.3141 18.88 24.1241Z"
                fill="white"
            />

            <defs>
                <linearGradient
                    id="test"
                    x1="5.07974"
                    y1="2.12542"
                    x2="27.3318"
                    y2="28.8279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="0.0625rem solid #a1b7f8 " />
                    <stop offset="1" stopColor="#4154B0" />
                </linearGradient>
            </defs>
        </svg>
    );
};
