import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";

import { injected } from "utils/connector";
import { activateInjectedProvider, useAppSelector } from "hooks";

export function useEagerConnect() {
  const { activate, active } = useWeb3React();
  const { connectedWallet, provider } = useAppSelector((state) => state.wallet);

  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (connectedWallet && provider) {
      injected.isAuthorized().then((isAuthorized: boolean) => {
        if (isAuthorized) {
          activateInjectedProvider(provider as "Metamask" | "Coinbase");
          setTimeout(
            () =>
              activate(injected, undefined, true).catch(() => {
                setTried(true);
              }),
            500
          );
        } else {
          setTried(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress: boolean = false) {
  const { active, error } = useWeb3React();
  const { connectedWallet } = useAppSelector((state) => state.wallet);

  useEffect((): any => {
    const { ethereum } = window as any;
    if (connectedWallet) {
      if (ethereum && ethereum.on && !active && !error && !suppress) {
        const handleConnect = () => {
          // console.log("Handling 'connect' event");
          // activate(injected);
        };
        const handleChainChanged = (chainId: string | number) => {
          // console.log("Handling 'chainChanged' event with payload", chainId);
          // activate(injected);
        };
        const handleAccountsChanged = (accounts: string[]) => {
          // console.log(
          //   "Handling 'accountsChanged' event with payload",
          //   accounts
          // );
          if (accounts.length > 0) {
            // activate(injected);
          }
        };
        const handleNetworkChanged = (networkId: string | number) => {
          // console.log(
          //   "Handling 'networkChanged' event with payload",
          //   networkId
          // );
          // activate(injected);
        };

        ethereum.on("connect", handleConnect);
        ethereum.on("chainChanged", handleChainChanged);
        ethereum.on("accountsChanged", handleAccountsChanged);
        ethereum.on("networkChanged", handleNetworkChanged);

        return () => {
          if (ethereum.removeListener) {
            ethereum.removeListener("connect", handleConnect);
            ethereum.removeListener("chainChanged", handleChainChanged);
            ethereum.removeListener("accountsChanged", handleAccountsChanged);
            ethereum.removeListener("networkChanged", handleNetworkChanged);
          }
        };
      }
    }
  }, [active, error, suppress, connectedWallet]);
}
