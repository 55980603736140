import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";

//useDispatch hook with types.
export const useAppDispatch = () => useDispatch<AppDispatch>();
//useSelector hook with types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export * from "./useContract";

// export * from "./useArt";
// export * from "./useUserArts";
// export * from "./useAuction";
// export * from "./useAuctionsList";
// export * from "./useBidsForAuction";
// export * from "./useUserBalance";
// export * from "./useCreator";
// export * from "./useCreatorArts";
// export * from "./useCreators";
// export * from "./useSocket";
