import { NewCMCG } from 'components/assets';
import { BscIcon, BusdIcon } from 'components/assets/fixSidebar.icon';

export const CURRENTCIES = [
    {
        name: 'CMCG',
        icon: <NewCMCG />,
    },
    // {
    //     name: 'BNB',
    //     icon: <BscIcon className="none-fill" />,
    // },
    // {
    //     name: 'BUSD',
    //     icon: <BusdIcon className="none-fill" />,
    // },
];
