import { FC, Fragment, memo } from 'react';
import { INFTListProps } from './NFTList.props';
import { List, NFTSkeleton, EmptyCart, NewNFTItem } from './NFTList.style';

export const NFTList: FC<INFTListProps> = ({
    marketItems,
    loading,
    hasFilter = false,
    skeletonLength = 8,
    handleClearFilter = () => {},
    fetchNft,
    ...rest
}) => {
    return (
        <List isEmpty={!loading && marketItems?.length === 0} {...rest}>
            {loading ? (
                [...Array(skeletonLength)].map((_, idx) => <NFTSkeleton key={idx} />)
            ) : marketItems && marketItems.length > 0 ? (
                marketItems.map(
                    (marketItem, idx) => (
                        // marketItem?.metadata && (
                        <Fragment key={idx}>
                            <NewNFTItem marketItem={marketItem} fetchNft={fetchNft} />
                        </Fragment>
                    ),
                    // )
                )
            ) : (
                <EmptyCart hasFilter={hasFilter} handleClearFilter={handleClearFilter} />
            )}
        </List>
    );
};

export default memo(NFTList);
