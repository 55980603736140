import { Select as ASelect } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
    .ant-select-dropdown {
        background: none;
        /* animation-duration: 0s; */
    }

    .ant-select {
        width: 100%;
        height: 100%;
    }
`;

export const Option = styled(ASelect.Option)`
    background: ${({ theme }) => theme.marketPlaceDetail.menuBg.color};
    display: none;
    height: 42px;
`;

export const RightBlock = styled.div`
    display: inline;
`;

export const Select = styled(ASelect)<{ $isShowInputIconBefore: boolean }>`
    width: 100%;
    &.ant-select-selector {
        background: #0f0e19;
    }
    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        background: #171626;
        color: ${({ theme }) => theme.textOrigin.color};
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0.6875rem;
        color: ${({ theme }) => theme.select.default.color};
        border: ${({ theme }) => theme.select.default.border};
        border-radius: 0.375rem;
        height: 54px;
        display: flex;
        align-items: center;
        transition: none;
        font-size: 0.875rem;
        /* background: ${({ theme }) => theme.select.default.background}; */
        background: #0f0e19;
    }

    .ant-select-arrow {
        right: 0.625rem;

        svg {
            path {
                fill: ${({ theme }) => theme.select.default.color};
            }
        }
    }

    .ant-input:placeholder-shown {
        font-size: 1rem;
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #3b4167;
        box-shadow: none;
        span {
            color: #fff;
        }
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-arrow {
        svg {
            path {
                fill: #fff;
            }
        }
    }

    .ant-select-item {
        min-height: 42px !important;
    }

    .ant-select-selection-item {
        ${({ $isShowInputIconBefore }) => {
            if (!$isShowInputIconBefore) {
                return `
        svg {
          display: none;
          }
        `;
            }
        }}

        ${RightBlock} {
            display: none;
        }
    }

    .ant-select-item-empty {
        visibility: hidden;
    }

    &.ant-select .ant-select-selection-item {
        color: ${({ theme }) => theme.select.default.color};
        line-height: 1.125rem;
        font-size: 1rem;
    }

    &:not(.ant-select-customize-input) .ant-select-selector input {
        cursor: url(/assets/images/cursor-white.svg), pointer;
    }

    .ant-select-selection-overflow {
        gap: 0.5rem;
    }

    &.ant-select-disabled {
        .ant-select-selector {
            background-color: #2a2846 !important;
            .ant-select-selection-item {
                color: rgba(255, 255, 255, 0.6);
            }
            .ant-select-selection-search {
                background-color: #2a2846 !important;
            }
        }
    }
`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border: ${({ theme }) => theme.select.default.border};
    border-radius: 0.375rem;
    line-height: 1.6875rem;
    cursor: url(/assets/images/cursor.svg), pointer;
    padding: 0.375rem 2rem 0.375rem 0.625rem;

    & > span {
        display: flex;
        align-items: center;
    }
`;

export const Icon = styled.div`
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);

    svg {
        path {
            fill: ${({ theme }) => theme.select.default.color};
        }
    }
`;

export const DropdownMenu = styled.div`
    margin: -0.25rem 0;
    border-radius: 0.375rem;
    overflow: hidden;

    .rc-virtual-list {
        border-radius: 0.375rem;
        border: 0.0625rem solid ${({ theme }) => theme.colors.border};
        box-shadow: 0rem 0.25rem 1.5rem rgba(255, 255, 255, 0.15);
        background: ${({ theme }) => theme.select.options.background};
        overflow: hidden;
    }

    .ant-select-item {
        background: ${({ theme }) => theme.select.options.background};
        transition: 0.3s;
        font-weight: 400;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;

        &:hover {
            background: ${({ theme }) => theme.select.hover?.background};
        }

        .ant-select-item-option-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: ${({ theme }) => theme.title.color};

            span {
                position: relative;
                top: -0.125rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            svg {
                width: 1.3125rem;
                height: 1.3125rem;
            }

            svg:not(.none-fill) {
                path {
                    fill: ${({ theme }) => theme.select.activeIcon};
                }
            }
        }
    }
`;

export const OptionBlock = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const LeftBlock = styled.div`
    display: inline-flex;
    column-gap: 8px;
`;

export const IconBefore = styled.div``;

export const OptionText = styled.div`
    justify-self: start;
`;

export const IconAfter = styled.div`
    margin-left: auto;
`;
