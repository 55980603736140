import { FC } from "react";
import { TagProps } from "./tag.props";
import { TagContainer, TagText } from "./tag.style";

const Tag: FC<TagProps> = ({ icon, children, ...rest }) => {
  return (
    <TagContainer {...rest}>
      <TagText>{children}</TagText>
      {icon}
    </TagContainer>
  );
};

export default Tag;
