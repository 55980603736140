import { AddEthereumChainParameter } from 'type/network';
import { NETWORK_CHAIN_IDS } from './chainIds';

export const CHAIN_ID_LIST = [
    {
        id: 43114,
        name: 'Avalanche Mainnet',
    },
    {
        id: 43113,
        name: 'Avalanche Testnet',
    },
    {
        id: 1,
        name: 'Ethereum Mainnet',
    },
    {
        id: 10,
        name: 'Optimism',
    },
    {
        id: 137,
        name: 'Polygon Mainnet',
    },
    {
        id: 80001,
        name: 'Mumbai',
    },
    // {
    //     id: NETWORK_CHAIN_IDS.bscTestnet,
    //     name: 'Binance Smart Chain Testnet',
    // },
    {
        id: NETWORK_CHAIN_IDS.bscMainnet,
        name: 'Binance Smart Chain Mainnet',
    },
];

export const ETHEREUM_CHAIN: { [key: number]: AddEthereumChainParameter } = {
    43114: {
        chainName: 'Avalanche Mainnet',
        chainId: `0x${Number(43114).toString(16)}`,
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        nativeCurrency: {
            name: 'AVAX',
            symbol: 'AVAX',
            decimals: 18,
        },
        blockExplorerUrls: ['https://snowtrace.io'],
    },
    43113: {
        chainName: 'Avalanche Testnet',
        chainId: `0x${Number(43113).toString(16)}`,
        nativeCurrency: {
            name: 'AVAX',
            symbol: 'AVAX',
            decimals: 18,
        },
        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://testnet.snowtrace.io'],
    },
    56: {
        chainName: 'Binance Smart Chain Mainnet',
        chainId: `0x${Number(56).toString(16)}`,
        rpcUrls: [
            'https://bsc-dataseed1.binance.org',
            'https://bsc-dataseed2.binance.org',
            'https://bsc-dataseed3.binance.org',
            'https://bsc-dataseed4.binance.org',
        ],
        nativeCurrency: {
            name: 'Binance Chain Native Token',
            symbol: 'BNB',
            decimals: 18,
        },
        blockExplorerUrls: ['https://bscscan.com'],
    },
    // 97: {
    //     chainName: 'Binance Smart Chain Testnet',
    //     chainId: `0x${Number(97).toString(16)}`,
    //     rpcUrls: [
    //         'https://data-seed-prebsc-1-s1.binance.org:8545',
    //         'https://data-seed-prebsc-2-s1.binance.org:8545',
    //         'https://data-seed-prebsc-1-s2.binance.org:8545',
    //     ],
    //     nativeCurrency: {
    //         name: 'Binance Chain Native Token',
    //         symbol: 'tBNB',
    //         decimals: 18,
    //     },
    //     blockExplorerUrls: ['https://testnet.bscscan.com'],
    // },
};
