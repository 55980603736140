export const Listing = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9479 4.94802H4.95448M1 3.10567V6.04982C1 6.3717 1 6.53264 1.03636 6.68411C1.06859 6.81834 1.12177 6.94671 1.19392 7.06449C1.2753 7.19727 1.3891 7.3111 1.6167 7.5387L6.66254 12.5845C7.44422 13.3662 7.83513 13.7571 8.28578 13.9035C8.68228 14.0323 9.10931 14.0323 9.50575 13.9035C9.95646 13.7571 10.3474 13.3662 11.1291 12.5845L12.5844 11.1292C13.3661 10.3475 13.757 9.95659 13.9034 9.50587C14.0322 9.10943 14.0322 8.6824 13.9034 8.2859C13.757 7.83525 13.3661 7.44434 12.5844 6.66266L7.53857 1.61682C7.31098 1.38922 7.19715 1.27542 7.06437 1.19404C6.94659 1.12189 6.81821 1.06872 6.68399 1.03648C6.53252 1.00012 6.37158 1.00012 6.0497 1.00012H3.10555C2.36854 1.00012 2.00003 1.00012 1.71853 1.14356C1.47092 1.26972 1.2696 1.47103 1.14343 1.71865C1 2.00015 1 2.36865 1 3.10567ZM5.27689 4.94802C5.27689 5.12972 5.12959 5.27701 4.9479 5.27701C4.7662 5.27701 4.61891 5.12972 4.61891 4.94802C4.61891 4.76632 4.7662 4.61903 4.9479 4.61903C5.12959 4.61903 5.27689 4.76632 5.27689 4.94802Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Transfer = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11.5001H13M13 11.5001L10 8.50012M13 11.5001L10 14.5001M13 4.00012H1M1 4.00012L4 1.00012M1 4.00012L4 7.00012"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Mint = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M7.49802 8.74256L13.6369 5.33203M7.49802 8.74256V15.6037V8.74256ZM7.49802 8.74256L1.35913 5.33203L7.49802 8.74256Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.93883 1.83231C7.14366 1.71851 7.24607 1.66162 7.35454 1.63932C7.45053 1.61958 7.54947 1.61958 7.64553 1.63932C7.75393 1.66162 7.85634 1.71851 8.06117 1.83231L13.4056 4.80144C13.622 4.92163 13.7301 4.98172 13.8089 5.06718C13.8785 5.14279 13.9313 5.23241 13.9635 5.33003C14 5.44039 14 5.56412 14 5.81159V11.674C14 11.9214 14 12.0451 13.9635 12.1555C13.9313 12.2531 13.8785 12.3428 13.8089 12.4184C13.7301 12.5038 13.622 12.5639 13.4056 12.6841L8.06117 15.6532C7.85634 15.767 7.75393 15.8239 7.64553 15.8462C7.54947 15.866 7.45053 15.866 7.35454 15.8462C7.24607 15.8239 7.14366 15.767 6.93883 15.6532L1.59437 12.6841C1.37805 12.5639 1.26989 12.5038 1.19112 12.4184C1.12145 12.3428 1.06872 12.2531 1.03646 12.1555C1 12.0451 1 11.9214 1 11.674V5.81159C1 5.56412 1 5.44039 1.03646 5.33003C1.06872 5.23241 1.12145 5.14279 1.19112 5.06719C1.26989 4.98172 1.37805 4.92163 1.59437 4.80145L6.93883 1.83231Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
