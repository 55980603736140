import styled from 'styled-components';
import { Title as CTitle } from 'components/base';
import { Image } from 'antd';

export const EventsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    overflow: unset;
`;

export const EventCard = styled.div`
    background: linear-gradient(121.34deg, #70ee9d 4.29%, #366be7 93.83%);
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    height: 20rem;
    align-items: end;
    overflow: hidden;
    padding: 0.0625rem;
    cursor: url(/assets/images/cursor-white.svg), pointer;
    flex-basis: 33%;
    min-width: 19.625rem;
    max-width: 19.625rem;

    &::before {
    }
`;

export const ImageArea = styled(Image)`
    width: 100%;
    height: 100%;
    border-radius: 0;
    object-fit: cover;
    object-position: center;
`;

export const EventCardCover = styled.div`
    z-index: 10;
    background: linear-gradient(121.19deg, #3259ba 10.61%, 0.0625rem solid #a1b7f8 81.85%);
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border-radius: 0.375rem 0.375rem 0 0;
    overflow: hidden;

    .ant-image {
        width: 100%;
        height: 100%;
    }
`;

export const EventCardBody = styled.div`
    grid-area: 1/1;
    background: ${({ theme }) => theme.admin.event.background};
    padding: 1.375rem 1.6325rem;
    border-radius: 0 0 0.375rem 0.375rem;
    z-index: 10;
    height: 8.4875rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
`;

export const EventCardTitle = styled(CTitle)`
    font-size: 1.375rem;
    line-height: 1.875rem;
    max-height: 3.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const EventCardContent = styled.div``;

export const EventCardInfo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    color: #ffffff80;
`;

export const CardInfo = styled.div`
    color: #ffffff80;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CardOutsiteLink = styled.div`
    color: #ffffff80;
    width: max-content;
    white-space: nowrap;
`;

export const EmptyContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0.625rem 0;
    width: 100%;
    .ant-empty-description {
        color: ${({ theme }) => theme.text.color};
    }
`;
