/* eslint-disable react-hooks/exhaustive-deps */
import { FC, memo, useEffect, useState, useRef } from 'react';
import {
    ModalTitle,
    FormItem,
    FormInput,
    FormPasswordInput,
    ModalDescription,
    ModalForm,
    PinkButtonOutlineAlone,
    PinkButtonOutline,
    WhiteButtonOutline,
    Wrapper,
    GradientWrapper,
    CloseModalBtn,
} from '../auth.style';
import {
    CodeInputNumber,
    CodeInputWrapper,
    RecoverSuccessIcon,
    OtpForm,
    ErrorMessage,
    HelpContainer,
    HelpTitle,
    HelpDescription,
    ResendText,
} from './forgotPassword.style';
import ForgotPasswordFormProps, {
    IRecoverPasswordSuccessProps,
    IFirstStepProps,
    ISecondStepProps,
    IThirdStepProps,
} from './forgotPassword.props';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import { BREAKPOINTS } from 'utils/constants/breakpoints';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'utils/hooks';
import { forgotPassword, verifyOtp, updatePassword } from 'redux/apis';
import { useMutation } from 'react-query';
import { Form } from 'antd';
import { CrossMark } from 'components/assets';
import { mouseLeave, mouseEnter } from 'utils';

const FirstStep: FC<IFirstStepProps> = ({ isError, setError, handleClick, submitLoading }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'forgot.firstStep',
    });
    const modal = useAppSelector((state) => state.modal);

    const handleFinish = (values: any) => {
        handleClick(values.email);
    };
    const [form] = Form.useForm();

    useEffect(() => {
        if (Object.values(modal).every((item) => !item)) {
            form.resetFields();
        }
    }, [modal.forgot]);

    return (
        <>
            <ModalTitle level={3}>{t('title')}</ModalTitle>
            <ModalDescription>{t('description')}</ModalDescription>
            <ModalForm onFinish={handleFinish} form={form} onValuesChange={() => setError(false)}>
                <FormItem
                    name="email"
                    labelCol={{ span: 24 }}
                    label="Email"
                    rules={[
                        { required: true, message: t('form.error.0') },
                        { type: 'email', message: t('form.error.1') },
                    ]}
                    validateTrigger="onBlur"
                >
                    <FormInput type="email" placeholder={t('form.label')} />
                </FormItem>
                {isError && <ErrorMessage>{t('form.emailNotfound')}</ErrorMessage>}
                <PinkButtonOutlineAlone loading={submitLoading} disabled={submitLoading}>
                    {t('form.submit')}
                </PinkButtonOutlineAlone>
            </ModalForm>
        </>
    );
};

const SecondStep: FC<ISecondStepProps> = ({ isError, setError, handleRecover, submitLoading, resendEmail }) => {
    const [otp, setOtp] = useState('');
    const { t } = useTranslation('translation', {
        keyPrefix: 'forgot.secondStep',
    });
    const { t: tCommon } = useTranslation();
    const modal = useAppSelector((state) => state.modal);
    const [form] = Form.useForm();
    const [hasClickedSend, setHasClickSend] = useState(false);

    const handleChangeOtp = (otp: string) => {
        if (isError) {
            setError(false);
        }
        setOtp(otp);
    };

    const handleFinish = (values: any) => {
        if (otp.split('').length === 5) {
            handleRecover(otp);
        }
    };

    const handleClickSend = () => {
        if (!hasClickedSend) {
            setHasClickSend(true);
            resendEmail();
            mouseLeave();
        }
    };

    useEffect(() => {
        if (Object.values(modal).every((item) => !item)) {
            form.resetFields();
        }
    }, [modal]);

    useEffect(() => {
        let timeoutSendEmail: NodeJS.Timeout;

        if (hasClickedSend) {
            timeoutSendEmail = setTimeout(() => {
                setHasClickSend(false);
            }, 3000);
        }

        return () => clearTimeout(timeoutSendEmail);
    }, [hasClickedSend]);

    return (
        <>
            <ModalTitle level={3}>{t('title')}</ModalTitle>
            <ModalDescription>{t('description')}</ModalDescription>
            <ModalForm onFinish={handleFinish} form={form}>
                <OtpForm name="code">
                    <CodeInputWrapper>
                        <CodeInputNumber
                            numInputs={5}
                            isInputNum
                            value={otp}
                            onChange={handleChangeOtp}
                            shouldAutoFocus
                            placeholder="_____"
                        />
                    </CodeInputWrapper>
                </OtpForm>
                {isError && <ErrorMessage>{t('form.otpNotfound')}</ErrorMessage>}
                <ResendText
                    onMouseEnter={hasClickedSend ? undefined : mouseEnter}
                    onMouseLeave={hasClickedSend ? undefined : mouseLeave}
                    onClick={handleClickSend}
                    isSend={hasClickedSend}
                >
                    {hasClickedSend ? 'Sent' : "Didn't receive verification code? Send again"}
                </ResendText>
                <WhiteButtonOutline loading={submitLoading} disabled={submitLoading}>
                    {t('form.submit')}
                </WhiteButtonOutline>
                <HelpContainer>
                    <HelpTitle>{tCommon('walletModal.helpConnect')}</HelpTitle>
                    <HelpDescription href="https://discord.com/" target="_blank">
                        {tCommon('walletModal.chat')}
                    </HelpDescription>
                </HelpContainer>
            </ModalForm>
        </>
    );
};

const ThirdStep: FC<IThirdStepProps> = ({ handleUpdate, submitLoading }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'forgot.thirdStep',
    });
    const modal = useAppSelector((state) => state.modal);
    const [form] = Form.useForm();

    const handleFinish = (values: any) => {
        handleUpdate(values.password);
    };

    useEffect(() => {
        if (Object.values(modal).every((item) => !item)) {
            form.resetFields();
        }
    }, [modal]);

    return (
        <>
            <ModalTitle level={3}>{t('title')}</ModalTitle>
            <ModalDescription>{t('description')}</ModalDescription>
            <ModalForm onFinish={handleFinish} form={form}>
                <FormItem
                    name="password"
                    labelCol={{ span: 24 }}
                    label={t('form.label.0')}
                    rules={[
                        { required: true, message: t('form.error.0') },
                        { min: 8, message: t('form.error.1') },
                    ]}
                    validateTrigger="onBlur"
                >
                    <FormPasswordInput placeholder={t('form.placeholder.0')} visibilityToggle={false} />
                </FormItem>
                <FormItem
                    name="confirmPassword"
                    labelCol={{ span: 24 }}
                    label={t('form.label.1')}
                    rules={[
                        { required: true, message: t('form.error.0') },
                        { min: 8, message: t('form.error.1') },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords do not match'));
                            },
                        }),
                    ]}
                >
                    <FormPasswordInput placeholder={t('form.placeholder.0')} visibilityToggle={false} />
                </FormItem>
                <WhiteButtonOutline loading={submitLoading} disabled={submitLoading}>
                    {t('form.submit')}
                </WhiteButtonOutline>
            </ModalForm>
        </>
    );
};

const RecoverPasswordSuccessStep: FC<IRecoverPasswordSuccessProps> = ({ resetStep, closeModal }) => {
    const [isBiggerThanMobile] = useMediaQuery(`(min-width: ${BREAKPOINTS.sm})`);
    const { t } = useTranslation('translation', {
        keyPrefix: 'forgot.successStep',
    });
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        resetStep();
        closeModal?.();
        if (location.pathname !== '/') {
            navigate('/');
        }
    };

    return (
        <>
            <ModalTitle level={3}>{t('title')}</ModalTitle>
            <ModalForm>
                <RecoverSuccessIcon>
                    <svg width="99" height="92" viewBox="0 0 99 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="99" height="92" rx="5.96639" fill="#1D1C2C" />
                        <path
                            d="M60.3819 62.5303H39.6187C37.5424 62.5303 36.1582 61.1461 36.1582 59.0697V45.2276C36.1582 43.1513 37.5424 41.7671 39.6187 41.7671H60.3819C62.4582 41.7671 63.8424 43.1513 63.8424 45.2276V59.0697C63.8424 61.1461 62.4582 62.5303 60.3819 62.5303Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="6.92106"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M43.0791 41.7667V36.2298C43.0791 33.1154 44.8094 30.3469 47.5778 29.3088C52.4225 27.9246 56.9212 31.3851 56.9212 35.8838V41.7667"
                            stroke="white"
                            strokeWidth="6.92106"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <circle cx="65" cy="60" r="13" fill="0.0625rem solid #a1b7f8 " />
                        <path
                            d="M61.25 60L64.25 63L70.25 57"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </RecoverSuccessIcon>
                <PinkButtonOutline onClick={handleClick}>
                    {isBiggerThanMobile ? t('actions.0') : t('actions.1')}
                </PinkButtonOutline>
            </ModalForm>
        </>
    );
};

const ForgotContent: FC<ForgotPasswordFormProps> = ({ handleClose }) => {
    const forgotPasswordMutation = useMutation(forgotPassword);
    const verifyOtpMutation = useMutation(verifyOtp);
    const updatePasswordMutation = useMutation(updatePassword);
    const tokenRef = useRef<string>('');
    const emailRef = useRef<string>('');
    const [errorEmail, setErrorEmail] = useState<boolean>(false);
    const [errorOtp, setErrorOtp] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const modal = useAppSelector((state) => state.modal);
    const [step, setStep] = useState(0);

    const handleNextStep = () => {
        setStep((prevState) => prevState + 1);
    };

    const handleResetStep = () => {
        setStep(0);
    };

    const handleClickForgotPassword = async (email: string) => {
        try {
            setSubmitLoading(true);
            const forgotPasswordResponse = await forgotPasswordMutation.mutateAsync({
                email,
            });
            tokenRef.current = forgotPasswordResponse.data.token;
            emailRef.current = email;
            setSubmitLoading(false);
            handleNextStep();
            if (errorEmail) setErrorEmail(false);
        } catch (error) {
            setErrorEmail(true);
            setSubmitLoading(false);
        }
    };

    const handleResentEmail = async () => {
        try {
            const forgotPasswordResponse = await forgotPasswordMutation.mutateAsync({
                email: emailRef.current,
            });
            tokenRef.current = forgotPasswordResponse.data.token;
        } catch (error) {}
    };

    const handleClickRecoverPassword = async (otp: string) => {
        try {
            setSubmitLoading(true);
            const verifyOtpResponse = await verifyOtpMutation.mutateAsync({
                otp,
                token: String(tokenRef.current),
            });
            if (verifyOtpResponse.status === 204) {
                setSubmitLoading(false);
                handleNextStep();
                if (errorOtp) setErrorOtp(false);
            }
        } catch (error) {
            setSubmitLoading(false);
            setErrorOtp(true);
        }
    };

    const handleClickUpdatePassword = async (password: string) => {
        try {
            setSubmitLoading(true);
            const updatePasswordResponse = await updatePasswordMutation.mutateAsync({
                email: emailRef.current,
                password,
            });
            if (updatePasswordResponse.status === 204) {
                setSubmitLoading(false);
                handleNextStep();
            }
        } catch (error) {
            setSubmitLoading(false);
        }
    };

    useEffect(() => {
        if (Object.values(modal).every((item) => !item)) {
            setStep(0);
            if (errorEmail) setErrorEmail(false);
            if (errorOtp) setErrorOtp(false);
        }
    }, [modal.forgot]);

    const steps = [
        <FirstStep
            submitLoading={submitLoading}
            isError={errorEmail}
            setError={setErrorEmail}
            handleClick={handleClickForgotPassword}
        />,
        <SecondStep
            submitLoading={submitLoading}
            isError={errorOtp}
            setError={setErrorOtp}
            resendEmail={handleResentEmail}
            handleRecover={handleClickRecoverPassword}
        />,
        <ThirdStep submitLoading={submitLoading} handleUpdate={handleClickUpdatePassword} />,
        <RecoverPasswordSuccessStep resetStep={handleResetStep} closeModal={handleClose} />,
    ];

    return (
        <GradientWrapper>
            <CloseModalBtn onClick={() => handleClose?.()}>
                <CrossMark />
            </CloseModalBtn>
            <Wrapper>{steps[step]}</Wrapper>
        </GradientWrapper>
    );
};

export default memo(ForgotContent);
