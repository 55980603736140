import React from "react";
import Skeleton from "../skeleton";
import { ITextProps } from "./text.props";
import { Span } from "./text.style";

const TextView: React.FC<ITextProps> = ({
  children,
  loading,
  skeleton,
  skeletonLineAlign,
  ...props
}: ITextProps) => {
  const skeArrgs = loading
    ? Array.isArray(skeleton)
      ? skeleton
      : skeleton
      ? [skeleton]
      : [{}]
    : [];

  return (
    <Span
      {...props}
      isSkeletonMultiLine={skeArrgs.length > 1}
      skeletonLineAlign={skeletonLineAlign}
    >
      {skeArrgs.length > 0
        ? skeArrgs.map((ske, idx) => <Skeleton key={idx} {...ske} />)
        : children}
    </Span>
  );
};

export default React.memo(TextView);
