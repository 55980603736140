import styled from 'styled-components';
import clsx from 'clsx';

const Icon: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('icon', className)}
        >
            <path
                d="M0.000488281 12.9243C0.000488281 1.91443 2.36023 -0.445312 13.3702 -0.445312H17.5219C28.5318 -0.445312 30.8916 1.91443 30.8916 12.9243V17.0761C30.8916 28.086 28.5318 30.4458 17.5219 30.4458H13.3702C2.36023 30.4458 0.000488281 28.086 0.000488281 17.0761V12.9243Z"
                fill="#22212C"
            />
            <path
                d="M23.9692 14.5547C23.9692 15.1172 23.5005 15.5547 22.9692 15.5547H21.9692L22.0005 20.5547C22.0005 21.6797 21.0942 22.5547 20.0005 22.5547H10.0005C8.87549 22.5547 8.00049 21.6797 8.00049 20.5547V15.5547H7.00049C6.43799 15.5547 6.00049 15.1172 6.00049 14.5547C6.00049 14.2734 6.09424 14.0234 6.31299 13.8047L14.313 6.83594C14.5317 6.61719 14.7817 6.55469 15.0005 6.55469C15.2192 6.55469 15.4692 6.64844 15.6567 6.80469L23.6255 13.8047C23.8755 14.0234 24.0005 14.2734 23.9692 14.5547Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="test"
                    x1="5.07974"
                    y1="2.12542"
                    x2="27.3318"
                    y2="28.8279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="0.0625rem solid #a1b7f8 " />
                    <stop offset="1" stopColor="#4154B0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const TokenIcon: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
        </svg>
    );
};

const IconMobile: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('icon', className)}
        >
            <path d="https://cdn-icons-png.flaticon.com/128/5805/5805620.png" fill="#22212C" />
            <path d="https://cdn-icons-png.flaticon.com/128/5805/5805620.png" fill="white" />
            <defs>
                <linearGradient
                    id="test2"
                    x1="5.07974"
                    y1="2.12542"
                    x2="27.3318"
                    y2="28.8279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="0.0625rem solid #a1b7f8 " />
                    <stop offset="1" stopColor="#4154B0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const Home = styled(TokenIcon)`
    & {
        transition: opacity 0.3s;
    }
`;

export const HomeMobile = styled(IconMobile)`
    & {
        transition: opacity 0.3s;
    }
`;
