import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';

import { ConnectorNames, connectorsByName, TorusConnector } from 'utils/connector';
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import { Notification } from 'components/base';
import { useAppDispatch } from 'hooks';
import { clearConnectedWallet } from 'redux/store/slices';
import { ethers } from 'ethers';
import { ETHEREUM_CHAIN, REACT_APP_CHAIN_ID } from 'utils/constants';
import { BscConnector } from '@binance-chain/bsc-connector';
import { useCallback, useEffect } from 'react';
import { MetaMask, NoMetamask } from 'components/assets';
import { MetamaskWrapper, InstallMetamask, HintText } from 'theme/default/layout.style';
import { mouseEnter, mouseLeave } from 'utils';

const handleError = (error: Error) => {
    if (error instanceof NoEthereumProviderError) {
        Notification({
            type: 'error',
            description: (
                <MetamaskWrapper>
                    <HintText>Install MetaMask on desktop or visit from a dApp browser on mobile.</HintText>
                    <InstallMetamask
                        onMouseEnter={mouseEnter}
                        onMouseLeave={mouseLeave}
                        onClick={() => {
                            window.open('https://metamask.io/download/', '_blank');
                            mouseLeave();
                        }}
                    >
                        <MetaMask />
                        Install Metamask
                    </InstallMetamask>
                </MetamaskWrapper>
            ),
            message: 'No Ethereum browser extension detected',
            icon: <NoMetamask />,
        });
    } else if (error instanceof UnsupportedChainIdError) {
        Notification({
            type: 'error',
            message: "You're connected to an unsupported network.",
        });
    } else if (error instanceof UserRejectedRequestErrorInjected) {
        Notification({
            type: 'error',
            message: 'Please authorize this website to access your Ethereum account.',
        });
    } else if ((error as any).code === -32002) {
        Notification({
            type: 'error',
            message: 'Already processing ethereum request Accounts. Please accept the request.',
        });
    } else if (error.name.includes('UserRejectedRequestError')) {
        Notification({
            type: 'error',
            message: 'The user rejected the request.',
        });
    } else {
        console.error(error.toString());
        Notification({
            type: 'error',
            message: 'An unknown error occurred. Check the console for more details.',
        });
    }
};

export function activateInjectedProvider(providerName: 'Metamask' | 'Coinbase') {
    const { ethereum } = window as any;

    if (!ethereum?.providers) {
        return undefined;
    }

    let provider;
    switch (providerName) {
        case 'Coinbase':
            provider = ethereum.providers.find(({ isCoinbaseWallet }: { isCoinbaseWallet: any }) => isCoinbaseWallet);
            break;
        case 'Metamask':
            provider = ethereum.providers.find(({ isMetaMask }: { isMetaMask: any }) => isMetaMask);
            break;
    }
    console.log('provider ', provider);

    if (provider) {
        ethereum.setSelectedProvider(provider);
    }
}

export const useWallet = () => {
    const web3 = useWeb3React<ethers.providers.Web3Provider>();
    const { activate, connector, library, chainId, active } = web3;

    const provider = library?.provider;

    const dispatch = useAppDispatch();

    const connect = async (type: keyof typeof ConnectorNames) => {
        try {
            if (type === ConnectorNames.Injected) {
                const { ethereum } = window as any;
                if (!ethereum) {
                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        let link = document.createElement('a');
                        link.href = 'https://metamask.app.link/dapp/www.google.com/';
                        link.target = '_blank';
                        link.click();
                    } else
                        return Notification({
                            type: 'error',
                            description: (
                                <MetamaskWrapper>
                                    <HintText>
                                        Install MetaMask on desktop or visit from a dApp browser on mobile.
                                    </HintText>
                                    <InstallMetamask
                                        onMouseEnter={mouseEnter}
                                        onMouseLeave={mouseLeave}
                                        onClick={() => {
                                            window.open('https://metamask.io/download/', '_blank');
                                            mouseLeave();
                                        }}
                                    >
                                        <MetaMask />
                                        Install Metamask
                                    </InstallMetamask>
                                </MetamaskWrapper>
                            ),
                            message: 'No Ethereum browser extension detected',
                            icon: <NoMetamask />,
                        });
                }

                activateInjectedProvider('Metamask');
            }

            return await activate(connectorsByName[type], undefined, true);
        } catch (err: any) {
            if (err instanceof UnsupportedChainIdError) {
                web3.deactivate();
                dispatch(clearConnectedWallet());
            }
            handleError(err);
            throw err;
        }
    };

    const requestSwitchNetwork = useCallback(
        async (chainId: number) => {
            if (provider) {
                if (connector instanceof TorusConnector) {
                    connector.torus?.setProvider({
                        chainId,
                        host: ETHEREUM_CHAIN[chainId].rpcUrls[0],
                        networkName: ETHEREUM_CHAIN[chainId].chainName,
                    });
                    return true;
                }

                if (connector instanceof BscConnector) {
                    return false;
                }

                const chainIdHex = `0x${chainId.toString(16)}`;
                try {
                    await provider.request?.({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: chainIdHex }],
                    });
                    return true;
                } catch (switchError: any) {
                    // This error code indicates that the chain has not been added to MetaMask.
                    if (switchError.code === 4902) {
                        try {
                            await provider.request?.({
                                method: 'wallet_addEthereumChain',
                                params: [ETHEREUM_CHAIN[chainId]],
                            });
                            return true;
                        } catch (addError) {
                            console.log({ addError });
                            return false;
                        }
                    }
                }
            } else {
                return false;
            }
        },
        [connector, provider],
    );

    useEffect(() => {
        if (active && chainId && REACT_APP_CHAIN_ID !== chainId) {
            requestSwitchNetwork(REACT_APP_CHAIN_ID);
        }
    }, [chainId, active, requestSwitchNetwork]);

    return {
        ...web3,
        connect,
        account: web3.account?.toLocaleLowerCase(),
        requestSwitchNetwork,
    };
};
