import { Form as AntdForm } from 'antd';
import { Notification } from 'components/base';
import { FC, memo } from 'react';
import { getBase64, mouseEnter, mouseLeave } from 'utils';
import { IUploadImageProps } from './uploadImage.props';
import { PreviewCustom, UploadBlock, UploadInfoWrapper, UploadTextDesc } from './uploadImage.style';

const UploadImage: FC<IUploadImageProps> = ({
    icon,
    title,
    subTitle,
    imageFile,
    setImageFile,
    imagePreview,
    setImagePreview,
    ...props
}) => {
    const [form] = AntdForm.useForm();

    const handleChangeImageFile = async (info: any) => {
        const file = info.file;
        if (!file) return;
        if (!file.type.includes('image'))
            return Notification({
                type: 'error',
                description: 'File types supported: JPG, PNG, GIF, JPEG',
                message: 'Error',
            });
        setImageFile(file);
        setImagePreview(await getBase64(file));
        form.setFieldsValue({ cover: file });
    };

    return (
        <UploadBlock
            multiple={false}
            listType="picture-card"
            className="avatar-uploader"
            onRemove={() => {
                setImageFile(undefined);
            }}
            showUploadList={false}
            beforeUpload={() => false}
            fileList={imageFile ? [imageFile as any] : []}
            onChange={handleChangeImageFile}
            accept=".png,.jpg,.gif,.jpeg"
            {...props}
        >
            <PreviewCustom onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} preview={imagePreview}>
                {imagePreview ? (
                    <img src={imagePreview} alt="" />
                ) : (
                    <UploadInfoWrapper>
                        {icon}
                        <UploadTextDesc>
                            {title}
                            <div>{subTitle}</div>
                        </UploadTextDesc>
                    </UploadInfoWrapper>
                )}
            </PreviewCustom>
        </UploadBlock>
    );
};

export default memo(UploadImage);
