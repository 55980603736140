import { BackPagination, NextPagination } from "components/assets";
import { TextThin } from "components/base";
import { useMediaQuery } from "hooks";
import { FC, memo } from "react";
import { mouseEnter, mouseLeave } from "utils";
import { IPaginationProps } from "./pagination.props";
import { PaginationView, PaginationArrowWrapper } from "./pagination.style";

const Pagination: FC<IPaginationProps> = ({
  current,
  pageSize = 12,
  total,
  ...rest
}) => {
  const [over1024] = useMediaQuery(`(min-width: 64rem)`);

  return (
    <PaginationView
      pageSize={pageSize}
      showLessItems={!over1024}
      current={current}
      showSizeChanger={false}
      showQuickJumper={false}
      total={total}
      itemRender={(page: number, type: string) => {
        const limit = total / pageSize;
        if (type === "prev")
          return (
            <PaginationArrowWrapper
              onMouseEnter={current > 1 ? mouseEnter : undefined}
              onMouseLeave={current > 1 ? mouseLeave : undefined}
              onClick={mouseLeave}
              direction="right"
            >
              <BackPagination />
              <TextThin>Previous</TextThin>
            </PaginationArrowWrapper>
          );

        if (type === "next")
          return (
            <PaginationArrowWrapper
              onMouseEnter={current < Math.ceil(limit) ? mouseEnter : undefined}
              onMouseLeave={current < Math.ceil(limit) ? mouseLeave : undefined}
              onClick={mouseLeave}
              direction="left"
            >
              <TextThin>Next</TextThin>
              <NextPagination />
            </PaginationArrowWrapper>
          );

        return (
          <div
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onClick={mouseLeave}
          >
            {page}
          </div>
        );
      }}
      {...rest}
    />
  );
};

export default memo(Pagination);
