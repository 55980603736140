import Skeleton from "components/base/skeleton";
import { FC, memo } from "react";
import IInputPasswordProps from "./inputPassword.props";
import { AntdInputPassword } from "./inputPassword.style";

const InputPassword: FC<IInputPasswordProps> = ({
  skeleton,
  loading,
  ...rest
}) => {
  return loading ? <Skeleton {...skeleton} /> : <AntdInputPassword {...rest} />;
};

export default memo(InputPassword);
