import styled from "styled-components";
import { SkeletonLineAlignType } from "type/baseComponent";

export const Span = styled.span<{
  isSkeletonMultiLine?: boolean;
  skeletonLineAlign?: SkeletonLineAlignType;
}>`
  color: ${({ theme }) => theme.text.color};
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;

  ${({ isSkeletonMultiLine, skeletonLineAlign }) => {
    let alignItems = "center";
    if (skeletonLineAlign === "LEFT") alignItems = "start";
    else if (skeletonLineAlign === "RIGHT") alignItems = "end";

    return !isSkeletonMultiLine
      ? ""
      : "display: flex; flex-direction: column; align-items: " +
          alignItems +
          ";";
  }}
`;
