import styled from "styled-components";
import Text from "../text";

export const TagText = styled(Text)`
  font-size: 0.875rem;
  line-height: 1;
  color: ${({ theme }) => theme.colors.white};
`;

export const TagContainer = styled.div`
  background: ${({ theme }) => theme.colors.border};
  border-radius: 0.25rem;
  padding: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
