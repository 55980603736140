import { Popover, List } from 'antd';
import { ButtonOutline, ButtonPrimary, Image, TextThin } from 'components/base';
import styled, { createGlobalStyle } from 'styled-components';
import { forwardRef } from 'react';

export const ChatBoxContainer = createGlobalStyle`
  body {
    .emoji-picker-react{
      background: ${({ theme }) => theme.chat.input.background} !important;
      border: 0.0625rem solid #3B4167 !important;
      box-sizing: border-box;
      border-radius: 0.625rem;
      box-shadow: none !important;
    }
    .emoji-picker-react input.emoji-search {
      background: ${({ theme }) => theme.chat.input.background};
      border: 0.0625rem solid #3B4167;
    }
    .emoji-picker-react .emoji-group:before{
      background: ${({ theme }) => theme.chat.input.background};
    }
    .emoji-picker-react .emoji-categories button {
      filter: invert(${({ theme }) => theme.chat.closeButton.invert});
    }
    .ant-popover-content {
      border: 0.0625rem solid ${({ theme }) => theme.chatBox.border};
      border-radius: 0.375rem;
      overflow: hidden;
    }

    .ant-popover-inner-content, .ant-popover-title {
      padding: 0;
    }

    .ant-popover-title{
      border-bottom: none;
    }

    input {
      background-color: ${({ theme }) => theme.section.background};
      color: ${({ theme }) => theme.text.color};
      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
    input:placeholder-shown {
      text-overflow: ellipsis;
      font-size: 0.875rem;
      line-height: 1.625rem;
      color: ${({ theme }) => theme.text.color};
    }

    .ant-popover-inner-content{
      width: 100%;
      // height: 25.75rem;
      // border: 0.0625rem solid ${({ theme }) => theme.chat.borderColor};

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}){
        width: 100%;
      }
    }
    .ant-tabs-dropdown-menu{
      background: ${({ theme }) => theme.chat.input.background};

      .ant-tabs-dropdown-menu-item:hover {
        background: ${({ theme }) => theme.header.searchResult.hover};
      }
    }
  }
`;

export const BoxUpload = styled.div`
    margin-left: 0.4375rem;
    width: 1.0625rem;
    position: relative;
    height: 1.25rem;

    input {
        z-index: 10;
        opacity: 0;
        position: absolute;
        width: 1.0625rem;
        display: none;
        top: 0;
    }

    svg {
        cursor: url(/assets/images/cursor-white.svg), pointer;
        position: absolute;
        top: 0.1875rem;
        top: 0;
    }
`;

export const FileBox = styled.div<{ fileUpload: boolean }>`
    padding: ${({ fileUpload }) => (fileUpload ? '0.4375rem' : '0')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: calc(100% - 1.875rem);
    height: ${({ fileUpload }) => (fileUpload ? '4.625rem' : '0')};
    background: #3b4167;
    border: 0.0625rem solid #3b4167;
    box-sizing: border-box;
    border-radius: 0.625rem;
    top: -4.6875rem;
    left: 0.9375rem;
    & > div:first-child {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        img,
        svg {
            width: fit-content;
            margin-right: 0.9375rem;
            border: 0.0625rem solid rgba(255, 255, 255, 0.1);
            box-sizing: border-box;
            border-radius: 0.375rem;
            height: 3.25rem;
            object-fit: contain;
        }
    }
    ${({ fileUpload }) => !fileUpload && `opacity: 0; visibility: hidden`}
    a {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-left: 10px;
        word-break: break-word;
        line-height: 22px;
        color: ${({ theme }) => theme.textOrigin.color};
    }
`;

export const ToolsBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 0.9419rem;
    cursor: url(/assets/images/cursor.svg), pointer;
    // border: 0.0625rem solid ${({ theme }) => theme.chat.borderColor};
    height: auto;
    max-height: 7rem;
    justify-content: space-between;
    background: ${({ theme }) => theme.profile.table.itemBg};

    .ant-input {
        resize: none;
        background: ${({ theme }) => theme.chat.input.background};
        border: none;
        color: ${({ theme }) => theme.title.color};
        margin-left: 0.625rem;
        padding: 0;
        overflow: auto;
        &:hover,
        &:focus,
        &:active {
            box-shadow: none;
        }
    }

    .ant-input:placeholder-shown {
        text-overflow: ellipsis;
        font-size: 0.875rem;
        line-height: 1.625rem;
        color: ${({ theme }) => theme.text.color};
    }
    textarea::placeholder {
        color: ${({ theme }) => theme.chat.input.placeholder};
        opacity: ${({ theme }) => theme.chat.input.opacity};
        display: flex;
        align-item: center;
    }
`;

export const ChatBoxPopover = styled(Popover)`
    // border: 0.0625rem solid ${({ theme }) => theme.chat.borderColor};
`;

export const CloseIcon = styled.div`
    position: absolute;
    top: 32%;
    right: 1.1875rem;
    cursor: url(/assets/images/cursor-white.svg), pointer;
    background: ${({ theme }) => theme.chat.closeButton.background};
    border-radius: 1.7663rem;
    padding: 0.3533rem;
    width: 1.625rem;
    height: 1.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 0.6875rem;
        height: 0.9375rem;
        margin-right: 0 !important;
    }
`;

export const BackIcon = styled.div`
    cursor: url(/assets/images/cursor-white.svg), pointer;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1.7663rem;
    padding: 0.3533rem;
    width: 1.625rem;
    height: 1.625rem;
    svg {
        width: 0.6875rem;
        height: 0.9375rem;
        margin-right: 0 !important;
    }
`;

export const SearchHeader = styled.div<{ currentChannel: string | undefined }>`
    display: flex;
    align-items: center;
    border-right: 0.0625rem solid ${({ theme }) => theme.chat.borderColor};
    span {
        padding-right: 1rem;
    }
    padding: 0.75rem;
    background: ${({ theme }) => theme.chat.header.background};
    cursor: url(/assets/images/cursor.svg), pointer;
`;

export const HeaderChannel = styled(SearchHeader)``;

export const HeaderContent = styled.div<{ currentChannel: string | undefined }>`
    padding-top: 1.1875rem;
    padding-left: 1.125rem;
    padding-bottom: 0.5rem;
    position: relative;
    z-index: 10;

    padding: 0;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.chat.borderColor};
    background: ${({ theme }) => theme.chatBox.background};

    b {
        color: inherit;
    }
`;

export const ChannelUser = styled.div`
    margin-left: 1rem;
`;

export const ChannelName = styled.div`
    display: flex;
    align-items: center;
    margin-left: 1.1875rem;
`;

export const TextBreak = styled.pre`
    width: 100%;
    word-break: break-word;
    overflow: hidden;
    white-space: pre-wrap;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.chat.chatBox.owner.color};
    margin-bottom: 0;
`;

export const ChatBoxHeader = styled.div`
    position: relative;
    width: 100%;

    img {
        position: absolute;
        inset: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        display: ${({ theme }) => (theme.chatBox.showMask ? 'block' : 'none')};
    }
`;

export const ChatContainer = styled.div<{ currentChannel: string | undefined }>`
    display: grid;
    background: #fefefe !important;
    grid-template-columns: ${({ currentChannel }) => (currentChannel ? '15.25rem 24.4375rem' : '23.1875rem')};
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: 23.1875rem;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        grid-template-columns: 20.1875rem;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        grid-template-columns: 17rem;
    }

    .ant-list {
        cursor: url(/assets/images/cursor.svg), pointer;
        background-color: ${({ theme }) => theme.chat.channel.backgroundChatChannel};
    }
`;

export const ChannelItem = styled(List.Item)<{ selected?: boolean }>`
    user-select: none;
    margin: 0.5rem 0rem;
    padding: 0.625rem;
    border-bottom: none !important;
    background: ${({ theme, selected }) => (selected ? theme.chat.channel.backgroundItem : 'transparent')};
    border-radius: 0.625rem;
    cursor: url('/assets/images/cursor-white.svg'), pointer;

    .ant-list-item-meta-title {
        a {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
            line-height: 22px;
            color: ${({ theme, selected }) => (selected ? '#fff' : theme.chat.channel.headerText)};
            line-height: 1.375rem;
        }
    }
    .ant-list-item-meta-description {
        b {
            color: ${({ theme, selected }) => (selected ? '#fff' : theme.chat.channel.colorTime)};
        }
    }
`;

export const ActiveStatusText = styled(TextThin)<{
    selected?: boolean;
}>`
    color: ${({ theme, selected }) =>
        selected ? theme.chat.channel.statusActive : theme.chat.channel.status} !important;
    b {
        color: ${({ theme, selected }) =>
            selected ? theme.chat.channel.statusActive : theme.chat.channel.status} !important;
    }
    font-size: 0.7rem;
`;

export const AvatarImage = styled(Image)`
    object-fit: cover;
    width: 2.3125rem !important;
    height: 2.3125rem !important;
    border-radius: 50%;
    display: inherit !important;
    position: initial !important;
`;

export const ChatList = styled(List)`
    background: ${({ theme }) => theme.chat.channel.background};
    padding: 0;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
        padding: 0.75rem;
    }
    border-right: 0.0625rem solid ${({ theme }) => theme.chat.borderColor};
    height: 33.625rem;
    overflow-y: scroll;
    .ant-list-item-meta-avatar {
        .react-loading-skeleton {
            border-radius: 50% !important;
        }
    }
`;

export const ChatBoxWarning = styled.div`
    position: absolute;
    z-index: 1;
    padding: 10px;
    background: #222534;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    top: 10px;
    right: 10px;
    left: 10px;
`;

export const ChatBoxContent = styled.div`
    height: 100%;
    display: grid;
    grid-auto-rows: auto min-content;
    position: relative;
    .pn-msg__avatar {
        flex-shrink: 0;
    }
    .pn-msg__bubble {
        word-break: break-word;
    }
    .pn-msg-input__send,
    .pn-msg-input__icons {
        path {
            color: ${({ theme }) => theme.chat.channel.color};
        }
    }
    .mess-time {
        font-size: 0.625rem;
        line-height: 0.625rem;
        color: ${({ theme }) => theme.chat.chatBox.owner.color};
        margin-top: 0.4063rem;
        opacity: 0.5;
    }
    .pn-msg {
        .pn-msg__avatar,
        .pn-msg__title {
            display: none;
        }
        .mess-time {
            text-align: left;
        }
        &:hover {
            background: ${({ theme }) => theme.chat.chatBox.owner.background};
        }
        margin-left: 1.625rem;
        width: auto;
        max-width: 75%;
        height: auto;
        padding: 0.9375rem;
        background: ${({ theme }) => theme.chat.chatBox.owner.background};
        border: 0.0625rem solid ${({ theme }) => theme.chat.borderColor};
        box-sizing: border-box;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        line-height: 1.375rem;
        margin-top: 1rem;
        .pn-msg__bubble {
            color: ${({ theme }) => theme.chat.chatBox.owner.color};
        }
    }
    .pn-msg:nth-last-child(2) {
        margin-bottom: 1rem;
    }
    .pn-msg--own {
        .mess-time {
            text-align: right;
        }
        &:hover {
            background: ${({ theme }) => theme.chat.chatBox.user.background};
        }
        padding: 0.9375rem;
        background: ${({ theme }) => theme.chat.chatBox.user.background};
        border: 0.0625rem solid ${({ theme }) => theme.chat.borderColor};
        box-sizing: border-box;
        border-radius: 0.375rem;
        line-height: 1.375rem;
        margin-top: 1rem;
        margin-left: auto;
        margin-right: 1.25rem;
        font-size: 0.875rem;
        width: auto;
        .pn-msg__bubble {
            color: ${({ theme }) => theme.chat.chatBox.user.color};
        }
        line-height: 0.875rem;
    }
    .pn-msg--own:nth-last-child(2) {
        margin-bottom: 1rem;
    }

    .pn-msg__main {
        width: calc(100% - 3.375rem);
        max-width: unset;
    }

    .pn-msg__author {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .pn-msg__time {
        flex-shrink: 0;
    }

    .pn-msg__content {
        width: 100%;
    }

    .pn-msg__title {
        width: 100%;
    }

    .pn-msg-list {
        height: 100%;
        background: ${({ theme }) => theme.chatBox.chatBackground} !important;
        padding-top: 60px;
    }

    .pn-msg-input {
        background: transparent;
        padding: 0.625rem 0 0.625rem 0.625rem;
    }
    .loader {
        margin-bottom: 1.375rem;
    }
    .pn-msg-list__spinner {
        display: none;
    }

    .pn-msg-list-scroller {
        cursor: url(/assets/images/cursor.svg), pointer;
    }

    textarea {
        border: none;
        margin: 0 0.625rem;
        border-radius: 0;
        background: transparent;
        height: fit-content;
        min-height: auto;
        line-height: 1.625rem;
        overflow: hidden;
        &:hover,
        &:focus,
        &:active {
            box-shadow: none;
        }
    }

    .pn-msg-input__textarea:placeholder-shown {
        text-overflow: ellipsis;
        font-size: 0.875rem;
        line-height: 1.625rem;
        color: ${({ theme }) => theme.text.color};
        opacity: 0.5;
    }
`;

export const ChannelContainer = styled.div`
    button {
        display: flex;
        align-items: center;
        background: ${({ theme }) => theme.profile.table.itemBg} !important;
        border: none !important;
        border-radius: 10px;
        padding: 10px 15px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
            background: transparent !important;
            margin-right: -10px !important;
            border-radius: 20px;
            padding: unset;
        }

        span {
            font-size: 0.875rem;
            line-height: 0.875rem;
            color: #f2f2f4;
            margin-left: 0.625rem;
            @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
                display: none;
            }
        }
    }

    svg {
        width: 1.8125rem;
        height: 1.8125rem;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
`;

export const FileMessage = styled.div`
    img,
    svg {
        width: 4.375rem;
        margin-right: 0.9375rem;
        border: 0.0625rem solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        border-radius: 0.375rem;
        height: 3.25rem;
        object-fit: contain;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
`;

export const Container = styled.div`
    position: absolute;
    z-index: 100;
    right: 1.25rem;
    bottom: 0.625rem;
`;

export const NoOutlineButton = styled(
    forwardRef<any, any>(({ children, ...props }, ref) => {
        return <ButtonOutline {...props}>{children}</ButtonOutline>;
    }),
)`
    border: none;
    cursor: url(/assets/images/cursor-white.svg), pointer;
    &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
            border: none;
            background: none;
            path {
                fill: ${({ theme }) => theme.chat.searchButton.stroke} !important;
            }
        }
    }
`;

export const SentButton = styled(ButtonPrimary)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.625rem;
    height: 1.625rem;
    /* background: 0.0625rem solid #a1b7f8 ; */
    /* border-radius: 50%; */
    background: transparent;
    border: none !important;
    &:after {
        border: none !important;
        background: none !important;
    }
    div {
        display: none;
    }
    svg {
        margin-right: 0;
    }
    &:hover,
    &:focus,
    &:active {
        /* background: 0.0625rem solid #a1b7f8 ; */
        svg {
            fill: 0.0625rem solid #a1b7f8;
        }
    }
`;

export const FileArticle = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    line-height: 1.375rem;
`;

export const Cursor = styled.div`
    width: 100%;
    display: inline-block;
    cursor: url(/assets/images/cursor-white.svg), pointer;
`;

export const ChannelTitle = styled.div`
    color: ${({ theme }) => theme.textOrigin.color};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    line-height: 1.375rem;
`;

export const MessageCount = styled.div`
    background-color: #ac4585;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.625rem;
    right: -0.625rem;
    font-size: 0.875rem;
`;
