import { FC, memo } from 'react';
import Skeleton from 'components/base/skeleton';
import InputProps from './input.props';
import { Input as InputStyled } from './input.style';

const Input: FC<InputProps> = ({ skeleton, loading, ...rest }) => {
    return loading ? <Skeleton {...skeleton} /> : <InputStyled {...rest} />;
};

export default memo(Input);
