import { Input as AInput } from "antd";
import styled from "styled-components";

export const Input = styled(AInput)`
  width: 100%;
  background: ${({ theme }) => theme.mint.box.background};
  border: 0.0625rem solid #3b4167;
  box-shadow: none;
  height: 3.375rem;
  border-radius: 0.375rem;
  color: ${({ theme }) => theme.mint.box.color};
  outline: none;
  font-size: 0.875rem;
  line-height: 1.625rem;
  padding: 0.625rem 0.875rem;

  .ant-input {
    background: ${({ theme }) => theme.mint.box.background};
  }

  &.ant-input-affix-wrapper-status-error {
    background: ${({ theme }) => theme.mint.box.background} !important;
    .ant-input {
      background: ${({ theme }) => theme.mint.box.background} !important;
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.halfBlurredWhite};
    font-size: 1rem;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }

  -moz-appearance: textfield;

  &:hover {
    background: ${({ theme }) => theme.mint.hoverBg};
    border-color: #3b4167 !important;
    .ant-input {
      background: #171626;
    }
  }

  &:focus,
  .ant-input-focused {
    border-color: #3b4167;
    box-shadow: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1.25rem;
  }
  &[disabled] {
    background-color: #2a2846 !important;
    color: rgba(255, 255, 255, 0.6);
    border: 0.0625rem solid #3b4167;
    &:hover {
      border: 0.0625rem solid #3b4167;
    }
  }
`;
