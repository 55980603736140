import { ethers } from "ethers";
import PancakeRouterAbi from "abis/PancakeSwap/PancakeRouter.sol/PancakeRouter.json";
import { PANCAKE_ROUTER } from "contract-address";

export const PancakeRouterInterface = new ethers.utils.Interface(
  PancakeRouterAbi.abi
);

export const PancakeRouterContract = new ethers.Contract(
  PANCAKE_ROUTER,
  PancakeRouterInterface
);
