export const SeeMore = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H12C13.0938 14 14 13.125 14 12V2C14 0.90625 13.0938 0 12 0ZM11.6875 7.71875L8.46875 10.9375C8.09375 11.3438 7.4375 11.3438 7.0625 10.9375C6.65625 10.5625 6.65625 9.90625 7.0625 9.53125L8.5625 8H3C2.4375 8 2 7.5625 2 7C2 6.46875 2.4375 6 3 6H8.5625L7.03125 4.46875C6.625 4.09375 6.625 3.4375 7.03125 3.0625C7.40625 2.65625 8.0625 2.65625 8.4375 3.0625L11.6562 6.28125C11.9375 6.5625 12 6.875 12 7C12 7.15625 11.9375 7.46875 11.6875 7.71875Z"
        fill="white"
      />
    </svg>
  );
};
