import { createSlice } from "@reduxjs/toolkit";
import { unionBy } from "lodash";

interface IState {
  usersByWallets: any[];
  marketplaceItems: any[];
  inventoryItems: any[];
}

const initialState: IState = {
  usersByWallets: [],
  marketplaceItems: [],
  inventoryItems: [],
};

export const nftItemsSlice = createSlice({
  name: "nftItems",
  initialState,
  reducers: {
    setUsersByWallets: (state, action) => {
      // state.usersByWallets = union([...state.usersByWallets], action.payload);
      state.usersByWallets = unionBy(
        [...state.usersByWallets],
        action.payload,
        "id"
      );
    },
    setMarketplaceItems: (state, action) => {
      state.marketplaceItems = action.payload;
    },
    setInventoryItems: (state, action) => {
      state.inventoryItems = action.payload;
    },
  },
});

export const { setUsersByWallets, setMarketplaceItems, setInventoryItems } =
  nftItemsSlice.actions;
