import styled from "styled-components";
import { Text as CText } from "components/base";

export const BaseText = styled(CText)`
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.textOrigin.color};
`;

export const CoinPrice = styled(BaseText)``;

export const USDPrice = styled(BaseText)`
  color: ${({ theme }) => theme.text.color};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 1.875rem);
  gap: 0.3125rem;
`;

export const Icon = styled.div`
  flex-shrink: 0;

  svg {
    width: 1.3125rem;
    height: 1.3125rem;
    position: relative;
    top: 0.0625rem;
  }
`;

export const PriceDetail = styled.div<{ sale?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;

  ${CoinPrice}, ${USDPrice} {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ sale }) =>
    sale === true &&
    `
    overflow: hidden;

    ${CoinPrice} {
      width: 100%;
      max-width: calc(100% - 1.875rem);
    }
  `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-wrap: unset;
    overflow: hidden;

    ${CoinPrice} {
      max-width: unset;
    }
  }
`;
