import React, { lazy, Suspense } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { DarkTheme } from 'theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import Loading from 'components/modules/loading';
import Cursor from 'components/modules/cursor';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { SUPPORT_ROUTES, USER_ROUTES } from 'routes/route';
import { SocketProvider } from 'context';
import PrivateRoute from 'routes/privateRoute';
// import { MoralisProvider } from 'react-moralis';
import { REACT_APP_MORALIS_APP_ID, REACT_APP_MORALIS_DAPP_URL, REACT_APP_MORALIS_RPC_URL } from 'utils/constants';
import { AuthAndWalletModals } from 'components/modules';
import { Web3ReactProvider } from '@web3-react/core';
import { WalletModalProvider } from 'context/wallet';
import {
    clearConnectedWallet,
    clearInfo,
    clearToken,
    initialState,
    setInfo,
    setToken,
    showModal,
    toggleFullLoading,
} from 'redux/store/slices';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'utils/hooks';
import { useAppSelector, useWallet } from 'hooks';
// import { IUser } from "type/models";
import { canAuth, canLogin, getProfile, updateUserWallet, walletLogin } from 'redux/apis';

import { ethers } from 'ethers';
import DAppProvider from 'context/dapp';
// import { CoingeckoProvider } from 'utils/context';

import { useAxiosLoading } from 'components/modules/loading/ld';

const Page403 = React.lazy(() => import('pages/403'));
const Page404 = React.lazy(() => import('pages/404'));
const Page500 = React.lazy(() => import('pages/500'));

const ProfilePage = lazy(() => import('pages/profile'));
const ProfileMain = lazy(() => import('components/modules/profile/main'));

const Offer = lazy(() => import('components/modules/profile/offer'));

const Settings = lazy(() => import('components/modules/profile/settings'));

const Inventory = lazy(() => import('components/modules/profile/inventory'));

const queryClientOption = {
    defaultOptions: {
        queries: { refetchOnWindowFocus: false, retry: false },
    },
};

const queryClient = new QueryClient(queryClientOption);

const MainLayout = React.lazy(() => import('theme/default'));

const GlobalStyle = createGlobalStyle`
  body, html, #root, #main-layout {
    height: -webkit-fill-available;
  }

  .safari {
    height: -webkit-fill-available !important;
  }

  .pb-14 {
    padding-bottom: 0.875rem;
  }

  .safari-wallet-sidebar {
    width: 104%;
    height: 150%;
  }

  body {
    cursor: url(/assets/images/cursor.svg), pointer;

    .ck.ck-button, a.ck.ck-button {
      color: ${({ theme }) => theme.colors.white};
    }

    .ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label {
      color: #1890ff;
    }

    .ck.ck-link-actions, .ck.ck-link-form {
      background: #0f0e19;
    }

    .ck.ck-button.ck-button-save, a.ck.ck-button.ck-button-save, .ck.ck-button.ck-button-cancel, a.ck.ck-button.ck-button-cancel {
      color: ${({ theme }) => theme.colors.white};
    }

    /* .ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover {
      background: #ab2f85;
    } */

    .ck.ck-labeled-field-view
    > .ck.ck-labeled-field-view__input-wrapper
    > .ck.ck-label {
      background: ${({ theme }) => theme.mint.box.background};
      color: ${({ theme }) => theme.colors.white};
    }

    .ck.ck-input {
      background: ${({ theme }) => theme.mint.box.background};
      border: 0.0625rem solid ${({ theme }) => theme.mint.box.inputBorder};
      color: ${({ theme }) => theme.mint.box.color};
    }
  
    .ck.ck-input:focus {
      border: 0.0625rem solid ${({ theme }) => theme.colors.border};
      box-shadow: none;
    }

    .ck.ck-responsive-form {
      background: #0F0E19;
    }
  }

  .ant-btn, 
  .ant-switch, 
  .ant-select, 
  .ant-select-item, 
  .ant-select-selection-item, 
  .ant-pagination-item,
  .ant-pagination-next,
  .ant-pagination-prev, 
  .ant-checkbox,
  .ant-checkbox-wrapper,
  li[class*='menu-item'],
  button,
  a {
    cursor: url(/assets/images/cursor-white.svg), pointer;
  }

  .ant-btn.ant-btn-loading, .ant-checkbox-input, label {
    cursor: url(/assets/images/cursor.svg), pointer;
  }

  ::-webkit-scrollbar {
    width: 0.375rem;
    background: ${({ theme }) => theme.scrollbar.background};
    height: 0.375rem;
    border-radius: 62.5rem;
    cursor: auto;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar.color};
    border-radius: 62.5rem;
  }

  .mb-20 {
    margin-bottom: 1.25rem;
  }

  .mb-75 {
    margin-bottom: 4.6875rem;
  }
  
  #popup-root {
    position: relative;

    .ant-dropdown {
      /* animation-duration: 0s; */

      border: 0.0625rem solid rgb(32, 31, 42);
      box-shadow: none;
      border-radius: 0.375rem;
      // width: calc(100% - 1rem);

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}){
        width: auto;
      }
    }

    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }

    .ant-notification-notice {
      background: #0F0E19;
    }

    .no-desc {
      div.ant-notification-notice-with-icon {
        display: flex;
        align-items: center;
      }
    }

    .ant-notification-notice-error {
      border: 1px solid #FF5E58;
      border-radius: 0.375rem;

      .ant-notification-notice-message, .ant-notification-notice-description {
        color: #fff;
        font-size: 16px;
        line-height: 1;
      }

      .ant-notification-notice-description {
        line-height: 22px;
      }
    }

    .ant-notification-notice-success {
      border: 1px solid #71C25D;
      border-radius: 0.375rem;

      .ant-notification-notice-message, .ant-notification-notice-description{
        color: #fff;
        font-size: 16px;
        line-height: 1;
      }

      .ant-notification-notice-description {
        line-height: 22px;
      }
    }

    .ant-notification-notice-info {
      border: 1px solid #FFCCC6;
      border-radius: 0.375rem;

      .ant-notification-notice-message, .ant-notification-notice-description{
        color: #fff;
        font-size: 16px;
        line-height: 1;
      }

      .ant-notification-notice-description {
        line-height: 22px;
      }
    }

    .anticon.ant-notification-notice-icon-error {
      svg {
        fill: #ff4d4f;
      }
    }

    .anticon.ant-notification-notice-icon-info {
      svg {
        fill: #FF9996;
      }
    }

    .anticon.ant-notification-notice-icon-success {
      svg {
        fill: #71C25D;
      }
    }
  }
  
  .ant-tooltip-arrow-content {
    background: white;
    width: 0.5125rem;
    height: 0.5125rem;
  }

  .ant-tooltip-inner{
    padding: 8px 12px;
    background: #1D1C2C;
    border-radius: 4px;
    svg{
      display: none;
    }
  }

  .ant-tooltip-arrow-content{
    background: #1D1C2C;
  }

  .areachart-tooltip-data-label {
    font-size: 0.75rem;
  }

  .atcb-text {
    color: #fefefe;
  }

  .grecaptcha-badge {
    bottom: 4.375rem !important; 
  }
  .ant-dropdown-menu {
    background-color: #232534 !important;
  }
`;

const HelpPage = lazy(() => import('pages/support'));

const getLibrary = (provider: any) => new ethers.providers.Web3Provider(provider);

const Configure: React.FC = () => {
    const wallet = useWallet();
    const { info } = useAppSelector((state) => state.user);
    const { connectedWallet } = useAppSelector((state) => state.wallet);
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translation', { keyPrefix: 'login' });
    const { deactivate } = useWallet();

    const getError = (msg: string) => {
        let message = '';
        switch (msg) {
            case 'wallet_and_user_not_mapping':
                message = t('errors.wallet.0');
                break;
            case 'email_and_wallet_not_mapping':
                message = t('errors.email.0');
                break;
            case 'user_and_wallet_not_mapping_and_wallet_not_connected':
                message = t('errors.email.0');
                break;
            case 'user_and_wallet_not_mapping_and_wallet_connected':
                message = t('errors.email.0');
                break;
            case 'user_and_wallet_not_mapping_and_email_not_connected_with_wallet':
                message = t('errors.wallet.0');
                break;
            case 'user_and_wallet_not_mapping_and_email_connected_with_wallet':
                message = t('errors.wallet.0');
                break;
            case 'user_not_found':
                message = t('errors.user.0');
                break;
            case 'user_is_registered_by_another_provider':
                message = t('errors.user.1');
                break;
            case 'user_not_active':
                message = t('errors.user.2');
                break;
            case 'user_is_inactive':
                message = 'Your account is inactive';
                break;
            case 'user_is_freeze':
                message = 'Your account has been freezed';
                break;
            case 'user_is_banned':
                message = 'Your account has been banned';
                break;
            case 'user_has_been_deleted':
                message = 'Your account has been deleted';
                break;
            default:
                break;
        }

        return message;
    };

    // get current authenticated user
    const getUser = async () => {
        dispatch(toggleFullLoading(true));
        const token = localStorage.getItem('access-token');
        if (token) {
            const response = await getProfile();
            if (response?.data?.data?.user) {
                dispatch(setInfo(response.data.data.user));
            }
        }
        dispatch(toggleFullLoading(false));
    };

    // which run first
    React.useEffect(() => {
        const first = localStorage.getItem('first');

        if (info && !first) {
            localStorage.setItem('first', 'USER');
        }

        if (!info && first === 'USER') {
            localStorage.removeItem('first');
        }
    }, [info]);

    React.useEffect(() => {
        const first = localStorage.getItem('first');

        if (wallet.active && !first) {
            localStorage.setItem('first', 'WALLET');
        }

        if (!wallet.active && first === 'WALLET') {
            localStorage.removeItem('first');
        }
    }, [wallet.active]);

    React.useEffect(() => {
        getUser();
        // eslint-disable-next-line
    }, []);

    // handle for not google
    React.useEffect(() => {
        (async () => {
            if (wallet.active && wallet.account && info?.email) {
                const response = await canLogin(
                    {
                        email: info.email.toLowerCase(),
                        walletAddress: wallet.account,
                    },
                    'emailFirst',
                );

                if (response.data.code === 400) {
                    const message = getError(response.data.message?.toLowerCase());

                    deactivate();
                    dispatch(clearConnectedWallet());
                    dispatch(
                        showModal({
                            ...initialState,
                            used: message,
                        }),
                    );
                    return;
                }

                await updateUserWallet({
                    walletAddress: wallet.account,
                    email: info.email,
                });
            }
        })();

        // eslint-disable-next-line
    }, [wallet.active, wallet.account, info?.email, dispatch]);

    // save current wallet public key
    // React.useEffect(() => {
    //   if (wallet.active && wallet.account) {
    //     if (connectedWallet && connectedWallet !== wallet.account) {
    //       deactivate();
    //       setConnectedWallet(null);
    //     }
    //     else if (!connectedWallet) {
    //       setConnectedWallet(wallet.account);
    //     }
    //   }
    // }, [wallet.active, wallet.account, connectedWallet, deactivate]);

    React.useEffect(() => {
        const first = localStorage.getItem('first');

        (async () => {
            if (info?.email) {
                const canAuthResponse = await canAuth({
                    email: info.email,
                });

                if (canAuthResponse.data.code !== 200) {
                    const message = getError(canAuthResponse.data.message?.toLowerCase());

                    dispatch(
                        showModal({
                            ...initialState,
                            used: message,
                        }),
                    );

                    dispatch(clearInfo());
                    dispatch(clearToken());

                    return;
                }
            }

            const token = localStorage.getItem('access-token');

            if (connectedWallet && info?.email) {
                const response = await canLogin(
                    {
                        email: info.email.toLowerCase(),
                        walletAddress: connectedWallet,
                    },
                    'emailFirst',
                );

                if (response.data.code === 400) {
                    const message = getError(response.data.message?.toLowerCase());

                    if (first === 'USER') {
                        // wallet.disconnect();
                        deactivate();
                        dispatch(clearConnectedWallet());
                        dispatch(
                            showModal({
                                ...initialState,
                                used: message,
                            }),
                        );
                        return;
                    }
                    if (first === 'WALLET') {
                        localStorage.setItem('error', message);
                        dispatch(clearInfo());
                        dispatch(clearToken());
                        return;
                    }
                }
            } else {
                if (connectedWallet && !token && !info?.brandTemporary) {
                    // const response = await walletLogin({
                    //     walletAddress: wallet.account,
                    //     type: 'wallet',
                    // });

                    // if (response.data.code !== 400) {
                    //     const { token, user } = response.data.data;
                    //     localStorage.setItem('access-token', token);
                    //     dispatch(setToken(token));
                    //     dispatch(setInfo(user));
                    // } else {
                    //     Notification({
                    //         type: 'error',
                    //         description: 'Error',
                    //         message: 'Error',
                    //     });
                    //     return;
                    // }
                    return;
                } else {
                    if (info?.brandTemporary && connectedWallet) {
                        const loginRespon = await canLogin(
                            {
                                email: info.brandTemporary.email.toLowerCase(),
                                walletAddress: connectedWallet,
                            },
                            'register',
                        );
                        if (loginRespon.data.code === 400) {
                            const message = getError(loginRespon.data.message?.toLowerCase());
                            deactivate();
                            dispatch(clearConnectedWallet());
                            dispatch(
                                showModal({
                                    ...initialState,
                                    used: message,
                                }),
                            );
                        }
                        return;
                    }
                }
            }
        })();
        // eslint-disable-next-line
    }, [connectedWallet, info?.brandTemporary?.email, info?.email, dispatch]);

    return null;
};

function App() {
    const { fullLoading: AppLoading } = useAppSelector((state) => state.loading);
    const { info } = useAppSelector((state) => state.user);
    const [loading] = useAxiosLoading();
    const fullLoading = loading || AppLoading;
    return (
        <ThemeProvider theme={DarkTheme}>
            <QueryClientProvider client={queryClient}>
                {/* <MoralisProvider serverUrl={REACT_APP_MORALIS_DAPP_URL} appId={REACT_APP_MORALIS_APP_ID}> */}
                <Web3ReactProvider getLibrary={getLibrary}>
                    <DAppProvider rpcUrl={REACT_APP_MORALIS_RPC_URL}>
                        {/* <CoingeckoProvider> */}
                        <WalletModalProvider>
                            {/* <GoogleReCaptchaProvider
                                reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITEKEY as string}
                            > */}
                            <Suspense fallback={<Loading loading />}>
                                <SocketProvider>
                                    <BrowserRouter>
                                        <Configure />
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <MainLayout>
                                                        <React.Suspense fallback={<Loading loading />}>
                                                            <Outlet />
                                                        </React.Suspense>
                                                    </MainLayout>
                                                }
                                            >
                                                {USER_ROUTES.map(
                                                    (route) =>
                                                        (route.role === info?.role || !route.role) && (
                                                            <Route
                                                                key={route.path}
                                                                path={route.path}
                                                                element={route.element}
                                                            />
                                                        ),
                                                )}
                                                <Route path="profile" element={<ProfilePage />}>
                                                    {/* <Route path="watchlist" element={<Watchlist />} />
                                                                <Route path="activity" element={<Activity />} /> */}
                                                    {/* <Route path="offer" element={<Offer />} /> */}
                                                    <Route path="inventory" element={<Inventory />} />
                                                    {/* <Route path="referral" element={<Referral />} /> */}
                                                    <Route path="settings" element={<Settings />} />
                                                    <Route path="offerlist" element={<Offer />} />
                                                    <Route index element={<ProfileMain />} />
                                                </Route>
                                                <Route path="403" element={<Page403 />} />
                                                <Route path="500" element={<Page500 />} />
                                                <Route path="*" element={<Page404 />} />
                                            </Route>
                                            <Route
                                                element={
                                                    <MainLayout>
                                                        <React.Suspense fallback={<Loading loading />}>
                                                            <Outlet />
                                                        </React.Suspense>
                                                    </MainLayout>
                                                }
                                            >
                                                {/* <Route path="/support/*" element={<HelpPage />}>
                                                        {SUPPORT_ROUTES.map((route) => (
                                                            <Route
                                                                key={route.path}
                                                                path={route.path}
                                                                element={route.element}
                                                            />
                                                        ))}
                                                    </Route> */}
                                                {/* <Route
                                                        path="/admin"
                                                        element={
                                                            <React.Suspense fallback={<Loading loading />}>
                                                                <AdminOutlet />
                                                            </React.Suspense>
                                                        }
                                                    >
                                                        <Route path="events">
                                                            <Route index element={<AdminEventsPage />} />
                                                            <Route path="create" element={<AdminCreateEventsPage />} />
                                                            <Route path=":id" element={<AdminEventDetailsPage />} />
                                                        </Route>
                                                        <Route path="accounts" element={<AccountPage />} />
                                                        <Route path="brands" element={<BrandPage />} />
                                                        <Route path="marketplace" element={<AdminMarketplacePage />} />

                                                        <Route path="support">
                                                            <Route path="article" element={<AdminManageArticle />} />
                                                            <Route path="ticket" element={<AdminManageSupport />} />
                                                            <Route index element={<AdminSupportPage />} />
                                                        </Route>

                                                        <Route path="report" element={<AdminReport />} />

                                                        <Route path="membership">
                                                            <Route index element={<AdminMembershipPage />} />
                                                            <Route
                                                                path="create"
                                                                element={<AdminCreateMembershipPage />}
                                                            />
                                                            <Route
                                                                path="update/:id"
                                                                element={<AdminEditMembershipPage />}
                                                            />
                                                        </Route>

                                                        <Route index element={<AdminPage />} />
                                                    </Route> */}
                                            </Route>
                                            <Route element={<PrivateRoute />}></Route>
                                        </Routes>
                                        <AuthAndWalletModals />
                                    </BrowserRouter>
                                </SocketProvider>
                            </Suspense>
                            {/* </GoogleReCaptchaProvider> */}
                        </WalletModalProvider>
                        {/* </CoingeckoProvider> */}
                    </DAppProvider>
                </Web3ReactProvider>
                {/* </MoralisProvider> */}
                <GlobalStyle />
                <Loading loading={fullLoading} />
                <Cursor />
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
