import styled from 'styled-components';

export const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    display: grid;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
`;

export const LoadingContent = styled.div`
    position: relative;

    & > div:last-child {
        position: absolute;
        top: 1.5625rem;
        left: 0.875rem;
    }
`;
