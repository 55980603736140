export const ErrorNoti = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 13.8496C32 2.44445 29.5555 0 18.1504 0H13.8496C2.44445 0 0 2.44445 0 13.8496V18.1504C0 29.5555 2.44445 32 13.8496 32H18.1504C29.5555 32 32 29.5555 32 18.1504V13.8496Z"
        fill="#F2F2F4"
      />
      <path
        d="M16 26C21.5228 26 26 21.5228 26 16C26 10.4771 21.5228 6 16 6C10.4771 6 6 10.4771 6 16C6 21.5228 10.4771 26 16 26Z"
        fill="#FF4D4E"
      />
      <path
        d="M16 20V16M16 12H16.01"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SuccessNoti = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 13.8496C32 2.44445 29.5555 0 18.1504 0H13.8496C2.44446 0 7.15256e-07 2.44445 7.15256e-07 13.8496V18.1504C7.15256e-07 29.5555 2.44446 32 13.8496 32H18.1504C29.5555 32 32 29.5555 32 18.1504V13.8496Z"
        fill="#F2F2F4"
      />
      <path
        d="M16 26C21.5228 26 26 21.5228 26 16C26 10.4771 21.5228 6 16 6C10.4771 6 6 10.4771 6 16C6 21.5228 10.4771 26 16 26Z"
        fill="#71C25D"
      />
      <path
        d="M11.5 16L14.5 19L20.5 13"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const WarningNoti = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 13.8496C32 2.44445 29.5555 0 18.1504 0H13.8496C2.44445 0 0 2.44445 0 13.8496V18.1504C0 29.5555 2.44445 32 13.8496 32H18.1504C29.5555 32 32 29.5555 32 18.1504V13.8496Z"
        fill="#F2F2F4"
      />
      <path
        d="M16 26C21.5228 26 26 21.5228 26 16C26 10.4771 21.5228 6 16 6C10.4771 6 6 10.4771 6 16C6 21.5228 10.4771 26 16 26Z"
        fill="#F3BA2F"
      />
      <path
        d="M16 20V16M16 12H16.01"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
