const Web3 = require('web3');
const axios = require('axios');
const web3 = new Web3(window.ethereum);

// window.ethereum.enable(); // Request access to Ethereum

export const createStandardToken = async (name, symbol, decimals, initSupply, Id) => {
    const json = require(`abis/FT/${Id}.json`);

    // Create new contract instance
    var contract = new web3.eth.Contract(json.abi);

    try {
        // Get the first account
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];

        // Estimate gas
        const gas = await contract
            .deploy({
                data: json.bytecode,
                arguments: [name, symbol, decimals, initSupply, Id, '0xB4482D1f19F7202368d64c3ab74E463136335726'],
            })
            .estimateGas({ from: account, value: web3.utils.toWei('0.25', 'ether') });

        // Deploy contract
        const instance = await contract
            .deploy({
                data: json.bytecode,
                arguments: [name, symbol, decimals, initSupply, Id, '0xB4482D1f19F7202368d64c3ab74E463136335726'],
            })
            .send({ from: account, gas, value: web3.utils.toWei('0.25', 'ether') });

        // Return contract address
        return instance.options.address;
    } catch (error) {
        console.error('An error occurred while creating the standard token: ', error);
        // Consider rethrowing the error if you want it to propagate.
    }
};

export const createTaxAndReflecToken = async (
    name,
    symbol,
    decimals,
    initSupply,
    router,
    tax_reflection,
    tax_wallet,
    tax_burn,
    tax_liquidity,
    tax_buyback,
) => {
    const json = require(`abis/FT/Cryptool_TAX.json`);

    // Create new contract instance
    var contract = new web3.eth.Contract(json.abi);

    const base = web3.utils.toBN(initSupply);
    const multiplier = web3.utils.toBN(10).pow(web3.utils.toBN(decimals));
    const initSupplyBN = base.mul(multiplier);
    console.log('initSupplyBN: ', initSupplyBN);
    try {
        // Get the first account
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];

        // Estimate gas
        const gas = await contract
            .deploy({
                data: json.bytecode,
                arguments: [
                    name,
                    symbol,
                    decimals,
                    initSupplyBN,
                    router,
                    tax_reflection,
                    tax_wallet,
                    tax_burn,
                    tax_liquidity,
                    tax_buyback,
                    '0xB4482D1f19F7202368d64c3ab74E463136335726', //admin receive fee create contract
                ],
            })
            .estimateGas({ from: account, value: web3.utils.toWei('0.35', 'ether') });

        // Deploy contract
        const instance = await contract
            .deploy({
                data: json.bytecode,
                arguments: [
                    name,
                    symbol,
                    decimals,
                    initSupplyBN,
                    router,
                    tax_reflection,
                    tax_wallet,
                    tax_burn,
                    tax_liquidity,
                    tax_buyback,
                    '0xB4482D1f19F7202368d64c3ab74E463136335726',
                ],
            })
            .send({ from: account, gas, value: web3.utils.toWei('0.35', 'ether') });

        // Return contract address
        return instance.options.address;
    } catch (error) {
        console.error('An error occurred while creating the standard token: ', error);
        // Consider rethrowing the error if you want it to propagate.
    }
};

export const getTokenInformation = async (contractAddress) => {
    const json = require(`abis/FT/0.json`);
    // Create new contract instance
    const contract = new web3.eth.Contract(json.abi, contractAddress);

    let createdByCryptool = null;
    let getCryptoolId = null;

    try {
        const name = await contract.methods.name().call();
        const symbol = await contract.methods.symbol().call();
        const decimals = await contract.methods.decimals().call();
        const totalSupply = await contract.methods.totalSupply().call();

        try {
            createdByCryptool = await contract.methods.createdByCryptool().call();
            getCryptoolId = await contract.methods.getCryptoolId().call();
        } catch (error) {}

        return {
            name: name,
            symbol: symbol,
            decimals: decimals,
            totalSupply: totalSupply,
            createdByCryptool: createdByCryptool,
            getCryptoolId: getCryptoolId,
        };
    } catch (error) {
        console.error('An error occurred while get token info: ', error);
        // Consider rethrowing the error if you want it to propagate.
    }
};

export const verifyToken = async (contractAddress) => {
    const tokenInfo = await getTokenInformation(contractAddress);

    if (tokenInfo.getCryptoolId !== null) {
        const sourceCode = await fetch(`/assets/FT/${tokenInfo.getCryptoolId}.txt`)
            .then((response) => response.text())
            .then((sourceCode) => {
                return sourceCode;
            });

        const abiEncodedConstructorArgs = web3.eth.abi
            .encodeParameters(
                ['string', 'string', 'uint8', 'uint256', 'string', 'address'],
                [
                    tokenInfo.name,
                    tokenInfo.symbol,
                    tokenInfo.decimals,
                    tokenInfo.totalSupply / 10 ** tokenInfo.decimals,
                    tokenInfo.getCryptoolId,
                    '0xB4482D1f19F7202368d64c3ab74E463136335726',
                ],
            )
            .slice(2);

        const params = new URLSearchParams();
        params.append('apikey', 'FE9QHFRTFMH357P7M6STQFSTN6Y4T4E81Y');
        params.append('module', 'contract');
        params.append('action', 'verifysourcecode');
        params.append('contractaddress', contractAddress);
        params.append('sourceCode', sourceCode);
        params.append('codeformat', 'solidity-single-file');
        params.append('contractname', 'ERC20Token');
        params.append('compilerversion', 'v0.8.18+commit.87f61d96');
        params.append('optimizationUsed', '1');
        params.append('runs', 200);
        params.append('constructorArguements', abiEncodedConstructorArgs);

        return axios
            .post('https://api-testnet.bscscan.com/api', params.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }
};
