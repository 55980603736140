import { SVGProps } from "./svg.props";

const Japan = (props: SVGProps): JSX.Element => {
  const { width, height } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 20}
      height={height || 20}
      xmlns="http://www.w3.org/2000/svg"
      id="flag-icons-jp"
      viewBox="0 0 512 512"
    >
      <defs>
        <clipPath id="jp-a">
          <path fillOpacity=".7" d="M177.2 0h708.6v708.7H177.2z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        strokeWidth="1pt"
        clipPath="url(#jp-a)"
        transform="translate(-128) scale(.72249)"
      >
        <path fill="#fff" d="M0 0h1063v708.7H0z" />
        <circle
          cx="523.1"
          cy="344.1"
          r="194.9"
          fill="#d30000"
          transform="translate(-59.7 -34.5) scale(1.1302)"
        />
      </g>
    </svg>
  );
};

export default Japan;
