import { Plus } from 'components/assets/plus.icon';
import { Subtract } from 'components/assets/subtract.icon';
import Skeleton from 'components/base/skeleton';
import { FC, memo } from 'react';
import { mouseEnter, mouseLeave } from 'utils/mouseEvent';
import InputProps from './inputNumber.props';
import { InputNumber as InputNumberStyled } from './inputNumber.style';

const InputNumber: FC<InputProps> = ({ skeleton, loading, ...props }) => {
    return loading ? (
        <Skeleton {...skeleton} />
    ) : (
        <InputNumberStyled
            upHandler={
                <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} className="icon">
                    <Plus />
                </div>
            }
            downHandler={
                <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} className="icon">
                    <Subtract />
                </div>
            }
            {...props}
        />
    );
};

export default memo(InputNumber);
