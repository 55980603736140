import { ConnectorNames } from 'utils/connector';
import { Coinbase, Binance, Torus, WalletConnect } from 'components/assets';

export interface SupportWallet {
    Icon: any;
    name: string;
    connector: ConnectorNames;
}

export const supportWallets: SupportWallet[] = [
    // {
    //   Icon: Coinbase,
    //   name: "Coinbase",
    //   connector: ConnectorNames.WalletLink,
    // },
    // {
    //   Icon: Torus,
    //   name: "Torus",
    //   connector: ConnectorNames.Torus,
    // },
    // {
    //   Icon: Binance,
    //   name: "Binance",
    //   connector: ConnectorNames.Binance,
    // },
    // {
    //   Icon: WalletConnect,
    //   name: "WalletConnect",
    //   connector: ConnectorNames.WalletConnect,
    // },
];
