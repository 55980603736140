import { FC, memo } from 'react';
import { mouseEnter, mouseLeave } from 'utils';
import IDropDownProps from './dropdown.props';
import { DropdownWrapper, StyledDropdown } from './dropdown.style';

const Dropdown: FC<IDropDownProps> = ({ children, hover = true, ...rest }) => {
    return (
        <DropdownWrapper onMouseEnter={hover ? mouseEnter : undefined} onMouseLeave={hover ? mouseLeave : undefined}>
            <StyledDropdown
                {...rest}
                trigger={['click']}
                // getPopupContainer={() => document.getElementById("popup-root")!}
            >
                {children}
            </StyledDropdown>
        </DropdownWrapper>
    );
};

export default memo(Dropdown);
