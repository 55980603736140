export const Buy = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.43 6.70803V4.41608C11.43 2.72833 10.0618 1.36014 8.37403 1.36014C6.68626 1.36014 5.31807 2.72833 5.31807 4.41608V6.70803M1.95043 7.74094L1.49204 12.6304C1.36171 14.0207 1.29655 14.7157 1.52722 15.2527C1.72987 15.7243 2.08498 16.1143 2.53566 16.3601C3.04868 16.6398 3.74684 16.6398 5.14316 16.6398H11.6048C13.0012 16.6398 13.6993 16.6398 14.2123 16.3601C14.663 16.1143 15.0182 15.7243 15.2208 15.2527C15.4515 14.7157 15.3863 14.0207 15.256 12.6304L14.7976 7.74094C14.6875 6.56696 14.6325 5.97998 14.3685 5.5362C14.136 5.14536 13.7924 4.83253 13.3816 4.63751C12.9152 4.41608 12.3256 4.41608 11.1465 4.41608H5.60155C4.42245 4.41608 3.8329 4.41608 3.36642 4.63751C2.95558 4.83253 2.61205 5.14536 2.37953 5.5362C2.11552 5.97998 2.06049 6.56695 1.95043 7.74094Z"
        stroke="#F4F4F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Cancel = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.04102 7.64648C8.26953 7.90039 8.26953 8.28125 8.04102 8.50977C7.78711 8.76367 7.40625 8.76367 7.17773 8.50977L4.18164 5.48828L1.16016 8.50977C0.90625 8.76367 0.525391 8.76367 0.296875 8.50977C0.0429688 8.28125 0.0429688 7.90039 0.296875 7.64648L3.31836 4.625L0.296875 1.60352C0.0429688 1.34961 0.0429688 0.96875 0.296875 0.740234C0.525391 0.486328 0.90625 0.486328 1.13477 0.740234L4.18164 3.78711L7.20312 0.765625C7.43164 0.511719 7.8125 0.511719 8.04102 0.765625C8.29492 0.994141 8.29492 1.375 8.04102 1.62891L5.01953 4.625L8.04102 7.64648Z"
        fill="white"
      />
    </svg>
  );
};

export const Request = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.03062 3.18682H4.69087C3.35485 3.18682 2.68684 3.18682 2.17655 3.44683C1.72768 3.67554 1.36274 4.04049 1.13403 4.48935C0.874024 4.99965 0.874023 5.66766 0.874023 7.00368V13.6832C0.874023 15.0192 0.874024 15.6872 1.13403 16.1975C1.36274 16.6463 1.72768 17.0113 2.17655 17.24C2.68684 17.5 3.35485 17.5 4.69087 17.5H11.3704C12.7064 17.5 13.3744 17.5 13.8847 17.24C14.3336 17.0113 14.6985 16.6463 14.9272 16.1975C15.1872 15.6872 15.1872 15.0192 15.1872 13.6832V10.3434M5.64506 12.729H6.97663C7.36561 12.729 7.56011 12.729 7.74316 12.685C7.90538 12.646 8.06052 12.5818 8.20285 12.4946C8.36332 12.3963 8.50089 12.2587 8.77594 11.9836L16.38 4.37959C17.0387 3.72085 17.0387 2.65281 16.38 1.99406C15.7213 1.33532 14.6532 1.33531 13.9944 1.99406L6.39035 9.59812C6.1153 9.87317 5.97777 10.0107 5.87942 10.1712C5.79223 10.3135 5.72797 10.4686 5.689 10.6309C5.64506 10.8139 5.64506 11.0084 5.64506 11.3974V12.729Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Sell = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33789 2.96875C2.33789 2.60352 2.63867 2.28125 3.02539 2.28125C3.39062 2.28125 3.71289 2.60352 3.71289 2.96875C3.71289 3.35547 3.39062 3.65625 3.02539 3.65625C2.63867 3.65625 2.33789 3.35547 2.33789 2.96875ZM4.85156 0.5625C5.2168 0.5625 5.56055 0.712891 5.81836 0.970703L9.59961 4.75195C10.1367 5.28906 10.1367 6.16992 9.59961 6.70703L6.74219 9.56445C6.20508 10.1016 5.32422 10.1016 4.78711 9.56445L1.00586 5.7832C0.748047 5.52539 0.619141 5.18164 0.619141 4.81641V1.59375C0.619141 1.03516 1.07031 0.5625 1.65039 0.5625H4.85156ZM1.73633 5.05273L5.51758 8.83398C5.64648 8.98438 5.88281 8.98438 6.01172 8.83398L8.86914 5.97656C9.01953 5.84766 9.01953 5.61133 8.86914 5.48242L5.08789 1.70117C5.02344 1.63672 4.9375 1.59375 4.85156 1.59375H1.65039V4.81641C1.65039 4.90234 1.67188 4.98828 1.73633 5.05273Z"
        fill="white"
      />
    </svg>
  );
};
