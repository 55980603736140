import { InputNumber as AInputNumber } from 'antd';
import styled from 'styled-components';

export const InputNumber = styled(AInputNumber)`
    box-shadow: none !important;
    width: 100%;
    height: 3.5rem;
    border: 0.0625rem solid #3b4167;
    border-radius: 0.375rem;
    min-width: 5rem;
    background: #f5f5f5; // Light grey background

    &.ant-input-number-disabled {
        background: #f5f5f5;
        .ant-input-number-input-wrap:hover {
            background: none;
        }
    }
    &.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number {
        background: #f5f5f5;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 0px;
    }

    &:hover,
    &:focus {
        border-color: #f5f5f5;
        box-shadow: none;
        outline: none;
    }

    & .ant-input-number-handler-wrap {
        background: transparent;
        opacity: 1;
        border-radius: 0.375rem;
        right: 0.75rem;
        svg {
            path {
                fill: #000; // Black color for the icons
            }
        }
        &:hover .ant-input-number-handler {
            height: 50%;
        }
    }

    & .ant-input-number-handler-up:hover,
    & .ant-input-number-handler-down:hover {
        height: 50% !important;
        box-shadow: none;
        background: #f5f5f5; // Keep the background light grey when not hovering
    }

    & .ant-input-number-input-wrap {
        height: 100%;
        border-radius: 0.375rem;
        &:hover {
            background-color: #f5f5f5; // Keep the background light grey when not hovering
        }
    }

    & input {
        height: 100%;
        border-radius: 0.375rem;
        padding-left: 1.3125rem;
        color: #000; // Black input text
    }

    .ant-input-number-handler {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        &:hover {
            height: 50%;
        }
        &:active {
            background: none;
        }
    }

    & input::placeholder {
        color: #a9a9a9; // Grey placeholder text
    }

    &:hover input {
        color: rgba(0, 0, 0, 0.9); // Black color with a bit more opacity when hover
    }

    &:active input,
    &:focus input {
        color: rgba(0, 0, 0, 0.9); // Black color with a bit more opacity when active or focused
    }
`;
