import styled from "styled-components";
import CSelect, { Option } from "components/base/select";

export const ViewType = styled(CSelect)`
  height: 3.375rem;
  max-width: 18.6875rem;
  // box-shadow: 0px 1.5rem 2.75rem 1.25rem rgba(255, 244, 244, 0.06);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 26.25rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    max-width: 15.6875rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.0625rem solid
      ${({ theme }) => theme.marketPlaceDetail.menuBg.borderColor};
  }

  .ant-select-selection-item {
    font-size: 0.875rem;
    line-height: 0.875rem !important;
    overflow: visible;

    svg {
      display: none;
    }
  }

  &:hover {
    .ant-select-selector {
      background: ${({ theme }) => theme.mint.hoverBg} !important;
    }
  }
`;

export const ViewTypeOption = styled(Option)``;
