export const MessageSupport = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.96484 5.5625C4.47266 5.5625 4.11719 5.97266 4.11719 6.4375C4.11719 6.92969 4.5 7.3125 4.96484 7.3125C5.42969 7.3125 5.8125 6.92969 5.8125 6.4375C5.8125 5.97266 5.45703 5.5625 4.96484 5.5625ZM8.02734 5.5625C7.53516 5.5625 7.15234 5.97266 7.15234 6.4375C7.15234 6.92969 7.53516 7.3125 8.02734 7.3125C8.51953 7.3125 8.875 6.92969 8.875 6.4375C8.875 5.97266 8.51953 5.5625 8.02734 5.5625ZM11.0898 5.5625C10.5977 5.5625 10.2422 5.97266 10.2422 6.4375C10.2422 6.92969 10.625 7.3125 11.0898 7.3125C11.5547 7.3125 11.9375 6.92969 11.9375 6.4375C11.9375 5.97266 11.582 5.5625 11.0898 5.5625ZM13.2227 0.75H2.72266C1.73828 0.75 0.972656 1.54297 0.972656 2.5V10.3477C0.972656 11.3047 1.73828 12.0703 2.72266 12.0703H5.34766V14.3398C5.34766 14.6133 5.64844 14.75 5.86719 14.6133L9.28516 12.043H13.2227C14.1797 12.043 14.9727 11.25 14.9727 10.3203V2.5C14.9727 1.54297 14.207 0.75 13.2227 0.75ZM13.6875 10.375C13.6875 10.6211 13.4688 10.8125 13.25 10.8125H8.875L6.6875 12.4531V10.8125H2.75C2.50391 10.8125 2.3125 10.6211 2.3125 10.375V2.5C2.3125 2.28125 2.50391 2.0625 2.75 2.0625H13.25C13.4688 2.0625 13.6875 2.28125 13.6875 2.5V10.375Z"
        fill="white"
      />
    </svg>
  );
};

export const BlueMessage = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.5" cy="14.5" r="14.5" fill="#5A86F3" />
      <path
        d="M21.6309 10.3485V18.1678C21.6309 19.1214 20.8408 19.8842 19.8872 19.8842H15.9639L12.5583 22.418C12.3404 22.5815 12.0407 22.4452 12.0407 22.1728V19.8842H9.42517C8.44435 19.8842 7.6815 19.0941 7.6815 18.1678V10.3485C7.6815 9.36771 8.44435 8.60485 9.42517 8.60485H19.8872C20.868 8.60485 21.6309 9.39495 21.6309 10.3485Z"
        fill="white"
      />
    </svg>
  );
};
