import { useAppSelector } from "hooks";
import { FC, memo } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute: FC<{ role?: string | undefined }> = ({ role }) => {
  const { info } = useAppSelector((state) => state.user);
  const sessionUser = localStorage.getItem("user");

  return sessionUser ||
    !role ||
    info?.role === role ||
    info?.role === "admin" ? (
    <Outlet />
  ) : (
    <Navigate to="/403" />
  );
};

export default memo(PrivateRoute);
