import { Tag } from 'antd';
import styled from 'styled-components';

const { CheckableTag } = Tag;

export const ACheckableTag = styled(CheckableTag)`
    font-size: 0.875rem;
    line-height: 1;
    color: ${({ theme }) => theme.colors.white};
`;

export const TagContainer = styled.div`
    background: ${({ theme }) => theme.colors.border};
    border-radius: 0.25rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-tag-checkable-checked {
        background: 0.0625rem solid #a1b7f8;
    }
    .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
        color: 0.0625rem solid #a1b7f8;
        border-radius: 0.25rem;
    }
    .ant-tag-checkable:active {
        background: 0.0625rem solid #a1b7f8;
    }
    .ant-tag {
        margin-right: 0;
        border-radius: 0.25rem;
        padding: 0.3125rem;
    }
`;
