export const Lock = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 6V4.5C2.5 2.03125 4.5 0 7 0C9.46875 0 11.5 2.03125 11.5 4.5V6H12C13.0938 6 14 6.90625 14 8V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V8C0 6.90625 0.875 6 2 6H2.5ZM4.5 6H9.5V4.5C9.5 3.125 8.375 2 7 2C5.59375 2 4.5 3.125 4.5 4.5V6Z"
        fill="#F2F2F4"
      />
    </svg>
  );
};

export const StakingLock = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 4V3C7.5 1.61929 6.3807 0.5 5 0.5C3.61929 0.5 2.5 1.61929 2.5 3V4M5 6.25V7.25M3.4 9.5H6.6C7.4401 9.5 7.8601 9.5 8.181 9.3365C8.46325 9.1927 8.6927 8.96325 8.8365 8.681C9 8.3601 9 7.9401 9 7.1V6.4C9 5.5599 9 5.1399 8.8365 4.819C8.6927 4.53675 8.46325 4.3073 8.181 4.1635C7.8601 4 7.4401 4 6.6 4H3.4C2.55992 4 2.13988 4 1.81902 4.1635C1.53677 4.3073 1.3073 4.53675 1.16349 4.819C1 5.1399 1 5.5599 1 6.4V7.1C1 7.9401 1 8.3601 1.16349 8.681C1.3073 8.96325 1.53677 9.1927 1.81902 9.3365C2.13988 9.5 2.55992 9.5 3.4 9.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
