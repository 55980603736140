import { AxiosResponse } from 'axios';
import {
    ICanAuthBody,
    ICanLoginBody,
    ICheckEmail,
    ICheckUserName,
    IForgotPasswordBody,
    IForgotPasswordResponse,
    ILoginBody,
    IUpdatePasswordBody,
    IVerifyOtpBody,
    IWalletLoginBody,
} from 'models';
import instance from './common';

export const register = (body: ILoginBody) => {
    return instance.post('/auth/register', body);
};

export const login = (body: ILoginBody): Promise<AxiosResponse<any>> => {
    return instance.post('/auth/login', body);
};

export const canLogin = (body: ICanLoginBody, first: 'walletFirst' | 'emailFirst' | 'register' | null) => {
    return instance.post(`/auth/can-login${first ? `?type=${first}` : ''}`, body);
};

export const walletLogin = (body: IWalletLoginBody) => {
    return instance.post(`/auth/login`, body);
};

export const canAuth = (body: ICanAuthBody) => instance.post('/auth/check-can-auth', body);

export const validateUserName = (body: ICheckUserName) => {
    return instance.post('/auth/check-username', body);
};

export const validateEmail = (body: ICheckEmail) => {
    return instance.post('/auth/check-email', body);
};

export const forgotPassword = (body: IForgotPasswordBody) => {
    return instance.post(`/auth/forgot-password`, body);
};

export const verifyOtp = (body: IVerifyOtpBody) => {
    return instance.post(`/auth/verify-otp`, body);
};

export const updatePassword = (body: IUpdatePasswordBody) => {
    return instance.post('/auth/update-password', body);
};

export const activeEmail = (body: IForgotPasswordBody) => {
    return instance.post('/auth/request-confirm-account', body);
};

export const verifyToken = (body: IForgotPasswordResponse) => {
    return instance.post('/auth/confirm-account', body);
};
