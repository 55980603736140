import { Input as AInput } from 'antd';
import styled from 'styled-components';

export const Input = styled(AInput)`
    width: 100%;
    background: ${({ theme }) => theme.mint.box.background};
    border: 0.0625rem solid #201f2a;
    height: 3.375rem;
    border-radius: 0.375rem;
    color: ${({ theme }) => theme.mint.box.color};
    outline: none;
    font-size: 0.875rem;
    line-height: 1.625rem;
    padding: 0.625rem 0.875rem;

    &::placeholder {
        color: ${({ theme }) => theme.colors.halfBlurredWhite};
        font-size: 1rem;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
    }

    -moz-appearance: textfield;

    &:hover {
        background: ${({ theme }) => theme.mint.hoverBg};
        border-color: #201f2a;
    }

    &:focus {
        background: ${({ theme }) => theme.mint.box.background};
        border-color: #201f2a;
    }
    .ant-input {
        background: transparent;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding-left: 1.25rem;
    }
    &[disabled] {
        background-color: #2a2846 !important;
        color: rgba(255, 255, 255, 0.6);
        border: none;
    }
    .ant-input[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #0f0e19 !important;
        border-color: #d9d9d9;
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;
    }
    .ant-input-disabled {
        background-color: red;
    }
`;

export const { Password } = Input;
