import { FC, memo, useRef, useState } from "react";
import { Search } from "components/assets";

import { ISearchBoxProps } from "./searchBox.props";
import { SearchView, Gradient } from "./searchBox.style";

const SearchBox: FC<ISearchBoxProps> = ({
  gradientStyle,
  gradientClass = "",
  variant = "gradient",
  className = "",
  ...rest
}) => {
  const gradientRef = useRef<HTMLDivElement>(null);
  const [showGradient, setShowGradient] = useState<boolean>(false);

  const handleShowGradient = () => {
    const ele = gradientRef.current?.querySelector(
      ".ant-input-affix-wrapper > input.ant-input:focus"
    );

    setShowGradient(Boolean(ele));
  };

  return (
    <Gradient
      variant={variant}
      style={gradientStyle}
      ref={gradientRef}
      onClick={handleShowGradient}
      className={`  ${gradientClass} ${className}`}
      onBlur={() => setShowGradient(false)}
      showGradient={showGradient}
    >
      <SearchView prefix={<Search />} {...rest} />
    </Gradient>
  );
};

export default memo(SearchBox);
