import { useState, useEffect } from "react";
import { debounce } from "./debounce";
export default function useMediaQuery(query: string, defaultValue?: boolean) {
  const [matches, setMatches] = useState(
    defaultValue || window.matchMedia(query).matches
  );

  useEffect(() => {
    const resize = () => {
      setMatches(window.matchMedia(query).matches);
    };
    resize();
    const debouncedResize = debounce(resize, 100);

    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, [query]);

  return [matches];
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
  var arr = dataurl.split(","),
    mime = arr[0]?.match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
