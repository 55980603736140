import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Notification, NOTIFICATION_TYPE } from 'components/base';
import { useCollectionFactoryCreate } from 'contracts/Collection/CollectionFactory/hooks';
import { useWallet } from 'hooks';
import { IBrandTbl } from 'models/brand';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getBrandByUser } from 'redux/apis/brand';
import { createCollection } from 'redux/apis/collection';
import { UserPaths } from 'utils/constants/paths';
import { uploadImageToS3 } from 'utils/file';
import FtForm from '../componentTokenVerify/ftForm';
import { ECollectonFormType, ICollectionForm } from '../component/ftForm/collectionForm.props';
import { ICreateCollectionProps } from './createCollection.props';
import { getTokenInformation, verifyToken } from 'contracts/FtFactory';

const TokenVerify = ({ ...props }) => {
    const { send: createCollectionByContract } = useCollectionFactoryCreate();
    const [myBrand, setMyBrand] = useState({});
    const navigate = useNavigate();
    const { account } = useWallet();
    const [isCreating, setIsCreating] = useState(false);
    const [infoToken, setInfoToken] = useState({});
    const [verifyResponse, setVerifyResponse] = useState({});

    const showNotification = (type, message) => {
        return Notification({
            type: type,
            message: message,
        });
    };

    const fetchTokenInfo = async (tokenAddress) => {
        try {
            return await getTokenInformation(tokenAddress);
        } catch (err) {
            return null;
        }
    };

    const getTokenInfo = async (tokenAddress) => {
        setIsCreating(true);

        try {
            const info = await fetchTokenInfo(tokenAddress);

            if (info == null) {
                setIsCreating(false);
                showNotification('error', 'Get Token Info failed!');
                return;
            }

            setIsCreating(false);
            // navigate(`/collections`);
            // showNotification('success', 'Get Token Info successfully');
            setInfoToken(info);
        } catch (err) {
            setIsCreating(false);
            showNotification('error', 'Get Token Info failed!');
        }
    };

    const handeVerify = async (tokenAddress) => {
        setIsCreating(true);

        try {
            const getverifyResponse: any = await verifyToken(tokenAddress);
            setIsCreating(false);
            // navigate(`/collections`)

            setVerifyResponse(getverifyResponse);
        } catch (err) {
            setIsCreating(false);
            showNotification('error', 'Verify Contract Token failed!');
        }
    };
    return (
        <FtForm
            // fetchUpdateCollectionByAddress={fetchCreateCollectionByAddress}
            getTokenInfo={getTokenInfo}
            isProcessing={isCreating}
            tokenInfo={infoToken}
            verifyResponse={verifyResponse}
            handeVerify={handeVerify}
        />
    );
};

export default TokenVerify;
