import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInfoData } from "models/user";

interface UserState {
  token: string;
  info: IInfoData | null;
}

const initialState: UserState = {
  token: localStorage.getItem("access-token") || "",
  info: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = "";
    },
    setInfo: (state, action: PayloadAction<any | null>) => {
      state.info = { ...state.info, ...action.payload };
    },
    clearInfo: (state) => {
      state.info = null;
    },
  },
});

export const { setToken, clearToken, setInfo, clearInfo } = userSlice.actions;
