export const CheckedWhite = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM11.5938 6.625L7.59375 10.625C7.4375 10.8125 7.21875 10.875 7 10.875C6.75 10.875 6.53125 10.8125 6.375 10.625L4.375 8.625C4.03125 8.28125 4.03125 7.75 4.375 7.40625C4.71875 7.0625 5.25 7.0625 5.59375 7.40625L7 8.78125L10.375 5.40625C10.7188 5.0625 11.25 5.0625 11.5938 5.40625C11.9375 5.75 11.9375 6.28125 11.5938 6.625Z"
        fill="#F2F2F4"
      />
    </svg>
  );
};

export const CheckedWhiteOutline = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4688 5.46875L7 8.96875L5.5 7.46875C5.1875 7.1875 4.71875 7.1875 4.4375 7.46875C4.125 7.78125 4.125 8.25 4.4375 8.53125L6.4375 10.5312C6.59375 10.6875 6.78125 10.75 7 10.75C7.1875 10.75 7.375 10.6875 7.5 10.5312L11.5 6.53125C11.8125 6.25 11.8125 5.78125 11.5 5.5C11.2188 5.1875 10.75 5.1875 10.4688 5.46875ZM8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM8 14.5C4.40625 14.5 1.5 11.5938 1.5 8C1.5 4.4375 4.40625 1.5 8 1.5C11.5625 1.5 14.5 4.4375 14.5 8C14.5 11.5938 11.5625 14.5 8 14.5Z"
        fill="white"
      />
    </svg>
  );
};
