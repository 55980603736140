import { IconPositionType } from "components/base/buttonPrimary/buttonPrimary.props";
import styled from "styled-components";

export const Glow = styled.div`
  position: absolute;
  border-radius: 50%;
  filter: blur(1.125rem);
  transition: 0.3s ease-in-out;
`;

export const Button = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  cursor: url(/assets/images/cursor-white.svg), pointer;

  position: relative;
  border-radius: 0.375rem;
  border: none !important;
  background: ${({ theme }) => theme.button.primary.default.background};
  overflow: hidden;
  padding: 1.0625rem;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.0625rem solid rgba(255, 255, 255, 0.1);
    border-radius: 0.375rem;
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 0.0625rem solid rgba(255, 255, 255, 0.1);
    background: ${({ theme }) => theme.button.primary.default.background};
  }

  & > ${Glow}:nth-of-type(1) {
    width: 15.875rem;
    height: 7rem;
    background: #a03984;
    top: 1.5625rem;
    left: -0.3125rem;
  }

  & > ${Glow}:nth-of-type(2) {
    width: 10.3125rem;
    height: 7rem;
    background: #3759a5;
    top: -1.8625rem;
    left: 1.5875rem;
  }

  & > ${Glow}:nth-of-type(3) {
    width: 8.8125rem;
    height: 5.125rem;
    top: -3.375rem;
    left: 0.0375rem;
    background: #dd7ac5;
  }

  &:hover {
    & > ${Glow}:nth-of-type(1) {
      top: -1.4375rem;
      left: 4.6875rem;
    }

    & > ${Glow}:nth-of-type(2) {
      top: -3.8625rem;
      left: 0.5875rem;
    }

    & > ${Glow}:nth-of-type(3) {
      top: -1.375rem;
      left: -2.9625rem;
    }
  }

  &:disabled {
    border: 0.0625rem solid #999eaf;
    background: rgba(153, 158, 175, 1);
    opacity: 0.7;
  }
`;

export const ButtonContent = styled.span<{ iconPosition?: IconPositionType }>`
  color: rgba(255, 255, 255, 1);
  font-size: 1rem;
  line-height: 1rem;

  position: relative;
  z-index: 10;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: center;

  > svg {
    ${({ iconPosition }) =>
      iconPosition === "LEFT"
        ? "margin-right: .8056rem;"
        : "margin-left: .8056rem;"}
  }
`;
