import styled from "styled-components";

export const CollapseView = styled.div``;

export const CollapseItemView = styled.div<{ expanded?: boolean }>``;

export const CollapseContent = styled.div<{ expanded: boolean }>`
  ${({ expanded }) =>
    !expanded &&
    `
    opacity:0;
    max-height: 0;
    height:0;
    overflow: hidden;
  `}
  ${({ expanded }) =>
    expanded &&
    `
    opacity: 1;
    max-height: unset;
    height:auto;
    overflow: unset;
  `} // transition: opacity 0.15s ease;
`;

export const CollpaseHeader = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  padding: 0.6875rem 1.0625rem;
  align-items: center;
  line-height: 1;
  font-size: 1rem;
  color: ${({ theme }) => theme.title.color};
  cursor: url(/assets/images/cursor-white.svg), pointer;

  svg {
    transition: 0.3s;
    transform: rotateZ(${({ isExpanded }) => (isExpanded ? "0deg" : "180deg")});
  }

  svg path {
    fill: ${({ theme }) => theme.title.color};
  }
`;
