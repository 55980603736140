import styled from 'styled-components';
import { Layout as ALayout } from 'antd';

export const Layout = styled.div<{ isView?: boolean }>`
    height: 100vh;
    background: ${({ theme }) => theme.loading.background};

    .ant-layout {
        // height: 100%;
        background: ${({ theme }) => theme.container.background};
        overflow: hidden;
        height: -webkit-fill-available;

        .ant-layout-header {
            // position: relative;
            position: fixed;
            z-index: 999;
            width: 100%;
            height: fit-content;
            border: none;
            padding: 0 0.625rem;
            background: ${({ theme }) => theme.header.background};

            @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                border-bottom: ${({ theme }) => `0.0625rem solid ${theme.header.border}`};
            }
        }

        .ant-layout-sider {
            background: ${({ theme }) => theme.sider.background};
            transition-property: width;

            > .ant-layout-sider-children {
                height: -webkit-fill-available;
            }
        }

        > .ant-layout-content {
            padding-top: ${({ isView }) => (isView === true ? '8.875rem' : '5.6875rem')};

            @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                padding-top: 5.75rem;
            }
        }
    }

    .ant-layout-content {
        overflow-y: auto;
    }
`;

export const Content = styled(ALayout.Content)`
    ::-webkit-scrollbar {
        display: none;

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
            display: block;
        }
    }
`;

export const InnerLayout = styled(ALayout)`
    transform: translateZ(0);
`;

export const HintText = styled.span`
    display: inline-block;
    margin-left: 48px;
    color: rgba(255, 255, 255, 0.7);
`;

export const MetamaskWrapper = styled.div`
    margin-left: -48px;
`;

export const InstallMetamask = styled.button`
    margin-top: 30px;
    width: 100%;
    background: 0.0625rem solid #a1b7f8;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: none;
    outline: none;
    padding-block: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: #fff;
`;

export const InstallMetamaskToLogin = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    background: rgb(246, 133, 27) !important;
    border-radius: 8px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: #ffff;
`;
