import { ethers } from "ethers";
import MarketplaceManagerAbi from "abis/lib/ErrorHelper.sol/ErrorHelper.json";
import { FormatTypes } from "ethers/lib/utils";

const abi = MarketplaceManagerAbi.abi;
const _interface = new ethers.utils.Interface(abi);

export const debugContractError = (err: any) => {
  if (!(process?.env?.NODE_ENV === "development")) return;

  const error_data = err?.error?.data?.data;
  _interface.fragments.map((fragment) => {
    try {
      const decoded = _interface.decodeErrorResult(fragment, error_data);
      console.error(
        "[DEBUG] Transaction error--------------\n",
        `${fragment.format(FormatTypes.full)}:`,
        decoded
      );
    } catch (err) {}
  });
};
