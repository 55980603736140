import { createContext, useContext } from "react";

export type ILayoutContext = [boolean, () => void];

const LayoutContext = createContext<ILayoutContext>([false, () => {}]);

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};

export default LayoutContext.Provider;
