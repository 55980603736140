import styled from 'styled-components';

export const Container = styled(({ loading, disable, children, ...props }) => (
    <button {...props} disabled={disable}>
        {children}
    </button>
))`
    transform-style: preserve-3d;
    border: 0.0625rem solid #a1b7f8;
    border-radius: 1.875rem;
    padding: 0.9375rem 2.8rem 1rem 2.8rem;
    background: rgba(171, 47, 133, 1);

    span {
        color: #fff;
    }

    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1rem;

    ${({ disable, theme }) =>
        disable
            ? `border: ${theme.button.outline.disabled?.border} !important;
        background: ${theme.button.outline.disabled?.background} !important;
        opacity: 0.7;`
            : `
    &::after {
      content: "";
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      visibility: hidden;
      border-radius: 2.5rem;
      transition: opacity 0.3s, visibility 0.3s;
      left: 0; 
      background: linear-gradient(90deg, #ce73bb 0%, #8f3981 50%, #3759a4 100%);
      filter: blur(0.875rem); 
      transform: translateZ(-0.625rem);
    }
  `}

    &:hover,
  &:active,
  &:focus {
        color: ${({ theme }) => theme.colors.white};
        background: #912570;
        border: 0.0625rem solid #a1b7f8;

        &::after {
            opacity: 1;
            visibility: visible;
        }
    }

    &:disabled {
        background: #999eaf;
        border: 0.0625rem solid #999eaf;
        color: ${({ theme }) => theme.colors.halfBlurredWhite};
    }

    &:not(:disabled) {
        cursor: url(/assets/images/cursor-white.svg), pointer;
    }

    ${({ loading }) => loading && `cursor: url(/assets/images/cursor.svg), pointer;`}
`;
