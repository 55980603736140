export const GifIcon = () => {
  return (
    <svg
      width="30"
      height="18"
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M10.2151 11.0828C11.7627 11.0828 12.8551 10.1641 12.8799 8.23586H10.571V8.72414H12.2841L12.2758 8.84828C12.1268 9.95724 11.291 10.5697 10.2151 10.5697C8.89924 10.5697 7.88131 9.48552 7.88131 8.16138C7.88131 6.83724 8.89924 5.74483 10.2151 5.74483C10.7613 5.74483 11.2744 5.95172 11.6634 6.26621L12.0275 5.89379C11.5475 5.48828 10.9103 5.23172 10.2151 5.23172C8.59304 5.23172 7.33511 6.53931 7.33511 8.16138C7.33511 9.77517 8.59304 11.0828 10.2151 11.0828ZM15.3753 11H15.9215V5.29793H15.3753V11ZM22.1882 5.81103V5.29793H18.9772V11H19.5234V8.45931H21.6337V7.94621H19.5234V5.81103H22.1882Z"
        fill="white"
      />
      <rect
        x="0.344828"
        y="0.344828"
        width="28.9655"
        height="17.2414"
        rx="3.7931"
        stroke="#9F9FA3"
        strokeWidth="0.689655"
      />
    </svg>
  );
};

export const FaceIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.58619 12.4138C9.97628 12.4138 10.9461 11.7349 11.528 11.056C11.722 10.8297 12.0452 10.8297 12.2715 10.9914C12.4978 11.1853 12.4978 11.5086 12.3362 11.7349C11.5927 12.5754 10.3642 13.4483 8.58619 13.4483C6.77585 13.4483 5.5474 12.5754 4.80387 11.7349C4.64223 11.5086 4.64223 11.1853 4.86852 10.9914C5.09482 10.8297 5.41809 10.8297 5.61206 11.056C6.19395 11.7349 7.16378 12.4138 8.58619 12.4138ZM6.77585 7.24138C6.77585 7.69397 6.42025 8.01724 5.99999 8.01724C5.57973 8.01724 5.22413 7.69397 5.22413 7.24138C5.22413 6.82112 5.57973 6.46552 5.99999 6.46552C6.42025 6.46552 6.77585 6.82112 6.77585 7.24138ZM10.3965 7.24138C10.3965 6.82112 10.7521 6.46552 11.1724 6.46552C11.5927 6.46552 11.9483 6.82112 11.9483 7.24138C11.9483 7.69397 11.5927 8.01724 11.1724 8.01724C10.7521 8.01724 10.3965 7.69397 10.3965 7.24138ZM16.8621 8.7931C16.8621 13.3836 13.1444 17.069 8.58619 17.069C3.99568 17.069 0.310333 13.3836 0.310333 8.7931C0.310333 4.23491 3.99568 0.517242 8.58619 0.517242C13.1444 0.517242 16.8621 4.23491 16.8621 8.7931ZM8.58619 1.55172C4.57757 1.55172 1.34482 4.81681 1.34482 8.7931C1.34482 12.8017 4.57757 16.0345 8.58619 16.0345C12.5625 16.0345 15.8276 12.8017 15.8276 8.7931C15.8276 4.81681 12.5625 1.55172 8.58619 1.55172Z"
        fill="#9F9FA3"
      />
    </svg>
  );
};

export const PinIcon = ({
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <path
        d="M12.8017 2.71552C11.8642 1.77802 10.3448 1.77802 9.40733 2.71552L3.20043 8.92241C1.68104 10.4418 1.68104 12.8664 3.20043 14.3858C4.71983 15.9052 7.1444 15.9052 8.66379 14.3858L13.5776 9.47198C13.7716 9.27802 14.1272 9.27802 14.3211 9.47198C14.5151 9.66595 14.5151 10.0216 14.3211 10.2155L9.40733 15.1293C7.5 17.0366 4.36423 17.0366 2.4569 15.1293C0.54957 13.1897 0.54957 10.0862 2.4569 8.17888L8.66379 1.97198C10.0216 0.646552 12.1875 0.646552 13.5453 1.97198C14.8707 3.32974 14.8707 5.49569 13.5453 6.85345L7.59698 12.8017C6.69181 13.7069 5.17241 13.6099 4.36423 12.6078C3.68535 11.7349 3.75 10.5065 4.52586 9.7306L9.43966 4.81681C9.63362 4.62285 9.98922 4.62285 10.1832 4.81681C10.3772 5.01078 10.3772 5.36638 10.1832 5.56035L5.2694 10.4741C4.84914 10.8621 4.81681 11.5086 5.17241 11.9612C5.59267 12.4784 6.40086 12.5431 6.85345 12.0582L12.8017 6.10991C13.7392 5.17241 13.7392 3.65302 12.8017 2.71552Z"
        fill="#9F9FA3"
      />
    </svg>
  );
};

export const SentIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9518 6.56539C13.9518 6.88332 13.7399 7.20126 13.4219 7.33373L1.57885 12.4472C1.47287 12.5002 1.36689 12.5002 1.26091 12.5002C1.02246 12.5002 0.78401 12.4207 0.625042 12.2352C0.386591 11.9968 0.333602 11.5994 0.49257 11.2814L2.45317 7.38672L8.86485 6.59188L2.45317 5.77055L0.49257 1.87585C0.333602 1.55791 0.386591 1.1605 0.625042 0.922045C0.863494 0.657099 1.26091 0.577616 1.57885 0.710089L13.4484 5.79704C13.7663 5.92952 13.9518 6.24745 13.9518 6.56539Z"
        fill="white"
      />
    </svg>
  );
};

export const XIcon = () => {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25887 8.69854C9.09991 8.85751 8.80847 8.85751 8.6495 8.69854L5.15222 5.17476L1.62844 8.69854C1.46947 8.85751 1.17803 8.85751 1.01906 8.69854C0.860097 8.53957 0.860097 8.24813 1.01906 8.08916L4.54284 4.56539L1.01906 1.0681C0.860097 0.909137 0.860097 0.617697 1.01906 0.45873C1.17803 0.299762 1.46947 0.299762 1.62844 0.45873L5.15222 3.98251L8.6495 0.45873C8.80847 0.299762 9.09991 0.299762 9.25887 0.45873C9.41784 0.617697 9.41784 0.909137 9.25887 1.0681L5.7351 4.56539L9.25887 8.08916C9.41784 8.24813 9.41784 8.53957 9.25887 8.69854Z"
        fill="white"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 16L12.4584 12.4521M14.4211 7.71053C14.4211 9.49027 13.7141 11.1971 12.4556 12.4556C11.1971 13.7141 9.49027 14.4211 7.71053 14.4211C5.93078 14.4211 4.22394 13.7141 2.96547 12.4556C1.707 11.1971 1 9.49027 1 7.71053C1 5.93078 1.707 4.22394 2.96547 2.96547C4.22394 1.707 5.93078 1 7.71053 1C9.49027 1 11.1971 1.707 12.4556 2.96547C13.7141 4.22394 14.4211 5.93078 14.4211 7.71053Z"
        stroke="#F2F2F4"
        strokeWidth="1.57895"
        strokeLinecap="round"
        fill="white"
      />
    </svg>
  );
};
