import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  accountSlice,
  loadingSlice,
  modalSlice,
  notificationSlice,
  userSlice,
  nftItemsSlice,
  themeSlice,
  walletSlice,
} from "./slices";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});
export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    accounts: accountSlice.reducer,
    loading: loadingSlice.reducer,
    modal: modalSlice.reducer,
    notifications: notificationSlice.reducer,
    nftItems: nftItemsSlice.reducer,
    theme: themeSlice.reducer,
    wallet: walletSlice.reducer,
  },
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
