import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Notification, NOTIFICATION_TYPE } from 'components/base';
import { useCollectionFactoryCreate } from 'contracts/Collection/CollectionFactory/hooks';
import { useWallet } from 'hooks';
import { IBrandTbl } from 'models/brand';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getBrandByUser } from 'redux/apis/brand';
import { createCollection } from 'redux/apis/collection';
import { UserPaths } from 'utils/constants/paths';
import { uploadImageToS3 } from 'utils/file';
import FtForm from '../component/ftForm';
import { ECollectonFormType, ICollectionForm } from '../component/ftForm/collectionForm.props';
import { ICreateCollectionProps } from './createCollection.props';
import { createStandardToken, createTaxAndReflecToken } from 'contracts/FtFactory';

const CreateFt = ({ ...props }) => {
    const { send: createCollectionByContract } = useCollectionFactoryCreate();
    const [myBrand, setMyBrand] = useState({});
    const navigate = useNavigate();
    const { account } = useWallet();
    const [isCreating, setIsCreating] = useState(false);
    const [tokenAddress, setTokenAddress] = useState(null);

    const showNotification = (type, message) => {
        return Notification({
            type: type,
            message: message,
        });
    };

    const fetchCreateStandardToken = async (argument) => {
        let id = '';
        let options = [
            argument.mintable,
            argument.burnable,
            argument.pausable,
            argument.permit,
            argument.votes,
            argument.flashMinting,
            argument.snapshots,
        ];

        for (let i = 0; i < options.length; i++) {
            if (options[i]) {
                id += (i + 1).toString();
            }
        }

        if (id === '') {
            id = '0';
        }

        try {
            return await createStandardToken(
                argument.name,
                argument.symbol,
                argument.decimals,
                argument.initSupply,
                id,
            );
        } catch (err) {
            return null;
        }
    };

    const fetchCreateTaxAndReflecToken = async (argument) => {
        try {
            return await createTaxAndReflecToken(
                argument.name,
                argument.symbol,
                argument.decimals,
                argument.initSupply,
                '0xd99d1c33f9fc3444f8101754abc46c52416550d1', //router
                argument.reflection,
                argument.marketing,
                argument.burn,
                argument.liquidityPool,
                argument.buyback,
            );
        } catch (err) {
            return null;
        }
    };

    const createNewToken = async (argument) => {
        setIsCreating(true);

        try {
            const tokenAddress = await fetchCreateStandardToken(argument);

            if (!tokenAddress) {
                setIsCreating(false);
                showNotification('error', 'Creating token by contract is failed!');
                return;
            }

            setIsCreating(false);
            // navigate(`/collections`);
            showNotification('success', 'Create token successfully');

            setTokenAddress(tokenAddress);
        } catch (err) {
            setIsCreating(false);
            showNotification('error', 'Create token fail!');
        }
    };

    const createNewTaxAndRelecToken = async (argument) => {
        setIsCreating(true);

        try {
            const tokenAddress = await fetchCreateTaxAndReflecToken(argument);

            if (!tokenAddress) {
                setIsCreating(false);
                showNotification('error', 'Creating token by contract is failed!');
                return;
            }

            setIsCreating(false);
            // navigate(`/collections`);
            showNotification('success', 'Create token successfully');

            setTokenAddress(tokenAddress);
        } catch (err) {
            setIsCreating(false);
            showNotification('error', 'Create token fail!');
        }
    };

    return (
        <FtForm
            // fetchUpdateCollectionByAddress={fetchCreateCollectionByAddress}
            createToken={createNewToken}
            createTaxAndReflecToken={createNewTaxAndRelecToken}
            isProcessing={isCreating}
            tokenAddress={tokenAddress}
        />
    );
};

export default CreateFt;
