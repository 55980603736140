export const CopyWhite = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3H1C0.4485 3 0 3.4485 0 4V9C0 9.5515 0.4485 10 1 10H6C6.5515 10 7 9.5515 7 9V4C7 3.4485 6.5515 3 6 3Z"
        fill="white"
      />
      <path
        d="M9 0H4C3.73478 0 3.48043 0.105357 3.29289 0.292893C3.10536 0.48043 3 0.734784 3 1V2H7C7.26522 2 7.51957 2.10536 7.70711 2.29289C7.89464 2.48043 8 2.73478 8 3V7H9C9.26522 7 9.51957 6.89464 9.70711 6.70711C9.89464 6.51957 10 6.26522 10 6V1C10 0.734784 9.89464 0.48043 9.70711 0.292893C9.51957 0.105357 9.26522 0 9 0Z"
        fill="white"
      />
    </svg>
  );
};

export const NewCopy = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.08002 9.18039C2.55817 9.18039 2.29725 9.18039 2.09142 9.09516C1.81699 8.98148 1.59895 8.76341 1.48528 8.48901C1.40002 8.28316 1.40002 8.02225 1.40002 7.50039V3.69239C1.40002 3.06513 1.40002 2.7515 1.5221 2.51192C1.62947 2.30118 1.80081 2.12984 2.01156 2.02246C2.25113 1.90039 2.56477 1.90039 3.19202 1.90039H7.00002C7.52189 1.90039 7.78279 1.90039 7.98865 1.98565C8.26305 2.09932 8.48111 2.31736 8.59479 2.59178C8.68002 2.79761 8.68002 3.05854 8.68002 3.58039M7.11202 13.1004H10.808C11.4353 13.1004 11.7489 13.1004 11.9885 12.9783C12.1992 12.871 12.3706 12.6996 12.4779 12.4889C12.6 12.2493 12.6 11.9356 12.6 11.3084V7.61239C12.6 6.98513 12.6 6.67148 12.4779 6.43191C12.3706 6.22118 12.1992 6.04984 11.9885 5.94246C11.7489 5.82039 11.4353 5.82039 10.808 5.82039H7.11202C6.48477 5.82039 6.17111 5.82039 5.93154 5.94246C5.72081 6.04984 5.54947 6.22118 5.4421 6.43191C5.32002 6.67148 5.32002 6.98513 5.32002 7.61239V11.3084C5.32002 11.9356 5.32002 12.2493 5.4421 12.4889C5.54947 12.6996 5.72081 12.871 5.93154 12.9783C6.17111 13.1004 6.48477 13.1004 7.11202 13.1004Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NewShare = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6 5.63373V1.9004M12.6 1.9004H8.86666M12.6 1.9004L6.99999 7.50039M5.75555 1.90039H4.38666C3.34123 1.90039 2.81851 1.90039 2.41921 2.10384C2.06797 2.28281 1.78241 2.56837 1.60345 2.91961C1.39999 3.31891 1.39999 3.84162 1.39999 4.88706V10.1137C1.39999 11.1592 1.39999 11.6818 1.60345 12.0812C1.78241 12.4324 2.06797 12.718 2.41921 12.8969C2.81851 13.1004 3.34123 13.1004 4.38666 13.1004H9.61333C10.6588 13.1004 11.1815 13.1004 11.5808 12.8969C11.932 12.718 12.2176 12.4324 12.3965 12.0812C12.6 11.6818 12.6 11.1592 12.6 10.1137V8.74483"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CopyNew = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: "-2px" }}
    >
      <path
        d="M10.5 3.375V0.75H7.4375C6.69922 0.75 6.125 1.35156 6.125 2.0625V9.9375C6.125 10.6758 6.69922 11.25 7.4375 11.25H12.6875C13.3984 11.25 14 10.6758 14 9.9375V4.25H11.375C10.8828 4.25 10.5 3.86719 10.5 3.375ZM11.375 0.75V3.375H14L11.375 0.75ZM5.25 10.375V4.25H1.3125C0.574219 4.25 0 4.85156 0 5.5625V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H6.5625C7.27344 14.75 7.875 14.1758 7.875 13.4375V12.125H7C6.01562 12.125 5.25 11.3594 5.25 10.375Z"
        fill="white"
      />
    </svg>
  );
};

export const CopyAddress = () => {
  return (
    <svg
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.08039 12.6804C2.55854 12.6804 2.29761 12.6804 2.09178 12.5952C1.81736 12.4815 1.59932 12.2634 1.48565 11.989C1.40039 11.7832 1.40039 11.5223 1.40039 11.0004V7.19239C1.40039 6.56513 1.40039 6.2515 1.52246 6.01192C1.62984 5.80118 1.80118 5.62984 2.01192 5.52246C2.2515 5.40039 2.56513 5.40039 3.19239 5.40039H7.00039C7.52225 5.40039 7.78316 5.40039 7.98901 5.48565C8.26341 5.59932 8.48148 5.81736 8.59516 6.09178C8.68039 6.29761 8.68039 6.55854 8.68039 7.08039M7.11239 16.6004H10.8084C11.4356 16.6004 11.7493 16.6004 11.9889 16.4783C12.1996 16.371 12.371 16.1996 12.4783 15.9889C12.6004 15.7493 12.6004 15.4356 12.6004 14.8084V11.1124C12.6004 10.4851 12.6004 10.1715 12.4783 9.93191C12.371 9.72118 12.1996 9.54984 11.9889 9.44246C11.7493 9.32039 11.4356 9.32039 10.8084 9.32039H7.11239C6.48513 9.32039 6.17148 9.32039 5.93191 9.44246C5.72118 9.54984 5.54984 9.72118 5.44246 9.93191C5.32039 10.1715 5.32039 10.4851 5.32039 11.1124V14.8084C5.32039 15.4356 5.32039 15.7493 5.44246 15.9889C5.54984 16.1996 5.72118 16.371 5.93191 16.4783C6.17148 16.6004 6.48513 16.6004 7.11239 16.6004Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
