import { FC, useState, useEffect, useRef, useCallback, useContext } from 'react';
import CModal from 'components/base/newModal';
import { Notification } from 'components/base';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import {
    ModalsWrapper,
    PageWapper,
    Button,
    WalletContainer,
    CancelModalIcon,
    CancelModalTitle,
    CancelModalButtonContainer,
    CancelButton,
    WalletIconWrapper,
    CollapseWrapper,
    ModalDescription,
    WithEmailBtn,
    WalletGradientWrapper,
    ModalCustom,
} from './modals.style';
import { LoginFormContent } from '../auth/login';
import { SignupContent } from '../auth/signup';
import ForgotContent from 'components/modules/auth/forgotPassword';

import { showModal, initialState } from 'redux/store/slices/modalSlice';
import { useSearchParams } from 'react-router-dom';
import { Collapse } from 'antd';
import { Text } from 'components/base';
import { MetaMask, DownArrowCollapseExpand, UpArrowCollapseExpand, BlueIcon, CrossMark } from 'components/assets';
import { useTranslation } from 'react-i18next';
import { useWallet } from 'hooks';
import {
    setConnectedWallet,
    clearConnectedWallet,
    setWalletProvider,
    toggleFullLoading,
    clearToken,
    clearInfo,
} from 'redux/store/slices';
import { ConnectorNames } from 'utils/connector';
import { SupportWallet, supportWallets } from 'utils/constants/supportWallets';
import { CloseModalBtn } from '../auth/auth.style';
import clsx from 'clsx';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import { WalletConnect } from 'components/assets';
import { mouseEnter, mouseLeave } from 'utils';
import { SocketContext } from 'context';
const { Panel } = Collapse;

export const WalletModal: FC<{ onClose?: () => void }> = ({ onClose }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'modal',
    });
    const { t: tLogin } = useTranslation('translation', {
        keyPrefix: 'login',
    });
    const { connect, account, deactivate } = useWallet();
    const { info } = useAppSelector((state: any) => state.user);

    const { provider } = useAppSelector((state) => state.wallet);
    const dispatch = useAppDispatch();
    const keyToDisplay =
        account && account.length > 20
            ? `${account.substring(0, 4)}...${account.substring(account.length - 4, account.length)}`
            : account;
    const [searchParams, setSearchParams] = useSearchParams();
    const typeAsObject = Object.fromEntries(new URLSearchParams(searchParams));
    const { type } = typeAsObject;

    // const phatomWallet = useMemo(() => getPhantomWallet(), []);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const currentConnected = supportWallets.find((w) => w.name === provider);
    const socketCtx = useContext(SocketContext);
    const socket = socketCtx?.ioInstance;

    const handleConnectMetamaskWallet = async () => {
        if (keyToDisplay) {
            Notification({
                type: 'error',
                description: 'Please disconnect current wallet',
                message: 'Error',
            });
            return;
        }

        try {
            await connect(ConnectorNames.Injected);
            dispatch(setWalletProvider('Metamask'));
            onClose?.();
        } catch (error: any) {
            console.log(error);
            if (error.code === 4001) {
                const mesErr = error.message.split(':');
                Notification({
                    type: 'error',
                    description: mesErr[1],
                    message: mesErr[0],
                });
            }
        }
    };

    const handleConnectWalletConnect = async () => {
        if (keyToDisplay) {
            Notification({
                type: 'error',
                description: 'Please disconnect current wallet',
                message: 'Error',
            });
            return;
        }

        try {
            await connect(ConnectorNames.WalletConnect);
            dispatch(setWalletProvider('WalletConnect'));
            onClose?.();
        } catch (error: any) {
            console.log(error);
            if (error.code === 4001) {
                const mesErr = error.message.split(':');
                Notification({
                    type: 'error',
                    description: mesErr[1],
                    message: mesErr[0],
                });
            }
        }
    };

    const handleConnectOtherWallets = async (wallet: SupportWallet) => {
        if (keyToDisplay) {
            Notification({
                type: 'error',
                description: 'Please disconnect current wallet',
                message: 'Error',
            });
            return;
        }

        try {
            await connect(wallet.connector);
            dispatch(setWalletProvider(wallet.name));
            onClose?.();
        } catch (error) {
            console.log(error);
            onClose?.();
        }
    };

    const handleSwitchToSignIn = () => {
        if (type !== 'login') {
            dispatch(
                showModal({
                    ...initialState,
                    wallet: false,
                    login: true,
                }),
            );

            searchParams.set('type', 'login');
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        const closeModalBtn = document.querySelector('button.ant-modal-close') as HTMLButtonElement;

        if (closeModalBtn) {
            closeModalBtn.addEventListener('mouseenter', mouseEnter);
            closeModalBtn.addEventListener('mouseleave', mouseLeave);
        }

        return () => {
            closeModalBtn?.removeEventListener('mouseenter', mouseEnter);
            closeModalBtn?.removeEventListener('mouseleave', mouseLeave);
        };
    }, []);

    const CurrentConnectedWalletIcon = currentConnected?.Icon || MetaMask;
    const [isLargerThan768px] = useMediaQuery(`(min-width: 768px)`);
    return (
        <WalletGradientWrapper>
            <CloseModalBtn onClick={() => onClose?.()}>
                <CrossMark />
            </CloseModalBtn>
            <WalletContainer user={info}>
                {info !== null && (
                    <div className="connected-user">
                        {info?.avatar ? <img src={info?.avatar} alt="" /> : <BlueIcon />}

                        <div className="user-info">
                            <span>{info?.email}</span>
                        </div>
                    </div>
                )}

                <h1>{t('connectWallet')}</h1>
                {info === null ? <ModalDescription>{tLogin('description')}</ModalDescription> : null}
                {isLargerThan768px ? (
                    <Button
                        className="phantom-button metaplex-button"
                        onClick={!account ? handleConnectMetamaskWallet : undefined}
                        onMouseEnter={mouseEnter}
                        onMouseLeave={mouseLeave}
                    >
                        <div>
                            <WalletIconWrapper>
                                <CurrentConnectedWalletIcon />
                            </WalletIconWrapper>
                            <Text>{account ? keyToDisplay : currentConnected?.name || t('connectToPlantom')}</Text>
                        </div>
                        {account && (
                            <Text
                                className="wallet-disconnect"
                                onClick={(e) => {
                                    const first = localStorage.getItem('first');
                                    e.stopPropagation();
                                    if (first === 'WALLET') {
                                        localStorage.removeItem('access-token');
                                        dispatch(clearToken());
                                        dispatch(clearInfo());
                                    }

                                    dispatch(toggleFullLoading(true));
                                    socket?.disconnect();
                                    socketCtx?.setIoInstance(undefined);
                                    deactivate();
                                    dispatch(clearConnectedWallet());
                                    dispatch(toggleFullLoading(false));
                                    onClose?.();
                                }}
                            >
                                {t('disconnect')}
                            </Text>
                        )}
                    </Button>
                ) : (
                    <>
                        {/* <Button
                            className="phantom-button metaplex-button"
                            onClick={!account ? handleConnectWalletConnect : undefined}
                            onMouseEnter={mouseEnter}
                            onMouseLeave={mouseLeave}
                        >
                            <div>
                                <WalletIconWrapper>
                                    <WalletConnect />
                                </WalletIconWrapper>
                                <Text>{'Wallet Connect'}</Text>
                            </div>
                            {account && (
                                <Text
                                    className="wallet-disconnect"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // logout();
                                        deactivate();
                                        dispatch(clearConnectedWallet());
                                        onClose?.();
                                    }}
                                >
                                    {t('disconnect')}
                                </Text>
                            )}
                        </Button> */}
                        <Button
                            className="phantom-button metaplex-button"
                            onClick={!account ? handleConnectMetamaskWallet : undefined}
                            onMouseEnter={mouseEnter}
                            onMouseLeave={mouseLeave}
                        >
                            <div>
                                <WalletIconWrapper>
                                    <CurrentConnectedWalletIcon />
                                </WalletIconWrapper>
                                <Text>{account ? keyToDisplay : currentConnected?.name || t('connectToPlantom')}</Text>
                            </div>
                            {account && (
                                <Text
                                    className="wallet-disconnect"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // logout();
                                        deactivate();
                                        dispatch(clearConnectedWallet());
                                        onClose?.();
                                    }}
                                >
                                    {t('disconnect')}
                                </Text>
                            )}
                        </Button>
                    </>
                )}
                {isLargerThan768px && (
                    <CollapseWrapper onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                        <Collapse
                            ghost
                            expandIconPosition="end"
                            expandIcon={(panelProps) =>
                                panelProps.isActive ? <UpArrowCollapseExpand /> : <DownArrowCollapseExpand />
                            }
                            onChange={() => setIsCollapsed((prev) => !prev)}
                        >
                            {/* <Panel header={<span>{isCollapsed ? t('hideWallet') : t('otherWallet')}</span>} key="1">
                                {provider && provider !== 'Metamask' && (
                                    <Button
                                        className="phantom-button metaplex-button"
                                        onClick={handleConnectMetamaskWallet}
                                        onMouseEnter={mouseEnter}
                                        onMouseLeave={mouseLeave}
                                    >
                                        <div>
                                            <WalletIconWrapper>
                                                <MetaMask />
                                            </WalletIconWrapper>
                                            <Text>{t('connectToPlantom')}</Text>
                                        </div>
                                    </Button>
                                )}
                                {supportWallets
                                    .filter((w) => w.name !== provider)
                                    .map((wallet, idx) => {
                                        const { Icon } = wallet;
                                        return (
                                            <Button
                                                key={idx}
                                                className="metaplex-button w100"
                                                onClick={
                                                    () => handleConnectOtherWallets(wallet)
                                                    //   () => {
                                                    //   // select(wallet.name);
                                                    //   if (wallet.name === "Coinbase") {
                                                    //     if (keyToDisplay) {
                                                    //       Notification(
                                                    //         "error",
                                                    //         "",
                                                    //         "Please disconnect current wallet"
                                                    //       );
                                                    //       return;
                                                    //     }
                                                    //     connect(ConnectorNames.WalletLink);
                                                    //   }
                                                    //   onClose?.();
                                                    // }
                                                }
                                                onMouseEnter={mouseEnter}
                                                onMouseLeave={mouseLeave}
                                            >
                                                <WalletIconWrapper>
                                                    <Icon />
                                                </WalletIconWrapper>
                                                <Text>{wallet.name}</Text>
                                            </Button>
                                        );
                                    })}
                            </Panel> */}
                        </Collapse>
                    </CollapseWrapper>
                )}

                {/* {info ? null : (
          <WithEmailBtn onClick={handleSwitchToSignIn}>
            {tLogin("withEmail")}
          </WithEmailBtn>
        )} */}
                <div className="get-help">
                    <div className="get-help-text">
                        <span>{t('getHelp')}</span>
                        <a
                            href="https://discord.com/"
                            target="_blank"
                            onMouseEnter={mouseEnter}
                            onMouseLeave={mouseLeave}
                            style={{ marginTop: 10, marginBottom: -10 }}
                            rel="noreferrer"
                        >
                            {t('chatWithUs')}
                        </a>
                    </div>
                </div>
            </WalletContainer>
        </WalletGradientWrapper>
    );
};

const Modals: FC = () => {
    const modal = useAppSelector((state) => state.modal);
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const typeAsObject = Object.fromEntries(new URLSearchParams(searchParams));
    const [loading, setLoading] = useState(false);
    const { account: publicKey } = useWallet();
    const [walletConnected, setWalletConnected] = useState(!!publicKey);
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation('translation', {
        keyPrefix: 'modal',
    });
    const info = useAppSelector((state) => state.user.info);
    const { connectedWallet } = useAppSelector((state) => state.wallet);
    const isSafariOnMobile =
        window.navigator.userAgent.includes('iPhone') && window.navigator.userAgent.includes('Safari');

    const forceCloseModal = useCallback(() => {
        searchParams.delete('type');
        setSearchParams(searchParams);
        dispatch(showModal(initialState));
    }, [dispatch, searchParams, setSearchParams]);

    const handleCloseModal = () => {
        if (loading) return;
        searchParams.delete('type');
        setSearchParams(searchParams);
        dispatch(showModal(initialState));
    };

    const { type } = typeAsObject;

    const handleSwitchToSignUp = () => {
        if (loading) return;

        searchParams.set('type', 'signup');
        setSearchParams(searchParams);
        dispatch(
            showModal({
                ...initialState,
                register: true,
            }),
        );
    };

    const handleSwitchToSignIn = () => {
        if (loading) return;

        searchParams.set('type', 'login');
        setSearchParams(searchParams);
        dispatch(
            showModal({
                ...initialState,
                login: true,
            }),
        );
    };

    const handleSwitchToForgot = () => {
        if (loading) return;

        searchParams.set('type', 'forgot');
        setSearchParams(searchParams);
        dispatch(
            showModal({
                ...initialState,
                forgot: true,
            }),
        );
    };

    const handleOpenLoginModal = () => {
        if (loading) return;

        searchParams.set('type', 'login');
        setSearchParams(searchParams);
        dispatch(
            showModal({
                ...initialState,
                login: true,
            }),
        );
    };

    const handleCloseUsedModal = () => {
        searchParams.delete('type');
        setSearchParams(searchParams);
        dispatch(
            showModal({
                ...initialState,
                used: null,
            }),
        );
    };

    useEffect(() => {
        if (publicKey && !connectedWallet) {
            const base58 = publicKey;
            const keyToDisplay =
                base58.length > 20
                    ? `${base58.substring(0, 7)}.....${base58.substring(base58.length - 7, base58.length)}`
                    : base58;

            dispatch(setConnectedWallet(publicKey));

            Notification({
                type: 'success',
                description: t('connected') + ' ' + keyToDisplay,
                message: t('walletUpdate'),
            });
        }
    }, [connectedWallet, dispatch, publicKey, t]);

    useEffect(() => {
        if (!publicKey && walletConnected) {
            Notification({
                type: 'info',
                description: t('disconnected'),
                message: t('walletUpdate'),
            });
            // notify({
            //   message: t("walletUpdate"),
            //   description: t("disconnected"),
            // });
        }
        setWalletConnected(!!publicKey);
    }, [publicKey, walletConnected, t]);

    useEffect(() => {
        if (!type) {
            dispatch(showModal(initialState));
            return;
        }

        setOpenModal(true);
        switch (type) {
            case 'login': {
                dispatch(showModal({ ...initialState, login: true }));
                return;
            }
            case 'signup': {
                dispatch(showModal({ ...initialState, register: true }));
                return;
            }
            case 'forgot': {
                dispatch(showModal({ ...initialState, forgot: true }));
                return;
            }
            case 'wallet': {
                dispatch(showModal({ ...initialState, wallet: true }));
                return;
            }
            default: {
                dispatch(showModal(initialState));
            }
        }
    }, [type, dispatch, info?.role]);

    const content = openModal ? (
        <ModalsWrapper ref={ref}>
            <CModal visible={!!modal.used} onCancel={handleCloseUsedModal}>
                <CancelModalIcon>
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.8359 11.1523L9.01172 1.19922C8.76562 0.816406 8.38281 0.625 8 0.625C7.58984 0.625 7.20703 0.816406 6.96094 1.19922L1.10938 11.1523C0.699219 11.918 1.24609 12.875 2.14844 12.875H13.8242C14.7266 12.875 15.2734 11.918 14.8359 11.1523ZM2.42188 11.5625L7.97266 2.07422L13.5508 11.5625H2.42188ZM8 8.99219C7.50781 8.99219 7.125 9.375 7.125 9.83984C7.125 10.3047 7.50781 10.6875 8 10.6875C8.46484 10.6875 8.84766 10.3047 8.84766 9.83984C8.84766 9.375 8.46484 8.99219 8 8.99219ZM7.34375 4.78125V7.40625C7.34375 7.78906 7.61719 8.0625 8 8.0625C8.35547 8.0625 8.65625 7.78906 8.65625 7.40625V4.78125C8.65625 4.42578 8.35547 4.125 8 4.125C7.61719 4.125 7.34375 4.42578 7.34375 4.78125Z"
                            fill="#912570"
                        />
                    </svg>
                </CancelModalIcon>
                <CancelModalTitle level={4}>
                    {/* Sorry, this{" "}
          {modal.used === "EMAIL"
            ? "email"
            : modal.used === "WALLET" && "wallet address"}{" "}
          has been used */}
                    {modal.used}
                </CancelModalTitle>
                <CancelModalButtonContainer>
                    <CancelButton
                        onClick={() => {
                            handleCloseUsedModal();
                            modal.used === 'EMAIL' && handleOpenLoginModal();
                        }}
                    >
                        {t('ok')}
                    </CancelButton>
                </CancelModalButtonContainer>
            </CModal>
            <ModalCustom
                visible={modal.login}
                onCancel={handleCloseModal}
                getContainer={() => ref.current!}
                width={456}
                destroyOnClose
            >
                <LoginFormContent
                    close={forceCloseModal}
                    switchToSignUp={handleSwitchToSignUp}
                    handleForgotOpen={handleSwitchToForgot}
                    authLoadingState={[loading, setLoading]}
                />
            </ModalCustom>
            <ModalCustom
                visible={modal.register}
                onCancel={handleCloseModal}
                getContainer={() => ref.current!}
                width={456}
                destroyOnClose
            >
                <SignupContent
                    onCancel={forceCloseModal}
                    switchToSignIn={handleSwitchToSignIn}
                    handleForgotOpen={handleSwitchToForgot}
                    authLoadingState={[loading, setLoading]}
                />
            </ModalCustom>
            <ModalCustom
                visible={modal.forgot}
                onCancel={handleCloseModal}
                getContainer={() => ref.current!}
                width={456}
                destroyOnClose
            >
                <ForgotContent handleClose={forceCloseModal} />
            </ModalCustom>
            <CModal
                visible={modal.wallet}
                onCancel={handleCloseModal}
                getContainer={() => ref.current!}
                width={456}
                destroyOnClose
            >
                <WalletModal onClose={forceCloseModal} />
            </CModal>
            {Object.values(modal).some(Boolean) && (
                <PageWapper className={clsx(isSafariOnMobile && 'safari')}>
                    {modal.login && (
                        <LoginFormContent
                            close={forceCloseModal}
                            switchToSignUp={handleSwitchToSignUp}
                            handleForgotOpen={handleSwitchToForgot}
                            authLoadingState={[loading, setLoading]}
                        />
                    )}
                    {modal.register && (
                        <SignupContent
                            onCancel={forceCloseModal}
                            switchToSignIn={handleSwitchToSignIn}
                            handleForgotOpen={handleSwitchToForgot}
                            authLoadingState={[loading, setLoading]}
                        />
                    )}
                    {modal.forgot && <ForgotContent handleClose={forceCloseModal} />}
                    {modal.wallet && <WalletModal onClose={forceCloseModal} />}
                </PageWapper>
            )}
        </ModalsWrapper>
    ) : null;

    return content;
};

export default Modals;
