import styled from "styled-components";

export const BtnWrapper = styled.div`
  width: 1.8125rem;
  height: 1.75rem;
  border-radius: 50%;

  svg {
    position: relative;
    top: 1px;
  }
`;
