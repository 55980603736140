import { createSlice } from "@reduxjs/toolkit";

const storageWallet = localStorage.getItem("wallet");
const storageProvider = localStorage.getItem("provider");

interface IWallet {
  connectedWallet: string | null;
  provider: string | null;
}

const initialState: IWallet = {
  connectedWallet: storageWallet,
  provider: storageProvider,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletProvider: (state, action) => {
      localStorage.setItem("provider", action.payload);
      state.provider = action.payload;
    },
    setConnectedWallet: (state, action) => {
      localStorage.setItem("wallet", action.payload);
      state.connectedWallet = action.payload;
    },
    clearConnectedWallet: (state) => {
      localStorage.removeItem("wallet");
      localStorage.removeItem("provider");
      state.connectedWallet = null;
      state.provider = null;
    },
  },
});

export const { setWalletProvider, setConnectedWallet, clearConnectedWallet } =
  walletSlice.actions;
