import { Input } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export const TextAreaCustom = styled(TextArea)`
  width: 100%;
  background: ${({ theme }) => theme.mint.box.background};
  border: 0.0625rem solid #201f2a;
  height: 3.375rem;
  border-radius: 0.375rem;
  color: ${({ theme }) => theme.mint.box.color};
  outline: none;
  font-size: 0.875rem;
  line-height: 1.625rem;
  padding: 0.625rem 0.875rem;

  &::placeholder {
    color: ${({ theme }) => theme.colors.halfBlurredWhite};
    font-size: 1rem;
  }

  &:hover {
    background: ${({ theme }) => theme.mint.hoverBg};
    border-color: ${({ theme }) => theme.colors.border};
  }

  .ant-input-number-input {
    height: 100%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1.25rem;
  }
`;
