import { ButtonOutline } from "components/base";
import styled from "styled-components";

export const HStack = styled.div`
  display: flex;
  gap: 0.625rem;
`;

export const Button = styled(ButtonOutline)`
  border: 0.0625rem solid #ffffff1a;
`;
