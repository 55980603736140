import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";

//useDispatch hook with types.
export const useAppDispatch = () => useDispatch<AppDispatch>();
//useSelector hook with types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from "./useImageUrl";
export * from "./useMediaQuery";
export * from "./useDebounce";
export * from "./usePagination";
export * from "./useVisibility";
export * from "./useAudio";
export * from "./useWalletDispatch";
export * from "./useWallet";
export * from "./web3";
export * from "./useCheckLogin";
