import ManageSupport from 'components/modules/support/manageSupport';
import { UserRole } from 'models';
import BaseComponents from 'pages/baseComponents';

import CreateFT from 'pages/ft/createFt';
import TokenInfo from 'pages/ft/TokenInfo';
import TokenVerify from 'pages/ft/TokenVerify';

import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { UserPaths } from 'utils/constants';

// const ComingSoon = lazy(() => import("pages/commingSoon"));

// const SellItem = lazy(() => import('pages/sellItem'));

interface UserRoutes extends RouteProps {
    role?: UserRole.Admin | UserRole.Brand | UserRole.User;
}

export const USER_ROUTES: UserRoutes[] = [
    {
        path: UserPaths.home,
        element: <CreateFT />,
    },
    // {
    //     path: UserPaths.marketplace,
    //     element: <MarketplacePage />,
    // },
    // {
    //     path: UserPaths.marketplaceDetail,
    //     element: <MarketplaceDetail />,
    // },
    // {
    //     path: UserPaths.staking,
    //     element: <StakingPage />,
    // },
    // {
    //     path: UserPaths.dashboard,
    //     element: <BrandPage />,
    //     role: UserRole.Brand,
    // },
    // {
    //     path: UserPaths.upcomingMints,
    //     element: <UpcomingMintPage />,
    // },
    // {
    //     path: UserPaths.createUpcomingMint,
    //     element: <CreateUpcomingMint />,
    //     role: UserRole.Brand,
    // },
    // {
    //     path: UserPaths.upcomingMintDetail,
    //     element: <DetailUpcomingMint />,
    // },
    // {
    //     path: UserPaths.eventDetail,
    //     element: <EventDetail />,
    // },
    // {
    //     path: UserPaths.eventRegisterSuccessful,
    //     element: <RegisterSuccessful />,
    // },
    // {
    //     path: UserPaths.pastEvents,
    //     element: <PastEventsPage />,
    // },
    // {
    //     path: UserPaths.currentEvents,
    //     element: <CurrentEventsPage />,
    // },
    // {
    //     path: UserPaths.about,
    //     element: <About />,
    // },
    // {
    //     path: AdminPaths.support,
    //     element: <SupportPage />,
    // },
    // {
    //     path: UserPaths.create,
    //     element: <Mint />,
    // },
    // {
    //     path: UserPaths.creatorDetail,
    //     element: <Creators />,
    // },
    // {
    //     path: UserPaths.search,
    //     element: <Search />,
    // },
    // {
    //     path: UserPaths.sellItem,
    //     element: <SellItem />,
    // },
    // {
    //     path: UserPaths.nftDetail,
    //     element: <NftDetail />,
    // },
    // {
    //     path: UserPaths.activeAccount,
    //     element: <ActiveAccount />,
    // },
    // {
    //     path: UserPaths.verifyAccount,
    //     element: <VerifyAccount />,
    // },
    // {
    //     path: UserPaths.generateLink,
    //     element: <GenerateLink />,
    // },
    {
        path: UserPaths.createFt,
        element: <CreateFT />,
    },
    {
        path: UserPaths.tokenInfo,
        element: <TokenInfo />,
    },
    {
        path: UserPaths.tokenVerify,
        element: <TokenVerify />,
    },
    //     {
    //         path: UserPaths.createCollection,
    //         element: <CreateCollection />,
    //     },
    //     {
    //         path: UserPaths.createAuction,
    //         element: <CreateAuction />,
    //     },
    //     {
    //         path: UserPaths.createMyCollection,
    //         element: <CreateCollection />,
    //     },
    //     { path: UserPaths.membership, element: <Membership /> },
    //     {
    //         path: UserPaths.collectionList,
    //         element: <CollectionList />,
    //     },
    //     {
    //         path: UserPaths.auctions,
    //         element: <Auctions />,
    //     },
    //     {
    //         path: UserPaths.updateCollection,
    //         element: <UpdateCollection />,
    //     },
    //     {
    //         path: UserPaths.collectionDetail,
    //         element: <CollectionDetail />,
    //     },
    //     {
    //         path: UserPaths.auctionDetail,
    //         element: <AuctionDetail />,
    //     },
    //     {
    //         path: UserPaths.collections,
    //         element: <Collections />,
    //     },
    //     {
    //         path: UserPaths.myCollections,
    //         element: <MyCollections />,
    //     },
    //     {
    //         path: UserPaths.baseComponents,
    //         element: <BaseComponents />,
    //     },
];

export const SUPPORT_ROUTES: RouteProps[] = [
    //     {
    //         path: SupportPaths.article,
    //         element: <SupportArticle />,
    //     },
    //     {
    //         path: SupportPaths.getStarted,
    //         element: <SupportBuying />,
    //     },
    //     {
    //         path: SupportPaths.buying,
    //         element: <SupportBuying />,
    //     },
    //     {
    //         path: SupportPaths.selling,
    //         element: <SupportBuying />,
    //     },
    //     {
    //         path: SupportPaths.creating,
    //         element: <SupportBuying />,
    //     },
    //     {
    //         path: SupportPaths.faq,
    //         element: <SupportBuying />,
    //     },
    //     {
    //         path: SupportPaths.userSafety,
    //         element: <SupportBuying />,
    //     },
    //     {
    //         path: SupportPaths.submit,
    //         element: <SupportSubmit />,
    //     },
    //     {
    //         path: SupportPaths.ticketDetail,
    //         element: <SupportTicketDetail />,
    //     },
    // {
    //     path: SupportPaths.allTickets,
    //     element: <ManageSupport />,
    // },
];

export const ADMIN_ROUTES: RouteProps[] = [
    // {
    //     path: AdminPaths.home,
    //     element: <AdminPage />,
    // },
    // {
    //     path: AdminPaths.events,
    //     element: <AdminPage />,
    // },
    // {
    //     path: AdminPaths.marketplace,
    //     element: <AdminMarketplace />,
    // },
];
