export const Membership: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="#fff"
      className={className}
    >
      <path d="M27,6H5A3,3,0,0,0,2,9V23a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V9A3,3,0,0,0,27,6ZM5,8H27a1,1,0,0,1,1,1v2H4V9A1,1,0,0,1,5,8ZM27,24H5a1,1,0,0,1-1-1V15H28v8A1,1,0,0,1,27,24Z" />
      <rect width="9" height="2" x="16" y="21" />
    </svg>
  );
};
