import { CrossMark } from "components/assets";
import { FC, memo } from "react";
import { mouseEnter, mouseLeave } from "utils";
import { IModalProps } from "./newModal.props";
import { Modal, CloseIconWrapper } from "./newModal.style";

const NewModal: FC<IModalProps> = (props) => {
  return (
    <Modal
      closeIcon={
        <CloseIconWrapper onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <CrossMark />
        </CloseIconWrapper>
      }
      footer={null}
      {...props}
    />
  );
};

export default memo(NewModal);
