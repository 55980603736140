import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
// import { ETHEREUM_CHAIN, NETWORK_CHAIN_IDS } from "./constants";
import { AbstractConnector } from '@web3-react/abstract-connector';
import { ConnectorUpdate } from '@web3-react/types';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import Torus, { TorusCtorArgs, TorusParams, TorusLoginParams } from '@toruslabs/torus-embed';

interface TorusConnectorArguments {
    initOptions?: TorusParams;
    constructorOptions?: TorusCtorArgs;
    loginOptions?: TorusLoginParams;
    supportedChainIds?: number[];
}

export class TorusConnector extends AbstractConnector {
    private readonly initOptions: TorusParams;
    private readonly constructorOptions: TorusCtorArgs;
    private readonly loginOptions: TorusLoginParams;

    public torus: Torus | undefined;

    constructor({
        initOptions = {},
        constructorOptions = {},
        loginOptions = {},
        supportedChainIds,
    }: TorusConnectorArguments) {
        super({ supportedChainIds });

        this.initOptions = initOptions;
        this.constructorOptions = constructorOptions;
        this.loginOptions = loginOptions;

        this.handleNetworkChanged = this.handleNetworkChanged.bind(this);
        this.handleChainChanged = this.handleChainChanged.bind(this);
        this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
        this.close = this.close.bind(this);
    }

    public async activate(): Promise<ConnectorUpdate> {
        if (!this.torus) {
            this.torus = new Torus(this.constructorOptions);
            await this.torus.init(this.initOptions);
        }

        const account = await this.torus.login(this.loginOptions).then((accounts: string[]): string => accounts[0]);

        this.torus.provider.on('chainChanged', this.handleChainChanged);
        this.torus.provider.on('accountsChanged', this.handleAccountsChanged);
        this.torus.provider.on('networkChanged', this.handleNetworkChanged);

        return { provider: this.torus.provider, account };
    }

    public async getProvider(): Promise<any> {
        return this.torus?.provider;
    }

    public async getChainId(): Promise<any> {
        return this.torus?.ethereum.chainId;
    }

    public async getAccount(): Promise<null | string> {
        return this.torus?.ethereum.request({ method: 'eth_accounts' }).then((accounts: any) => accounts[0]);
    }

    public async deactivate() {
        if (this.torus) {
            this.torus.provider.removeListener('chainChanged', this.handleChainChanged);
            this.torus.provider.removeListener('accountsChanged', this.handleAccountsChanged);
            this.torus.provider.removeListener('networkChanged', this.handleNetworkChanged);
        }
    }

    public async close() {
        await this.torus?.cleanUp();
        this.torus = undefined;
        this.emitDeactivate();
    }

    private handleChainChanged(chainId: any): void {
        // // @ts-ignore
        // if (__DEV__) {
        //   console.log("Handling 'chainChanged' event with payload", chainId);
        // }
        this.emitUpdate({ chainId: chainId, provider: this.torus?.provider });
    }

    private handleAccountsChanged(accounts: any): void {
        // // @ts-ignore
        // if (__DEV__) {
        //   console.log("Handling 'accountsChanged' event with payload", accounts);
        // }
        this.emitUpdate({ account: accounts[0] });
    }

    private handleNetworkChanged(networkId: any): void {
        // // @ts-ignore
        // if (__DEV__) {
        //   console.log("Handling 'networkChanged' event with payload", networkId);
        // }
        this.emitUpdate({ chainId: networkId, provider: this.torus?.provider });
    }
}

export const injected = new InjectedConnector({
    // supportedChainIds: [
    //   NETWORK_CHAIN_IDS.bscMainnet,
    //   NETWORK_CHAIN_IDS.bscTestnet,
    // ],
});

export const coinbaseWallet = new WalletLinkConnector({
    url: 'https://mainnet.infura.io/v3/e94bb994441c44619943b0851d6d9611',
    appName: 'Metaversus',
    // supportedChainIds: [
    //   NETWORK_CHAIN_IDS.bscMainnet,
    //   NETWORK_CHAIN_IDS.bscTestnet,
    // ],
});
const rpcUrl = 'https://meta-test.infura-ipfs.io/v3/2DAE88mSYMpN5QH9umB4JhjLHhe';
export const walletconnect = new WalletConnectConnector({
    rpc: { [`${process.env.REACT_APP_CHAIN_ID}`]: rpcUrl },
    // rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
    // bridge: "https://bridge.walletconnect.org",
    // qrcode: true
});

export const torus = new TorusConnector({
    initOptions: {
        network: {
            //   host: ETHEREUM_CHAIN[NETWORK_CHAIN_IDS.bscTestnet].rpcUrls[0],
            //   chainId: NETWORK_CHAIN_IDS.bscTestnet,
            host: 'https://data-seed-prebsc-1-s1.binance.org:8545',
            chainId: 56,
        },
        showTorusButton: true,
    },
});

export const binance = new BscConnector({
    supportedChainIds: [
        // NETWORK_CHAIN_IDS.bscMainnet,
        // NETWORK_CHAIN_IDS.bscTestnet,
        56,
    ],
});

export enum ConnectorNames {
    Injected = 'Injected',
    WalletLink = 'WalletLink',
    Torus = 'Torus',
    Binance = 'Binance',
    WalletConnect = 'WalletConnect',
}

export const connectorsByName = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletLink]: coinbaseWallet,
    [ConnectorNames.Torus]: torus,
    [ConnectorNames.Binance]: binance,
    [ConnectorNames.WalletConnect]: walletconnect,
};
