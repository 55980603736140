export const BNBCoin = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#F3BA2F" />
            <path
                d="M10.0364 11.5464L12.5997 8.98319L15.1643 11.5477L16.6558 10.0562L12.5997 6L8.54492 10.0549L10.0364 11.5464Z"
                fill="#fefefe"
            />
            <path d="M8.98316 12.5969L7.4917 11.1055L6.00016 12.597L7.49163 14.0885L8.98316 12.5969Z" fill="#fefefe" />
            <path
                d="M10.0362 13.6546L12.5994 16.2177L15.164 13.6533L16.6563 15.144L16.6556 15.1448L12.5994 19.2009L8.54457 15.1462L8.54248 15.1441L10.0362 13.6546Z"
                fill="#fefefe"
            />
            <path
                d="M17.7077 14.0921L19.1992 12.6006L17.7078 11.1091L16.2162 12.6007L17.7077 14.0921Z"
                fill="#fefefe"
            />
            <path
                d="M14.1125 12.5965H14.1132L12.5997 11.083L11.4812 12.2015H11.4811L11.3526 12.33L11.0875 12.5951L11.0854 12.5972L11.0875 12.5994L12.5997 14.1115L14.1132 12.5981L14.1139 12.5972L14.1125 12.5965Z"
                fill="#fefefe"
            />
        </svg>
    );
};
