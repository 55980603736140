import { IChainName } from 'type/network';
import { NETWORK_CHAIN_IDS } from './chainIds';

export const API_END_POINT = process.env.REACT_APP_API_BE || 'http://localhost:4000';
export const API_VERSION = 'v2';
export const CURSOR_HOVER = 'cursor-hover';

export const REACT_APP_MORALIS_DAPP_URL = process.env.REACT_APP_MORALIS_DAPP_URL || '';
export const REACT_APP_MORALIS_APP_ID = process.env.REACT_APP_MORALIS_APP_ID || '';
export const REACT_APP_MORALIS_RPC_URL =
    process.env.REACT_APP_MORALIS_RPC_URL || 'https://data-seed-prebsc-1-s3.binance.org:8545';

export const REACT_APP_IPFS_GATEWAY_URL = process.env.REACT_APP_IPFS_GATEWAY_URL || '';
export const REACT_APP_IPFS_INFURA_PROJECT_ID = process.env.REACT_APP_IPFS_INFURA_PROJECT_ID || '';
export const REACT_APP_IPFS_INFURA_PROJECT_SECRET = process.env.REACT_APP_IPFS_INFURA_PROJECT_SECRET || '';
export const REACT_APP_IPFS_INFURA_DEDICATED_GATEWAY_URL =
    process.env.REACT_APP_IPFS_INFURA_DEDICATED_GATEWAY_URL || '';

export const REACT_APP_BSC_SCAN = process.env.REACT_APP_BSC_SCAN || '';

// @ts-ignore
export const CHAIN_NAME: IChainName = process.env.REACT_APP_CHAIN_NAME || 'bsc';

export const REACT_APP_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID || '0') || NETWORK_CHAIN_IDS.bscMainnet;

export * from './breakpoints';
export * from './header';
export * from './locales';
export * from './menu';
export * from './paths';
export * from './social';
export * from './chainIds';
export * from './chainIdList';
export * from './currencies';
