import { Image } from 'antd';

export const NewCMCG = () => {
    return (
        // <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <circle cx="10.5" cy="11" r="10.5" fill="0.0625rem solid #a1b7f8 " />
        //     <path
        //         d="M13.1056 12.2391H17.2806L14.2562 7L11.8754 11.1225H8.99025L9.63505 12.2391H11.2306L10.1403 14.1278L6.025 7L3 12.2391H7.76042L10.1403 16.361L14.2556 9.2341L15.3481 11.1225H13.7525L13.1056 12.2391ZM4.9347 11.1225L6.025 9.2341L7.11593 11.1225H4.9347Z"
        //         fill="white"
        //     />
        // </svg>
        <Image src="/logo-global.webp" style={{ width: 21, height: 21, borderRadius: '50%' }} />
    );
};

export const NewCMCGBlack = () => {
    return (
        // <svg
        //   width="21"
        //   height="21"
        //   viewBox="0 0 21 21"
        //   fill="none"
        //   xmlns="http://www.w3.org/2000/svg"
        // >
        //   <circle cx="10.5" cy="10.5" r="10.5" fill="#201F2A" />
        //   <path
        //     d="M12.9264 11.6293H16.6159L13.9432 7L11.8406 10.6423H9.29028L9.86031 11.6293H11.2705L10.3088 13.2974L6.67268 7.00262L4 11.6319H8.20617L10.3088 15.2733L13.945 8.9785L14.9067 10.6466H13.4964L12.9264 11.6293ZM5.7066 10.6423L6.66831 8.97325L7.63002 10.6423H5.7066Z"
        //     fill="white"
        //   />
        // </svg>
        <Image src="/logo-global.webp" style={{ width: 21, height: 'auto', borderRadius: '50%' }} />
    );
};

export const CMCGTransaction = () => {
    return (
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5349 8.85855H21.9531L17.3038 0.804688L13.6439 7.14206H9.2086L10.1998 8.85855H12.6526L10.9765 11.762L4.65023 0.804688L0 8.85855H7.31802L10.9765 15.195L17.3029 4.2391L18.9823 7.14206H16.5295L15.5349 8.85855ZM2.97415 7.14206L4.65023 4.2391L6.32728 7.14206H2.97415Z"
                fill="0.0625rem solid #a1b7f8 "
            />
        </svg>
    );
};
