export const isContainSpecialCharacter = (value: string) => {
  const specialChars = /[`!@#$%^&*()_+-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(value);
};

export const isContainOnlyHyphen = (value: string) => {
  const specialChars = /[`!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/;
  return !specialChars.test(value);
};

export const isValidEmail = (email: string) => {
  const validateEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validateEmailRegex.test(email);
};
