import styled from 'styled-components';
import { Menu as AMenu, Layout as ALayout } from 'antd';
import CText from 'components/base/text';
import ButtonOutline from 'components/base/buttonOutline';
import MConnectWallet from 'components/modules/sidebar/connectWallet';
import CSection from 'components/base/section';
import CDropdown from 'components/base/dropdown';
import CImg from 'components/base/image';
import CTitle from 'components/base/title';
import { forwardRef } from 'react';
import MNftPrice from 'components/modules/price/nftPrice';

export const Dropdown = styled(CDropdown)``;

export const BaseText = styled(CText)`
    font-style: normal;
    font-weight: normal;
    text-align: center;
    line-height: 1.3;
`;

export const Layout = styled.div`
    height: 100vh;
    background: #fefefe;

    .ant-layout {
        height: 100%;
        background: #fefefe;
        .ant-layout-header {
            height: fit-content;
            border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
            padding: 0 0.625rem 0 1.125rem;
            background: #fefefe;
        }
        .ant-layout-sider {
            background: #fefefe;
        }
    }
    .ant-layout-content {
        overflow-y: auto;
    }
`;

export const MobileSiderMask = styled.div`
    position: fixed;
    width: 100vw;
    height: 100%;
    display: none;
`;

export const MobileSider = styled.div`
    position: fixed;
    top: 5.625rem;
    left: 0;
    z-index: 999;
    height: calc(100vh - 5.625rem);
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.3s ease-in-out;

    &.active {
        transform: translate3d(0, 0, 0);

        ${MobileSiderMask} {
            display: block;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
    }
`;

export const MobileSiderContent = styled.div`
    width: 18.875rem;
    overflow-y: auto;
    overflow-x: hidden;
    background: ${({ theme }) => theme.sider.background};
    height: 100%;
    position: relative;
    z-index: 1000;
`;

export const ReportWrapper = styled.div`
    &,
    .ant-menu.ant-menu-inline-collapsed &.ant-menu-item-icon {
        display: flex;
    }

    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    background: #22212c;
    border-radius: 0.625rem;

    svg {
        width: 1.25rem;
        height: 1.25rem;
    }
`;

export const Menu = styled(({ hide, ...rest }) => <AMenu {...rest} />)`
    background: transparent;
    border: none;
    transition: none;
    width: 100%;

    li:last-child {
        border-bottom: none;
    }

    .menu-item-link {
        span {
            color: ${({ theme }) => theme.sider.menu.itemText};
        }
    }

    .ant-menu-item {
        padding: 0.9656rem 0.9656rem;
        height: 5rem;
        display: flex;
        align-items: center;
        gap: 0.625rem;
        color: #fff;
        margin: 0;
        opacity: ${({ theme }) => theme.sider.menu.itemOpacity};
        transition: 0.3s ease-in-out;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
            padding: 0.9656rem 2rem;
            svg:nth-child(2) {
                margin-right: 3rem;
            }
        }

        &:not(:first-child) {
            // border-top: 0.0625rem solid ${({ theme }) => theme.sider.menu.itemBorder};
        }

        &:last-child {
            border-top: 0.0625rem solid ${({ theme }) => theme.sider.menu.itemBorder};
        }

        &.ant-menu-item {
            user-select: none;

            &:after {
                display: none;
            }

            .ant-menu-title-content {
                display: flex;
                align-items: center;
                font-size: 1rem;
                line-height: 1;
                margin-left: 0.2rem;
                font-weight: 500;
                overflow: unset;

                @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                    width: 1rem;
                    height: 1rem;
                    font-size: 1rem;
                    line-height: 1rem;
                }
            }

            margin-bottom: 0;
            &-active,
            &-selected {
                opacity: 1;
                background: #272634;
                color: #fff;

                span {
                    color: #fff;
                }

                .icon {
                    .active {
                        opacity: 1;
                    }
                    .default {
                        opacity: 0;
                    }
                }
            }
        }

        .icon {
            width: 3.1806rem;
            height: 3.1806rem;
            flex-shrink: 0;
            @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
                width: 2.5625rem;
                height: 2.5625rem;
            }
            g {
                opacity: 1;

                path {
                    fill: ${({ theme }) => theme.sider.menu.itemIconFill};
                }

                path:first-child {
                    fill: ${({ theme }) => theme.sider.menu.itemIconBg};
                }
            }

            & > path {
                fill: ${({ theme }) => theme.sider.menu.itemIconFill};
            }

            & > path:first-child {
                fill: ${({ theme }) => theme.sider.menu.itemIconBg};
                stroke: ${({ theme }) => theme.sider.menu.itemIconStroke};
            }

            @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                width: 2rem;
                height: 2rem;
            }
        }
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-selected > div {
        background: #272634;
        color: white;
        opacity: 1 !important;

        .icon {
            opacity: 1;

            & > path {
                fill: #fff;
            }

            & > path:first-child {
                fill: url(#test2) !important;
                @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                    fill: url(#test) !important;
                }
            }

            g {
                opacity: 1;

                & > path {
                    fill: #fff;
                }

                & > path:first-child {
                    fill: url(#test2) !important;
                    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                        fill: url(#test) !important;
                    }
                }
            }
        }
    }

    .ant-menu-item-selected {
        ${ReportWrapper} {
            background: linear-gradient(140.19deg, 0.0625rem solid #a1b7f8 8.94%, #4154b0 88.83%), #22212c;
        }
    }

    & > li:first-child {
        .icon {
            .default {
                path:first-child {
                    fill: ${({ theme }) => theme.sider.menu.itemIconBg};
                    stroke: none;
                }

                path:last-child {
                    fill: none;
                    stroke: none;
                }
            }
        }
    }

    .has-collapse {
        height: unset;
        flex-direction: column;
        justify-content: center;

        > div {
            height: 3.8613rem !important;
            gap: 0.2rem;
            margin-block: 0;
            opacity: 0.5;
            transition: opacity 0.3s !important;
            cursor: url(/assets/images/cursor-white.svg), pointer;

            .icon {
                width: 2.5625rem;
                height: 2.5625rem;
                flex-shrink: 0;

                g {
                    opacity: 1;

                    path {
                        fill: ${({ theme }) => theme.sider.menu.itemIconFill};
                    }

                    path:first-child {
                        fill: ${({ theme }) => theme.sider.menu.itemIconBg};
                    }
                }

                & > path {
                    fill: ${({ theme }) => theme.sider.menu.itemIconFill};
                }

                & > path:first-child {
                    fill: ${({ theme }) => theme.sider.menu.itemIconBg};
                    stroke: ${({ theme }) => theme.sider.menu.itemIconStroke};
                }

                @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }

        .ant-menu-sub.ant-menu-inline {
            background: #fefefe;

            .sub-menu-item-link {
                height: 3rem !important;
                padding-left: 32px !important;
                padding-top: 1.7rem !important;
                padding-bottom: 1.7rem !important;
                border: none;

                svg {
                    margin-left: 0.625rem !important;
                }

                span {
                    color: ${({ theme }) => theme.sider.menu.itemText};
                    font-size: 1rem !important;
                    overflow: unset;
                }
            }
        }

        &:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
        &:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
            color: #fff;
        }
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background: #fefefe;
    }
`;

export const MenuItem = styled(({ children, ...props }) => <AMenu.Item {...props}>{children}</AMenu.Item>)`
    span {
        color: ${({ theme }) => theme.sider.menu.itemText};
    }
`;

export const MenuTitle = styled.div`
    display: flex;
`;

export const MenuIcon = styled.div`
    position: absolute;
    right: 14px;
`;

export const MenuItemTitle = styled.span``;

export const ConnectWallet = styled(MConnectWallet)`
    margin: 0 auto;
    flex-shrink: 0;
    padding: 0 0.375rem;
`;

export const TopMenuWrap = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 10.625rem;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 7.5rem;
    }
`;

export const LanguageButton = styled(
    forwardRef<any, any>(({ collapsed, children, ...props }, ref) => {
        return <ButtonOutline {...props}>{children}</ButtonOutline>;
    }),
)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.0625rem 0.9375rem;
    max-width: 17.375rem;
    width: 100%;
    height: 3.125rem;
    margin: 0 auto 1.25rem;
    font-size: 1.125rem;
    line-height: 1rem;
    text-transform: uppercase;
    background: ${({ theme }) => theme.header.button.background};

    & > span {
        display: flex;
        align-items: center;
        justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'space-between')};
        width: 100%;

        span {
            color: ${({ theme }) => theme.sider.menu.switchBtnText};
        }

        svg {
            border-radius: 50%;
        }

        // svg {
        //   path {
        //     fill: ${({ theme }) => theme.sider.menu.solanaIconText};
        //   }
        // }
    }
`;

export const LanguageIcon = styled.span``;

export const LanguageMenu = styled(({ children, ...props }) => <AMenu {...props}>{children}</AMenu>)`
    background: ${({ theme }) => theme.section.background};
    border: 0.0625rem solid ${({ theme }) => theme.colors.border};
    box-shadow: 0rem 0.25rem 1.5rem rgba(255, 255, 255, 0.15);
    border-radius: 0.375rem;
    padding: 0 0.5rem;
    width: 100%;
    min-width: 17.3125rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        min-width: 10.625rem;
    }

    .ant-dropdown-menu-item {
        height: 2.4rem;

        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
            height: 2.5rem;
        }

        &-active {
            span {
                color: #000;
            }
        }

        .ant-dropdown-menu-title-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        span {
            color: ${({ theme }) => theme.header.lang.text};
        }

        .ant-dropdown-menu-title-content {
            & > div {
                svg {
                    border-radius: 50%;
                    // box-shadow: 0rem 0.25rem 0.5rem rgb(196 196 196);
                }
            }

            & > div > div:last-child {
                span {
                    color: ${({ theme }) => theme.header.lang.text};
                    text-transform: capitalize;
                }
            }

            & > svg {
                path {
                    fill: ${({ theme }) => theme.header.lang.activeIcon};
                }
            }
        }

        &:hover {
            background: ${({ theme }) => theme.header.lang.hover};
            span {
                color: #fff;
            }
        }
    }
`;

export const LanguageMenuItem = styled(({ children, ...props }) => <AMenu.Item {...props}>{children}</AMenu.Item>)`
    span {
        color: ${({ theme }) => theme.header.lang.text};
    }

    .ant-dropdown-menu-title-content {
        & > div {
            svg {
                border-radius: 50%;
                // box-shadow: 0rem 0.25rem 0.5rem rgb(196 196 196);
            }
        }

        & > div > div:last-child {
            span {
                color: ${({ theme }) => theme.header.lang.text};
            }
        }

        & > svg {
            path {
                fill: ${({ theme }) => theme.header.lang.activeIcon};
            }
        }
    }

    &:hover {
        background: ${({ theme }) => theme.header.lang.hover};
        span {
            color: #fff;
        }
    }
`;

export const WalletInfoListItem = styled.li`
    display: flex;
    align-items: flex-start;
    // svg {
    //   width: 1.5625rem;
    //   height: 1.5625rem;
    // }

    &:not(:last-child) {
        margin-bottom: 0.625rem;
    }

    // & > div {
    //   margin-left: 0.375rem;
    // }
`;

export const BNBWrapper = styled.div`
    & > span {
        margin-bottom: 0.75rem;
    }
`;

export const BottomMenuWrap = styled(
    forwardRef<any, any>(({ collapsed, children, ...rest }, ref) => <div {...rest}>{children}</div>),
)`
    padding: 0 0.625rem 1.25rem;
    display: grid;
    place-items: center;

    & > div:first-child {
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: ${({ collapsed }) => (collapsed ? '0 0.625rem' : '0 1.0625rem')};

        & > div:first-child {
            width: unset;
        }

        & > div:nth-child(2) {
            width: 100%;
        }
    }

    @media screen and (min-width: 59.375rem) {
        padding: ${({ collapsed }) => (collapsed ? '0 0.625rem' : '0 1.0625rem')};
        .lang-btn {
            display: none;
        }
    }
`;

export const MenuContainer = styled(({ collapsed, ...props }) => <div {...props} />)`
    height: 100%;
    border-right: 0.0625rem solid ${({ theme }) => theme.sider.menu.border};
    border-left: 0;
    height: -webkit-fill-available;

    .logo {
        height: 5.6875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: ${({ collapsed }) => (collapsed ? ' 0.6875rem' : '0.6875rem')};
        padding-right: 0.6875rem;
        button {
            width: 1.875rem;
            height: 1.875rem;
            border: none;
            flex-shrink: 0;
        }
        a {
            display: inline-flex;
            margin-right: 0.375rem;
        }

        svg {
            path:first-child,
            path:last-child {
                fill: ${({ theme }) => theme.sider.logoText};
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &-only {
            svg {
                path:first-child,
                path:last-child {
                    fill: 0.0625rem solid #a1b7f8;
                }
            }
        }
    }
    .ant-menu.ant-menu-dark {
        background: #fefefe;
    }

    .menu {
        background: ${({ theme }) => theme.sider.background};
        color: white;
        border: none;
        overflow-x: hidden;

        .ant-menu-item {
            display: flex;
            justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'inherit')};
            padding: ${({ collapsed }) => (collapsed ? '0' : '1.25rem')} !important;
            svg {
                margin-left: ${({ collapsed }) => (collapsed ? '0rem !important' : '0rem')};
            }
            opacity: ${({ theme }) => theme.sider.menu.itemOpacity};
            transition: opacity 0.3s;

            .ant-menu-title-content {
                display: flex;
                align-items: center;
                line-height: unset;
                margin-block: 0;
                overflow: unset;

                span {
                    display: flex;
                    align-items: center;
                }
            }
        }
        &.ant-menu-inline-collapsed {
            .ant-menu-title-content {
                display: none !important;
            }
        }
        .ant-menu-item:not(.ant-menu-item-disabled):hover {
            opacity: 1;
            color: white;
            background: #272634;

            .icon {
                .active {
                    opacity: 1;
                }
                .default {
                    opacity: 0;
                }
            }
        }
        .ant-menu-item:not(.ant-menu-item-disabled):active,
        .ant-menu-submenu-title:active {
            background: #3e3d46;
            opacity: 1;
        }

        .ant-menu-item-disabled:active {
            background: none;
        }

        .ant-menu-item-selected,
        .ant-menu-submenu-selected > div {
            background: #272634;
            color: white;
            opacity: 1 !important;

            .icon {
                opacity: 1;

                & > path {
                    fill: #fff;
                }

                & > path:first-child {
                    fill: url(#test2) !important;
                    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                        fill: url(#test) !important;
                    }
                }

                g {
                    opacity: 1;

                    & > path {
                        fill: #fff;
                    }

                    & > path:first-child {
                        fill: url(#test2) !important;
                        @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
                            fill: url(#test) !important;
                        }
                    }
                }
            }
        }

        li {
            // border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
            display: flex;
            align-items: center;
            margin: 0 !important;
            height: 3.8613rem;
        }

        .has-collapse {
            height: unset;
            flex-direction: column;
            justify-content: center;

            > div {
                padding-left: ${({ collapsed }) => (collapsed ? `0` : `1.25rem !important`)};
                height: 3.8613rem;
                gap: 0.2rem;
                margin-block: 0;
                opacity: 0.5;
                transition: opacity 0.3s;
                cursor: url(/assets/images/cursor-white.svg), pointer;
            }

            ${({ collapsed }) =>
                collapsed &&
                `
        > div {
          display: flex;
          align-items: center;
        }
      `}

            .ant-menu-sub.ant-menu-inline {
                background: #fefefe;

                .sub-menu-item-link {
                    height: 3rem !important;
                    svg {
                        margin-left: 0.625rem !important;
                    }

                    span {
                        color: ${({ theme }) => theme.sider.menu.itemText};
                        font-size: 1rem !important;
                    }
                    padding-top: 1.7rem !important;
                    padding-bottom: 1.7rem !important;
                }
            }

            &:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
            &:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
                color: #fff;
            }
        }
    }
`;

export const WalletConnect = styled(({ collapsed, ...props }) => <MConnectWallet {...props} />)`
    margin: 0 auto;
    ${({ collapsed }) =>
        collapsed &&
        `
    width: 3.5rem;
    height: 3.75rem;

    h5, span {
      display: none;
    }
  `}
`;

export const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 5.6875rem);
    padding-bottom: 0.625rem;
    overflow-y: auto;
`;

export const Section = styled(CSection)``;

export const Sider = styled(ALayout.Sider)`
    width: 16rem;
    display: none;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
    }
`;

export const BinancePower = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6875rem;
    padding-top: 0.625rem;

    span {
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 3px;
        color: #fff0cc;
        margin: 0.3125rem;
        text-transform: uppercase;
    }

    svg {
        display: flex;
        justify-content: center;
        width: 100%;
    }
`;

export const Flag = styled(CImg)`
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    object-fit: cover;
`;

export const Mask = styled.div`
    position: absolute;
    inset: -0.375rem;
    pointer-events: none;

    svg {
        width: 100%;
        height: 110%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .ant-image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const WalletInfoBalance = styled.div`
    background: red;
`;

export const WalletInfoBalanceTitle = styled(CTitle)`
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    margin-bottom: 0.3125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 13.125rem;
    overflow: hidden;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        max-width: 6.25rem;
    }
`;

export const WalletInfoPriceText = styled(CText)`
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 13.125rem;
    display: block;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        max-width: 6.25rem;
    }
`;

export const WalletInfoPrice = styled.div``;

export const WalletInfoList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 10;
`;

export const WalletInfoTitle = styled(CText)`
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    margin-left: 0.375rem;
`;

export const WalletInfo = styled.div<{ collapsed?: boolean }>`
    max-width: 17.375rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border-radius: 0.375rem;
    position: relative;
    overflow: hidden;
    border: 0.0625rem solid rgba(255, 255, 255, 0.1);
    padding: 0.5rem 0.8125rem 0.5rem;
    justify-content: center;
    background: ${({ collapsed }) =>
        collapsed === true ? 'none' : 'linear-gradient(180deg, #fefefe 44.79%, #0a1227 100%)'};

    ${({ collapsed }) =>
        collapsed &&
        `
    width: 3.5rem;
    height: 3.5rem;

    ${WalletInfoList}, ${WalletInfoTitle} {
      display: none;
    }
  `}

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        max-width: 100%;
        height: 8.375rem;

        ${({ collapsed }) =>
            collapsed === true &&
            `
    width: 3.5rem;
    height: 3.5rem;
    border: none;

    & > svg {
      width: 100%;
    }

    ${Mask} {
      display: none;
    }

    ${WalletInfoList}, ${WalletInfoTitle} {
      display: none;
    }
  `}
    }
`;

export const ComingSoonBadge = styled.div`
    border-radius: 0.375rem;
    padding: 0.375rem 0.25rem;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    line-height: 1;
    color: #fefefe;
    margin-left: 0.4375rem;
`;

export const ShowNetworkWrapper = styled.span`
    display: inline-block;
`;

export const WalletBalanceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NftPrice = styled(MNftPrice)`
    span {
        font-size: 0.875rem;
    }
`;
