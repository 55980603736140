import { FC } from "react";

export const MarkRead: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="11" cy="11" r="10.5" stroke="white" />
      <circle cx="11" cy="11" r="7.5" fill="white" stroke="white" />
      <path
        d="M16.3125 7.66406C16.5469 7.89844 16.5469 8.25 16.3125 8.46094L10.125 14.6484C9.91406 14.8828 9.5625 14.8828 9.35156 14.6484L6.16406 11.4609C5.92969 11.25 5.92969 10.8984 6.16406 10.6641C6.375 10.4531 6.72656 10.4531 6.9375 10.6641L9.75 13.4766L15.5391 7.66406C15.75 7.45312 16.1016 7.45312 16.3125 7.66406Z"
        fill="#13121E"
      />
    </svg>
  );
};
