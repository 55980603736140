export const Subtract = () => {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.78125 0.875C9.78125 1.17578 9.54492 1.39062 9.26562 1.39062H1.35938C1.05859 1.39062 0.84375 1.17578 0.84375 0.896484C0.84375 0.595703 1.05859 0.359375 1.35938 0.359375H9.26562C9.54492 0.359375 9.78125 0.595703 9.78125 0.875Z"
        fill="white"
      />
    </svg>
  );
};
