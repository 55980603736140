import axios from 'axios';
import { API_END_POINT, API_VERSION } from 'utils/constants';
import { store } from '../store';

export const instance = axios.create({
    baseURL: `${API_END_POINT}`,
});

instance.interceptors.request.use(
    (config) => {
        const token = store.getState().user.token || localStorage.getItem('access-token');
        if (config.headers) {
            config.withCredentials = false;
            config.headers.Authorization = token ? `Bearer ${token}` : '';
        }
        return config;
    },
    function (error) {
        // Do something with request error
        // console.log({ error });
        return Promise.reject(error);
    },
);
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // console.log({ error });
        return Promise.reject(error);
    },
);

export default instance;
