import { Form as AntdForm, Radio, Switch } from 'antd';
// import Tooltip from 'react-tooltip-lite';
import { Row, Col, Slider, Tooltip } from 'antd';
import { Notification } from 'components/base';
import { NOTIFICATION_TYPE } from 'components/base/Notification';
import { useAppSelector, useWallet } from 'hooks';
import { FC, memo, useEffect, useState, useCallback, useRef } from 'react';
import { useMutation } from 'react-query';
import { getShortedUrlByUrl } from 'redux/apis/shortedUrl';
import { getBase64, mouseEnter, mouseLeave } from 'utils';
import { generateSlug } from 'utils/formatter';
import { isContainOnlyHyphen } from 'utils/validateText';
import { fetchData } from 'utils/fetchApi';
import {
    ECollectonFormType,
    ICollectionForm,
    ICollectionFormProps,
    IFieldValidation,
    ICategory,
    NFTType,
    CategoryType,
} from './collectionForm.props';
import {
    BasicInfo,
    ButtonCreate,
    CollectionFormContainer,
    CollectionFormWrapper,
    ContractTypeSelect,
    CategorySelect,
    CoverUpload,
    CoverUploadFormItem,
    CustomTextarea,
    FormBlock,
    FormItem,
    GridContractTypeBlock,
    GridPermissionBlock,
    InputNumberCustom,
    LogoUpload,
    LogoUploadFormItem,
    MainTitle,
    NameSymbolBlock,
    PermissionBlock,
    RoyaltyBlock,
    RadioWithoutHand,
    SubTitle,
    TextareaBlock,
    TextInput,
    TitleBlock,
    UrlInput,
    SwitchButton,
    CheckBoxButton,
    LinkBlock,
    StyledLink,
} from './collectionForm.style';

import UploadImage from './image';

import { useNavigate } from 'react-router-dom';

import { ethers } from 'ethers';
import xssFilters from 'xss-filters';

const FtForm = ({ createToken, createTaxAndReflecToken, isProcessing, tokenAddress }) => {
    const { account } = useWallet();
    const navigate = useNavigate();

    const { connectedWallet } = useAppSelector((state) => state.wallet);
    const [tokenType, setTokenType] = useState(0);
    const [form] = AntdForm.useForm();

    const [isDisable, setDisable] = useState(false);

    const [ftForm, setFtForm] = useState({
        name: '',
        symbol: '',
        initSupply: 1000,
        decimals: 18,
        mintable: false,
        burnable: false,
        pausable: false,
        permit: false,
        votes: false,
        flashMinting: false,
        snapshots: false,
        feeCreator: 0,
        enableTaxAndReflec: false,
        accessControl: false,
        ownable: false,
        roles: false,
    });

    const [enableTax, setEnableTax] = useState(false);

    const [taxAndReflec, setTaxAndReflec] = useState({
        reflection: 0,
        marketing: 0,
        burn: 0,
        liquidityPool: 0,
        buyback: 0,
    });

    const myRef: any = useRef(null);

    const getSupplyLabel = (value) => {
        if (value < 1000) return value;
        if (value >= 1000 && value < 1000000) return `≈ ${value / 1000}k`;
        if (value >= 1000000 && value < 1000000000) return `≈ ${value / 1000000} million`;
        if (value >= 1000000000 && value < 1000000000000) return `≈ ${value / 1000000000} billion`;
        if (value >= 1000000000000 && value < 1000000000000000) return `≈ ${value / 1000000000000} trillion`;
        if (value >= 1000000000000000) return `≈ ${value / 1000000000000000} quadrillion`;
    };
    const RedStarLabel = ({ children }) => (
        <span>
            <span style={{ color: 'red' }}>*</span>
            {children}
        </span>
    );
    const showNotification = (type, message) => {
        return Notification({
            type: type,
            message: message,
        });
    };

    useEffect(() => {
        if (!isProcessing) {
            setDisable(false);
        }
    }, [isProcessing]);

    useEffect(() => {
        if (tokenAddress && myRef.current) {
            myRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [tokenAddress]);

    return (
        <CollectionFormContainer>
            <CollectionFormWrapper>
                <div style={{ display: 'block', height: '40px' }} ref={myRef} />
                {tokenAddress && (
                    <LinkBlock>
                        <StyledLink
                            href={`https://testnet.bscscan.com/token/${tokenAddress}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Token Address: {tokenAddress}
                        </StyledLink>
                        <StyledLink href="/ft/tokenInfo" target="_blank" rel="noopener noreferrer">
                            Check Token Infomation
                        </StyledLink>
                        <StyledLink href="/ft/tokenVerify" target="_blank" rel="noopener noreferrer">
                            Verify Token
                        </StyledLink>
                    </LinkBlock>
                )}
                <FormBlock
                    form={form}
                    onFinish={() => {
                        // if (isFormError) {
                        //   return;
                        // }
                        try {
                            if (enableTax === false) {
                                createToken(ftForm);
                            } else {
                                createTaxAndReflecToken({ ...ftForm, ...taxAndReflec });
                            }
                        } catch (e) {
                            setDisable(false);
                        }
                        setDisable(true);
                    }}
                    autoComplete="off"
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TitleBlock>
                            <MainTitle>Token Information</MainTitle>
                        </TitleBlock>
                    </div>
                    <NameSymbolBlock>
                        <FormItem
                            name="name"
                            labelCol={{ span: 24 }}
                            label={<RedStarLabel> Name</RedStarLabel>}
                            rules={[
                                { required: true, message: 'This field is required' },
                                () => {
                                    return {
                                        validator(_, value) {
                                            if (value && value.trim().length === 0 && value.length > 0) {
                                                return Promise.reject(new Error('Name Text is invalid'));
                                            }

                                            if (value && xssFilters.inHTMLData(value) !== value && value.length > 0) {
                                                return Promise.reject(new Error('Description Text is invalid'));
                                            }

                                            return Promise.resolve();
                                        },
                                    };
                                },
                            ]}
                            initialValue={null}
                        >
                            <TextInput
                                placeholder={'Enter name'}
                                onChange={(event) => {
                                    setFtForm({
                                        ...ftForm,
                                        name: event.target.value,
                                    });
                                }}
                                disabled={isDisable}
                            />
                        </FormItem>
                        <FormItem
                            name="symbol"
                            labelCol={{ span: 24 }}
                            label={<RedStarLabel> Symbol</RedStarLabel>}
                            rules={[
                                { required: true, message: 'This field is required' },
                                { max: 9, message: 'Symbol must be maximum 5 characters.' },
                                () => {
                                    return {
                                        validator(_, value) {
                                            if (value && value.trim().length === 0 && value.length > 0) {
                                                return Promise.reject(new Error('Symbol Text is invalid'));
                                            }

                                            if (value && xssFilters.inHTMLData(value) !== value && value.length > 0) {
                                                return Promise.reject(new Error('Description Text is invalid'));
                                            }

                                            return Promise.resolve();
                                        },
                                    };
                                },
                            ]}
                            initialValue={null}
                        >
                            <TextInput
                                placeholder={'Enter symbol'}
                                disabled={isDisable}
                                onChange={(event) => {
                                    setFtForm({
                                        ...ftForm,
                                        symbol: event.target.value,
                                    });
                                }}
                            />
                        </FormItem>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <FormItem
                                name="supply"
                                labelCol={{ span: 24 }}
                                label={<RedStarLabel> Initial Supply</RedStarLabel>}
                                rules={[{ required: true, message: 'This field is required' }]}
                                initialValue={1000}
                                style={{ width: '65%' }}
                            >
                                <InputNumberCustom
                                    placeholder={''}
                                    type="number"
                                    min={1}
                                    max={100000000000000000000}
                                    disabled={isDisable}
                                    onChange={(event: any) => {
                                        setFtForm({
                                            ...ftForm,
                                            initSupply: event,
                                        });
                                    }}
                                />
                            </FormItem>
                            <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                {getSupplyLabel(ftForm.initSupply)}
                            </div>
                        </div>

                        <FormItem
                            name="decimals"
                            labelCol={{ span: 24 }}
                            label={<RedStarLabel> Decimals(1-18):</RedStarLabel>}
                            rules={[{ required: true, message: 'This field is required' }]}
                            initialValue={18}
                        >
                            <InputNumberCustom
                                placeholder={''}
                                type="number"
                                min={1}
                                max={18}
                                disabled={isDisable}
                                onChange={(event: any) => {
                                    setFtForm({
                                        ...ftForm,
                                        decimals: event,
                                    });
                                }}
                            />
                        </FormItem>
                    </NameSymbolBlock>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '25px 0',
                            borderTop: '2px dashed #3b4167',
                        }}
                    >
                        <TitleBlock>
                            <MainTitle>Token Features</MainTitle>
                        </TitleBlock>
                    </div>
                    <GridPermissionBlock>
                        <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <SwitchButton
                                // className="custom-switch"
                                checked={ftForm.mintable}
                                onChange={() => {
                                    // if (royalty === true) {
                                    setFtForm({
                                        ...ftForm,
                                        mintable: !ftForm.mintable,
                                    });
                                    if (ftForm.mintable === false) {
                                        setEnableTax(false);
                                    }
                                    // }
                                }}
                                disabled={isDisable}
                            />
                            <span style={{ paddingLeft: 25 }}>Mintable</span>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: '25vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '50%',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip
                                    title="Privileged accounts will be able to create more supply."
                                    trigger="hover"
                                >
                                    ?
                                </Tooltip>
                            </div>
                        </RoyaltyBlock>
                        {/* other blocks... */}

                        <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <SwitchButton
                                // className="custom-switch"
                                checked={ftForm.burnable}
                                onChange={() => {
                                    // if (royalty === true) {
                                    setFtForm({
                                        ...ftForm,
                                        burnable: !ftForm.burnable,
                                    });
                                    if (ftForm.burnable === false) {
                                        setEnableTax(false);
                                    }
                                    // }
                                }}
                                disabled={isDisable}
                            />{' '}
                            <span style={{ paddingLeft: 25 }}>Burnable</span>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: '25vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '50%',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip title="Token holders will be able to destroy their tokens." trigger="hover">
                                    ?
                                </Tooltip>
                            </div>
                        </RoyaltyBlock>
                        <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <SwitchButton
                                // className="custom-switch"
                                checked={ftForm.pausable}
                                onChange={() => {
                                    // if (royalty === true) {
                                    setFtForm({
                                        ...ftForm,
                                        pausable: !ftForm.pausable,
                                    });
                                    if (ftForm.pausable === false) {
                                        setEnableTax(false);
                                    }
                                    // }
                                }}
                                disabled={isDisable}
                            />{' '}
                            <span style={{ paddingLeft: 25 }}>Pausable</span>
                            <div
                                style={{
                                    position: 'absolute',
                                    marginLeft: '25vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '50%',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip
                                    title="Privileged accounts will be able to pause the functionality marked as whenNotPaused. Useful for emergency response."
                                    trigger="hover"
                                >
                                    ?
                                </Tooltip>
                            </div>
                        </RoyaltyBlock>
                        <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <SwitchButton
                                // className="custom-switch"
                                checked={ftForm.permit}
                                onChange={() => {
                                    // if (royalty === true) {
                                    setFtForm({
                                        ...ftForm,
                                        permit: !ftForm.permit,
                                    });
                                    if (ftForm.permit === false) {
                                        setEnableTax(false);
                                    }
                                    // }
                                }}
                                disabled={isDisable}
                            />{' '}
                            <span style={{ paddingLeft: 25 }}>Permit</span>
                            <div
                                style={{
                                    position: 'absolute',
                                    marginLeft: '25vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1) ',
                                    borderRadius: '50%',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip
                                    title="Without paying gas, token holders will be able to allow third parties to transfer from their account."
                                    trigger="hover"
                                >
                                    ?
                                </Tooltip>
                            </div>
                        </RoyaltyBlock>

                        <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <SwitchButton
                                // className="custom-switch"
                                checked={ftForm.votes}
                                onChange={() => {
                                    // if (royalty === true) {
                                    setFtForm({
                                        ...ftForm,
                                        votes: !ftForm.votes,
                                    });
                                    if (ftForm.votes === false) {
                                        setEnableTax(false);
                                    }
                                    // }
                                }}
                                disabled={isDisable}
                            />{' '}
                            <span style={{ paddingLeft: 25 }}>Votes</span>
                            <div
                                style={{
                                    position: 'absolute',
                                    marginLeft: '25vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '50%',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip
                                    title="Keeps track of historical balances for voting in on-chain governance, with a way to delegate one's voting power to a trusted account."
                                    trigger="hover"
                                >
                                    ?
                                </Tooltip>
                            </div>
                        </RoyaltyBlock>
                        <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <SwitchButton
                                // className="custom-switch"
                                checked={ftForm.flashMinting}
                                onChange={() => {
                                    // if (royalty === true) {
                                    setFtForm({
                                        ...ftForm,
                                        flashMinting: !ftForm.flashMinting,
                                    });
                                    if (ftForm.flashMinting === false) {
                                        setEnableTax(false);
                                    }
                                    // }
                                }}
                                disabled={isDisable}
                            />{' '}
                            <span style={{ paddingLeft: 25 }}>Flash Minting</span>
                            <div
                                style={{
                                    position: 'absolute',
                                    marginLeft: '25vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '50%',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip
                                    title="Built-in flash loans. Lend tokens without requiring collateral as long as they're returned in the same transaction."
                                    trigger="hover"
                                >
                                    ?
                                </Tooltip>
                            </div>
                        </RoyaltyBlock>
                        <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <SwitchButton
                                // className="custom-switch"
                                checked={ftForm.snapshots}
                                onChange={() => {
                                    // if (royalty === true) {
                                    setFtForm({
                                        ...ftForm,
                                        snapshots: !ftForm.snapshots,
                                    });
                                    if (ftForm.snapshots === false) {
                                        setEnableTax(false);
                                    }
                                    // }
                                }}
                                disabled={isDisable}
                            />{' '}
                            <span style={{ paddingLeft: 25 }}>Snapshots</span>
                            <div
                                style={{
                                    position: 'absolute',
                                    marginLeft: '25vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '50%',
                                    padding: '5px',
                                }}
                            >
                                <Tooltip
                                    title="Privileged accounts will be able to store snapshots of balances that can be retrieved later."
                                    trigger="hover"
                                >
                                    ?
                                </Tooltip>
                            </div>
                        </RoyaltyBlock>

                        <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <SwitchButton
                                // className="custom-switch"
                                checked={enableTax}
                                onChange={() => {
                                    // if (royalty === true) {
                                    setEnableTax(!enableTax);
                                    if (enableTax === false) {
                                        setFtForm({
                                            ...ftForm,
                                            mintable: false,
                                            burnable: false,
                                            pausable: false,
                                            permit: false,
                                            votes: false,
                                            flashMinting: false,
                                            snapshots: false,
                                            enableTaxAndReflec: false,
                                            accessControl: false,
                                            ownable: false,
                                            roles: false,
                                        });
                                    }
                                    // }
                                }}
                                disabled={isDisable}
                            />{' '}
                            <span style={{ paddingLeft: 25, color: '#0300b9', fontSize: 17 }}>
                                Enable Taxation & Reflection
                            </span>
                            <div
                                style={{
                                    position: 'absolute',
                                    marginLeft: '25vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '50%',
                                    padding: '5px',
                                }}
                            ></div>
                        </RoyaltyBlock>
                    </GridPermissionBlock>

                    {/* Taxing, Liquidity, Burn & Buyback */}
                    {enableTax && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '25px 0',
                                borderTop: '2px dashed #3b4167',
                            }}
                        >
                            <TitleBlock>
                                <MainTitle>Taxing, Liquidity, Burn & Buyback</MainTitle>
                            </TitleBlock>
                        </div>
                    )}

                    {enableTax && (
                        <GridPermissionBlock>
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <span>Tax % Back to Holders (reflection)</span>
                                <div
                                    style={{
                                        marginLeft: '1rem',
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: '50%',
                                        padding: '5px',
                                    }}
                                >
                                    <Tooltip
                                        title="What percentage of transaction should go towards holders as reflection?"
                                        trigger="hover"
                                    >
                                        ?
                                    </Tooltip>
                                </div>
                            </div>
                            <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Col span={12}>
                                    <Slider
                                        min={1}
                                        max={10}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                reflection: event,
                                            });
                                        }}
                                        value={taxAndReflec.reflection}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumberCustom
                                        min={1}
                                        max={10}
                                        style={{
                                            margin: '0 16px',
                                            fontSize: '1,5rem',
                                            lineHeight: '1.5rem',
                                            background: 'white',
                                            borderRadius: '5px',
                                            height: '1.5rem',
                                        }}
                                        value={taxAndReflec.reflection}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                reflection: event,
                                            });
                                        }}
                                    />
                                </Col>
                            </RoyaltyBlock>
                            {/* other blocks... */}

                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <span>Tax % to Marketing/Charity Wallet</span>
                                <div
                                    style={{
                                        marginLeft: '1rem',
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: '50%',
                                        padding: '5px',
                                    }}
                                >
                                    <Tooltip
                                        title="What percentage of transaction should go to Marketing/Charity wallet? Deploying address will be used as wallet (can be changed later)."
                                        trigger="hover"
                                    >
                                        ?
                                    </Tooltip>
                                </div>
                            </div>
                            <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Col span={12}>
                                    <Slider
                                        min={1}
                                        max={10}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                marketing: event,
                                            });
                                        }}
                                        value={taxAndReflec.marketing}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumberCustom
                                        min={1}
                                        max={10}
                                        style={{
                                            margin: '0 16px',
                                            fontSize: '1,5rem',
                                            lineHeight: '1.5rem',
                                            background: 'white',
                                            borderRadius: '5px',
                                            height: '1.5rem',
                                        }}
                                        value={taxAndReflec.marketing}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                marketing: event,
                                            });
                                        }}
                                    />
                                </Col>
                            </RoyaltyBlock>

                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <span>Tax % to Burn</span>
                                <div
                                    style={{
                                        marginLeft: '1rem',
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: '50%',
                                        padding: '5px',
                                    }}
                                >
                                    <Tooltip title="What percentage of transaction should be burned?" trigger="hover">
                                        ?
                                    </Tooltip>
                                </div>
                            </div>
                            <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Col span={12}>
                                    <Slider
                                        min={1}
                                        max={10}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                burn: event,
                                            });
                                        }}
                                        value={taxAndReflec.burn}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumberCustom
                                        min={1}
                                        max={10}
                                        style={{
                                            margin: '0 16px',
                                            fontSize: '1,5rem',
                                            lineHeight: '1.5rem',
                                            background: 'white',
                                            borderRadius: '5px',
                                            height: '1.5rem',
                                        }}
                                        value={taxAndReflec.burn}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                burn: event,
                                            });
                                        }}
                                    />
                                </Col>
                            </RoyaltyBlock>

                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <span>Tax % to Liquidity Pool</span>
                                <div
                                    style={{
                                        marginLeft: '1rem',
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: '50%',
                                        padding: '5px',
                                    }}
                                >
                                    <Tooltip
                                        title="What percentage of transaction should go towards Liquidity on QuickSwap?"
                                        trigger="hover"
                                    >
                                        ?
                                    </Tooltip>
                                </div>
                            </div>
                            <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Col span={12}>
                                    <Slider
                                        min={1}
                                        max={10}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                liquidityPool: event,
                                            });
                                        }}
                                        value={taxAndReflec.liquidityPool}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumberCustom
                                        min={1}
                                        max={10}
                                        style={{
                                            margin: '0 16px',
                                            fontSize: '1,5rem',
                                            lineHeight: '1.5rem',
                                            background: 'white',
                                            borderRadius: '5px',
                                            height: '1.5rem',
                                        }}
                                        value={taxAndReflec.liquidityPool}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                liquidityPool: event,
                                            });
                                        }}
                                    />
                                </Col>
                            </RoyaltyBlock>

                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <span>Tax % to Buyback</span>
                                <div
                                    style={{
                                        marginLeft: '1rem',
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: '50%',
                                        padding: '5px',
                                    }}
                                >
                                    <Tooltip
                                        title="What percentage of transaction should be used to buyback tokens when selling on QuickSwap (Hyper-deflation)?"
                                        trigger="hover"
                                    >
                                        ?
                                    </Tooltip>
                                </div>
                            </div>
                            <RoyaltyBlock style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Col span={12}>
                                    <Slider
                                        min={1}
                                        max={10}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                buyback: event,
                                            });
                                        }}
                                        value={taxAndReflec.buyback}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumberCustom
                                        min={1}
                                        max={10}
                                        style={{
                                            margin: '0 16px',
                                            fontSize: '1,5rem',
                                            lineHeight: '1.5rem',
                                            background: 'white',
                                            borderRadius: '5px',
                                            height: '1.5rem',
                                        }}
                                        value={taxAndReflec.buyback}
                                        onChange={(event: any) => {
                                            setTaxAndReflec({
                                                ...taxAndReflec,
                                                buyback: event,
                                            });
                                        }}
                                    />
                                </Col>
                            </RoyaltyBlock>
                        </GridPermissionBlock>
                    )}

                    {/* ToTal TAX*/}
                    {enableTax && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '25px 0',
                                borderTop: '2px dashed #5b6cdc',
                                width: '100%',
                            }}
                        >
                            <TitleBlock style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <MainTitle>
                                    Total Tax :{' '}
                                    {taxAndReflec.burn +
                                        taxAndReflec.buyback +
                                        taxAndReflec.liquidityPool +
                                        taxAndReflec.marketing +
                                        taxAndReflec.reflection}
                                </MainTitle>
                                {taxAndReflec.burn +
                                    taxAndReflec.buyback +
                                    taxAndReflec.liquidityPool +
                                    taxAndReflec.marketing +
                                    taxAndReflec.reflection >
                                    20 && (
                                    <div style={{ color: 'red' }}>
                                        Total Tax is very high, we recommend keeping it under 20%.
                                    </div>
                                )}
                            </TitleBlock>
                        </div>
                    )}

                    <ButtonCreate onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} loading={isProcessing}>
                        {'Create Token'}
                    </ButtonCreate>
                </FormBlock>
            </CollectionFormWrapper>
        </CollectionFormContainer>
    );
};

export default FtForm;
