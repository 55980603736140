import { FC, memo, useState, useEffect } from 'react';
import * as Styled from './profileInfo.style';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from 'antd';
import { NewCopy, NewShare, SocialFacebook, SocialInstagram, SocialTwitter } from 'components/assets';
import { useAppSelector, useMediaQuery, useWallet } from 'hooks';
import { ProfileInfoProps } from './profileInfo.props';
import { DiscordIcon, YoutubeIcon } from 'components/assets/channel.icon';
import { Telegram } from 'components/assets/svg';
import MiddleTruncate from '../middleTruncate';
import { getImageUrl, mouseEnter, mouseLeave } from 'utils';
import axios from 'axios';

const ProfileInfo: FC<ProfileInfoProps> = ({ infoToGet, share = true, children, borderless = false, ...rest }) => {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);
    const { info } = useAppSelector((state) => state.user);
    const { loading } = useAppSelector((state) => state.loading);
    const { account } = useWallet();
    const [image, setImage] = useState<string>('');
    const haveOtherInfo = !!infoToGet ? infoToGet : info;
    const [isLargerThan900px] = useMediaQuery(`(min-width: 900px)`);
    const socialLinks = [
        {
            href: haveOtherInfo?.social?.facebook,
            icon: <SocialFacebook />,
        },
        {
            href: haveOtherInfo?.social?.youtube,
            icon: <YoutubeIcon />,
        },
        {
            href: haveOtherInfo?.social?.instagram,
            icon: <SocialInstagram />,
        },
        {
            href: haveOtherInfo?.social?.discord,
            icon: <DiscordIcon />,
        },
        {
            href: haveOtherInfo?.social?.telegram,
            icon: <Telegram />,
        },
        {
            href: haveOtherInfo?.social?.twitter,
            icon: <SocialTwitter />,
        },
    ]
        .filter((item) => !!item?.href)
        .map((item) => {
            if (/^(ftp|http|https):\/\/[^ "]+$/.test(item.href as string)) {
                return item;
            } else {
                return { ...item, href: `https://${item.href}` };
            }
        });

    useEffect(() => {
        let isMount = true;

        if (isCopied) {
            setTimeout(() => isMount && setIsCopied(false), 3000);
        }

        return () => {
            isMount = false;
        };
    }, [isCopied]);
    useEffect(() => {
        (async () => {
            const getdata = await axios.get(`${process.env.REACT_APP_API_BE}/user/${account}`);

            if (getdata) {
                setImage(getdata?.data?.avatar);
                // dispatch(setInfo(getdata?.data));
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const walletAddress = account || haveOtherInfo?.walletAddress;

    return (
        <Styled.Information borderless={borderless} {...rest}>
            <Styled.Detail hasChildren={!!children}>
                <Styled.Avatar>
                    <Styled.Image
                        src={image ? image : '/assets/images/avatar-profile.svg'}
                        alt={haveOtherInfo?.email}
                    />
                </Styled.Avatar>
                <Styled.InfoWrapper>
                    <Styled.ProfileWrapper>
                        {haveOtherInfo?.username && (
                            <Styled.ColumnWrapper>
                                <Styled.Name
                                    skeleton={
                                        loading
                                            ? {
                                                  width: '150px',
                                                  height: '0.9375rem',
                                              }
                                            : {}
                                    }
                                >
                                    {haveOtherInfo?.username}
                                </Styled.Name>
                                <Styled.ProfileLink>
                                    {haveOtherInfo?.email ?? t('profile.profileTab.noEmail')}
                                </Styled.ProfileLink>
                            </Styled.ColumnWrapper>
                        )}
                        {haveOtherInfo?.personalWebsite && (
                            <Styled.WebsiteWrapper>
                                <Styled.Name>{t('profile.profileTab.website')}</Styled.Name>
                                {haveOtherInfo?.personalWebsite ? (
                                    <Styled.DescriptionLink
                                        href={
                                            haveOtherInfo?.personalWebsite?.startsWith('http')
                                                ? info?.personalWebsite
                                                : 'https://' + haveOtherInfo?.personalWebsite
                                        }
                                        target="_blank"
                                        onMouseEnter={mouseEnter}
                                        onMouseLeave={mouseLeave}
                                    >
                                        {haveOtherInfo?.personalWebsite}
                                    </Styled.DescriptionLink>
                                ) : (
                                    <Styled.Description>{t('profile.profileTab.noWebsite')}</Styled.Description>
                                )}
                            </Styled.WebsiteWrapper>
                        )}
                        <Styled.CoverWallet>
                            <Styled.WalletAddWrapper>
                                <Styled.Name>{t('profile.profileTab.walletAddress')}</Styled.Name>
                                {!walletAddress && <Styled.Description>{'No wallet attached'}</Styled.Description>}
                                {walletAddress && (
                                    <Styled.WalletAddAndActions>
                                        {isLargerThan900px ? (
                                            <Styled.WalletAddress onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                                                {walletAddress}
                                            </Styled.WalletAddress>
                                        ) : (
                                            //   </Tooltip>
                                            // </CopyToClipboard>
                                            <Styled.WalletAddress onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                                                <MiddleTruncate
                                                    text={walletAddress || ''}
                                                    ellipsis="..."
                                                    start={6}
                                                    end={4}
                                                />
                                            </Styled.WalletAddress>
                                        )}
                                    </Styled.WalletAddAndActions>
                                )}
                            </Styled.WalletAddWrapper>

                            <Styled.ColumnWrapper>
                                <Styled.WalletActions>
                                    {walletAddress && (
                                        <>
                                            <CopyToClipboard
                                                text={walletAddress ?? 'No wallet attached'}
                                                onCopy={() => setIsCopied(true)}
                                            >
                                                <Tooltip
                                                    trigger={['click']}
                                                    title={isCopied ? 'Copied to clipboard' : 'Tap to copy'}
                                                >
                                                    <Styled.WalletAction
                                                        onMouseEnter={mouseEnter}
                                                        onMouseLeave={mouseLeave}
                                                    >
                                                        <NewCopy />
                                                    </Styled.WalletAction>
                                                </Tooltip>
                                            </CopyToClipboard>
                                            <Styled.WalletAction
                                                onMouseEnter={mouseEnter}
                                                onMouseLeave={mouseLeave}
                                                onClick={() =>
                                                    window.open(
                                                        `${process.env.REACT_APP_BSC_SCAN}address/${walletAddress}`,
                                                        '_blank',
                                                    )
                                                }
                                            >
                                                <Tooltip title="Share">
                                                    <NewShare />
                                                </Tooltip>
                                            </Styled.WalletAction>
                                        </>
                                    )}
                                </Styled.WalletActions>
                            </Styled.ColumnWrapper>
                        </Styled.CoverWallet>
                    </Styled.ProfileWrapper>
                    {share ? (
                        <Styled.ShareContainer items={socialLinks as any} isExternal />
                    ) : (
                        <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
                    )}
                </Styled.InfoWrapper>
            </Styled.Detail>
        </Styled.Information>
    );
};

export default memo(ProfileInfo);
