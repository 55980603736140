import clsx from "clsx";
import { FC } from "react";
import styled from "styled-components";

export const Icon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("icon", className)}
    >
      <g>
        <path
          d="M0.891113 14.2505C0.891113 3.2406 3.25086 0.880859 14.2608 0.880859H18.4125C29.4225 0.880859 31.7822 3.2406 31.7822 14.2505V18.4023C31.7822 29.4122 29.4225 31.7719 18.4125 31.7719H14.2608C3.25086 31.7719 0.891113 29.4122 0.891113 18.4023V14.2505Z"
          fill="#22212C"
        />
        <path
          d="M12.8911 16C15.0786 16 16.8911 14.2188 16.8911 12C16.8911 9.8125 15.0786 8 12.8911 8C10.6724 8 8.89111 9.8125 8.89111 12C8.89111 14.2188 10.6724 16 12.8911 16ZM14.4536 17.5H11.2974C8.29736 17.5 5.89111 19.9375 5.89111 22.9375C5.89111 23.5312 6.35986 24 6.95361 24H18.7974C19.3911 24 19.8911 23.5312 19.8911 22.9375C19.8911 19.9375 17.4536 17.5 14.4536 17.5ZM20.8599 18H18.5474C19.9849 19.1875 20.8911 20.9688 20.8911 22.9375C20.8911 23.3438 20.7661 23.6875 20.5786 24H24.8911C25.4224 24 25.8911 23.5625 25.8911 23C25.8911 20.25 23.6411 18 20.8599 18ZM19.3911 16C21.2974 16 22.8911 14.4375 22.8911 12.5C22.8911 10.5938 21.2974 9 19.3911 9C18.5786 9 17.8599 9.28125 17.2974 9.71875C17.6411 10.4062 17.8911 11.1875 17.8911 12C17.8911 13.125 17.5161 14.1562 16.8911 14.9688C17.5161 15.625 18.3911 16 19.3911 16Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const Accounts = styled(Icon)`
  & > g {
    transition: opacity 0.3s;
  }
`;
