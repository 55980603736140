import styled from 'styled-components';
import { Pagination as APagination } from 'antd';

export const PaginationView = styled(({ ...rest }) => <APagination {...rest} />)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5625rem auto;
    width: fit-content;
    max-width: 100%;
    user-select: none;

    border: ${({ theme }) => theme.colors.border} 0.0625rem solid;
    border-radius: 0.5rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        justify-content: flex-start;
    }

    .ant-pagination-item {
        border: none;
        border-right: ${({ theme }) => theme.colors.border} 0.0625rem solid;
        border-left: ${({ theme }) => theme.colors.border} 0.0625rem solid;

        &-after-jump-prev {
            border-left: ${({ theme }) => theme.colors.border} 0.0625rem solid;
        }
    }

    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
        & > div {
            color: transparent;
            position: relative;

            &::before {
                position: absolute;
                content: '...';
                color: white;
                bottom: 8%;
                left: 45%;
            }
        }
    }

    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
        min-width: 1.875rem;
        height: 2.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        background-color: ${({ theme }) => theme.marketPlace.pagination.defaultBg};

        button {
            cursor: url(/assets/images/cursor-white.svg), pointer;
        }

        &:not(:first-child, :last-child) > div {
            position: relative;
            top: -0.125rem;
        }
    }

    .ant-pagination-prev {
        border-radius: 0.375rem 0 0 0.375rem;
    }

    .ant-pagination-next {
        margin-right: 0;
        border-radius: 0 0.375rem 0.375rem 0;
    }

    .ant-pagination-item div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -0.125rem;
        padding: 0 0.375rem;
        color: ${({ theme }) => theme.marketPlace.pagination.color};
    }

    .ant-pagination-item:hover:not(.ant-pagination-item-active) {
        border-color: ${({ theme }) => theme.marketPlace.pagination.light};
        background: ${({ theme }) => theme.marketPlace.pagination.bgColor};

        div {
            color: ${({ theme }) => theme.marketPlace.pagination.colorActive};
        }
    }

    .ant-pagination-item-active {
        border-color: transparent;
        background: ${({ theme }) => theme.marketPlace.pagination.bgColor};

        div {
            color: ${({ theme }) => theme.marketPlace.pagination.colorActive};
        }
    }

    // .ant-pagination-prev,
    // .ant-pagination-next {
    //   div {
    //     svg path {
    //       fill: ${({ theme }) => theme.title.color};
    //     }
    //     font-size: 0.75rem;
    //     border: none;
    //     border-radius: 50%;
    //     background: transparent;
    //   }
    // }

    .ant-pagination-prev:not(.ant-pagination-disabled),
    .ant-pagination-next:not(.ant-pagination-disabled) {
        svg path {
            fill: ${({ theme }) => theme.title.color};
        }

        &:hover {
            background: ${({ theme }) => theme.marketPlace.pagination.bgColor};

            svg path {
                fill: ${({ theme }) => theme.marketPlace.pagination.colorActive};
            }

            span {
                color: #fefefe;
            }
        }
    }

    .ant-pagination-disabled {
        opacity: 0.5;
    }

    @media screen and (min-width: 23.75rem) {
        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next {
            min-width: 2.375rem;
            height: 2.375rem;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        justify-content: flex-start;

        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next {
            min-width: 2.625rem;
            height: 2.625rem;
        }
    }
`;

export const PaginationArrowWrapper = styled.div<{ direction: string }>`
    display: flex;
    align-items: center;
    padding-inline: 0.25rem;
    user-select: none;

    & > span {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #f9fafb;
        font-weight: 400;
        display: none;
    }

    & > svg {
        position: relative;
        top: 0.0625rem;
        margin: 0;
    }

    @media screen and (min-width: 26.75rem) {
        & > span {
            display: block;
        }

        & > svg {
            ${({ direction }) =>
                direction === 'right'
                    ? `
        margin-right: 0.25rem;
      `
                    : `
        margin-left: 0.25rem;
      `}
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-inline: 0.625rem;

        & > svg {
            ${({ direction }) =>
                direction === 'right'
                    ? `
        margin-right: 1rem;
      `
                    : `
        margin-left: 1rem;
      `}
        }
    }
`;
