import { SVGProps } from "./svg.props";

const England = (props: SVGProps): JSX.Element => {
  const { width, height } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0v20h20V0H0Z" fill="#fff" />
      <path d="M11.667 0H8.333v20h3.334V0Z" fill="#E32D3C" />
      <path d="M20 11.667V8.334H0v3.333h20Z" fill="#E32D3C" />
    </svg>
  );
};

export default England;
