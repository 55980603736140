import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  loading: boolean;
  fullLoading: boolean;
} = {
  loading: false,
  fullLoading: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    toggleFullLoading: (state, action: PayloadAction<boolean>) => {
      state.fullLoading = action.payload;
    },
  },
});

export const { toggleLoading, toggleFullLoading } = loadingSlice.actions;
