import {
  Spain,
  Germany,
  Japan,
  England,
  Korea,
  Chinese,
} from "components/assets/svg";

export const LOCALES = [
  {
    name: "English",
    img: "/assets/images/english.png",
    code: "en",
    icon: <England />,
  },
  {
    name: "Japanese",
    img: "/assets/images/japan.png",
    code: "ja",
    icon: <Japan />,
  },
  {
    name: "Chinese",
    img: "/assets/images/japan.png",
    code: "zh",
    icon: <Chinese />,
  },
  {
    name: "Korean",
    img: "/assets/images/japan.png",
    code: "ko",
    icon: <Korea />,
  },
  {
    name: "Spanish",
    img: "/assets/images/spain.png",
    code: "es",
    icon: <Spain />,
  },
  {
    name: "German",
    img: "/assets/images/germany.png",
    code: "de",
    icon: <Germany />,
  },
];
