export const Info = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 11.5C6.95869 11.5 8.35764 10.9205 9.38909 9.88909C10.4205 8.85764 11 7.45869 11 6C11 4.54131 10.4205 3.14236 9.38909 2.11091C8.35764 1.07946 6.95869 0.5 5.5 0.5C4.04131 0.5 2.64236 1.07946 1.61091 2.11091C0.579463 3.14236 0 4.54131 0 6C0 7.45869 0.579463 8.85764 1.61091 9.88909C2.64236 10.9205 4.04131 11.5 5.5 11.5ZM6.13938 5.02925L5.45187 8.26394C5.40375 8.49769 5.47181 8.63037 5.66088 8.63037C5.79425 8.63037 5.99569 8.58225 6.1325 8.46125L6.072 8.74725C5.87469 8.98513 5.4395 9.15837 5.06481 9.15837C4.5815 9.15837 4.37594 8.86825 4.50931 8.25156L5.01669 5.86731C5.06069 5.66587 5.02081 5.593 4.81938 5.54419L4.50931 5.4885L4.56569 5.22656L6.14006 5.02925H6.13938ZM5.5 4.28125C5.31766 4.28125 5.1428 4.20882 5.01386 4.07989C4.88493 3.95095 4.8125 3.77609 4.8125 3.59375C4.8125 3.41141 4.88493 3.23655 5.01386 3.10761C5.1428 2.97868 5.31766 2.90625 5.5 2.90625C5.68234 2.90625 5.8572 2.97868 5.98614 3.10761C6.11507 3.23655 6.1875 3.41141 6.1875 3.59375C6.1875 3.77609 6.11507 3.95095 5.98614 4.07989C5.8572 4.20882 5.68234 4.28125 5.5 4.28125Z"
        fill="#9F9FA3"
      />
    </svg>
  );
};

export const UserLogin = ({ className }: { className?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 16.75C15 15.5289 15 14.9184 14.8493 14.4215C14.51 13.3029 13.6346 12.4275 12.516 12.0882C12.0191 11.9375 11.4086 11.9375 10.1875 11.9375H5.8125C4.59138 11.9375 3.98082 11.9375 3.484 12.0882C2.36539 12.4275 1.49003 13.3029 1.15071 14.4215C1 14.9184 1 15.5289 1 16.75M11.9375 4.9375C11.9375 7.11212 10.1746 8.875 8 8.875C5.82538 8.875 4.0625 7.11212 4.0625 4.9375C4.0625 2.76288 5.82538 1 8 1C10.1746 1 11.9375 2.76288 11.9375 4.9375Z"
        stroke="#F2F2F4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InfoLarge = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM8 4C8.53125 4 9 4.46875 9 5C9 5.5625 8.53125 6 8 6C7.4375 6 7 5.5625 7 5C7 4.46875 7.4375 4 8 4ZM9.25 12H6.75C6.3125 12 6 11.6875 6 11.25C6 10.8438 6.3125 10.5 6.75 10.5H7.25V8.5H7C6.5625 8.5 6.25 8.1875 6.25 7.75C6.25 7.34375 6.5625 7 7 7H8C8.40625 7 8.75 7.34375 8.75 7.75V10.5H9.25C9.65625 10.5 10 10.8438 10 11.25C10 11.6875 9.65625 12 9.25 12Z"
        fill="white"
      />
    </svg>
  );
};

export const StakingInfo = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 12.2V9M9 5.8H9.008M17 9C17 13.4182 13.4182 17 9 17C4.58172 17 1 13.4182 1 9C1 4.58172 4.58172 1 9 1C13.4182 1 17 4.58172 17 9Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
