import {
  FC,
  memo,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  MouseEvent,
} from "react";
import Picker, { IEmojiData } from "emoji-picker-react";
import { Container } from "./emoji.style";
import { mouseEnter, mouseLeave } from "utils";

const Emoji: FC<{
  setInputValue: Dispatch<SetStateAction<string>>;
}> = ({ setInputValue }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const addEvent = (item: Element) => {
      item.addEventListener("mouseenter", mouseEnter);
      item.addEventListener("mouseleave", mouseLeave);
    };

    const removeEvent = (item: Element) => {
      item.removeEventListener("mouseenter", mouseEnter);
      item.removeEventListener("mouseleave", mouseLeave);
    };

    const list = ref.current.querySelectorAll(".emoji");
    list.forEach(addEvent);
    const categories = ref.current.querySelectorAll(".emoji-categories button");
    categories.forEach(addEvent);
    const tones = ref.current.querySelectorAll(".skin-tones-list li label");
    tones.forEach(addEvent);

    return () => {
      list.forEach(removeEvent);
      categories.forEach(removeEvent);
      tones.forEach(removeEvent);
    };
  }, []);

  return (
    <Container ref={ref}>
      <Picker
        onEmojiClick={(event: MouseEvent, emoji: IEmojiData) => {
          setInputValue((prevState) => prevState + emoji.emoji);
        }}
        native={true}
      />
    </Container>
  );
};

export default memo(Emoji);
