import { forwardRef, memo } from "react";
import Skeleton from "../skeleton";
import { ITextThinProps } from "./textThin.props";
import { Span } from "./textThin.style";

const TextSmallView = forwardRef<HTMLHeadingElement, ITextThinProps>(
  ({ children, loading, skeleton, skeletonLineAlign, ...props }, ref) => {
    const skeArrgs = loading
      ? Array.isArray(skeleton)
        ? skeleton
        : skeleton
        ? [skeleton]
        : [{}]
      : [];

    return (
      <Span
        {...props}
        isSkeletonMultiLine={skeArrgs.length > 1}
        skeletonLineAlign={skeletonLineAlign}
        ref={ref}
      >
        {skeArrgs.length > 0
          ? skeArrgs.map((ske, idx) => (
              <Skeleton
                key={idx}
                style={{
                  margin: ske.margin,
                }}
                {...ske}
              />
            ))
          : children}
      </Span>
    );
  }
);

export default memo(TextSmallView);
