import { FC } from "react";

export const GoToBsc: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.2 5.13373V1.4004M12.2 1.4004H8.46667M12.2 1.4004L6.6 7.00039M5.35556 1.40039H3.98667C2.94123 1.40039 2.41852 1.40039 2.01922 1.60384C1.66798 1.78281 1.38242 2.06837 1.20345 2.41961C1 2.81891 1 3.34162 1 4.38706V9.61372C1 10.6592 1 11.1818 1.20345 11.5812C1.38242 11.9324 1.66798 12.218 2.01922 12.3969C2.41852 12.6004 2.94123 12.6004 3.98667 12.6004H9.21333C10.2588 12.6004 10.7815 12.6004 11.1808 12.3969C11.532 12.218 11.8176 11.9324 11.9965 11.5812C12.2 11.1818 12.2 10.6592 12.2 9.61372V8.24483"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
