import { useAppSelector } from "hooks";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import { API_END_POINT } from "utils/constants";

interface ISocketContext {
  ioInstance: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  setIoInstance: Dispatch<
    SetStateAction<Socket<DefaultEventsMap, DefaultEventsMap> | undefined>
  >;
}

export const SocketContext = createContext<ISocketContext | null>(null);

export const SocketProvider: FC<{ chlidren?: ReactNode }> = ({ children }) => {
  const { info, token } = useAppSelector((state) => state.user);

  const [ioInstance, setIoInstance] =
    useState<Socket<DefaultEventsMap, DefaultEventsMap>>();

  useEffect(() => {
    if (info && token && !ioInstance) {
      const socket = io(`${API_END_POINT}/socket`, {
        auth: {
          token: token || localStorage.getItem("access-token"),
        },
        reconnection: true,
        transports: ["websocket"],
      });

      setIoInstance(socket);
    }
  }, [info, token, ioInstance]);

  return (
    <SocketContext.Provider value={{ ioInstance, setIoInstance }}>
      {children}
    </SocketContext.Provider>
  );
};
