import styled from 'styled-components';
import clsx from 'clsx';

const Icon: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('icon', className)}
        >
            <path
                d="M0.891113 13.9341C0.891113 2.9242 3.25086 0.564453 14.2608 0.564453H18.4125C29.4225 0.564453 31.7822 2.9242 31.7822 13.9341V18.0859C31.7822 29.0958 29.4225 31.4555 18.4125 31.4555H14.2608C3.25086 31.4555 0.891113 29.0958 0.891113 18.0859V13.9341Z"
                fill="#22212C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.5578 9.37927H21.5207V8.39779C21.5207 7.93853 21.1467 7.56445 20.6874 7.56445H20.0207C19.5615 7.56445 19.1874 7.93853 19.1874 8.39779V9.37927H13.5948V8.39779C13.5948 7.93853 13.2207 7.56445 12.7615 7.56445H12.0948C11.6356 7.56445 11.2615 7.93853 11.2615 8.39779V9.37927H9.22445C8.48741 9.37927 7.89111 9.97556 7.89111 10.7126V22.7126C7.89111 23.4496 8.48741 24.0459 9.22445 24.0459H23.5578C24.2948 24.0459 24.8911 23.4496 24.8911 22.7126V10.7126C24.8911 9.97556 24.2948 9.37927 23.5578 9.37927ZM22.8911 21.3793C22.8911 21.7459 22.5911 22.0459 22.2244 22.0459H10.5578C10.1911 22.0459 9.89111 21.7459 9.89111 21.3793V14.0459C9.89111 13.6793 10.1911 13.3793 10.5578 13.3793H22.2244C22.5911 13.3793 22.8911 13.6793 22.8911 14.0459V21.3793Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.2248 16.0488H18.2248C17.8581 16.0488 17.5581 16.3488 17.5581 16.7155V18.7155C17.5581 19.0822 17.8581 19.3822 18.2248 19.3822H20.2248C20.5914 19.3822 20.8914 19.0822 20.8914 18.7155V16.7155C20.8914 16.3488 20.5914 16.0488 20.2248 16.0488Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="test"
                    x1="5.07974"
                    y1="2.12542"
                    x2="27.3318"
                    y2="28.8279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="0.0625rem solid #a1b7f8 " />
                    <stop offset="1" stopColor="#4154B0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

const IconMobile: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('icon', className)}
        >
            <path
                d="M1.55778 14.1607C1.55778 11.4234 1.70532 9.2693 2.08264 7.57149C2.45828 5.88126 3.05274 4.68442 3.91863 3.81853C4.78452 2.95264 5.98136 2.35818 7.67159 1.98254C9.36939 1.60522 11.5235 1.45768 14.2608 1.45768H18.4125C21.1498 1.45768 23.3039 1.60522 25.0017 1.98254C26.692 2.35818 27.8888 2.95264 28.7547 3.81853C29.6206 4.68442 30.215 5.88126 30.5907 7.57149C30.968 9.2693 31.1155 11.4234 31.1155 14.1607V18.3124C31.1155 21.0497 30.968 23.2038 30.5907 24.9016C30.215 26.5919 29.6206 27.7887 28.7547 28.6546C27.8888 29.5205 26.692 30.1149 25.0017 30.4906C23.3039 30.8679 21.1498 31.0154 18.4125 31.0154H14.2608C11.5235 31.0154 9.36939 30.8679 7.67159 30.4906C5.98136 30.1149 4.78452 29.5205 3.91863 28.6546C3.05274 27.7887 2.45828 26.5919 2.08264 24.9016C1.70532 23.2038 1.55778 21.0497 1.55778 18.3124V14.1607Z"
                stroke="#22212C"
                strokeWidth="1.33333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.5578 9.60583H21.5207V8.62435C21.5207 8.16509 21.1467 7.79102 20.6874 7.79102H20.0207C19.5615 7.79102 19.1874 8.16509 19.1874 8.62435V9.60583H13.5948V8.62435C13.5948 8.16509 13.2207 7.79102 12.7615 7.79102H12.0948C11.6356 7.79102 11.2615 8.16509 11.2615 8.62435V9.60583H9.22445C8.48741 9.60583 7.89111 10.2021 7.89111 10.9392V22.9392C7.89111 23.6762 8.48741 24.2725 9.22445 24.2725H23.5578C24.2948 24.2725 24.8911 23.6762 24.8911 22.9392V10.9392C24.8911 10.2021 24.2948 9.60583 23.5578 9.60583ZM22.8911 21.6058C22.8911 21.9725 22.5911 22.2725 22.2244 22.2725H10.5578C10.1911 22.2725 9.89111 21.9725 9.89111 21.6058V14.2725C9.89111 13.9058 10.1911 13.6058 10.5578 13.6058H22.2244C22.5911 13.6058 22.8911 13.9058 22.8911 14.2725V21.6058Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.2244 16.2734H18.2244C17.8577 16.2734 17.5577 16.5734 17.5577 16.9401V18.9401C17.5577 19.3068 17.8577 19.6068 18.2244 19.6068H20.2244C20.5911 19.6068 20.8911 19.3068 20.8911 18.9401V16.9401C20.8911 16.5734 20.5911 16.2734 20.2244 16.2734Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="test2"
                    x1="5.07974"
                    y1="2.12542"
                    x2="27.3318"
                    y2="28.8279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="0.0625rem solid #a1b7f8 " />
                    <stop offset="1" stopColor="#4154B0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const Events = styled(Icon)`
    & {
        transition: opacity 0.3s;
    }
`;

export const EventsMobile = styled(IconMobile)`
    & {
        transition: opacity 0.3s;
    }
`;
