import { Buy, Cancel, Request, Sell } from 'components/assets';
import { FlowerCheck } from 'components/assets/check.icon';
import { Image } from 'components/base';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector, useWallet } from 'hooks';
import { FC, memo, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { translateDynamicData } from 'redux/apis';
import { toggleFullLoading } from 'redux/store/slices';
import { IMarketplaceMetadata } from 'type/nft';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { NFTItemProps, NewNFTItemProps } from './newNFTItem.props';
import {
    CardWrapper,
    GlowWrapper,
    Description,
    OnwerInfo,
    UserName,
    Info,
    PriceAndActions,
    NFTButton,
    Card,
    BadgeRole,
    BadgeNFTType,
    AmountTag,
    TimeBuy,
} from './newNFTItem.style';

import { Skeleton as SkeletonBase } from 'components/base';
import { NftPrice } from 'components/modules';
import { CountDown } from './countdown';
import { getImageUrl } from 'utils';
import { useWeb3React } from '@web3-react/core';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
    relativeTime: {
        ...dayjs.Ls.en.relativeTime,
        s: 'a second',
        ss: '%d seconds',
    },
});

export const NFTItemCollection: FC<NFTItemProps> = ({ marketItem, isSale }) => {
    const { account } = useWeb3React();
    isSale = marketItem.status === 0;
    const wallet = useWallet();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const { usersByWallets } = useAppSelector((state) => state.nftItems);
    const tokenMetadata = JSON.parse(marketItem?.metadata || ('{}' as string));

    const [localLoading, setLocalLoading] = useState(false);

    const [translatedNftName, setTranslatedNftName] = useState('');

    const getNftOwner = marketItem?.owner_of?.toLowerCase();

    const ownerFromDb = usersByWallets.find((item) => getNftOwner === item?.walletAddress);
    const tempOwner = ownerFromDb ? ownerFromDb?.username || ownerFromDb?.walletAddress : getNftOwner;

    const hasDoingAction = marketItem?.transaction_state !== undefined;
    const isOwner =
        marketItem?.seller !== null
            ? marketItem?.seller.toLowerCase() === account?.toLowerCase()
            : marketItem?.owner.toLowerCase() === account?.toLowerCase();

    const getCurrent = () => {
        if (isSale) {
            if (isOwner) {
                return 'CANCEL';
            } else {
                return 'BUY';
            }
        } else if ((marketItem?.startTime as number) >= dayjs().unix()) {
            if (isOwner) {
                return 'CANCEL';
            }
        } else return 'REQUEST';
    };

    let [buttonType, setButtonType] = useState(getCurrent());
    const [issSale, setIssSale] = useState(isSale);

    const s3Image = tokenMetadata?.properties?.thumbnail;

    useEffect(() => {
        if (issSale) {
            if (isOwner) {
                setButtonType('CANCEL');
            } else {
                setButtonType('BUY');
            }
        } else {
            if ((marketItem?.startTime as number) >= dayjs().unix()) {
                setButtonType('COUNTDOWN');
            } else if (isOwner) {
                setButtonType('SELL');
            } else setButtonType('REQUEST');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketItem, isOwner]);

    const mouseEnter = () => {
        if (hasDoingAction) {
            return;
        }

        document.body.classList.add('hovering');
    };

    const mouseLeave = () => {
        if (hasDoingAction) {
            return;
        }

        document.body.classList.remove('hovering');
    };

    const handleClountdownPendingFinish = () => {
        setIssSale(true);
        if (isOwner) {
            setButtonType('CANCEL');
        } else setButtonType('BUY');
    };

    let buttonContent = <></>;

    switch (buttonType) {
        case 'BUY':
            buttonContent = (
                <>
                    <Buy />
                    <span>{t('common.btnBuy')}</span>
                </>
            );
            break;
        case 'COUNTDOWN':
            buttonContent = (
                <CountDown
                    timestamp={marketItem ? marketItem?.startTime ?? 0 : 0}
                    text="Start"
                    onFinish={handleClountdownPendingFinish}
                />
            );
            break;
        case 'CANCEL':
            buttonContent = (
                <>
                    <Cancel />
                    <span>{t('common.btnCancel')}</span>
                </>
            );
            break;
        case 'REQUEST':
            buttonContent = (
                <>
                    <Request />
                    {/* <span>{t("common.btnRequest")}</span> */}
                    <span>Make Offer</span>
                </>
            );
            break;
        case 'SELL':
            buttonContent = (
                <>
                    <Sell />
                    <span>{t('common.btnSell')}</span>
                </>
            );
            break;
        default:
            buttonContent = <> </>;
            break;
    }

    // const handleCardClick = () => {
    //     if (hasDoingAction) {
    //         return;
    //     }

    //     var mainContainer = document.getElementById('main-content');
    //     if (mainContainer) {
    //         mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
    //     }
    //     if (issSale || buttonType === 'BUY' || buttonType === 'COUNTDOWN') {
    //         navigate(`/marketplace/${marketItem?.marketId}`);
    //     } else {
    //         navigate(`/nft-detail/${marketItem?.collectionAddress}-${marketItem?.tokenId}`);
    //     }

    //     mouseLeave();
    // };

    const handleCardClick = () => {
        if (hasDoingAction) {
            return;
        }

        var mainContainer = document.getElementById('main-content');
        if (mainContainer) {
            mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (marketItem?.collectionType === 1155 && marketItem.status === 0) {
            navigate(`/marketplace/${marketItem?.marketId}`);
        } else {
            navigate(`/nft-detail/${marketItem?.nftId}`);
        }

        mouseLeave();
    };

    const handleOwnerClick = (e: MouseEvent) => {
        e.stopPropagation();
        navigate(`/creators/${marketItem?.owner_of}`);
        mouseLeave();
    };
    const handleButtonClick = async (e: MouseEvent) => {
        if (hasDoingAction) {
            return;
        }

        try {
            dispatch(toggleFullLoading(true));
            e.stopPropagation();
            mouseLeave();
            if (issSale || buttonType === 'BUY' || buttonType === 'COUNTDOWN') {
                navigate(`/marketplace/${marketItem?.marketId}`, {});
            } else {
                navigate(`/nft-detail/${marketItem?.nftId}`, {});
            }

            dispatch(toggleFullLoading(false));
        } catch (error) {}
    };

    // const DataJson = JSON.parse(marketItem?.metadata || ('{}' as string));

    const showImage = () => {
        return (
            <Image
                imgKey={marketItem?.id}
                id={marketItem?.id}
                src={
                    (tokenMetadata && tokenMetadata?.properties?.thumbnail?.url) ||
                    (tokenMetadata?.image?.replace('ipfs://', 'https://ipfs.io/ipfs/') as string) ||
                    ''
                }
            />
        );
    };

    return (
        <CardWrapper
            onClick={handleCardClick}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            id="child-component"
            hasAction={hasDoingAction}
        >
            <GlowWrapper></GlowWrapper>
            <Card cover={showImage()} hasAction={hasDoingAction}>
                <Description>
                    <OnwerInfo onClick={handleOwnerClick}>
                        <>
                            {' '}
                            <FlowerCheck />
                            <UserName>{tempOwner}</UserName>
                            <BadgeRole>{tokenMetadata?.name}</BadgeRole>{' '}
                        </>
                    </OnwerInfo>
                    <Info loading={localLoading} skeleton={{ width: '7.25rem', height: '0.5625rem' }}>
                        <span>{translatedNftName}</span>
                        {<AmountTag>{marketItem?.amount}</AmountTag>}
                    </Info>
                </Description>
                <PriceAndActions>
                    <NftPrice
                        sale={issSale}
                        coinLoading={localLoading}
                        priceFormatted={marketItem?.price / 10 ** 18 ?? 0}
                        token={marketItem?.paymentToken}
                    />
                    <BadgeNFTType>{marketItem?.collectionType}</BadgeNFTType>
                </PriceAndActions>

                <NFTButton hoverable={false} disabled={hasDoingAction} onClick={handleButtonClick}>
                    {marketItem?.transaction_state || buttonContent}
                </NFTButton>
            </Card>
        </CardWrapper>
    );
};

const NewNFTItem: React.FC<NewNFTItemProps> = ({ marketItem, ...rest }) => {
    // status = 0 && (startTime > now || endTime = 0)
    // guest: request to buy, owner: sell item (and cancel sell)

    // status = 0 && startTime <= now && now < endTime
    // guest: buy, owner: cancel sell

    // status = 0 && endTime > 0 && endTime <= now
    // guest: request to buy, owner: sell item (and cancel sell)

    // status = 1
    // guest: request to buy, owner: sell item

    const getSaleNft = (item?: IMarketplaceMetadata) => {
        const now = dayjs().unix();

        if (item) {
            switch (item.status) {
                case 0:
                    if ((item.startTime as number) > now || item.endTime === 0) {
                        return false;
                    }

                    if ((item.startTime as number) <= now && now <= (item.endTime as number)) {
                        return true;
                    }

                    if ((item.endTime as number) > 0 && (item.endTime as number) <= now) {
                        return false;
                    }

                    return false;

                case 1:
                    return false;

                default:
                    return false;
            }
        }

        return false;
    };

    const isSale = getSaleNft(marketItem);

    return <NFTItemCollection marketItem={marketItem} isSale={isSale} {...rest} />;
};

export default memo(NewNFTItem);
