import styled from "styled-components";
import { SkeletonLineAlignType } from "type/baseComponent";

export const H1 = styled.h1<{
  isSkeletonMultiLine?: boolean;
  skeletonLineAlign?: SkeletonLineAlignType;
}>`
  color: ${({ theme }) => theme.title.color};
  font-size: 2.8125rem;
  line-height: 1;
  font-weight: 400;

  ${({ isSkeletonMultiLine, skeletonLineAlign }) => {
    let alignItems = "center";
    if (skeletonLineAlign === "LEFT") alignItems = "start";
    else if (skeletonLineAlign === "RIGHT") alignItems = "end";

    return !isSkeletonMultiLine
      ? ""
      : "display: flex; flex-direction: column; align-items: " +
          alignItems +
          ";";
  }}
`;

export const H2 = styled.h2<{
  isSkeletonMultiLine?: boolean;
  skeletonLineAlign?: SkeletonLineAlignType;
}>`
  color: ${({ theme }) => theme.title.color};
  font-size: 2.1875rem;
  line-height: 1.3;
  font-weight: 400;

  ${({ isSkeletonMultiLine, skeletonLineAlign }) => {
    let alignItems = "center";
    if (skeletonLineAlign === "LEFT") alignItems = "start";
    else if (skeletonLineAlign === "RIGHT") alignItems = "end";

    return !isSkeletonMultiLine
      ? ""
      : "display: flex; flex-direction: column; align-items: " +
          alignItems +
          ";";
  }}
`;

export const H3 = styled.h3<{
  isSkeletonMultiLine?: boolean;
  skeletonLineAlign?: SkeletonLineAlignType;
}>`
  color: ${({ theme }) => theme.title.color};
  font-size: 1.5625rem;
  line-height: 1.3;
  font-weight: 400;

  ${({ isSkeletonMultiLine, skeletonLineAlign }) => {
    let alignItems = "center";
    if (skeletonLineAlign === "LEFT") alignItems = "start";
    else if (skeletonLineAlign === "RIGHT") alignItems = "end";

    return !isSkeletonMultiLine
      ? ""
      : "display: flex; flex-direction: column; align-items: " +
          alignItems +
          ";";
  }}
`;

export const H4 = styled.h4<{
  isSkeletonMultiLine?: boolean;
  skeletonLineAlign?: SkeletonLineAlignType;
}>`
  color: ${({ theme }) => theme.title.color};
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 400;

  ${({ isSkeletonMultiLine, skeletonLineAlign }) => {
    let alignItems = "center";
    if (skeletonLineAlign === "LEFT") alignItems = "start";
    else if (skeletonLineAlign === "RIGHT") alignItems = "end";

    return !isSkeletonMultiLine
      ? ""
      : "display: flex; flex-direction: column; align-items: " +
          alignItems +
          ";";
  }}
`;

export const H5 = styled.h5<{
  isSkeletonMultiLine?: boolean;
  skeletonLineAlign?: SkeletonLineAlignType;
}>`
  color: ${({ theme }) => theme.title.color};
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 400;

  ${({ isSkeletonMultiLine, skeletonLineAlign }) => {
    let alignItems = "center";
    if (skeletonLineAlign === "LEFT") alignItems = "start";
    else if (skeletonLineAlign === "RIGHT") alignItems = "end";

    return !isSkeletonMultiLine
      ? ""
      : "display: flex; flex-direction: column; align-items: " +
          alignItems +
          ";";
  }}
`;

export const H6 = styled.h6<{
  isSkeletonMultiLine?: boolean;
  skeletonLineAlign?: SkeletonLineAlignType;
}>`
  color: ${({ theme }) => theme.title.color};
  font-size: 0.75rem;
  line-height: 1.3;
  font-weight: 400;

  ${({ isSkeletonMultiLine, skeletonLineAlign }) => {
    let alignItems = "center";
    if (skeletonLineAlign === "LEFT") alignItems = "start";
    else if (skeletonLineAlign === "RIGHT") alignItems = "end";

    return !isSkeletonMultiLine
      ? ""
      : "display: flex; flex-direction: column; align-items: " +
          alignItems +
          ";";
  }}
`;
