import { Form as AForm } from 'antd';
import BaseFormItem from 'components/base/baseComponents/formItem';
import Input from 'components/base/baseComponents/input';
import InputPassword from 'components/base/baseComponents/inputPassword';
import PinkButton from 'components/base/button/rounded';
import CButtonOutline from 'components/base/buttonOutline';
import CTextThin from 'components/base/textThin';
import CTitle from 'components/base/title';
import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: 37.5rem;
    margin: 0 auto;
    background: #fefefe;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        border-radius: 6px;
        padding: 2.13rem 1.4rem;
    }
`;

export const ForgotText = styled.button`
    color: #fff;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 1.0625rem auto;
    display: block;
    background: none;
    border: none;
    outline: none;
`;

export const NoAccountText = styled.button`
    color: #fff;
    font-size: 1rem;
    line-height: 0.875rem;
    font-weight: 400;
    margin: 1.69rem auto 0;
    display: block;
    background: none;
    border: none;
    outline: none;
    color: rgba(256, 256, 256, 0.7);

    span {
        margin-left: 0.4rem;
        display: inline-flex;
        align-items: center;
        svg {
            margin-top: 0.5rem;
            margin-left: 0.2rem;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin: 2.6875rem auto 0;
    }
`;

export const FormItem = styled(BaseFormItem)``;

export const FormInput = styled(Input)``;

export const FormPasswordInput = styled(InputPassword)`
    .ant-input-suffix {
        display: none;
    }
`;

export const Line = styled.div`
    border-bottom: 0.0625rem solid #201f2a;
`;

export const ModalTitle = styled(CTitle)`
    font-size: 1.625rem;
    line-height: 1;
    text-align: center;
    margin-bottom: 0.8125rem;
`;

export const ModalDescription = styled(CTextThin)`
    max-width: 22.25rem;
    width: 100%;
    margin: 0 auto 1.69rem;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    display: block;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin: 0 auto 2.6875rem;
    }
`;

export const ButtonOutline = styled(CButtonOutline)`
    font-size: 1rem;
    line-height: 1.175rem;
    width: 100%;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
`;

export const ButtonWrapper = styled.div<{ vertical?: boolean }>`
    margin-bottom: 0.8125rem;

    ${({ vertical }) =>
        vertical !== true &&
        `
    display: flex;
    gap: 0.5rem;
  `}/* justify-content: space-between; */
`;

export const MiniText = styled.div`
    font-size: ${({ theme }) => theme.typography.textSmall};
    line-height: ${({ theme }) => theme.typography.textSmall};
    color: #ffffffa0 !important;
`;

export const PinkButtonOutline = styled(ButtonOutline)`
    border: 0.0625rem solid #a1b7f8;
    background: 0.0625rem solid #a1b7f8;
    color: #fff;
`;
export const ButtonWrapperOutline = styled(PinkButton)`
    flex-direction: column;
    align-items: flex-start;
    height: 3.2rem;
    border-radius: 0.375rem;
    text-align: left;
`;

export const PinkButtonOutlineAlone = styled(PinkButtonOutline)`
    margin-top: 0;
`;

export const WhiteButtonOutline = styled(ButtonOutline)`
    border: 0.0625rem solid #d0d5dd;
    background: #ffffff;
    color: #fefefe;
`;

export const ModalForm = styled(AForm)`
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
    }

    .ant-form-item-explain-success {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: #52c41a;
    }

    .ant-form-item-explain-error,
    .ant-form-item-explain-validating {
        /* padding-top: 0.625rem; */
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
    }
    label {
        color: white;
        font-weight: 500;
        padding-bottom: 0;
    }
`;

export const GradientWrapper = styled.div`
    @media screen and (max-width: 48rem) {
        background: linear-gradient(180deg, 0.0625rem solid #a1b7f8 0%, #ffdba8 100%);
        box-shadow: 0px 1.5rem 2.75rem 1.25rem rgb(255 244 244 / 6%);
        border-radius: 0.375rem;
        padding: 0.0625rem;
        max-width: 37.6rem;
        margin: 0 auto;
        position: relative;
    }
`;

export const HelpTooltipWrapper = styled.div`
    ${Line} {
        margin-bottom: 1.0625rem;
    }
`;

export const CloseModalBtn = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 1.125rem;
        height: 1.125rem;

        path {
            stroke: #fff;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
    }
`;
