import { FC } from "react";

export const Coinbase: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M34.5 14.7152C34.5 2.59723 31.9028 0 19.7848 0H15.2152C3.09723 0 0.5 2.59723 0.5 14.7152V19.2848C0.5 31.4028 3.09723 34 15.2152 34H19.7848C31.9028 34 34.5 31.4028 34.5 19.2848V14.7152Z"
        fill="#0052FF"
      />
      <path
        d="M17.5 3C24.6805 3 30.5 8.81953 30.5 16C30.5 23.1805 24.6805 29 17.5 29C10.3195 29 4.5 23.1805 4.5 16C4.5 8.81953 10.3195 3 17.5 3Z"
        fill="#0052FF"
      />
      <path
        d="M17.5025 20.5703C14.9787 20.5703 12.9322 18.5264 12.9322 16C12.9322 13.4736 14.9787 11.4297 17.5025 11.4297C19.7648 11.4297 21.6438 13.0801 22.0043 15.2383H26.6076C26.2191 10.5461 22.2912 6.85938 17.5 6.85938C12.4523 6.85938 8.35938 10.9523 8.35938 16C8.35938 21.0477 12.4523 25.1406 17.5 25.1406C22.2912 25.1406 26.2191 21.4539 26.6076 16.7617H22.0018C21.6387 18.9199 19.7648 20.5703 17.5025 20.5703Z"
        fill="white"
      />
    </svg>
  );
};
