import { FC, memo } from "react";
import { Container, Body, Cover, BodyPrice, Price } from "./NFTSkeleton.style";
import Skeleton from "components/base/skeleton";

const NFTSkeleton: FC<{ cardWidth?: string }> = ({ cardWidth }) => {
  return (
    <Container id="child-component">
      <Cover>
        <Skeleton
          width={cardWidth || "100%"}
          height="100%"
          borderRadius="0.375rem"
        />
      </Cover>
      <Body>
        <Skeleton width="100%" borderRadius="0.375rem" height="1.5rem" />
        <Skeleton width="100%" borderRadius="0.375rem" height="1.5rem" />
        <BodyPrice>
          <Price>
            <Skeleton width="100%" borderRadius="0.375rem" height="1.5rem" />
          </Price>
          <Price>
            <Skeleton width="100%" borderRadius="0.375rem" height="1.5rem" />
          </Price>
        </BodyPrice>
        <Skeleton width="100%" borderRadius="0.375rem" height="2.1875rem" />
      </Body>
    </Container>
  );
};

export default memo(NFTSkeleton);
