import styled from 'styled-components';
import { Card as CCard } from 'antd';
import { Text as CText, ButtonOutline as CButtonOutline, Tag } from 'components/base';

export const GlowWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(0.4375rem);
    background: linear-gradient(0deg, #dd7ac5 0%, #3759a5 50%, #a03984 100%);
    border-radius: 0.375rem;
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
        position: absolute;
        content: '';
        width: 50%;
        height: 50%;
        background: #a03984;
        left: 0.1875rem;
        top: 50%;
        transform: translateY(-50%);
        filter: blur(0.375rem);
    }
`;

export const CardWrapper = styled.div<{ hasAction: boolean }>`
    position: relative;
    height: 100%;
    min-width: 0;
    flex: 0 0 13.975rem;

    ${({ hasAction }) =>
        hasAction
            ? ``
            : `
    &:hover {
      ${GlowWrapper} {
        opacity: 0.5;
      }
    }
  `}

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        flex: 0 0 12rem;
    }
    // &:first-child {
    //   margin-left: -1.1rem;
    // }
`;

export const Card = styled(({ hasAction, children, ...rest }) => <CCard {...rest}>{children}</CCard>)`
    position: relative;
    width: 100%;
    border: 0.0625rem solid ${({ theme }) => theme.nftItem.card.border};
    border-radius: 0.375rem;
    background-color: ${({ theme }) => theme.nftItem.card.background};
    cursor: ${({ hasAction }) =>
        hasAction ? `url(/assets/images/cursor.svg), pointer` : `url(/assets/images/cursor-white.svg), pointer`};
    height: 100%;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 10;

    & > .ant-card-body {
        flex-grow: 1;
        padding: 0.625rem;
        display: flex;
        flex-direction: column;
    }

    .ant-card-cover {
        max-height: 14.375rem;
        // height: 100%;

        & > span {
            height: 100%;
            aspect-ratio: 1 / 1;
        }

        .ant-image {
            height: 100%;
        }

        .ant-image img {
            object-fit: cover;
            height: 100%;
            aspect-ratio: 1 / 1;
            // max-height: 14.375rem;
        }

        svg {
            height: 100%;
            // max-height: 14.375rem;
        }
    }

    // &:hover {
    //   & > .ant-card-cover {
    //     & > .ant-image {
    //       img {
    //         transform: scale(1.2);
    //       }
    //     }
    //   }
    // }
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.125rem;
    margin-top: 0;
    flex-grow: 1;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-bottom: 0.35rem;
    }
`;

export const Info = styled(CText)`
    display: block;
    color: ${({ theme }) => theme.nftItem.titleColor};
    font-size: 1rem;
    line-height: 1.3;
    display: flex;
    align-items: center;
    gap: 0.375rem;
    flex-wrap: wrap;
    justify-content: space-between;

    & > span {
        // word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const PriceAndActions = styled.div`
    display: grid;
    align-items: center;
    justify-content: unset;
    margin-bottom: 0.625rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        display: flex;
        justify-content: space-between;
    }

    .ant-row {
        align-items: end;
        width: 100%;
    }
`;

export const AmountTag = styled(Tag)`
    background: ${({ theme }) => theme.colors.border};

    span {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const BadgeNFTType = styled(AmountTag)`
    margin-top: 0.625rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-top: 0;
    }
`;

export const NFTButton = styled(CButtonOutline)`
    width: 100%;
    padding: 0.5rem;
    height: 38px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    svg {
        flex-shrink: 0;
    }

    span {
        margin-left: 0.25rem;
        font-size: 0.6875rem;
        line-height: 1;
        font-weight: 400;
    }

    @media screen and (min-width: 23.4375rem) {
        width: 100%;
    }

    @media screen and (min-width: 28.75rem) {
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        span {
            font-size: 1rem;
            line-height: 1;
        }
    }
`;

export const UserName = styled(CText)`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1rem;
    color: #4d61ca;
    max-width: calc(100% - 1.875rem);
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const OnwerInfo = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.3375rem;
    // flex-grow: 1;

    & > svg {
        position: relative;
        /* top: 0.0625rem; */
    }
`;

export const BadgeRole = styled.div`
    background: ${({ theme }) => theme.colors.border};
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TimeBuy = styled.div`
    margin: 0.625rem 0;
    /* text-align: left; */
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-top: 0;
    }
`;
