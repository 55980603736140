import { Tabs } from 'antd';
import { ButtonOutline } from 'components/base';
import { forwardRef } from 'react';
import styled from 'styled-components';

export const GifButton = styled(
    forwardRef<any, any>(({ children, ...props }, ref) => {
        return <ButtonOutline {...props}>{children}</ButtonOutline>;
    }),
)`
    border: none;
    padding: 0;
    svg path {
        opacity: 1;
    }
    &:hover,
    &:active,
    &:focus {
        border: none !important;
        background: transparent !important;
        path {
            fill: ${({ theme }) => theme.chat.searchButton.stroke} !important;
        }
    }
`;

export const GifPickerContainer = styled.div`
    padding: 0.0625rem;
    height: 26.875rem;
    width: 18.875rem;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 26.875rem;
    }
`;

export const GifPickerContent = styled.div`
    background: ${({ theme }) => theme.container.background};
    height: 100%;
    border: 0.0625rem solid rgb(59, 65, 103);
    border-radius: 0.375rem;
    .ant-input {
        background-color: ${({ theme }) => theme.section.background};
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
        color: ${({ theme }) => theme.text.color};
        &:hover,
        &:focus,
        &:active {
            box-shadow: none;
        }
    }
    .ant-input:placeholder-shown {
        text-overflow: ellipsis;
        font-size: 0.875rem;
        line-height: 1.625rem;
        color: ${({ theme }) => theme.text.color};
        opacity: 0.5;
    }
`;

export const GifWrapper = styled.div`
    padding: 0 0.8125rem;
    height: 100%;
    input {
    }
    .ant-input-affix-wrapper {
        background: #fefefe;
        border: 0.0625rem solid rgba(255, 255, 255, 0.1);
        border-radius: 0.3125rem;
        margin-top: 0.4625rem;
        margin-bottom: 0.75rem;
    }
    .ant-input-prefix {
        svg {
            width: 11px;
            height: 11px;
            path {
                fill: #fefefe;
            }
        }
    }
`;

export const GifTabs = styled(Tabs)`
    height: calc(100% - 3.7875rem);

    .ant-tabs-tab,
    .ant-tabs-nav-more {
        cursor: url(/assets/images/cursor-white.svg), pointer;
    }

    .ant-tabs-content-holder {
        overflow: auto;
    }
    .ant-tabs-ink-bar {
        background: ${({ theme }) => theme.profile.tab.background} !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.text.color};
    }
    .ant-tabs-ink-bar,
    .ant-tabs-nav::before {
        height: 0.125rem;
        background: ${({ theme }) => theme.header.searchResult.hover};
    }
    svg path {
        fill: ${({ theme }) => theme.title.color};
    }
`;
