import React from 'react';
import { IButtonOutlineProps } from './buttonOutline.props';
import { Container } from './buttonOutline.style';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { Spin } from 'antd';
import { mouseEnter, mouseLeave } from 'utils/mouseEvent';

const ButtonOutlineView: React.FC<IButtonOutlineProps> = ({
    children,
    disabled,
    onClick,
    fillSvg = true,
    loading,
    hoverable = true,
    ...props
}: IButtonOutlineProps) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (loading) return;

        onClick?.(e);
        mouseLeave();
    };

    const extendHoverable = hoverable
        ? {
              onMouseEnter: mouseEnter,
              onMouseLeave: mouseLeave,
          }
        : {};

    return (
        <Container fillSvg={fillSvg} disabled={disabled} onClick={handleClick} {...extendHoverable} {...props}>
            {loading ? <Spin indicator={<LoadingOutlined />} /> : children}
        </Container>
    );
};

export default React.memo(ButtonOutlineView);
