import styled from 'styled-components';
import CTitle from 'components/base/title';
import CText from 'components/base/text';

export const Title = styled(CTitle)`
    &.ant-typography {
        margin-bottom: 0.5rem;
        margin-top: 1.25rem;
        font-weight: 700;
        color: ${({ theme }) => theme.connectWallet.title};
    }
`;

export const Text = styled(CText)`
    font-size: 0.625rem;
    letter-spacing: 0.125rem;
    color: ${({ theme }) => theme.connectWallet.text};
`;

export const Icon = styled.div`
    opacity: 0.6;
    svg {
        width: 2.0625rem;
        height: 1.875rem;
    }
`;

export const Mask = styled.div`
    position: absolute;
    inset: -0.375rem;

    svg {
        width: 100%;
        height: 110%;
    }

    .ant-image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const Content = styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

export const Button = styled.button<{
    w?: string;
    h?: string;
}>`
    display: block;
    width: 100%;
    max-width: ${({ w }) => w};
    height: ${({ h }) => h};
    border-radius: 0.375rem;
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, #fefefe 44.79%, #0a1227 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    cursor: url(/assets/images/cursor-white.svg), pointer;

    ${Mask} {
        transform: scale(1);
        transition: transform 0.5s;
    }

    &:hover {
        ${Mask} {
            transform: scale(2);
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    // &:hover {
    //   ${Mask} {
    //     svg {
    //       animation: gerak 1s;
    //       animation-fill-mode: forwards;
    //     }

    //     @keyframes gerak {
    //       from {
    //         transform: scale();
    //       }
    //       to {
    //         transform: scale(2);
    //       }
    //     }
    //   }
    // }
`;
