import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  FormInput,
  FormItem,
  FormPasswordInput,
  ModalForm,
  PinkButtonOutlineAlone,
} from "../auth.style";
import { Form as AForm } from "antd";
import { useNavigate } from "react-router-dom";
import { validateEmail, validateUserName } from "redux/apis";
// import { debounce } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import { SignupFormProps } from "./signup.props";
import { setInfo } from "redux/store/slices";
// interface VALIDATE_FIELD {
//   validateStatus: "error" | "success" | "validating";
//   help: string;
// }

export const SignUpBrand: FC<SignupFormProps> = ({ onFinishFailed }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "signUp" });
  const { info } = useAppSelector((state) => state.user);
  const [form] = AForm.useForm();
  // const [validateUser, setValidateUser] = useState<{
  //   name: VALIDATE_FIELD | {};
  //   email: VALIDATE_FIELD | {};
  // }>({
  //   name: {},
  //   email: {},
  // });
  const navigate = useNavigate();

  const handleFinish = async (values: any) => {
    const formattedEmail = values.email.toLowerCase().trim();
    dispatch(
      setInfo({
        ...info,
        brandTemporary: {
          username: values.username,
          password: values.password,
          email: formattedEmail,
          role: "brand",
        },
      })
    );
    navigate("/");
  };

  const checkValidateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // const fetchUsername = (username: string) => {
  //   validateUserName({
  //     username,
  //   }).then((res) => {
  //     const error = res.data.code === 400;

  //     setValidateUser((prev) => ({
  //       ...prev,
  //       name: {
  //         validateStatus: error ? "error" : "success",
  //         help: username
  //           ? `${username} ${t(
  //               error ? "form.userName.error.1" : "form.userName.error.2"
  //             )}`
  //           : t("form.userName.error.0"),
  //       },
  //     }));
  //     if (!error) return;
  //   });
  // };

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceFetchUsername = useCallback(
  //   debounce((username) => fetchUsername(username), 400),
  //   []
  // );

  // const handleCheckUserName = async (_, value) => {
  //   setValidateUser((prev) => ({
  //     ...prev,
  //     name: {
  //       validateStatus: "validating",
  //       help: "Validating...",
  //     },
  //   }));
  //   if (!value) {
  //     setValidateUser((prev) => ({
  //       ...prev,
  //       name: {
  //         validateStatus: "error",
  //         help: t("form.userName.error.0"),
  //       },
  //     }));
  //     return;
  //   }

  //   debounceFetchUsername(value);
  // };

  // const fetchEmail = (email: string, inValidEmail: boolean) => {
  //   validateEmail({
  //     email,
  //   }).then((res) => {
  //     const error = res.data.code === 400;

  //     setValidateUser((prev) => ({
  //       ...prev,
  //       email: {
  //         validateStatus: error || inValidEmail ? "error" : "success",
  //         help: email
  //           ? inValidEmail
  //             ? t("form.email.invalid")
  //             : `${email} ${t(
  //                 error ? "form.userName.error.1" : "form.userName.error.2"
  //               )}`
  //           : t("form.email.error"),
  //       },
  //     }));
  //     if (!error) return;
  //   });
  // };

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceFetchEmail = useCallback(
  //   debounce(
  //     (email: string, inValidEmail: boolean) => fetchEmail(email, inValidEmail),
  //     400
  //   ),
  //   []
  // );

  // const handleCheckEmail = async (_, email) => {
  //   const value = email?.toLowerCase();
  //   const inValidEmail = !checkValidateEmail(value);

  //   setValidateUser((prev) => ({
  //     ...prev,
  //     email: {
  //       validateStatus: "validating",
  //       help: "Validating...",
  //     },
  //   }));

  //   if (!value) {
  //     setValidateUser((prev) => ({
  //       ...prev,
  //       email: {
  //         validateStatus: "error",
  //         help: t("form.email.invalid"),
  //       },
  //     }));
  //   }
  //   debounceFetchEmail(value, inValidEmail);
  // };

  const onValuesChange = (valuesChange: any) => {
    const emailChange = Object.keys(valuesChange).includes("email");
    const nameChange = Object.keys(valuesChange).includes("username");
    const { email, username } = valuesChange;
    if (emailChange && email && checkValidateEmail(email)) {
      form.setFields([
        {
          name: "email",
          errors: ["validating"],
        },
      ]);
      validateEmail({
        email,
      }).then((res) => {
        const error = res.data.code === 400;
        if (error) {
          form.setFields([
            {
              name: "email",
              errors: [`${email} ${t("form.userName.error.1")}`],
            },
          ]);
        }
        if (!error) return;
      });
    }
    if (username && nameChange) {
      validateUserName({
        username,
      }).then((res) => {
        const error = res.data.code === 400;
        if (error) {
          form.setFields([
            {
              name: "username",
              errors: [`${username} ${t("form.userName.error.1")}`],
            },
          ]);
        }
        if (!error) return;
      });
    }
  };

  return (
    <>
      <ModalForm
        form={form}
        onFinish={handleFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <FormItem
          name="email"
          labelCol={{ span: 24 }}
          label="Manager Email"
          rules={[
            {
              required: true,
              message: t("form.email.error"),
            },
            {
              type: "email",
              message: t("form.email.invalid"),
            },
          ]}
        >
          <FormInput placeholder="Manager Email" />
        </FormItem>
        <FormItem
          name="username"
          labelCol={{ span: 24 }}
          label="Manager Name"
          rules={[
            {
              required: true,
              message: t("form.userName.error.0"),
            },
          ]}
        >
          <FormInput placeholder="Manager Name" />
        </FormItem>
        <FormItem
          name="password"
          labelCol={{ span: 24 }}
          label={t("form.password.label")}
          validateTrigger={["onBlur"]}
          rules={[
            { required: true, message: t("form.password.error.0") },
            { min: 8, message: t("form.password.error.1") },
          ]}
        >
          <FormPasswordInput
            placeholder={t("form.password.label")}
            visibilityToggle={false}
          />
        </FormItem>
        <FormItem
          name="confirmPassword"
          labelCol={{ span: 24 }}
          dependencies={["password"]}
          validateTrigger={["onBlur"]}
          hasFeedback
          label="Confirm Password"
          rules={[
            { required: true, message: t("form.password.error.0") },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("form.password.error.2")));
              },
            }),
          ]}
        >
          <FormPasswordInput
            placeholder="Confirm Password"
            visibilityToggle={false}
          />
        </FormItem>
        <PinkButtonOutlineAlone>{t("form.continue")}</PinkButtonOutlineAlone>
      </ModalForm>
    </>
  );
};
