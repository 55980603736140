import styled from "styled-components";
import CTitle from "components/base/title";

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  flex-wrap: wrap;
`;

export const SocialItem = styled.div`
  padding: 0.875rem 1.375rem;
  cursor: url(/assets/images/cursor-white.svg), pointer;
  text-align: center;
  width: 100%;
  & > svg {
    width: 1rem;
    height: 1.0625rem;

    path {
      fill: ${({ theme }) => theme.creator.icon};
    }
  }

  @media screen and (min-width: 85.4375rem) {
    &:hover {
      & > svg {
        path {
          filter: invert(1);
        }
      }
    }
  }
`;

export const Social = styled.div<{ flex?: boolean; basicNumber?: number }>`
  align-items: center;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.border};=
  flex-wrap: nowrap;
  display: flex;
  margin: 0;
  padding: 0;
  width: ${({ basicNumber }) => (basicNumber ? "100%" : "fit-content")};
  overflow: hidden;
  background: ${({ theme }) => theme.colors.darken};
  
  & > a {
    display: flex;
    flex-basis: ${({ basicNumber }) =>
      basicNumber ? `${basicNumber}%` : "fit-content"};
  }

  & > a:not(:last-child) {
    border-right: 0.0625rem solid ${({ theme }) => theme.colors.border};
  }

  @media screen and (min-width: 85.4375rem) {
    & > a {
      &:hover {
        background: ${({ theme }) => theme.creator.icon};
      }
    }
  }
`;

export const Title = styled(CTitle)`
  margin-bottom: 0;
`;
