import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`;

export const Cover = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
`;

export const Body = styled.div`
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

export const BodyPrice = styled.div`
  display: flex;
  gap: 0.625rem;
  justify-content: space-between;
`;

export const Price = styled.div`
  width: 30%;
  min-width: 3.125rem;
`;
