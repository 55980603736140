import { FC } from "react";

export const RightArrowWhite = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.220168 0.220115C0.0792284 0.361098 5.24587e-05 0.552285 5.24611e-05 0.751634C5.24634e-05 0.950984 0.0792284 1.14217 0.220168 1.28315L3.94155 5.00454L0.220168 8.72592C0.0832225 8.86771 0.00744592 9.05762 0.00915872 9.25474C0.0108715 9.45186 0.0899368 9.64042 0.229326 9.77981C0.368715 9.91919 0.557274 9.99826 0.754393 9.99997C0.951511 10.0017 1.14142 9.92591 1.28321 9.78896L5.53611 5.53606C5.67705 5.39508 5.75623 5.20389 5.75623 5.00454C5.75623 4.80519 5.67705 4.614 5.53611 4.47302L1.28321 0.220115C1.14222 0.0791757 0.951036 5.73013e-08 0.751687 5.96785e-08C0.552338 6.20557e-08 0.36115 0.0791757 0.220168 0.220115Z"
        fill="white"
      />
    </svg>
  );
};

export const LeftArrowWhite = () => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.13281 15.0117L0.988281 8.48047C0.816406 8.26562 0.6875 8.00781 0.6875 7.75C0.6875 7.53516 0.816406 7.27734 0.988281 7.0625L7.13281 0.53125C7.51953 0.101562 8.16406 0.101562 8.59375 0.488281C9.02344 0.875 9.02344 1.51953 8.63672 1.94922L3.09375 7.79297L8.63672 13.5938C9.02344 14.0234 9.02344 14.668 8.59375 15.0547C8.16406 15.4414 7.51953 15.4414 7.13281 15.0117Z"
        fill="white"
      />
    </svg>
  );
};

export const RightArrowMini = () => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.78125 0.75L6.25 5.5C6.375 5.65625 6.46875 5.84375 6.46875 6C6.46875 6.1875 6.375 6.375 6.25 6.53125L1.78125 11.2812C1.5 11.5938 1.03125 11.5938 0.71875 11.3125C0.40625 11.0312 0.40625 10.5625 0.6875 10.25L4.6875 6L0.6875 1.78125C0.40625 1.46875 0.40625 1 0.71875 0.71875C1.03125 0.4375 1.5 0.4375 1.78125 0.75Z"
        fill="white"
      />
    </svg>
  );
};

export const DownArrow = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.264138 0.810037C0.433317 0.640909 0.662742 0.545898 0.901961 0.545898C1.14118 0.545898 1.37061 0.640909 1.53978 0.810037L6.00545 5.2757L10.4711 0.810037C10.6413 0.645702 10.8691 0.55477 11.1057 0.556825C11.3422 0.558881 11.5685 0.65376 11.7358 0.821026C11.903 0.988293 11.9979 1.21456 12 1.45111C12.002 1.68765 11.9111 1.91553 11.7468 2.08568L6.64327 7.18917C6.47409 7.3583 6.24467 7.45331 6.00545 7.45331C5.76623 7.45331 5.5368 7.3583 5.36762 7.18917L0.264138 2.08568C0.0950107 1.9165 0 1.68708 0 1.44786C0 1.20864 0.0950107 0.979216 0.264138 0.810037Z"
        fill="#F2F2F4"
      />
    </svg>
  );
};

export const DownArrowCollapseExpand: FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.1562 1.91406L7.8125 6.94141C7.63672 7.11719 7.42578 7.1875 7.25 7.1875C7.03906 7.1875 6.82812 7.11719 6.65234 6.97656L1.30859 1.91406C0.957031 1.59766 0.957031 1.07031 1.27344 0.71875C1.58984 0.367188 2.11719 0.367188 2.46875 0.683594L7.25 5.18359L11.9961 0.683594C12.3477 0.367188 12.875 0.367188 13.1914 0.71875C13.5078 1.07031 13.5078 1.59766 13.1562 1.91406Z"
        fill="white"
      />
    </svg>
  );
};

export const UpArrowCollapseExpand: FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.25 0C6.53125 0 6.78125 0.09375 6.96875 0.28125L11.9688 5.28125C12.375 5.65625 12.375 6.3125 11.9688 6.6875C11.5938 7.09375 10.9375 7.09375 10.5625 6.6875L6.25 2.40625L1.96875 6.6875C1.59375 7.09375 0.9375 7.09375 0.5625 6.6875C0.15625 6.3125 0.15625 5.65625 0.5625 5.28125L5.5625 0.28125C5.75 0.09375 6 0 6.25 0Z"
        fill="#F2F2F4"
      />
    </svg>
  );
};

export const RightArrowWhiteLong = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 1.21875L14.25 6.46875C14.4062 6.625 14.5 6.8125 14.5 7.03125C14.5 7.21875 14.4062 7.40625 14.25 7.5625L8.75 12.8125C8.46875 13.0938 7.96875 13.0938 7.6875 12.7812C7.40625 12.5 7.40625 12 7.71875 11.7188L11.875 7.78125H1.25C0.8125 7.78125 0.5 7.4375 0.5 7.03125C0.5 6.59375 0.8125 6.28125 1.25 6.28125H11.875L7.71875 2.3125C7.40625 2.03125 7.40625 1.53125 7.6875 1.25C7.96875 0.9375 8.4375 0.9375 8.75 1.21875Z"
        fill="white"
      />
    </svg>
  );
};
export const LeftArrowWhiteLong = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.28125 10.8086L0.468751 6.21484C0.332032 6.07812 0.250001 5.91406 0.250001 5.72266C0.250001 5.55859 0.332032 5.39453 0.468751 5.25781L5.28125 0.664062C5.52735 0.417968 5.96485 0.417968 6.21094 0.691406C6.45703 0.937499 6.45703 1.375 6.1836 1.62109L2.54688 5.06641L11.8438 5.06641C12.2266 5.06641 12.5 5.36719 12.5 5.72266C12.5 6.10547 12.2266 6.37891 11.8438 6.37891L2.54688 6.37891L6.18359 9.85156C6.45703 10.0977 6.45703 10.5352 6.21094 10.7812C5.96484 11.0547 5.55469 11.0547 5.28125 10.8086Z"
        fill="white"
      />
    </svg>
  );
};
