import { Chat } from '@pubnub/react-chat-components';
import { BlueMessage, LeftArrowWhite } from 'components/assets';
import { ButtonOutline } from 'components/base';
import Picker from './emoji';
import React, { FC, useEffect, useRef, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useMediaQuery } from 'hooks';
import { ChatBoxProps } from './chatBox.props';
import { Dropdown, List, Input } from 'antd';
import {
    BackIcon,
    BoxUpload,
    ChannelContainer,
    ChannelItem,
    ChannelName,
    ChatBoxContainer,
    ChatBoxContent,
    ChatBoxHeader,
    ChatBoxPopover,
    ChatContainer,
    ChatList,
    CloseIcon,
    Container,
    FileBox,
    HeaderChannel,
    HeaderContent,
    NoOutlineButton,
    SearchHeader,
    SentButton,
    ToolsBox,
    ChannelUser,
    AvatarImage,
    ChannelTitle,
    MessageCount,
    ActiveStatusText,
    ChatBoxWarning,
} from './chatBox.style';
import { INewUser, IInfoData } from 'models';
import { BREAKPOINTS } from 'utils/constants/breakpoints';
import { PinIcon, FaceIcon, XIcon, SentIcon } from 'components/assets/chatBox.icon';
import { FileIcon, IconType } from 'react-file-icon';
import { MiddleTruncate } from '..';
import UserSearchBar from '../userSearchBar';
import { PubNubProvider } from 'pubnub-react';
import { getPubNubInstance } from './service';
import { GifInput } from './gifs/GifInput';
import { useMutation, useQuery } from 'react-query';
import { createNewChannel, getChannelList, getPresignURL, sendNotifyToOther } from 'redux/apis';
import Pubnub from 'pubnub';
import Messages from './messageView';
import { IGif } from '@giphy/js-types';
import axios from 'axios';
import { SocketContext } from 'context';
import { FileCover } from '../support/supportRequest/supportRequest.style';
import { resetMessageCount, setChannel, setMessageCount, toggleVisibleChat } from 'redux/store/slices';
import RelativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { getImageUrl, mouseEnter, mouseLeave } from 'utils';

dayjs.extend(RelativeTime);

interface IChannel {
    channelName: string | null;
    createdAt?: string;
    id: string;
    name: string;
    updatedAt?: string;
    timestamp: number;
    avatar: string | null;
    to?: IInfoData;
    from?: IInfoData;
}

const ChatBox: FC<ChatBoxProps> = ({ owner, modalVisible, message, resetMessage, ...props }) => {
    const { t } = useTranslation();
    const socketCtx = useContext(SocketContext);
    const imageRef = useRef<HTMLImageElement>(null);
    const uploadRef = useRef<HTMLInputElement>(null);
    const { info } = useAppSelector((state) => state.user);
    const [isMobile] = useMediaQuery(`(min-width: ${BREAKPOINTS.md})`);
    const dispatch = useAppDispatch();

    const [pubnub, setPubnub] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [fileUpload, setFileUpload] = useState<File | null>(null);
    const [userList, setUserList] = useState<INewUser[] | null>(null);
    const [channelConnected, setChannelConnected] = useState<IChannel[]>([]);
    const [currentChannel, setCurrentChannel] = useState<string | undefined>('');
    const { messageCount } = useAppSelector((state) => state.notifications);
    const { visibleChat, channel } = useAppSelector((state) => state.modal);

    const getChannels = useQuery(['channelLists', info], () => info && getChannelList(info.id), {
        onSuccess: (res: any) => {
            const responseChannel = res?.data?.data;
            setChannelConnected(responseChannel);
            setCurrentChannel(responseChannel[0]?.id);
        },
        enabled: !!info?.id,
    });

    const createChannel = useMutation(createNewChannel);

    const currentChannelInfo = useMemo(() => {
        return channelConnected.find((item) => item.id === currentChannel);
    }, [currentChannel, channelConnected]);

    useEffect(() => {
        if (channel) {
            const mapCurrentChannel = channelConnected.find((el) => {
                const isReceiver = el?.from?.id === info?.id;
                return isReceiver ? el.to?.id === channel : el.from?.id === channel;
            });
            setCurrentChannel(mapCurrentChannel?.id);
        } else {
            setCurrentChannel('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channel]);

    const isSender = currentChannelInfo?.to?.id === info?.id;

    const userGetMessage = isSender ? currentChannelInfo?.from : currentChannelInfo?.to;

    const handleSubscribeChannel = (channelId: string) => {
        pubnub.subscribe({ channels: [channelId] });
    };

    const sendFirstMessage = (channelId: string) => {
        (pubnub as Pubnub).publish(
            {
                channel: channelId,
                message: {
                    text: `${message} ${window.location.href}`,
                },
            },
            function (status: any) {
                if (!status.error && resetMessage) {
                    resetMessage();
                }
            },
        );
    };

    const socket = socketCtx?.ioInstance;

    const handleNotification = () => {
        socket?.on('notiCreated', (data: any) => {
            const { message, type } = JSON.parse(data);
            if (type === 'chat-invite') {
                const newChannel = message.split(':')[1];
                if (pubnub) {
                    pubnub.subscribe({
                        channels: [newChannel],
                    });
                    getChannels.refetch().then((refetchRes: any) => {
                        setChannelConnected(refetchRes?.data?.data?.data);
                    });
                }
            }
            if (type === 'send-message' && !visibleChat) {
                dispatch(setMessageCount());
            }
        });
    };

    const handleSendNotifyWhenSendMessage = async (isFirstCreated: boolean, to: string, channelId?: string) => {
        const name =
            info?.username ||
            info?.fullname ||
            `${info?.walletAddress.slice(0, 6)}...${info?.walletAddress.slice(38, 42)}`;
        if (!isFirstCreated) {
            await sendNotifyToOther({
                userId: [to],
                type: 'send-message',
                msg: `${name} has send you a message`,
                sender: info?.id,
            });
        } else {
            await sendNotifyToOther({
                userId: [to],
                type: 'chat-invite',
                msg: `${name} has send you a message at channel:${channelId || currentChannel}`,
                sender: info?.id,
            });
            await sendNotifyToOther({
                userId: [to],
                type: 'send-message',
                msg: `${name} has send you a message`,
                sender: info?.id,
            });
        }
    };

    const handleCreateChannel = (userId: string, fromMarket?: boolean | undefined, message?: string) => {
        setUserList(null);
        return createChannel.mutate(
            {
                to: userId,
            },
            {
                onSuccess: (res: any) => {
                    const channelResponse = res.data.data;
                    if (channelResponse.isFirstCreated) {
                        getChannels.refetch().then((refetchRes: any) => {
                            setChannelConnected(refetchRes?.data?.data?.data);
                            if (fromMarket) {
                                sendFirstMessage(channelResponse.id as string);
                            }
                        });
                        // will create channel and send message
                        setCurrentChannel(channelResponse.id);
                        handleSubscribeChannel(channelResponse.id);
                        if (!fromMarket) {
                            (pubnub as Pubnub).publish(
                                {
                                    channel: channelResponse.id,
                                    message: {
                                        text: message,
                                    },
                                },
                                function (status: any) {
                                    if (!status.error) {
                                        setInputValue('');
                                        setLoading(false);
                                    }
                                },
                            );
                        }
                    } else {
                        const findOldChannel = channelConnected.find(
                            (item) => item.from?.id === userId || item.to?.id === userId,
                        );
                        if (findOldChannel) {
                            setCurrentChannel(findOldChannel.id);
                        }
                        sendFirstMessage(channelResponse.id as string);
                    }
                    handleSendNotifyWhenSendMessage(true, userId!, channelResponse.id);
                },
            },
        );
    };

    const handleAddListener = () => {
        pubnub.addListener({
            message: (message: any) => {},
        });
    };

    // initial pubnub
    useEffect(() => {
        if (!pubnub && info && info.id) {
            const initPubnub = getPubNubInstance({
                publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY as string,
                subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY as string,
                uuid: info.id?.toString(),
            });
            setPubnub(initPubnub);
        }
    }, [pubnub, info]);

    useEffect(() => {
        if (pubnub) {
            handleAddListener();
        }
        handleNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pubnub]);

    // send message from request to buy
    useEffect(() => {
        (async () => {
            if (!modalVisible && message && pubnub && owner && info) {
                dispatch(toggleVisibleChat(true));
                handleCreateChannel(owner.id, true);
            }
        })();
    }, [modalVisible, pubnub]); //eslint-disable-line

    const linkNoAction = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
    };

    const isExistedChannel = (toUser?: string) => {
        return channelConnected.find((channel) => channel.to?.id === toUser || toUser === channel.from?.id);
    };

    const handleSubmit = async () => {
        if (inputValue && inputValue.trim()) {
            if (!isExistedChannel(currentChannelInfo?.to?.id || currentChannel)) {
                handleCreateChannel(currentChannel!, false, inputValue.trim());
            } else {
                handleSendNotifyWhenSendMessage(false, userGetMessage!.id!);
                (pubnub as Pubnub).publish(
                    {
                        channel: currentChannel!,
                        message: {
                            text: inputValue,
                        },
                    },
                    function (status: any) {
                        if (!status.error) {
                            setInputValue('');
                        }
                    },
                );
            }
        }
    };

    const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const sendGif = (gif: IGif, query: string) => {
        const fileJSON = {
            type: 'gif',
            url: gif.embed_url,
            name: gif.slug,
        };
        if (!info?.id) {
            return;
        }
        if (!isExistedChannel(currentChannelInfo?.to?.id || currentChannel)) {
            handleCreateChannel(currentChannel!, false, JSON.stringify(fileJSON));
        } else {
            pubnub.publish({
                channel: currentChannel,
                message: {
                    text: JSON.stringify(fileJSON),
                },
            });
        }
    };

    const handleSendFile = async () => {
        setLoading(true);
        if (fileUpload) {
            const getPresignResponse = await getPresignURL({
                folder: 'chat',
                fileName: fileUpload.name,
            });

            const config = {
                method: 'PUT',
                url: getPresignResponse.data.data.urlUpload,
                data: fileUpload,
            };

            return axios.put(config.url, config.data).then((res) => {
                const fileJSON = {
                    type: 'file',
                    url: getPresignResponse.data.data.urlEndpoint,
                    name: fileUpload.name,
                    fileType: fileUpload.type,
                };
                if (!isExistedChannel(currentChannelInfo?.to?.id || currentChannel)) {
                    handleCreateChannel(currentChannel!, false, JSON.stringify(fileJSON));
                } else {
                    pubnub.publish(
                        {
                            channel: currentChannel,
                            message: {
                                text: JSON.stringify(fileJSON),
                            },
                        },
                        function (status: any) {
                            if (!status.error) {
                                setFileUpload(null);
                                setLoading(false);
                            }
                        },
                    );
                }
            });
        }
    };

    const handleUploadFile = (file: File) => {
        setFileUpload(file);
        if (file) {
            var src = URL.createObjectURL(file);
            if (!imageRef.current) return;
            if (imageRef.current?.tagName === 'IMG') {
                imageRef.current.src = src;
            }
            var preview = document.getElementById('file-ip-1-preview') as HTMLImageElement;
            if (preview?.tagName === 'IMG') {
                preview.src = src;
                preview.style.display = 'block';
            }
        }
    };

    const handleSendPubnub = () => {
        if (fileUpload) {
            handleSendFile();
        } else if (inputValue && inputValue.trim()) {
            handleSubmit();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
        }
    };

    const toggleShowChatbox = () => {
        dispatch(resetMessageCount());
        setChannel('');
        dispatch(toggleVisibleChat(!visibleChat));
    };

    if (!info?.id) return null;

    return (
        <Container>
            <ChatBoxContainer />
            <ChatBoxPopover
                defaultVisible={visibleChat}
                popupVisible={visibleChat}
                placement="topRight"
                onVisibleChange={() => {
                    toggleShowChatbox();
                }}
                visible={visibleChat}
                trigger="click"
                content={
                    <ChatContainer currentChannel={currentChannel}>
                        {!isMobile && currentChannel ? null : (
                            <ChatList
                                itemLayout="horizontal"
                                dataSource={userList ? userList : channelConnected}
                                renderItem={(channelItem: any) => {
                                    const isGetter = channelItem?.from?.id === info?.id;
                                    const getter = isGetter ? channelItem.to : channelItem.from;
                                    return (
                                        <ChannelItem
                                            onClick={() => {
                                                if (channelItem.status && !isExistedChannel(channelItem.id)) {
                                                    channelConnected.unshift({
                                                        channelName:
                                                            channelItem?.username ||
                                                            channelItem?.fullname ||
                                                            channelItem?.walletAddress,
                                                        id: channelItem?.id,
                                                        name:
                                                            channelItem?.username ||
                                                            channelItem?.fullname ||
                                                            channelItem?.walletAddress,

                                                        timestamp: new Date().getTime(),
                                                        avatar: channelItem?.avatar,
                                                    });
                                                    setChannelConnected(channelConnected);
                                                    setUserList(null);
                                                    setCurrentChannel(channelItem?.id);
                                                } else {
                                                    setCurrentChannel(channelItem.id);
                                                    setUserList(null);
                                                }
                                            }}
                                            selected={currentChannel === channelItem.id}
                                            onMouseEnter={mouseEnter}
                                            onMouseLeave={mouseLeave}
                                            key={channelItem.id}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <AvatarImage
                                                        src={
                                                            channelItem.avatar
                                                                ? getImageUrl(channelItem.avatar, '100', '100')
                                                                : '/assets/images/avatar.png'
                                                        }
                                                        alt="avatar"
                                                    />
                                                }
                                                title={
                                                    <ChannelTitle>
                                                        {channelItem.channelName || (
                                                            <MiddleTruncate
                                                                text={
                                                                    channelItem.name
                                                                        ? channelItem.name
                                                                        : channelItem.walletAddress
                                                                }
                                                                start={7}
                                                                end={7}
                                                                ellipsis="..."
                                                            />
                                                        )}
                                                    </ChannelTitle>
                                                }
                                                description={
                                                    <div>
                                                        <ActiveStatusText>
                                                            {getter?.isOnline ? (
                                                                t('chatBox.online')
                                                            ) : !getter?.offline_time ? null : (
                                                                <>
                                                                    {t('chatBox.lastOnline')}
                                                                    <b> {dayjs(getter?.offline_time).fromNow()}</b>
                                                                </>
                                                            )}
                                                        </ActiveStatusText>{' '}
                                                    </div>
                                                }
                                            />
                                        </ChannelItem>
                                    );
                                }}
                            />
                        )}
                        {currentChannel ? (
                            <div
                                style={{
                                    height: '33.625rem',
                                }}
                            >
                                <PubNubProvider client={pubnub}>
                                    <ChatBoxContent>
                                        <Chat
                                            {...{
                                                currentChannel,
                                                theme: 'dark',
                                            }}
                                        >
                                            <ChatBoxWarning>{t('chatBox.warning')}</ChatBoxWarning>
                                            <Messages />
                                            <ToolsBox>
                                                <FileBox fileUpload={!!fileUpload}>
                                                    <div>
                                                        <img
                                                            style={{
                                                                opacity: fileUpload?.type?.includes('image') ? 1 : 0,
                                                                visibility: fileUpload?.type?.includes('image')
                                                                    ? 'initial'
                                                                    : 'hidden',
                                                                height: fileUpload?.type?.includes('image')
                                                                    ? '100%'
                                                                    : 0,
                                                                display: fileUpload?.type?.includes('image')
                                                                    ? 'inherit'
                                                                    : 'none',
                                                            }}
                                                            alt="upload img"
                                                            id="file-ip-1-preview"
                                                            ref={imageRef}
                                                        />
                                                        <FileCover
                                                            hidden={!fileUpload || fileUpload?.type?.includes('image')}
                                                        >
                                                            <FileIcon type={fileUpload?.type as IconType} />
                                                        </FileCover>
                                                        <a aria-label="file-upload" href="/#" onClick={linkNoAction}>
                                                            {fileUpload?.name}
                                                        </a>
                                                    </div>
                                                    <NoOutlineButton onClick={() => setFileUpload(null)}>
                                                        <XIcon />
                                                    </NoOutlineButton>
                                                </FileBox>
                                                <BoxUpload>
                                                    <input
                                                        type="file"
                                                        onChange={(e: any) =>
                                                            // upload File
                                                            handleUploadFile(e.target.files[0])
                                                        }
                                                        ref={uploadRef}
                                                    />
                                                    <PinIcon
                                                        onMouseEnter={mouseEnter}
                                                        onMouseLeave={mouseLeave}
                                                        onClick={() => {
                                                            if (!uploadRef.current) return;
                                                            uploadRef.current.click();
                                                        }}
                                                    />
                                                </BoxUpload>
                                                <Input.TextArea
                                                    placeholder={t('chatBox.placeHolder')}
                                                    value={inputValue}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                                                        setInputValue(event.target.value)
                                                    }
                                                    onPressEnter={handlePressEnter}
                                                    onKeyDown={handleKeyDown}
                                                    autoSize={{ minRows: 1, maxRows: 4 }}
                                                />
                                                <Dropdown
                                                    trigger={['click']}
                                                    overlay={<Picker setInputValue={setInputValue} />}
                                                >
                                                    <NoOutlineButton
                                                        onMouseEnter={mouseEnter}
                                                        onMouseLeave={mouseLeave}
                                                    >
                                                        <FaceIcon />
                                                    </NoOutlineButton>
                                                </Dropdown>
                                                {process.env.REACT_APP_GIPHY_API_KEY && (
                                                    <GifInput onSelection={sendGif} />
                                                )}
                                                <SentButton disabled={loading} onClick={handleSendPubnub}>
                                                    <SentIcon />
                                                </SentButton>
                                            </ToolsBox>
                                        </Chat>
                                    </ChatBoxContent>
                                </PubNubProvider>
                            </div>
                        ) : null}
                    </ChatContainer>
                }
                title={
                    <ChatBoxHeader>
                        <HeaderContent currentChannel={currentChannel}>
                            {(isMobile && currentChannel) || !currentChannel ? (
                                <SearchHeader currentChannel={currentChannel}>
                                    <UserSearchBar userList={userList} setUserList={setUserList} />
                                    <CloseIcon
                                        onMouseEnter={mouseEnter}
                                        onMouseLeave={mouseLeave}
                                        onClick={toggleShowChatbox}
                                    >
                                        <XIcon />
                                    </CloseIcon>
                                </SearchHeader>
                            ) : (
                                <HeaderChannel currentChannel={currentChannel}>
                                    <BackIcon onClick={() => setCurrentChannel('')}>
                                        <LeftArrowWhite />
                                    </BackIcon>
                                    <ChannelName>
                                        <AvatarImage
                                            src={
                                                currentChannelInfo?.avatar
                                                    ? getImageUrl(currentChannelInfo.avatar, '100', '100')
                                                    : '/assets/images/avatar.png'
                                            }
                                            alt="avatar"
                                        />
                                        <ChannelUser>
                                            <a href="/#" aria-label="user">
                                                {currentChannelInfo
                                                    ? currentChannelInfo.channelName || (
                                                          <MiddleTruncate
                                                              text={currentChannelInfo.name}
                                                              start={currentChannelInfo.name.length - 11}
                                                              end={11}
                                                              ellipsis="..."
                                                          />
                                                      )
                                                    : 'Metaversus'}
                                            </a>
                                            <div>
                                                <ActiveStatusText>
                                                    {userGetMessage?.isOnline ? (
                                                        t('chatBox.online')
                                                    ) : (
                                                        <>
                                                            {t('chatBox.lastOnline')}
                                                            <b> {dayjs(userGetMessage?.offline_time).fromNow()}</b>
                                                        </>
                                                    )}
                                                </ActiveStatusText>{' '}
                                            </div>
                                        </ChannelUser>
                                    </ChannelName>
                                </HeaderChannel>
                            )}
                        </HeaderContent>
                    </ChatBoxHeader>
                }
            >
                <ChannelContainer>
                    <ButtonOutline>
                        <BlueMessage />
                        <span>Metaversus </span>
                        {messageCount > 0 && !visibleChat ? <MessageCount>{messageCount}</MessageCount> : null}
                    </ButtonOutline>
                </ChannelContainer>
            </ChatBoxPopover>
        </Container>
    );
};
export default React.memo(ChatBox);
