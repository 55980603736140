import React, { FC, useEffect, useRef, useState } from "react";
import { useAppSelector } from "hooks";
import {
  SearchButton,
  SearchChannelBox,
  SearchWrapper,
} from "./handleSearchUserChannel.style";
import { SearchIcon } from "components/assets/chatBox.icon";
import { UserSearchBarProps } from "./userSearchBar.props";
import instance from "redux/apis/common";
import { debounce } from "lodash";

const UserSearchBar: FC<UserSearchBarProps> = ({ userList, setUserList }) => {
  const searchRef = useRef<HTMLDivElement>(null);
  const { info } = useAppSelector((state) => state.user);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  useEffect(() => {
    if (!userList) {
      setShowSearch(false);
    }
  }, [userList]);

  const handleSearchUserChannel = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value) {
      const res = await instance.post("/user/search", {
        address: e.target.value,
      });
      if (res.data.code === 200) {
        setUserList(
          res.data.data
            .filter((el: any) => el?.id !== info?.id && !el.deletedAt)
            .map((item: any) => {
              return { ...item, name: item.username };
            })
        );
      } else {
        setUserList([]);
      }
    } else {
      setUserList(null);
    }
  };

  return (
    <SearchWrapper showSearch={showSearch} ref={searchRef}>
      {showSearch ? (
        <SearchChannelBox
          gradientClass="search-box-gradient"
          placeholder="Search..."
          onChange={debounce(handleSearchUserChannel, 300)}
          autoFocus
        />
      ) : (
        <SearchButton
          onClick={() => {
            setShowSearch(true);
          }}
        >
          <SearchIcon />
        </SearchButton>
      )}
    </SearchWrapper>
  );
};
export default React.memo(UserSearchBar);
