import { SVGProps } from "./svg.props";

const FlagChinese = (props: SVGProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M-2 20h30V0H-2v20Z" fill="#E74C3C" />
      <path
        d="m5.725 3.324.481 1.481h1.559l-1.261.918.48 1.483-1.26-.917-1.261.917.481-1.483-1.26-.918h1.558l.483-1.48Zm4.062-.775-.032.512.476.19-.497.126-.034.513-.274-.434-.496.127.327-.395-.274-.434.476.19.328-.395Zm1.751 1.661-.234.456.362.364-.505-.082-.236.457-.078-.508-.507-.08.457-.232-.078-.507.36.363.46-.232m-.527 2.081.157.488h.514l-.416.3.158.49-.414-.302-.416.301.16-.488-.415-.301h.512l.16-.489M9.784 7.855l-.034.513.477.19-.497.126-.033.512-.274-.433-.498.126.328-.393-.274-.435.477.19.329-.396"
        fill="#F1C40F"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={20} height={20} rx={10} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default FlagChinese;
