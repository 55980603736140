export const getImageUrl = (
  url: string,
  width: string,
  height: string
): string => {
  const validType = ["png", "jpg", "jpeg"];

  const isValidType = validType.find((item) => url.includes(item));

  return url.includes("https") || !isValidType
    ? url
    : `${url}/${width}x${height}`;
};
