export const Swap = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 15.25H3M3 15.25L6.5 11.75M3 15.25L6.5 18.75M3 6.5H17M17 6.5L13.5 3M17 6.5L13.5 10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
