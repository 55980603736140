import styled from "styled-components";

export const ContainerView = styled.div`
  max-width: 75rem;
  width: 100%;
  height: 100%;
  margin: 0 auto 1.875rem;
  padding-inline: 0.9375rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-inline: 2rem;
    margin: 0px auto 60px;
  }
`;
