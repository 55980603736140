import { useSearchParams } from "react-router-dom";
import { initialState, showModal } from "redux/store/slices/modalSlice";
import { useAppDispatch } from "../utils/hooks";

export const useWalletDispatch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const show = () => {
    searchParams.set("type", "wallet");
    setSearchParams(searchParams);
    dispatch(
      showModal({
        ...initialState,
        wallet: true,
      })
    );
  };

  const showRegister = () => {
    searchParams.set("type", "signup");
    setSearchParams(searchParams);
    dispatch(
      showModal({
        ...initialState,
        register: true,
      })
    );
  };

  return {
    show,
    showRegister,
  };
};
