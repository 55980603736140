import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { register } from './serviceWorkerRegistration';

// css
import './App.less';
import 'react-loading-skeleton/dist/skeleton.css';

import './i18n';

import dotenv from 'dotenv';
import { SkeletonTheme } from 'react-loading-skeleton';
import { createRoot } from 'react-dom/client';
dotenv.config();

// ReactDOM.render(
//   <Provider store={store}>
//     <SkeletonTheme
//       baseColor="rgba(255, 255, 255, 0.3)"
//       highlightColor="rgba(129, 129, 129, 0.35)"
//     >
//       <App />
//     </SkeletonTheme>
//   </Provider>,
//   document.getElementById("root")
// );

const domNode: any = document.getElementById('root');
const root = createRoot(domNode);

root.render(
    <Provider store={store}>
        <SkeletonTheme baseColor="rgba(255, 255, 255, 0.3)" highlightColor="rgba(129, 129, 129, 0.35)">
            <App />
        </SkeletonTheme>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
