import { Form as AForm } from 'antd';
import { Notification } from 'components/base';
import { useAppSelector, useWallet } from 'hooks';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { activeEmail, register, validateEmail, validateUserName } from 'redux/apis';
import { UserPaths } from 'utils/constants';
import { isValidEmail } from 'utils/validateText';
import { FormInput, FormItem, FormPasswordInput, ModalForm, PinkButtonOutlineAlone } from '../auth.style';
import { ErrorMessage } from '../forgotPassword/forgotPassword.style';
import { SignupFormProps } from './signup.props';

interface VALIDATE_FIELD {
    validateStatus: 'error' | 'success' | 'validating';
    help: string;
}

enum EField {
    email,
    userName,
}

export const SignUpConsumer: FC<SignupFormProps> = ({ onFinishFailed, authLoadingState }) => {
    const [validateUser, setValidateUser] = useState<{
        userName: VALIDATE_FIELD | {};
        email: VALIDATE_FIELD | {};
    }>({
        userName: {},
        email: {},
    });
    const [form] = AForm.useForm();
    const { t } = useTranslation('translation', { keyPrefix: 'signUp' });
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const modal = useAppSelector((state) => state.modal);
    const activeEmailMutation = useMutation(activeEmail);
    const { account } = useWallet();
    const [authLoading, setAuthLoading] = authLoadingState!;
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const setValidate = (field: EField, status: string, help: string) => {
        const validateInfo = { validateStatus: status, help: help };
        if (field === EField.userName) {
            setValidateUser((prev) => ({ ...prev, userName: validateInfo }));
            return;
        }
        if (field === EField.email) {
            setValidateUser((prev) => ({ ...prev, email: validateInfo }));
        }
    };

    const fetchUsername = (username: string) => {
        validateUserName({ username }).then((res) => {
            const error = res.data.code === 400;
            setValidateUser((prev) => ({
                ...prev,
                userName: {
                    validateStatus: error ? 'error' : 'success',
                    help: username
                        ? `${username} ${t(error ? 'form.userName.error.1' : 'form.userName.error.2')}`
                        : t('form.userName.error.0'),
                },
            }));
            if (!error) return;
        });
    };

    const fetchEmail = (email: string, inValidEmail: boolean) => {
        validateEmail({ email }).then((res) => {
            const error = res.data.code === 400;
            setValidateUser((prev) => ({
                ...prev,
                email: {
                    validateStatus: error || inValidEmail ? 'error' : 'success',
                    help: email
                        ? inValidEmail
                            ? t('form.email.invalid')
                            : `${email} ${t(error ? 'form.userName.error.1' : 'form.userName.error.2')}`
                        : t('form.email.error'),
                },
            }));
            if (!error) return;
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFetchEmail = useCallback(
        debounce((email: string, inValidEmail: boolean) => fetchEmail(email, inValidEmail), 400),
        [],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFetchUsername = useCallback(
        debounce((username: string) => fetchUsername(username), 400),
        [],
    );

    const handleCheckEmail = async (_, email) => {
        const value = email?.toLowerCase();
        const inValidEmail = !isValidEmail(value);
        setValidate(EField.email, 'validating', 'Validating...');
        if (!value) {
            setValidate(EField.email, 'error', t('form.email.error'));
            return;
        }
        debounceFetchEmail(value, inValidEmail);
    };

    const handleCheckUserName = (_, userName) => {
        setValidate(EField.userName, 'validating', 'Validating...');
        if (!userName) {
            setValidate(EField.userName, 'error', t('form.userName.error.0'));
            return;
        }
        debounceFetchUsername(userName);
    };

    const onChangeReCaptCha = useCallback(async () => {
        if (!executeRecaptcha) {
            setShowWarning(true);
            return;
        }
        const token = await executeRecaptcha('signUp');
        setCaptchaToken(token);
        setShowWarning(false);
    }, [executeRecaptcha]);

    const handleFinish = async (values: any) => {
        if (authLoading || !captchaToken) {
            setShowWarning(true);
            return;
        }
        try {
            setAuthLoading(true);
            const formattedEmail = values.email.toLowerCase().trim();
            const registerBody = {
                username: values.username,
                password: values.password,
                email: formattedEmail,
                role: 'user',
            };
            const activeAccountPath = `/${UserPaths.activeAccount}?email=${encodeURIComponent(formattedEmail)}`;

            const response = await register(account ? { ...registerBody, walletAddress: account } : registerBody);

            if (response.data.code !== 400) {
                Notification({
                    type: 'success',
                    message: 'Register success, please active you email',
                });
                activeEmailMutation.mutate({ email: formattedEmail });
                navigate(activeAccountPath);
                return;
            }
            const message: string = response.data.message.replaceAll('_', ' ');
            Notification({ type: 'error', description: message, message: 'err' });
        } catch (e: any) {
            Notification({ type: 'error', message: e.message });
        } finally {
            setAuthLoading(false);
        }
    };

    // useEffect(() => {
    //   onChangeReCaptCha();
    // }, [onChangeReCaptCha]);

    useEffect(() => {
        let isMount = true;

        if (Object.values(modal).every((item) => !item)) {
            setTimeout(() => {
                isMount && setCaptchaToken(null);
            }, 300);
        }

        return () => {
            isMount = false;
        };
    }, [modal]);

    return (
        <>
            <ModalForm form={form} onFinish={handleFinish} onFinishFailed={onFinishFailed}>
                <FormItem
                    name="email"
                    labelCol={{ span: 24 }}
                    label={t('form.email.label')}
                    rules={[
                        {
                            validator: async (rule, value) => {
                                handleCheckEmail(rule, value);
                            },
                        },
                    ]}
                    {...validateUser.email}
                >
                    <FormInput placeholder={t('form.email.label')} />
                </FormItem>
                <FormItem
                    name="username"
                    labelCol={{ span: 24 }}
                    label={t('form.userName.label')}
                    rules={[
                        {
                            validator: async (rule, value) => {
                                handleCheckUserName(rule, value);
                            },
                        },
                    ]}
                    {...validateUser.userName}
                >
                    <FormInput placeholder={t('form.userName.placeholder')} />
                </FormItem>
                <FormItem
                    name="password"
                    labelCol={{ span: 24 }}
                    label={t('form.password.label')}
                    validateTrigger={['onBlur']}
                    rules={[
                        { required: true, message: t('form.password.error.0') },
                        { min: 8, message: t('form.password.error.1') },
                    ]}
                >
                    <FormPasswordInput placeholder={t('form.password.label')} visibilityToggle={false} />
                </FormItem>
                <FormItem
                    name="confirmPassword"
                    labelCol={{ span: 24 }}
                    dependencies={['password']}
                    validateTrigger={['onBlur']}
                    hasFeedback
                    label="Confirm Password"
                    rules={[
                        { required: true, message: t('form.password.error.0') },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('form.password.error.2')));
                            },
                        }),
                    ]}
                >
                    <FormPasswordInput placeholder="Confirm Password" visibilityToggle={false} />
                </FormItem>
                {showWarning ? (
                    <ErrorMessage style={{ textAlign: 'center' }}>Captcha is loading, please try again</ErrorMessage>
                ) : null}
                <PinkButtonOutlineAlone loading={authLoading} disabled={authLoading}>
                    {t('form.submit')}
                </PinkButtonOutlineAlone>
            </ModalForm>
        </>
    );
};
