import styled from 'styled-components';
import CInput from 'components/base/input';

export const Gradient = styled.div<{
    variant: 'gradient' | 'normal';
    showGradient: boolean;
}>`
    padding: 0.0625rem;
    border-radius: 0.375rem;
    overflow: hidden;
    height: 2.8125rem;
    transition: none;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 0.375rem;
        padding: 0.0625rem;
        background: linear-gradient(90.07deg, #ffffff1a 1.39%, #ffffff1a 100.88%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        user-select: none;
        pointer-events: none;
    }

    ${({ showGradient }) =>
        showGradient
            ? `
        &:before {
          background: linear-gradient(90.07deg, 0.0625rem solid #a1b7f8  1.39%, #f2cb92 100.88%);
        }
      `
            : `
    &:before {
      background: linear-gradient(90.07deg, #ffffff1a 1.39%, #ffffff1a 100.88%);
    }
  `}
`;

export const SearchView = styled(CInput)`
    font-size: 1rem;
    line-height: 1;
    color: ${({ theme }) => theme.header.search.color};
    height: 100%;
    background: ${({ theme }) => theme.header.search.background};
    border: none;
    border-radius: 0.375rem;
    width: 100%;

    .span {
        width: 100%;
    }

    &:hover {
        border: none;
        box-shadow: none;
    }

    &.ant-input-affix-wrapper-focused {
        border: none;
        box-shadow: none;
    }

    .ant-input-prefix {
        svg {
            path {
                stroke: ${({ theme }) => theme.header.search.stroke};
            }
        }
    }

    .ant-input {
        color: ${({ theme }) => theme.header.search.color};
        background: transparent;
        font-weight: 400;
        transition: none !important;

        &::placeholder {
            color: ${({ theme }) => theme.header.search.placeholder};
            opacity: 0.5;
            line-height: unset;
            font-weight: 400;
        }
    }
`;
