import { Input } from "antd";
import styled from "styled-components";

export const AntdInputPassword = styled(Input.Password)`
  width: 100%;
  background: ${({ theme }) => theme.mint.box.background};
  border: 0.0625rem solid #3b4167;
  height: 3.375rem;
  border-radius: 0.375rem;
  color: ${({ theme }) => theme.mint.box.color};
  outline: none;
  font-size: 0.875rem;
  line-height: 1.625rem;
  padding: 0.625rem 0.875rem;
  &.ant-input-affix-wrapper {
    background: #0f0e19;
    .ant-input {
      background: #0f0e19;
    }
    &:active {
      box-shadow: none;
    }
  }
  &.ant-input-affix-wrapper-status-error {
    background: #0f0e19;
    &:has(.ant-input) {
      background: #0f0e19 !important;
    }
    .ant-input {
      background: #0f0e19 !important;
    }
    &:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    &:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      background: #0f0e19 !important;
      box-shadow: none !important;
      border-color: #ff4d4f !important;
    }
  }

  &.ant-input-status-error {
    box-shadow: none !important;
    border-color: #ff4d4f;
    &:focus {
      box-shadow: none !important;
      border-color: #ff4d4f !important;
    }
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #3b4167;
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background: #0f0e19;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.halfBlurredWhite};
    font-size: 1rem;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }

  -moz-appearance: textfield;

  &:hover {
    border-color: #3b4167;
    background: #171626;
    .ant-input {
      background: #171626;
      border-color: #3b4167;
    }
  }

  &:focus,
  .ant-input-focused {
    border-color: #3b4167;
    box-shadow: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1.25rem;
  }
  &[disabled] {
    background-color: #2a2846 !important;
    color: rgba(255, 255, 255, 0.6);
    border: 0.0625rem solid #3b4167;
    &:hover {
      border: 0.0625rem solid #3b4167;
    }
  }
`;
