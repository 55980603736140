import { Input as AInput } from 'antd';
import styled from 'styled-components';

export const Input = styled(AInput)`
    width: 100%;
    background: #f5f5f5;
    border: 0.0625rem solid ${({ theme }) => theme.settings.input.border};
    height: 3.375rem;
    border-radius: 0.375rem;
    color: #000; // Color of the input text is set to black
    outline: none;
    font-size: 0.875rem;
    line-height: 1.625rem;
    padding: 0.625rem 0.875rem;

    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        background: #f5f5f5;
    }
    &::placeholder {
        color: #a9a9a9;
        font-size: 1rem;
    }
    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: #3b4167;
    }
    &.search-input {
        background: #f5f5f5;
        .ant-input {
            background: transparent;
        }
        &:hover {
            background: #171626;
        }
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
    }

    -moz-appearance: textfield;

    &:hover,
    &:active,
    &:focus {
        background: #f5f5f5;
        border-color: ${({ theme }) => theme.settings.input.border};
        color: rgba(0, 0, 0, 0.9); // Black color with a little bit more opacity
    }

    .ant-input-focused {
        border-color: ${({ theme }) => theme.settings.input.border};
        box-shadow: none;
    }

    &.ant-input-status-error {
        &:focus {
            box-shadow: none !important;
            border-color: #ff4d4f !important;
        }
    }
    &.ant-input-affix-wrapper-has-feedback {
        .ant-input {
            background: #f5f5f5;
        }
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding-left: 1.25rem;
    }
    &[disabled] {
        background-color: #f5f5f5 !important;
        color: rgba(255, 255, 255, 0.6);
        border: 0.0625rem solid ${({ theme }) => theme.settings.input.border};
        &:hover {
            border: 0.0625rem solid ${({ theme }) => theme.settings.input.border};
        }
    }
`;
