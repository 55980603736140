import { FC, memo } from "react";
import { mouseEnter, mouseLeave } from "utils";

import { LinkProps } from "./link.props";
import { Link } from "./link.style";

const CLink: FC<LinkProps> = (props) => {
  return (
    <Link {...props} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} />
  );
};

export default memo(CLink);
