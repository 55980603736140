import styled from 'styled-components';
import { Text as CText, ButtonOutline, SocialView } from 'components/base';
import { forwardRef } from 'react';

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.8125rem;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        align-items: center;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`;

export const WalletAction = styled(
    forwardRef<any, any>(({ dark, children, ...props }, ref) => <ButtonOutline {...props}>{children}</ButtonOutline>),
)`
    ${({ dark }) => !dark && ``}
    display: inline-flex;
    align-items: center;
    width: 2.125rem;
    height: 1.875rem;
    padding: 0.5rem 0.625rem;
    gap: 0.375rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
    border-color: ${({ theme }) => theme.colors.white};
`;

export const WalletActions = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 0.625rem;
`;

export const CoverWallet = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 0.625rem;
    justify-content: space-between;
    align-items: self-end;
    & > div:first-child {
        padding-right: 68px;
    }
`;

export const WalletAddAndActions = styled.div`
    display: flex;
    align-items: center;
`;

export const DescriptionLink = styled.a`
    line-height: 1.25rem;
    color: ${({ theme }) => theme.profile.main.link};
    margin-bottom: 0;
`;

export const Text = styled(CText)`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.textOrigin.color};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
`;

export const WalletAddress = styled(
    forwardRef<any, any>(({ children, ...rest }, ref) => <Text {...rest}>{children}</Text>),
)`
    line-height: 1.25rem;
    color: rgba(242, 242, 244, 0.7);
    margin-bottom: 0;
    display: inline-block;
    cursor: url(/assets/images/cursor-white.svg), pointer;
`;

export const WalletAddWrapper = styled(ColumnWrapper)`
    ${WalletAddress} {
        max-width: 15.3125rem;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        ${WalletAddress} {
            max-width: unset;
            width: unset;
        }
    }
`;

export const WebsiteWrapper = styled(ColumnWrapper)`
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
        flex-grow: 1;
        width: 40%;
    }
`;

export const Link = styled(Text)`
    margin-bottom: 0;
    color: rgba(242, 242, 244, 0.7);
    line-height: 1.25rem;
`;

export const ProfileLink = styled(Link)`
    color: ${(g) => g.theme.colors.pink};
`;

export const Description = styled(Link)`
    line-height: 1.25rem;
    margin-bottom: 0;
`;

export const Name = styled(Text)`
    display: inline-block;
    margin-bottom: 0;
`;

export const ProfileWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem;
    flex-direction: column;
    justify-content: space-between;

    ${ColumnWrapper}:last-child {
        position: relative;
        top: 0.25rem;
    }

    @media screen and (min-width: 26.75rem) {
        flex-direction: row;
        align-items: flex-end;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
        justify-content: flex-start;
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Avatar = styled.div`
    width: 6.4375rem;
    height: 6.4375rem;
    border-radius: 50%;
    background: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    padding: 15px;
    border: 1px solid #ffffff1a;
`;

export const Detail = styled.div<{ hasChildren: boolean }>`
    display: flex;
    align-items: ${({ hasChildren }) => (hasChildren ? 'start' : 'center')};
    flex-wrap: wrap;
    gap: 1.1875rem;
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-wrap: nowrap;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        justify-content: center;
        & > div:last-child > div:first-child > div {
            width: 100%;
        }
    }
`;

export const Information = styled.div<{ borderless: boolean }>`
    display: flex;
    align-items: center;
    margin-bottom: 0.8125rem;
    border: ${({ theme, borderless }) => (borderless ? 'none' : `1px solid ${theme.colors.border}`)};
    border-radius: 0.5rem;
    padding: 1rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: 1.25rem 1.5625rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
        margin-bottom: 1.5625rem;
    }
`;

export const ShareContainer = styled(SocialView)`
    margin-top: 1.1875rem;
    justify-content: center;

    & > div {
        background: ${({ theme }) => theme.container.background};

        a > div {
            padding: 0.875rem 0.8125rem;
        }
    }

    @media screen and (min-width: 431px) {
        & > div {
            a > div {
                padding: 0.875rem 1.375rem;
            }
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        justify-content: unset;
    }
`;

export const ChildrenWrapper = styled.div``;
