import styled, { createGlobalStyle } from "styled-components";

export const CursorView = styled.div`
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  display: none;

  &::before,
  &::after {
    transition: transform 0.3s;
    position: absolute;
    content: "";
    transform: translate(-1rem, -0.8125rem) scale(0);
    border-radius: 50%;
  }

  &::before {
    top: 0;
    left: 0;
    width: 2.625rem;
    height: 2.625rem;
    background: linear-gradient(
      180deg,
      rgba(65, 81, 162, 0.3) 0%,
      rgba(221, 88, 180, 0.3) 100%
    );
  }

  &::after {
    top: 0.0625rem;
    left: 0.0625rem;
    width: 2.5rem;
    height: 2.5rem;
    background: rgba(171, 47, 133, 0.5);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.25);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

export const CursorGlobal = createGlobalStyle`
  body {
    cursor: url(/assets/images/cursor-white.svg), pointer;
    
    &.hovering ${CursorView}{
      &::before, &::after {
        transform: translate(-1rem, -0.8125rem) scale(1);
    }
  }

`;
