import { MessageList } from "@pubnub/react-chat-components";
import { FileIcon } from "components/assets";
// import { useAppSelector } from "hooks";
import React from "react";
import { mouseEnter, mouseLeave } from "utils";
import { Cursor, FileArticle, FileMessage, TextBreak } from "../chatBox.style";

const CursorWrapper: React.FC<{
  children: React.ReactNode;
  onClick?: () => void;
}> = ({ children, onClick }) => {
  return (
    <Cursor
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={onClick}
    >
      {children}
    </Cursor>
  );
};

const replaceURLs = (message: string) => {
  if (!message) return null;
  let tempUrl = "";
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const text = message.replace(urlRegex, function (url) {
    tempUrl = url;
    if (!tempUrl.match("^https?://")) {
      tempUrl = "http://" + tempUrl;
    }
    return tempUrl.replace(url, "");
  });
  return {
    text,
    tempUrl,
  };
};

const Messages = () => {
  return (
    <MessageList
      fetchMessages={100}
      messageRenderer={(mess: any) => {
        const stringfyItem = mess.message.message.text;
        try {
          JSON.parse(stringfyItem);
          const JSONfile = JSON.parse(stringfyItem);
          const JSONfileType = JSONfile.type;
          switch (JSONfileType) {
            case "gif":
              return (
                <CursorWrapper
                  onClick={() => window.open(JSONfile.url)}
                  key={mess.timetoken}
                >
                  <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    src={JSONfile.url}
                    title={JSONfile.name}
                    style={{ pointerEvents: "none" }}
                  />
                  <div className="mess-time">{mess.time}</div>
                </CursorWrapper>
              );

            case "file":
              return (
                <CursorWrapper key={mess.timetoken}>
                  <FileMessage
                    onClick={() => window.open(JSONfile.url, "mywindow")}
                  >
                    {JSONfile?.fileType.includes("image") ? (
                      JSONfile?.fileType.includes("svg") ? (
                        <svg>
                          <img alt={JSONfile.name} src={JSONfile.url} />
                        </svg>
                      ) : (
                        <img alt={JSONfile.name} src={JSONfile.url} />
                      )
                    ) : (
                      <FileIcon />
                    )}
                    <FileArticle>
                      <a
                        href={JSONfile.url}
                        target="_blank"
                        rel="noreferrer"
                        aria-label={JSONfile.name}
                      >
                        {JSONfile.name}
                      </a>
                    </FileArticle>
                  </FileMessage>
                  <div className="mess-time">{mess.time}</div>
                </CursorWrapper>
              );

            default:
              return (
                <TextBreak key={mess.timetoken}>
                  <>
                    {replaceURLs(mess.message.message.text)?.text}
                    <a
                      href={replaceURLs(mess.message.message.text)?.tempUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {replaceURLs(mess.message.message.text)?.tempUrl}{" "}
                    </a>
                  </>
                  <div className="mess-time">{mess.time}</div>
                </TextBreak>
              );
          }
        } catch (e) {
          return (
            <TextBreak key={mess.timetoken}>
              <>
                {replaceURLs(mess.message.message.text)?.text}
                <a
                  href={replaceURLs(mess.message.message.text)?.tempUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {replaceURLs(mess.message.message.text)?.tempUrl}{" "}
                </a>
              </>
              <div className="mess-time">{mess.time}</div>
            </TextBreak>
          );
        }
      }}
    />
  );
};
export default React.memo(Messages);
