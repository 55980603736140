export const SocialFacebook = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7188 8.75C17.7188 3.93359 13.8164 0.03125 9 0.03125C4.18359 0.03125 0.28125 3.93359 0.28125 8.75C0.28125 13.1094 3.44531 16.7305 7.62891 17.3633V11.2812H5.41406V8.75H7.62891V6.85156C7.62891 4.67188 8.92969 3.44141 10.8984 3.44141C11.8828 3.44141 12.8672 3.61719 12.8672 3.61719V5.76172H11.7773C10.6875 5.76172 10.3359 6.42969 10.3359 7.13281V8.75H12.7617L12.375 11.2812H10.3359V17.3633C14.5195 16.7305 17.7188 13.1094 17.7188 8.75Z"
        fill="white"
      />
    </svg>
  );
};

export const SocialTwitter = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1367 4.09375C16.1367 4.26953 16.1367 4.41016 16.1367 4.58594C16.1367 9.47266 12.4453 15.0625 5.66016 15.0625C3.55078 15.0625 1.61719 14.4648 0 13.4102C0.28125 13.4453 0.5625 13.4805 0.878906 13.4805C2.60156 13.4805 4.18359 12.8828 5.44922 11.8984C3.83203 11.8633 2.46094 10.8086 2.00391 9.33203C2.25 9.36719 2.46094 9.40234 2.70703 9.40234C3.02344 9.40234 3.375 9.33203 3.65625 9.26172C1.96875 8.91016 0.703125 7.43359 0.703125 5.64062V5.60547C1.19531 5.88672 1.79297 6.02734 2.39062 6.0625C1.37109 5.39453 0.738281 4.26953 0.738281 3.00391C0.738281 2.30078 0.914062 1.66797 1.23047 1.14062C3.05859 3.35547 5.80078 4.83203 8.85938 5.00781C8.78906 4.72656 8.75391 4.44531 8.75391 4.16406C8.75391 2.125 10.4062 0.472656 12.4453 0.472656C13.5 0.472656 14.4492 0.894531 15.1523 1.63281C15.9609 1.45703 16.7695 1.14062 17.4727 0.71875C17.1914 1.59766 16.6289 2.30078 15.8555 2.75781C16.5938 2.6875 17.332 2.47656 17.9648 2.19531C17.4727 2.93359 16.8398 3.56641 16.1367 4.09375Z"
        fill="white"
      />
    </svg>
  );
};

export const SocialInstagram = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.875 4.70703C6.625 4.70703 4.83203 6.53516 4.83203 8.75C4.83203 11 6.625 12.793 8.875 12.793C11.0898 12.793 12.918 11 12.918 8.75C12.918 6.53516 11.0898 4.70703 8.875 4.70703ZM8.875 11.3867C7.43359 11.3867 6.23828 10.2266 6.23828 8.75C6.23828 7.30859 7.39844 6.14844 8.875 6.14844C10.3164 6.14844 11.4766 7.30859 11.4766 8.75C11.4766 10.2266 10.3164 11.3867 8.875 11.3867ZM14.0078 4.56641C14.0078 5.09375 13.5859 5.51562 13.0586 5.51562C12.5312 5.51562 12.1094 5.09375 12.1094 4.56641C12.1094 4.03906 12.5312 3.61719 13.0586 3.61719C13.5859 3.61719 14.0078 4.03906 14.0078 4.56641ZM16.6797 5.51562C16.6094 4.25 16.3281 3.125 15.4141 2.21094C14.5 1.29688 13.375 1.01562 12.1094 0.945312C10.8086 0.875 6.90625 0.875 5.60547 0.945312C4.33984 1.01562 3.25 1.29688 2.30078 2.21094C1.38672 3.125 1.10547 4.25 1.03516 5.51562C0.964844 6.81641 0.964844 10.7188 1.03516 12.0195C1.10547 13.2852 1.38672 14.375 2.30078 15.3242C3.25 16.2383 4.33984 16.5195 5.60547 16.5898C6.90625 16.6602 10.8086 16.6602 12.1094 16.5898C13.375 16.5195 14.5 16.2383 15.4141 15.3242C16.3281 14.375 16.6094 13.2852 16.6797 12.0195C16.75 10.7188 16.75 6.81641 16.6797 5.51562ZM14.9922 13.3906C14.7461 14.0938 14.1836 14.6211 13.5156 14.9023C12.4609 15.3242 10 15.2188 8.875 15.2188C7.71484 15.2188 5.25391 15.3242 4.23438 14.9023C3.53125 14.6211 3.00391 14.0938 2.72266 13.3906C2.30078 12.3711 2.40625 9.91016 2.40625 8.75C2.40625 7.625 2.30078 5.16406 2.72266 4.10938C3.00391 3.44141 3.53125 2.91406 4.23438 2.63281C5.25391 2.21094 7.71484 2.31641 8.875 2.31641C10 2.31641 12.4609 2.21094 13.5156 2.63281C14.1836 2.87891 14.7109 3.44141 14.9922 4.10938C15.4141 5.16406 15.3086 7.625 15.3086 8.75C15.3086 9.91016 15.4141 12.3711 14.9922 13.3906Z"
        fill="white"
      />
    </svg>
  );
};

export const SocialLinkedin = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.625 0.875H1.08984C0.492188 0.875 0 1.40234 0 2.03516V15.5C0 16.1328 0.492188 16.625 1.08984 16.625H14.625C15.2227 16.625 15.75 16.1328 15.75 15.5V2.03516C15.75 1.40234 15.2227 0.875 14.625 0.875ZM4.74609 14.375H2.42578V6.88672H4.74609V14.375ZM3.58594 5.83203C2.8125 5.83203 2.21484 5.23438 2.21484 4.49609C2.21484 3.75781 2.8125 3.125 3.58594 3.125C4.32422 3.125 4.92188 3.75781 4.92188 4.49609C4.92188 5.23438 4.32422 5.83203 3.58594 5.83203ZM13.5 14.375H11.1445V10.7188C11.1445 9.875 11.1445 8.75 9.94922 8.75C8.71875 8.75 8.54297 9.69922 8.54297 10.6836V14.375H6.22266V6.88672H8.4375V7.90625H8.47266C8.78906 7.30859 9.5625 6.67578 10.6875 6.67578C13.043 6.67578 13.5 8.25781 13.5 10.2617V14.375Z"
        fill="white"
      />
    </svg>
  );
};
