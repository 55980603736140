import { createSlice } from "@reduxjs/toolkit";
import { Notification } from "models";

interface INotification {
  notifications: Notification[];
  translatedNotifications: Notification[];
  messageCount: number;
}

const initialState: INotification = {
  notifications: [
    {
      type: "test",
      messageId: "test",
      message: "test",
      timeStamp: "test",
      receiver: "test",
      sender: "test",
      isRead: false,
    },
  ],
  translatedNotifications: [
    {
      type: "test",
      messageId: "test",
      message: "test",
      timeStamp: "test",
      receiver: "test",
      sender: "test",
      isRead: false,
    },
  ],
  messageCount: 0,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNoti: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.notifications = action.payload;
      } else {
        const alreadyHaveNotiInList = state.notifications.some(
          (item) => item.messageId === action.payload?.messageId
        );

        if (!alreadyHaveNotiInList) {
          state.notifications = [...state.notifications, action.payload];
        }
      }
      state.notifications = state.notifications.sort(
        (a, b) =>
          new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime()
      );
      state.translatedNotifications = state.notifications.filter(
        (item) => !item.isRead
      );
    },
    updateTranslatedText: (state, action) => {
      state.translatedNotifications = state.translatedNotifications.map(
        (item, idx) => {
          return {
            ...item,
            message: action.payload[idx],
          };
        }
      );
    },
    updateItemMarkRead: (state, action) => {
      state.notifications = state.notifications.map((noti) => {
        if (noti.messageId === action.payload)
          return {
            ...noti,
            isRead: true,
          };

        return noti;
      });
      state.translatedNotifications = state.translatedNotifications.map(
        (noti) => {
          if (noti.messageId === action.payload)
            return {
              ...noti,
              isRead: true,
            };

          return noti;
        }
      );
    },
    updateMarkAll: (state) => {
      state.notifications = state.notifications.map((noti) => {
        return {
          ...noti,
          isRead: true,
        };
      });
      state.translatedNotifications = state.translatedNotifications.map(
        (noti) => {
          return {
            ...noti,
            isRead: true,
          };
        }
      );
    },
    setMessageCount: (state) => {
      state.messageCount += 1;
    },
    resetMessageCount: (state) => {
      state.messageCount = 0;
    },
  },
});

export const {
  addNoti,
  updateItemMarkRead,
  updateMarkAll,
  updateTranslatedText,
  setMessageCount,
  resetMessageCount,
} = notificationSlice.actions;
