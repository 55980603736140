import { Dropdown } from "antd";
import { GifIcon } from "components/assets/chatBox.icon";
import { useState } from "react";
import { mouseEnter, mouseLeave } from "utils";
import { GifPicker, onSelectedHandler } from "../GifPicker";
import { GifButton } from "./gifInput.style";

interface GifInputProps {
  onSelection: onSelectedHandler;
}

const GifInput = ({ onSelection }: GifInputProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      trigger={["click"]}
      overlay={<GifPicker setVisible={setVisible} onSelected={onSelection} />}
      placement="topLeft"
      visible={visible}
      onVisibleChange={() => setVisible(!visible)}
    >
      <GifButton
        style={{ marginRight: "0.625rem" }}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onClick={() => setVisible(true)}
      >
        <GifIcon />
      </GifButton>
    </Dropdown>
  );
};

export { GifInput };
