import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import relativeTime from 'dayjs/plugin/relativeTime';
import durationTime from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(durationTime);

dayjs.updateLocale('en', {
    relativeTime: {
        ...dayjs.Ls.en.relativeTime,
        s: 'a second',
        ss: '%d seconds',
    },
});

export const useCountDown = (targetDate: number, onFinish = () => {}) => {
    const countDownDate = dayjs.unix(targetDate);
    const [countDown, setCountDown] = useState(countDownDate.valueOf() - dayjs().valueOf());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate.valueOf() - dayjs().valueOf());
        }, 1000);
        if (countDownDate.valueOf() - dayjs().valueOf() <= 0) {
            clearInterval(interval);
            onFinish();
        }

        return () => clearInterval(interval);
    }, [countDownDate, onFinish]);

    const remainingTime = dayjs.duration(countDown);

    return {
        days: String(remainingTime.days()).padStart(2, '0'),
        hours: String(remainingTime.hours()).padStart(2, '0'),
        minutes: String(remainingTime.minutes()).padStart(2, '0'),
        seconds: String(remainingTime.seconds()).padStart(2, '0'),
        time: {
            days: remainingTime.days(),
            hours: remainingTime.hours(),
            minutes: remainingTime.minutes(),
            seconds: remainingTime.seconds(),
        },
        countDown: countDown,
    };
};
