import { SVGProps } from "./svg.props";

const Germany = (props: SVGProps): JSX.Element => {
  const { width, height } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 20}
      height={height || 20}
      xmlns="http://www.w3.org/2000/svg"
      id="flag-icons-de"
      viewBox="0 0 512 512"
    >
      <path fill="#ffce00" d="M0 341.3h512V512H0z" />
      <path d="M0 0h512v170.7H0z" />
      <path fill="#d00" d="M0 170.7h512v170.6H0z" />
    </svg>
  );
};

export default Germany;
