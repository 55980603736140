import styled from 'styled-components';
import clsx from 'clsx';

const Icon: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('icon', className)}
        >
            <path
                d="M0.891113 14.2603C0.891113 3.25037 3.25086 0.890625 14.2608 0.890625H18.4125C29.4225 0.890625 31.7822 3.25037 31.7822 14.2603V18.4121C31.7822 29.422 29.4225 31.7817 18.4125 31.7817H14.2608C3.25086 31.7817 0.891113 29.422 0.891113 18.4121V14.2603Z"
                fill="#22212C"
            />
            <path
                d="M7.00049 13.7656C7.00049 13.5156 7.06299 13.2969 7.18799 13.1094L9.53174 9.57812C9.81299 9.17188 10.2817 8.89062 10.7817 8.89062H23.188C23.688 8.89062 24.1567 9.17188 24.438 9.57812L26.7817 13.1094C26.9067 13.2969 27.0005 13.5156 27.0005 13.7656C27.0005 14.3906 26.4692 14.8906 25.8442 14.8906H8.12549C7.50049 14.8906 7.00049 14.3906 7.00049 13.7656ZM9.00049 15.8906H11.0005V20.8906H17.0005V15.8906H19.0005V23.3906C19.0005 24.2344 18.313 24.8906 17.5005 24.8906H10.5005C9.65674 24.8906 9.00049 24.2344 9.00049 23.3906V15.8906ZM23.0005 15.8906H25.0005V23.8906C25.0005 24.4531 24.5317 24.8906 24.0005 24.8906C23.438 24.8906 23.0005 24.4531 23.0005 23.8906V15.8906Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="test"
                    x1="5.07974"
                    y1="2.12542"
                    x2="27.3318"
                    y2="28.8279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="0.0625rem solid #a1b7f8 " />
                    <stop offset="1" stopColor="#4154B0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const Marketplace = styled(Icon)`
    & {
        transition: opacity 0.3s;
    }
`;
