import styled from "styled-components";
import { Link as RLink } from "react-router-dom";
import { LinkProps } from "./link.props";

export const Link = styled(RLink)<LinkProps>`
  display: inline-block;
  padding: 0.625rem 1.0625rem;
  color: ${({ theme }) => theme.colors.halfBlurredWhite};
  transition: all 0.3s ease-in-out;

  &:not([disabled]) {
    &:active,
    &:hover {
      background: #272634;
      color: #fff;
    }
  }

  &[disabled] {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: line-through;
  }
`;
