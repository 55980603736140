import { Table } from 'antd';
import styled from 'styled-components';

export const TransactionTable = styled(({ dataSource, ...props }) => <Table dataSource={dataSource} {...props} />)`
    background: ${({ theme }) => theme.profile.table.itemBg};
    transition: none;

    .ant-table {
        background: #fefefe;
        border: 1px solid #201f2a;
        border-radius: 0.375rem;
        position: relative;
        z-index: 10;

        .ant-table-thead > tr > th {
            border: none;
            background: none;
            color: ${({ theme }) => theme.profile.table.colorHead};
            letter-spacing: 0.125rem;
            font-size: 1rem;
            line-height: 1;
            text-transform: uppercase;
            padding: 1rem;
            transition: none;

            &::before {
                display: none;
            }
        }
        ${({ dataSource }) =>
            dataSource.length > 0 &&
            `
      .ant-table-thead > tr > th:nth-child(3) {
          text-align: center;
      }
      
      .ant-table-thead > tr > th:nth-child(4) {
          text-align: center;
      }
    `}

        .ant-table-thead > tr > th:nth-child(5) {
            text-align: center;
        }

        .ant-table-tbody > tr.ant-table-row:hover > td,
        .ant-table-tbody > tr.ant-table-placeholder:hover > td {
            background: none;
        }

        .ant-table-cell {
            background: none;
        }

        .ant-table-placeholder .ant-table-cell {
            color: #fff;
            font-size: 1rem;
            opacity: 0.6;
            height: 400px;
        }
        .ant-table-tbody > tr > td {
            border: none;
        }

        .ant-table-tbody {
            .ant-empty-normal {
                display: none;
            }
        }
    }
`;
