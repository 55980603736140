import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { create } from 'ipfs-http-client';
import { INFTMetadata } from 'type/nft';
import {
    REACT_APP_IPFS_GATEWAY_URL,
    REACT_APP_IPFS_INFURA_DEDICATED_GATEWAY_URL,
    REACT_APP_IPFS_INFURA_PROJECT_ID,
    REACT_APP_IPFS_INFURA_PROJECT_SECRET,
} from 'utils/constants';

const infura_auth =
    'Basic ' +
    Buffer.from(REACT_APP_IPFS_INFURA_PROJECT_ID + ':' + REACT_APP_IPFS_INFURA_PROJECT_SECRET).toString('base64');

const ipfsProtocol = 'ipfs://';

export const ipfs = create({
    url: REACT_APP_IPFS_GATEWAY_URL,
    headers: {
        Authorization: infura_auth,
        // "Access-Control-Allow-Origin": "*",
    },
});

export const ipfsGateWay = axios.create({
    baseURL: new URL(REACT_APP_IPFS_INFURA_DEDICATED_GATEWAY_URL).toString(),
    headers: {
        Authorization: infura_auth,
    },
});

export const uploadFile = async (file: File) => {
    return await ipfs.add(file);
};

export const uploadJsonObjectFile = async (obj: object) => {
    const blob = new Blob([JSON.stringify(obj)], {
        type: 'text/plain',
    });
    const file = new File([blob], `${Math.random() * 10}.json`);
    return uploadFile(file);
};

export const getIPFSUrlFromPath = (path: string) => {
    const url = `${ipfsProtocol}${path}`;
    return url;
};

export const getIPFSPath = (url: string) => {
    if (!url) return '';
    return url.replace(ipfsProtocol, '');
};

export const getIPFSUrl = (ipfsUrl: string) => {
    const url = ipfsUrl?.replace(ipfsProtocol, REACT_APP_IPFS_INFURA_DEDICATED_GATEWAY_URL);
    return url;
};

export const uploadNFTMetadata = async (metadata: INFTMetadata) => {
    const result = await uploadJsonObjectFile(metadata);
    return {
        ipfsUrl: getIPFSUrlFromPath(result.path),
        ...result,
    };
};

export const getFile = (
    uri: string,
    configs: AxiosRequestConfig<any> | undefined = undefined,
): Promise<AxiosResponse<any, any>> => {
    // get file from ipfs
    if (uri.startsWith(ipfsProtocol)) return ipfsGateWay.get(getIPFSPath(uri), configs);

    // if uri have more protocol, handle it here

    // default is http protocol
    return axios.get(uri, configs);
};
