import Title from 'components/base/title';
import OutlineButton from 'components/base/buttonOutline';
import { FC, memo } from 'react';
import EmptyCartProps from './emptyCart.props';
import { Container } from './emptyCart.style';
import { useTranslation } from 'react-i18next';

const EmptyCart: FC<EmptyCartProps> = ({ handleClearFilter = () => {}, hasFilter, ...props }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Title level={3}>{t('common.resultEmpty')}</Title>
            <svg width="283" height="182" viewBox="0 0 283 182" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M264.188 1.5498H18.3203C9.39313 1.5498 2.15625 8.7867 2.15625 17.7139V163.432C2.15625 172.359 9.39313 179.596 18.3203 179.596H264.188C273.115 179.596 280.352 172.359 280.352 163.432V17.7139C280.352 8.7867 273.115 1.5498 264.188 1.5498Z"
                    fill="#222534"
                />
                <path
                    d="M267.719 181.147H15.3863C11.5466 181.142 7.86556 179.615 5.15089 176.899C2.43622 174.184 0.909669 170.502 0.90625 166.662V14.4843C0.909669 10.6446 2.43622 6.96314 5.15089 4.24766C7.86556 1.53219 11.5466 0.00456139 15.3863 0H267.719C271.559 0.00455932 275.24 1.53205 277.956 4.2474C280.671 6.96275 282.198 10.6443 282.203 14.4843V166.662C282.198 170.503 280.671 174.184 277.956 176.899C275.24 179.615 271.559 181.142 267.719 181.147ZM15.3863 3.10102C12.369 3.10558 9.47674 4.30649 7.34362 6.44041C5.21051 8.57433 4.01069 11.4671 4.00727 14.4843V166.662C4.01069 169.68 5.21051 172.572 7.34362 174.706C9.47674 176.84 12.369 178.041 15.3863 178.046H267.719C270.735 178.04 273.626 176.839 275.759 174.705C277.891 172.571 279.09 169.679 279.093 166.662V14.4843C279.09 11.4671 277.89 8.57433 275.757 6.44041C273.624 4.30649 270.732 3.10558 267.714 3.10102H15.3863Z"
                    fill="0.0625rem solid #a1b7f8 "
                />
                <path
                    d="M124.488 72.3411C128.502 69.6931 133.303 69.0351 138.891 70.3671C144.06 71.5977 147.853 74.0408 150.269 77.6963C152.685 81.3519 153.353 85.4487 152.272 89.9868C151.618 92.7329 150.524 94.8277 148.989 96.2711C147.453 97.7146 144.543 99.6787 140.259 102.163C137.143 103.974 135.004 105.618 133.842 107.096C132.679 108.575 131.707 110.956 130.923 114.241L124 112.593C124.883 108.886 126.035 106.004 127.457 103.944C128.879 101.885 131.46 99.7536 135.201 97.5494L139.102 95.2335C140.188 94.6349 141.189 93.8918 142.076 93.0246C143.483 91.7156 144.478 90.0252 144.94 88.1598C145.56 85.7598 145.296 83.2165 144.195 80.9954C143.091 78.7592 140.752 77.2157 137.179 76.365C132.758 75.3127 129.319 76.1921 126.862 79.0033C125.482 80.5651 124.325 83.0152 123.392 86.3535L116.471 84.706C117.804 79.1066 120.476 74.985 124.488 72.3411ZM122.314 119.011L130.052 120.853L128.127 128.939L120.389 127.097L122.314 119.011Z"
                    fill="white"
                />
                <path
                    d="M278.32 24.8036H3.67627C3.25734 24.8036 2.85557 24.673 2.55934 24.4404C2.26311 24.2078 2.09668 23.8924 2.09668 23.5634C2.09668 23.2345 2.26311 22.9191 2.55934 22.6865C2.85557 22.4539 3.25734 22.3232 3.67627 22.3232H278.32C278.739 22.3232 279.141 22.4539 279.437 22.6865C279.734 22.9191 279.9 23.2345 279.9 23.5634C279.9 23.8924 279.734 24.2078 279.437 24.4404C279.141 24.673 278.739 24.8036 278.32 24.8036Z"
                    fill="0.0625rem solid #a1b7f8 "
                />
                <path
                    d="M20.2652 14.0015C20.5422 12.2946 19.3831 10.6862 17.6761 10.4091C15.9692 10.1321 14.3608 11.2912 14.0837 12.9981C13.8066 14.7051 14.9658 16.3135 16.6727 16.5906C18.3797 16.8676 19.9881 15.7085 20.2652 14.0015Z"
                    fill="#772D6D"
                />
                <path
                    d="M32.8229 14.1948C33.2202 12.5118 32.1778 10.8254 30.4947 10.4281C28.8117 10.0309 27.1253 11.0732 26.728 12.7563C26.3308 14.4393 27.3732 16.1257 29.0562 16.523C30.7393 16.9202 32.4257 15.8779 32.8229 14.1948Z"
                    fill="#772D6D"
                />
                <path
                    d="M45.5386 14.0006C45.8157 12.2936 44.6565 10.6852 42.9496 10.4081C41.2426 10.1311 39.6342 11.2902 39.3572 12.9972C39.0801 14.7041 40.2392 16.3125 41.9462 16.5896C43.6531 16.8667 45.2615 15.7075 45.5386 14.0006Z"
                    fill="#772D6D"
                />
                <path
                    opacity="0.1"
                    d="M183.984 80.8458C189.841 71.1976 186.767 58.6282 177.119 52.7712C167.471 46.9142 154.901 49.9876 149.044 59.6358C143.187 69.284 146.261 81.8535 155.909 87.7105C165.557 93.5674 178.127 90.494 183.984 80.8458Z"
                    fill="white"
                />
                <path
                    opacity="0.17"
                    d="M179.367 63.9775C178.997 63.9792 178.637 63.862 178.339 63.6433C178.041 63.4246 177.822 63.116 177.713 62.7629C176.991 60.4549 175.383 58.5278 173.241 57.4052C171.099 56.2826 168.599 56.0564 166.291 56.7762C165.852 56.9133 165.377 56.8705 164.97 56.6573C164.563 56.444 164.257 56.0778 164.12 55.6392C163.983 55.2006 164.026 54.7254 164.239 54.3184C164.452 53.9113 164.818 53.6055 165.257 53.4685C168.443 52.4737 171.893 52.7852 174.849 54.3344C177.806 55.8836 180.025 58.5435 181.02 61.7292C181.155 62.1656 181.111 62.6375 180.898 63.0413C180.684 63.4451 180.32 63.7477 179.883 63.8827C179.717 63.9392 179.543 63.9712 179.367 63.9775Z"
                    fill="#3A5AA6"
                />
                <path
                    d="M159.218 46.9092C154.6 48.3521 150.514 51.132 147.476 54.8972C144.438 58.6625 142.584 63.2441 142.15 68.0627C141.715 72.8813 142.719 77.7205 145.035 81.9685C147.351 86.2164 150.874 89.6824 155.159 91.9281C159.445 94.1737 164.3 95.0983 169.111 94.5848C173.921 94.0714 178.472 92.143 182.187 89.0434C185.902 85.9439 188.614 81.8124 189.981 77.1714C191.348 72.5304 191.308 67.5882 189.867 62.9698C187.932 56.776 183.616 51.6043 177.869 48.5923C172.121 45.5804 165.412 44.975 159.218 46.9092ZM172.609 89.7721C168.752 90.9743 164.625 91.0063 160.75 89.8641C156.874 88.7218 153.425 86.4566 150.836 83.3547C148.248 80.2527 146.637 76.4532 146.207 72.4361C145.777 68.4189 146.547 64.3645 148.421 60.785C150.294 57.2056 153.186 54.2616 156.732 52.3252C160.278 50.3888 164.318 49.5467 168.342 49.9054C172.366 50.2641 176.194 51.8075 179.341 54.3406C182.488 56.8736 184.814 60.2827 186.025 64.137C186.827 66.6997 187.116 69.3954 186.876 72.0699C186.636 74.7444 185.871 77.3454 184.625 79.7241C183.379 82.1029 181.676 84.2127 179.614 85.9331C177.553 87.6535 175.172 88.9507 172.609 89.7506V89.7721Z"
                    fill="0.0625rem solid #a1b7f8 "
                />
                <path
                    d="M175.61 92.1741L171.544 93.4443L177.327 111.956L181.393 110.686L175.61 92.1741Z"
                    fill="#D0DAED"
                />
                <path
                    d="M189.734 144.574C189.117 144.767 188.468 144.836 187.825 144.778C187.181 144.72 186.555 144.536 185.983 144.236C185.411 143.936 184.903 143.526 184.489 143.03C184.075 142.534 183.763 141.961 183.571 141.344L174.681 112.888C174.576 112.56 174.537 112.215 174.566 111.872C174.596 111.529 174.694 111.195 174.855 110.891C175.015 110.586 175.235 110.317 175.501 110.099C175.767 109.88 176.074 109.717 176.404 109.619L180.814 108.241C181.142 108.135 181.487 108.096 181.83 108.126C182.173 108.156 182.507 108.254 182.811 108.414C183.116 108.575 183.385 108.794 183.603 109.06C183.822 109.327 183.985 109.633 184.083 109.963L192.973 138.42C193.359 139.665 193.235 141.013 192.628 142.168C192.02 143.322 190.979 144.187 189.734 144.574Z"
                    fill="0.0625rem solid #a1b7f8 "
                />
            </svg>
            {hasFilter ? <OutlineButton onClick={handleClearFilter}>{t('common.clear')}</OutlineButton> : null}
        </Container>
    );
};
export default memo(EmptyCart);
