import { FC } from "react";

export const MetaMask: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M34.5 14.7152C34.5 2.59723 31.9028 0 19.7848 0H15.2152C3.09723 0 0.5 2.59723 0.5 14.7152V19.2848C0.5 31.4028 3.09723 34 15.2152 34H19.7848C31.9028 34 34.5 31.4028 34.5 19.2848V14.7152Z"
        fill="white"
      />
      <path
        d="M25.9886 8L18.978 13.2068L20.2745 10.1349L25.9886 8Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.8042 8L16.7584 13.2562L15.5254 10.1349L9.8042 8Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4658 20.0703L21.5986 22.9309L25.5936 24.0301L26.7421 20.1337L23.4658 20.0703Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06445 20.1337L10.2059 24.0301L14.2008 22.9309L12.3337 20.0703L9.06445 20.1337Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9755 15.2351L12.8623 16.9191L16.8291 17.0952L16.6882 12.8325L13.9755 15.2351Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8175 15.2361L19.0696 12.7842L18.978 17.0962L22.9378 16.9201L21.8175 15.2361Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2007 22.9299L16.5822 21.7673L14.5248 20.1609L14.2007 22.9299Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2104 21.7673L21.599 22.9299L21.2678 20.1609L19.2104 21.7673Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.599 22.9306L19.2104 21.7681L19.4007 23.3252L19.3795 23.9804L21.599 22.9306Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2012 22.9306L16.4206 23.9804L16.4065 23.3252L16.5827 21.7681L14.2012 22.9306Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4557 19.1332L14.4688 18.5484L15.8709 17.9072L16.4557 19.1332Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3374 19.1332L19.9222 17.9072L21.3314 18.5484L19.3374 19.1332Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2011 22.9304L14.5393 20.0698L12.334 20.1332L14.2011 22.9304Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2612 20.0698L21.5994 22.9304L23.4666 20.1332L21.2612 20.0698Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9378 16.9192L18.978 17.0953L19.3444 19.1316L19.9292 17.9056L21.3384 18.5468L22.9378 16.9192Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4687 18.5468L15.8779 17.9056L16.4557 19.1316L16.8291 17.0953L12.8623 16.9192L14.4687 18.5468Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8623 16.9192L14.5251 20.1603L14.4687 18.5468L12.8623 16.9192Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3385 18.5468L21.2681 20.1603L22.9379 16.9192L21.3385 18.5468Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.829 17.0947L16.4556 19.131L16.9206 21.5336L17.0263 18.37L16.829 17.0947Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9783 17.0947L18.7881 18.363L18.8726 21.5336L19.3447 19.131L18.9783 17.0947Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3441 19.1319L18.8721 21.5345L19.2103 21.767L21.2676 20.1606L21.3381 18.5471L19.3441 19.1319Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4688 18.5471L14.5251 20.1606L16.5825 21.767L16.9207 21.5345L16.4557 19.1319L14.4688 18.5471Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3793 23.9802L19.4005 23.325L19.2243 23.17H16.5681L16.406 23.325L16.4201 23.9802L14.2007 22.9304L14.9757 23.5645L16.5469 24.6566H19.2455L20.8237 23.5645L21.5988 22.9304L19.3793 23.9802Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2105 21.7669L18.8723 21.5344H16.9206L16.5824 21.7669L16.4062 23.3241L16.5683 23.169H19.2246L19.4007 23.3241L19.2105 21.7669Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.2844 13.545L26.8833 10.6704L25.9885 8L19.2104 13.0307L21.8174 15.236L25.5023 16.314L26.3197 15.3629L25.9674 15.1092L26.531 14.5949L26.0942 14.2567L26.6579 13.8269L26.2844 13.545Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.9165 10.6704L9.5154 13.545L9.13492 13.8269L9.69859 14.2567L9.26879 14.5949L9.83246 15.1092L9.48017 15.3629L10.2904 16.314L13.9754 15.236L16.5823 13.0307L9.80427 8L8.9165 10.6704Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5021 16.3141L21.8171 15.2361L22.9374 16.92L21.2676 20.1611L23.4659 20.1329H26.7422L25.5021 16.3141Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9754 15.2361L10.2904 16.3141L9.06445 20.1329H12.3337L14.5249 20.1611L12.8621 16.92L13.9754 15.2361Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9778 17.097L19.2103 13.0316L20.2813 10.1357H15.5254L16.5823 13.0316L16.8289 17.097L16.9134 18.3793L16.9205 21.5358H18.8721L18.8862 18.3793L18.9778 17.097Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.070458"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NoMetamask: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M32 13.8496C32 2.44445 29.5555 0 18.1504 0H13.8496C2.44445 0 0 2.44445 0 13.8496V18.1504C0 29.5555 2.44445 32 13.8496 32H18.1504C29.5555 32 32 29.5555 32 18.1504V13.8496Z"
        fill="#F2F2F4"
      />
      <path
        d="M25.1552 7.42969L17.6635 12.9939L19.0489 9.71109L25.1552 7.42969Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.86011 7.42969L15.2916 13.0466L13.974 9.71109L7.86011 7.42969Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4596 20.3281L20.4644 23.3851L24.7335 24.5596L25.9608 20.3959L22.4596 20.3281Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.06958 20.3959L8.28934 24.5596L12.5585 23.3851L10.5632 20.3281L7.06958 20.3959Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3176 15.1613L11.1279 16.9608L15.367 17.149L15.2164 12.5938L12.3176 15.1613Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6978 15.1632L17.7613 12.543L17.6635 17.151L21.895 16.9627L20.6978 15.1632Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5585 23.3848L15.1034 22.1425L12.9048 20.4258L12.5585 23.3848Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.912 22.1425L20.4645 23.3848L20.1106 20.4258L17.912 22.1425Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4645 23.3849L17.912 22.1426L18.1153 23.8066L18.0927 24.5068L20.4645 23.3849Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5586 23.3849L14.9304 24.5068L14.9153 23.8066L15.1035 22.1426L12.5586 23.3849Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9679 19.3277L12.8446 18.7028L14.343 18.0176L14.9679 19.3277Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0475 19.3277L18.6724 18.0176L20.1783 18.7028L18.0475 19.3277Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5586 23.3851L12.9201 20.3281L10.5634 20.3959L12.5586 23.3851Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.103 20.3281L20.4644 23.3851L22.4597 20.3959L20.103 20.3281Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8951 16.9609L17.6636 17.1492L18.0551 19.3252L18.68 18.0151L20.1859 18.7002L21.8951 16.9609Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8448 18.7002L14.3506 18.0151L14.968 19.3252L15.3671 17.1492L11.1281 16.9609L12.8448 18.7002Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1281 16.9609L12.905 20.4245L12.8448 18.7002L11.1281 16.9609Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1859 18.7002L20.1106 20.4245L21.8951 16.9609L20.1859 18.7002Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3671 17.1484L14.968 19.3244L15.465 21.892L15.5779 18.5113L15.3671 17.1484Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6635 17.1484L17.4602 18.5037L17.5506 21.892L18.055 19.3244L17.6635 17.1484Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.055 19.3261L17.5505 21.8936L17.9119 22.1421L20.1105 20.4254L20.1858 18.7012L18.055 19.3261Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8446 18.7012L12.9048 20.4254L15.1034 22.1421L15.4648 21.8936L14.9679 19.3261L12.8446 18.7012Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0926 24.5066L18.1152 23.8064L17.9269 23.6408H15.0883L14.9152 23.8064L14.9302 24.5066L12.5585 23.3848L13.3867 24.0624L15.0658 25.2295H17.9495L19.6361 24.0624L20.4643 23.3848L18.0926 24.5066Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.912 22.141L17.5506 21.8926H15.4649L15.1035 22.141L14.9153 23.805L15.0885 23.6394H17.927L18.1153 23.805L17.912 22.141Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4715 13.3553L26.1115 10.2833L25.1553 7.42969L17.912 12.8057L20.6979 15.1624L24.6357 16.3144L25.5091 15.2979L25.1327 15.0268L25.735 14.4772L25.2682 14.1158L25.8706 13.6565L25.4715 13.3553Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9115 10.2833L7.5515 13.3553L7.14491 13.6565L7.74726 14.1158L7.28797 14.4772L7.89032 15.0268L7.51385 15.2979L8.37973 16.3144L12.3176 15.1624L15.1035 12.8057L7.8602 7.42969L6.9115 10.2833Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6358 16.3141L20.6979 15.1621L21.8951 16.9616L20.1106 20.4252L22.4598 20.395H25.9609L24.6358 16.3141Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3176 15.1621L8.37969 16.3141L7.06958 20.395H10.5632L12.9049 20.4252L11.1279 16.9616L12.3176 15.1621Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6634 17.1519L17.9119 12.8075L19.0563 9.71289H13.974L15.1034 12.8075L15.3669 17.1519L15.4573 18.5223L15.4648 21.8954H17.5505L17.5655 18.5223L17.6634 17.1519Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0752939"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 6L7 26"
        stroke="#FF5E58"
        strokeWidth="2.85714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6L27 26"
        stroke="#FF5E58"
        strokeWidth="2.85714"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
