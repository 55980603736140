import { FC } from 'react';

export const WalletIcon: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg
            width="24"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                opacity="0.4"
                d="M22.4158 10.7125V5.09979C22.4158 3.76573 22.4158 3.09869 22.1348 2.68878C21.8892 2.33063 21.5089 2.08733 21.081 2.01449C20.591 1.93112 19.9854 2.21064 18.7741 2.76969L4.54632 9.33634C3.46606 9.83493 2.92592 10.0842 2.53032 10.4709C2.18058 10.8127 1.91362 11.2299 1.74983 11.6907C1.56458 12.2119 1.56458 12.8068 1.56458 13.9966V21.9401"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.2178 21.1405H23.2338M1.56458 15.8475V26.4335C1.56458 28.23 1.56458 29.1284 1.91422 29.8146C2.22176 30.4181 2.7125 30.9089 3.31611 31.2164C4.00231 31.5661 4.9006 31.5661 6.69719 31.5661H25.3029C27.0995 31.5661 27.9979 31.5661 28.684 31.2164C29.2876 30.9089 29.7784 30.4181 30.0859 29.8146C30.4355 29.1284 30.4355 28.23 30.4355 26.4335V15.8475C30.4355 14.0509 30.4355 13.1526 30.0859 12.4664C29.7784 11.8628 29.2876 11.372 28.684 11.0645C27.9979 10.7148 27.0995 10.7148 25.3029 10.7148H6.69719C4.90061 10.7148 4.00231 10.7148 3.31611 11.0645C2.71252 11.372 2.22176 11.8628 1.91422 12.4664C1.56458 13.1526 1.56458 14.0509 1.56458 15.8475ZM24.0198 21.1405C24.0198 21.5833 23.6606 21.9424 23.2178 21.9424C22.7749 21.9424 22.4158 21.5833 22.4158 21.1405C22.4158 20.6976 22.7749 20.3385 23.2178 20.3385C23.6606 20.3385 24.0198 20.6976 24.0198 21.1405Z"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const WalletIconDark = () => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.4"
                d="M14 7.21516V3.71581C14 2.88407 14 2.4682 13.8248 2.21263C13.6717 1.98934 13.4346 1.83765 13.1678 1.79224C12.8623 1.74026 12.4847 1.91453 11.7295 2.26308L2.85901 6.35715C2.18551 6.668 1.84875 6.82343 1.60211 7.06448C1.38406 7.27758 1.21762 7.53771 1.1155 7.82499C1 8.14995 1 8.52084 1 9.26263V14.2151"
                stroke="#fefefe"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5 13.7168H14.51M1 10.4168V17.0168C1 18.1369 1 18.697 1.21799 19.1248C1.40973 19.5011 1.71569 19.8071 2.09202 19.9988C2.51984 20.2168 3.07989 20.2168 4.2 20.2168H15.8C16.9201 20.2168 17.4802 20.2168 17.908 19.9988C18.2843 19.8071 18.5903 19.5011 18.782 19.1248C19 18.697 19 18.1369 19 17.0168V10.4168C19 9.2967 19 8.73664 18.782 8.30882C18.5903 7.9325 18.2843 7.62654 17.908 7.43479C17.4802 7.2168 16.9201 7.2168 15.8 7.2168H4.2C3.0799 7.2168 2.51984 7.2168 2.09202 7.43479C1.7157 7.62653 1.40973 7.93249 1.21799 8.30882C1 8.73664 1 9.2967 1 10.4168ZM15 13.7168C15 13.9929 14.7761 14.2168 14.5 14.2168C14.2239 14.2168 14 13.9929 14 13.7168C14 13.4407 14.2239 13.2168 14.5 13.2168C14.7761 13.2168 15 13.4407 15 13.7168Z"
                stroke="#fefefe"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
