import { AxiosResponse } from "axios";
import instance from "./common";

export const getUserID = (
  walletAdress: string
): Promise<AxiosResponse<any>> => {
  return instance.get(`/user/${walletAdress}`);
};

export const createNewChannel = (body: { to: string }) => {
  return instance.post("/channels", body);
};

export const getChannelList = (id: string) => {
  if (!id) return;
  return instance.get("/channels");
};
