import styled from 'styled-components';
import { lazy } from 'react';
import MNFTSkeleton from 'components/modules/NFTSkeleton';
import NFTItemCollection from 'components/modules/NFTItemCollection';

const CEmptyCart = lazy(() => import('components/base/emptyCart'));

export const List = styled.div<{ isEmpty?: boolean }>`
    display: grid;
    grid-template-columns: ${({ isEmpty }) => (isEmpty ? 'auto' : 'repeat(2, 1fr)')};
    gap: 1.125rem;

    @media screen and (min-width: 38.75rem) {
        grid-template-columns: ${({ isEmpty }) => (isEmpty ? 'auto' : 'repeat(3, 1fr)')};
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: ${({ isEmpty }) => (isEmpty ? 'auto' : 'repeat(2, 1fr)')};
    }

    @media screen and (min-width: 53.75rem) {
        grid-template-columns: ${({ isEmpty }) => (isEmpty ? 'auto' : 'repeat(3, 1fr)')};
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        gap: 1.125rem;
        grid-template-columns: ${({ isEmpty }) => (isEmpty ? 'auto' : 'repeat(auto-fill, minmax(14rem, 1fr))')};
    }
`;

export const NFTSkeleton = styled(MNFTSkeleton)``;
export const EmptyCart = styled(CEmptyCart)``;
export const NewNFTItemCollection = styled(NFTItemCollection)``;
