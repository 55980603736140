import {
  SocialFacebook,
  SocialInstagram,
  SocialLinkedin,
  SocialTwitter,
} from "components/assets";
import { DiscordIcon, WebIcon } from "components/assets/channel.icon";

export const SOCIAL = [
  {
    href: `https://www.facebook.com/sharer/sharer.php?u=`,
    icon: <SocialFacebook />,
  },
  {
    href: `http://twitter.com/share?url=`,
    icon: <SocialTwitter />,
  },
  {
    href: `https://www.instagram.com/?url=`,
    icon: <SocialInstagram />,
  },
  {
    href: `https://www.linkedin.com/shareArticle?mini=true&url=`,
    icon: <SocialLinkedin />,
  },
];

export const EVENTSOCIAL = [
  {
    href: `http://twitter.com/share?url=`,
    icon: <SocialTwitter />,
  },
  {
    href: `https://discord.gg/invite/`,
    icon: <DiscordIcon />,
  },
  {
    href: `https://solana.com/`,
    icon: <WebIcon />,
  },
];
