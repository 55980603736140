import styled from 'styled-components';
import { Text, CContainer, Title, Input } from 'components/base';
import CSelect, { Option } from 'components/base/select';
import { forwardRef } from 'react';
import { Form } from 'antd';

export const Container = styled(CContainer)`
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-top: 3.125rem;
        padding: 0;
    }
`;

export const BreadCrumbText = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
`;

export const Wrapper = styled(CContainer)`
    max-width: 434px;
    h1 {
        margin-bottom: 48px;
    }
    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-form-item-explain-error {
        margin-top: 5px;
    }
    .ant-select-clear {
        right: 2.25rem;
        background: ${({ theme }) => theme.support.select};
    }
    .anticon-close-circle {
        &:hover {
            color: ${({ theme }) => theme.icon.color};
            svg {
                color: ${({ theme }) => theme.icon.color};
                path {
                    fill: ${({ theme }) => theme.icon.color};
                }
            }
        }
        cursor: url(/assets/images/cursor-white.svg), pointer;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.fhd}) {
        max-width: 1110px;
    }
`;

export const FormTitle = styled(Title)`
    line-height: 1;
    text-align: center;
    margin: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
    }
`;

export const TextLabel = styled(Title)`
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.125rem;
    margin-bottom: 0.75rem;
`;

export const FormContentWrapper = styled.div`
    margin-top: 2.875rem;
    display: flex;
    flex-direction: column;
    gap: 2.6875rem;

    label.ant-form-item-required {
        &::before {
            display: none !important;
        }
    }
`;

export const ImageWrapper = styled.div``;

export const InputBox = styled.div`
    width: 100%;
    height: 3.875rem;
    background: ${({ theme }) => theme.support.select};
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: url(/assets/images/cursor-white.svg), pointer;
`;

export const InputText = styled(Text)`
    font-size: 18px;
    text-align: center;
    line-height: 18px;
    color: #c673bc;
`;

export const CustomSelect = styled(
    forwardRef<any, any>(({ children, ...props }, ref) => {
        return <CSelect {...props}>{children}</CSelect>;
    }),
)`
    border: 0.0625rem solid ${({ theme }) => theme.colors.inputBorder} !important;
    box-shadow: none !important;
    border-radius: 0.375rem !important;
    background: ${({ theme }) => theme.support.select} !important;
    .ant-select-selection-item {
        cursor: text;
    }
    .ant-select-selection-search-input {
        height: 100% !important;
        display: flex;
        align-items: center;
    }
    &:active,
    &:focus {
        box-shadow: none;
        border: none;
    }

    .ant-select-selector {
        padding: 1.025rem 1.25rem !important;
        background: ${({ theme }) => theme.support.select} !important;
        border: none !important;
    }

    .ant-select-focused {
        svg {
            path {
                fill: ${({ theme }) => theme.icon.color} !important;
            }
        }
    }

    .ant-select-arrow {
        right: 1rem;
    }
    .ant-select-dropdown-empty {
        border-radius: 0.375rem;
        border: 0.0625rem solid #201f2a;
        background: #fefefe;
        overflow: hidden;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector
        span {
        color: ${({ theme }) => theme.text.color};
    }
`;

export const CustomOption = styled(Option)``;

export const FilePreview = styled.div`
    display: grid;
    align-items: center;
    margin-bottom: 10px;
    grid-template-columns: 50px auto;
`;

export const FileName = styled.div`
    a {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-left: 10px;
        word-break: break-word;
        line-height: 22px;
        color: ${({ theme }) => theme.textOrigin.color};
    }
`;

export const FileCover = styled.div`
    max-width: 50px;

    & > svg > g > path:first-of-type {
        fill: ${({ theme }) => theme.support.fileIcon};
    }
`;

export const FormInputTicket = styled(forwardRef<any, any>(({ ...props }, ref) => <Input {...props} />))`
    &.ant-input {
        padding: 1.17rem 1.25rem;
        border-radius: 0.375rem;
        background: #0f0e19;
        border: 0.0625rem solid #3b4167;

        &:focus,
        &.ant-input-focused {
            box-shadow: none;
        }
    }

    &.ant-input-status-error:not(&.ant-input-disabled):not(&.ant-input-borderless)&.ant-input,
    &.ant-input-status-error:not(&.ant-input-disabled):not(&.ant-input-borderless)&.ant-input:hover {
        background: #0f0e19;
    }
`;

export const RequestFormItem = styled(Form.Item)`
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
    }
`;
