import styled from "styled-components";

export const Container = styled.div`
  .emoji-categories {
    button {
      cursor: url(/assets/images/cursor-white.svg), pointer;
    }
  }

  .emoji button {
    cursor: url(/assets/images/cursor-white.svg), pointer;
  }

  .skin-tones-list li label {
    cursor: url(/assets/images/cursor-white.svg), pointer !important;
  }
`;
