import { SVGProps } from "./svg.props";

const Telegram = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || "14"}
      height={height || "15"}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.78125 0.96875C3.03516 0.96875 0 4.00391 0 7.75C0 11.4961 3.03516 14.5312 6.78125 14.5312C10.5273 14.5312 13.5625 11.4961 13.5625 7.75C13.5625 4.00391 10.5273 0.96875 6.78125 0.96875ZM10.0898 5.61719L8.99609 10.8672C8.91406 11.25 8.69531 11.332 8.36719 11.168L6.67188 9.91016L5.85156 10.7031C5.76953 10.7852 5.6875 10.8672 5.52344 10.8672L5.63281 9.14453L8.77734 6.30078C8.91406 6.19141 8.75 6.10938 8.55859 6.21875L4.67578 8.67969L3.00781 8.16016C2.65234 8.05078 2.65234 7.77734 3.08984 7.61328L9.625 5.09766C9.92578 4.98828 10.1992 5.17969 10.0898 5.61719Z"
        fill={fill || "white"}
      />
    </svg>
  );
};

export default Telegram;
