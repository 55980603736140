import { FC } from "react";

export const Torus: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M34.5 14.7152C34.5 2.59723 31.9028 0 19.7848 0H15.2152C3.09723 0 0.5 2.59723 0.5 14.7152V19.2848C0.5 31.4028 3.09723 34 15.2152 34H19.7848C31.9028 34 34.5 31.4028 34.5 19.2848V14.7152Z"
        fill="#0016DE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6488 8.5C9.74412 8.5 9.01074 9.23052 9.01074 10.1317V12.0324C9.01074 12.9336 9.74412 13.6641 10.6488 13.6641H13.547V24.2971C13.547 25.1983 14.2803 25.9288 15.185 25.9288H17.0932C17.9979 25.9288 18.7313 25.1983 18.7313 24.2971V12.0462C18.7313 12.0416 18.7313 12.037 18.7313 12.0324V10.1317C18.7313 9.23052 17.998 8.5 17.0933 8.5H15.185H10.6488Z"
        fill="white"
      />
      <path
        d="M22.6195 13.6641C24.0511 13.6641 25.2117 12.5081 25.2117 11.082C25.2117 9.65601 24.0511 8.5 22.6195 8.5C21.1879 8.5 20.0273 9.65601 20.0273 11.082C20.0273 12.5081 21.1879 13.6641 22.6195 13.6641Z"
        fill="white"
      />
    </svg>
  );
};
