import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1.875rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 3.75rem;
  }

  h3 {
    font-size: 1.375rem;
    text-align: center;
  }

  svg {
    flex: none;
    flex-grow: 0;
    margin: 1.6875rem auto;
    width: 100%;
    aspect-ratio: 417 / 254;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin: 1.6875rem auto;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin: 1.6875rem auto;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      aspect-ratio: 600 / 366;
      margin: 1.6875rem 0rem;
    }
  }

  button {
    width: 9.1875rem;
    height: 3.125rem;
    margin: auto;
    border: 0.0625rem solid #ffffff;
    box-sizing: border-box;
    border-radius: 0.375rem;
  }
`;
