import { BigNumber } from 'ethers';
import { NFTType } from 'type/nft';
import { ITransactionOptions, useContractFunction } from 'utils/hooks';
import { CollectionFactoryContract } from '.';

export const useCollectionFactoryContractFunction = <T extends any[]>( // <T extends [...any]>
    method: string,
    options?: ITransactionOptions,
) => {
    const { state, send, resetState } = useContractFunction(CollectionFactoryContract, method, options);

    const handleSend = (...params: T) => {
        return send(...params);
    };

    return { state, send: handleSend, resetState };
};

// export const useCollectionFactoryCreate = (options?: ITransactionOptions) => {
//   return useCollectionFactoryContractFunction<
//     [
//       nftType: NFTType,
//       name: string,
//       symbol: string,
//       receiverRoyalty: string,
//       feeNumerator: number | BigNumber,
//     ]
//   >("create", options);
// };

export const useCollectionFactoryCreate = (options?: ITransactionOptions) => {
    const { send, state, resetState } = useContractFunction(CollectionFactoryContract, 'create', options);

    const handleSend = (
        collectionId: number,
        nftType: NFTType,
        name: string,
        symbol: string,
        maxSupply: number,
        receiverRoyalty: string,
        feeNumerator: number | BigNumber,
    ) => {
        return send(collectionId, nftType, name, symbol, maxSupply, receiverRoyalty, feeNumerator);
    };

    return {
        state,
        send: handleSend,
        resetState,
    };
};
